import React, {useState, useRef, useEffect} from "react";
import {GoogleMap, Marker, useLoadScript} from "@react-google-maps/api";
import useSupercluster from "use-supercluster";
import markerIcon from 'assets/icons/marker.svg';
import {useNavigate} from "react-router-dom";

const mapContainerStyle = {
    width: "100%",
    height: '100%',
};

const options = {
    zoomControl: true,
};

const MarkerClusterMap = ({markers: projectsMarkers}) => {
    const navigate = useNavigate();
    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    });

    const mapRef = useRef();
    const prevBoundsRef = useRef();
    const prevZoomRef = useRef();
    const [zoom, setZoom] = useState(2);
    const [bounds, setBounds] = useState(null);

    const onMapLoad = (map) => {
        mapRef.current = map;
        const initialBounds = map.getBounds()?.toJSON();
        if (initialBounds) {
            setBounds([
                initialBounds.west,
                initialBounds.south,
                initialBounds.east,
                initialBounds.north,
            ]);
            prevBoundsRef.current = [
                initialBounds.west,
                initialBounds.south,
                initialBounds.east,
                initialBounds.north,
            ];
        }
    };

    const onIdle = () => {
        const map = mapRef.current;
        const newZoom = map.getZoom();
        const newBounds = map.getBounds().toJSON();

        if (newZoom !== prevZoomRef.current) {
            setZoom(newZoom);
            prevZoomRef.current = newZoom;
        }

        if (
            newBounds.west !== prevBoundsRef.current?.[0] ||
            newBounds.south !== prevBoundsRef.current?.[1] ||
            newBounds.east !== prevBoundsRef.current?.[2] ||
            newBounds.north !== prevBoundsRef.current?.[3]
        ) {
            setBounds([
                newBounds.west,
                newBounds.south,
                newBounds.east,
                newBounds.north,
            ]);
            prevBoundsRef.current = [
                newBounds.west,
                newBounds.south,
                newBounds.east,
                newBounds.north,
            ];
        }
    };

    const points = projectsMarkers.map(project => ({
        type: "Feature",
        properties: {cluster: false, projectId: project.id},
        geometry: {
            type: "Point",
            coordinates: [
                parseFloat(project.lng),
                parseFloat(project.lat),
            ],
        },
    }));

    const {clusters, supercluster} = useSupercluster({
        points,
        bounds,
        zoom,
        options: {radius: 100, maxZoom: 15},
    });

    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading Maps...</div>;

    return (
        <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={zoom}
            center={{lat: projectsMarkers[0]?.lat || 0, lng: projectsMarkers[0]?.lng || 0}}
            options={options}
            onLoad={onMapLoad}
            onIdle={onIdle}
        >
            {clusters.map(cluster => {
                const [longitude, latitude] = cluster.geometry.coordinates;
                const {cluster: isCluster, point_count: pointCount} = cluster.properties;

                if (isCluster) {
                    return (
                        <Marker
                            key={`cluster-${cluster.id}`}
                            position={{lat: latitude, lng: longitude}}
                            icon={{
                                path: google.maps.SymbolPath.CIRCLE,
                                fillColor: "#1c78cb",
                                fillOpacity: 1,
                                strokeWeight: 0,
                                scale: Math.min(40, 10 + (pointCount / points.length) * 40),  // Размер круга зависит от количества точек в кластере
                            }}
                            label={{
                                text: `${pointCount}`,
                                color: "white",
                                fontSize: "14px",
                            }}
                            onClick={() => {
                                const expansionZoom = Math.min(
                                    supercluster.getClusterExpansionZoom(cluster.id),
                                    20
                                );
                                mapRef.current.setZoom(expansionZoom);
                                mapRef.current.panTo({lat: latitude, lng: longitude});
                            }}
                        />
                    );
                }

                return (
                    <Marker
                        key={`project-${cluster.properties.projectId}`}
                        position={{lat: latitude, lng: longitude}}
                        icon={{
                            url: markerIcon,
                            scaledSize: new window.google.maps.Size(50, 50),
                        }}
                        onClick={() => handleMarkerClick(cluster.properties.projectId)}
                    />
                );
            })}
        </GoogleMap>
    );

    function handleMarkerClick(projectId) {
        navigate(`/project/${projectId}`);
    }
};

export default MarkerClusterMap;
