import PropTypes from 'prop-types';
import React, {useContext, useEffect, useState} from 'react';
import {
    Drawer,
    Stack,
    Divider,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    FormControl,
    Box,
    styled,
    CircularProgress
} from '@mui/material';
import Iconify from '../../v2/iconify';
import {getActiveLanguageFromLS} from 'utils/index';
import globalDictionary from 'assets/translations/globalDictionary';
import {BoardContext} from './view/kanban-view';
import Image from "mui-image";
import {Link} from "react-router-dom";
import arrowUpRightFromSquareIcon from 'assets/icons/arrow-up-right-from-square.svg';
import {snackActions} from "utils/SnackBarUtils";
import {LoadingButton} from "@mui/lab";
import countries from "assets/translations/countries";
import telegramIcon from 'assets/icons/telegram.svg';
import whatsappIcon from 'assets/icons/whatsapp.svg';
import viberIcon from 'assets/icons/viber.svg';
import {getMainPhoto, getProfileData} from "utils";
import {ImageChecker} from "components";
import chatSendIcon from 'assets/icons/chat-send.svg';
import chatSendGreyIcon from 'assets/icons/chat-send-grey.svg';
import LeadService from "services/LeadService";
import {observer} from "mobx-react-lite";
import {useStore} from "../../../Providers";
import leadStatuses from "../../../assets/translations/leadStatuses";

const messengers = {
    "whatsapp": whatsappIcon,
    "telegram": telegramIcon,
    "viber": viberIcon,
};

const StyledLabel = styled('span')(({theme}) => ({
    ...theme.typography.caption,
    width: 175,
    flexShrink: 0,
    color: 'black',
    fontSize: '15px'
}));

const ChatMessage = (
    {
        message: {
            content,
            attachments,
            createdAt,
            updatedAt,
            user: {
                fname,
                lname,
                avatar,
                seoName,
                _id: userId,
            },
            commItem = {},
        },
        userId: currentUserId,
    }
) => {
    const isMyMessage = userId === currentUserId;
    const { contact = {}, logo, name: companyName } = commItem || {};
    const profileName = getProfileData('name', {fname, lname, contact, seoName, companyName});
    const userAvatar = avatar ? avatar : getMainPhoto(logo)?.url;
    const messageDate = new Date(createdAt);

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'flex-end',
            flexDirection: isMyMessage ? 'row-reverse' : 'row',
            gap: '5px',
            mb: '15px',
        }}>
            <ImageChecker
                src={userAvatar}
                alt={profileName}
                width={'40px'}
                height={'40px'}
                sx={{
                    borderRadius: '50%',
                }}/>
            <Box sx={{
                flex: 1,
                maxWidth: '320px',
            }}>
                <Typography sx={{
                    fontSize: '12px',
                    fontWeight: '700',
                    lineHeight: '16px',
                    textAlign: isMyMessage ? 'right' : 'left',

                }}>
                    {profileName}
                </Typography>
                <Box sx={{
                    backgroundColor: 'white',
                    borderRadius: '5px',
                    width: '100%',
                    padding: '8px 15px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                    mt: '7px'
                }}>
                    <Typography sx={{
                        fontSize: '12px',
                        lineHeight: '20px',
                    }}>
                        {content}
                    </Typography>
                    <Typography sx={{
                        fontSize: '12px',
                        lineHeight: '16px',
                        color: '#02122899'
                    }}>
                        {`${messageDate.toLocaleDateString()} ${messageDate.toLocaleTimeString()}`}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

const MessageList = ({messages, userId}) => {
    if (messages.loading) {
        return (
            <Box sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <CircularProgress/>
            </Box>
        )
    } else if (messages.data.length === 0) {
        return <Box sx={{flex: 1}}></Box>;
    }

    const reversedMessages = [...messages.data].reverse();

    return (
        <Box sx={{
            flex: 1,
            overflowY: 'auto',
            pr: '18px',
            display: 'flex',
            flexDirection: 'column-reverse',
        }}>
            {
                reversedMessages.map((message) => (
                    <ChatMessage {...{
                        message,
                        userId,
                    }}/>
                ))
            }
        </Box>
    );
}

const LeadChat = observer((
    {
        task
    }
) => {
    const {auth} = useStore();
    const userId = auth.user._id;
    const leadService = new LeadService();
    const [messages, setMessages] = useState(
        {
            data: [],
            loading: true,
        }
    );
    const [messageForm, setMessageForm] = useState({
        message: '',
        sending: false,
    });

    useEffect(() => {
        getMessages();
    }, []);

    const sendMessage = async () => {
        try {
            setMessageForm({...messageForm, sending: true});

            const payload = {
                content: messageForm.message,
                recipient: auth?.user?._id?.toString() !== (task?.recipient?.userId?.toString() || '')
                    ? task?.recipient?.userId?.toString()
                    : auth?.user?._id?.toString() !== (task?.sender?.userId?.toString() || '')
                        ? task?.sender?.userId?.toString()
                        : auth?.user?._id?.toString() !== (task?.building_id?.userId?.toString() || '')
                            ? task?.building_id?.userId?.toString()
                            : task?.reporter?.userId?.toString() || ''
            };

            const result = await leadService.sendMessage(task.id, payload);
            if (result.code !== 200) throw new Error('Send message error');
            setMessages({data: [...messages.data, result.data]})
            setMessageForm({...messageForm, message: ''});
        } catch (e) {
            console.error(e);
            snackActions.error('Send message error');
        } finally {
            setTimeout(() => {
                setMessageForm((prevState) => ({...prevState, sending: false}));
            }, 1000);
        }
    }

    const getMessages = async () => {
        try {
            setMessages({...messages, loading: true});
            const result = await leadService.getMessages(task.id);
            if (result.code !== 200) throw new Error('Get messages error');
            const {data} = result.data;
            setMessages({data, loading: false});
        } catch (e) {
            console.error(e);
            snackActions.error('Get messages error');
        } finally {
            setTimeout(() => {
                setMessages((prevState) => ({...prevState, loading: false}));
            }, 1000);
        }
    }

    return (
        <Box
            sx={{
                flex: 1,
                backgroundColor: '#F4F6F8',
                borderRadius: '5px',
                minHeight: '300px',
                maxHeight: '400px',
                mt: '20px',
                padding: '0px 10px 15px 10px',
                display: 'flex',
                flexDirection: 'column',
            }}>
            <MessageList {...{
                messages,
                userId,
            }}/>
            <Box sx={{
                position: 'relative',
                mt: '30px'
            }}>
                <textarea
                    value={messageForm.message}
                    onChange={(e) => setMessageForm({...messageForm, message: e.target.value})}
                    placeholder={globalDictionary.get('crm_lead_details_comment')}
                    style={{
                        width: '100%',
                        padding: '8px 40px 8px 16px',
                        borderRadius: '10px',
                        border: 'none',
                        outline: 'none',
                        height: '56px',
                        fontSize: '16px',
                        color: '#707991',
                        lineHeight: '20px',
                        resize: 'none'
                    }}
                />
                <img
                    src={messageForm?.message?.trim() !== '' ? chatSendIcon : chatSendGreyIcon}
                    alt={'send message'}
                    style={{
                        position: 'absolute',
                        right: '16px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        cursor: messageForm?.message?.trim() !== '' ?'pointer':'not-allowed',
                    }}
                    onClick={ ()=>{messageForm?.message?.trim() !== '' ? sendMessage() : () =>{}}}
                />
            </Box>
        </Box>
    );
});

export default function KanbanDetails({task, openDetails, onCloseDetails, change}) {
    const language = getActiveLanguageFromLS();

    const renderDetails = () => {
        const {
            customerContact: {email: customerEmail, phone: customerPhone},
            customerName,
            customerComments,
            brokerComments,
            proposedTerms,
            customerBudget,
            customerMessenger,
            customerCountry,

        } = task;
        const taskCreatedAt = new Date(task.createdAt).toLocaleDateString();
        const taskUpdatedAt = new Date(task.updatedAt).toLocaleDateString();
        const {moveTask, board} = useContext(BoardContext);
        const objectPhotos = task.targetType === 'complex' ? task.building_id?.complexPhotos : task.building_id?.photos;
        const objectType = task.targetType === 'complex' ? 'complex' : 'property';

        return (
            <Box sx={{
                overflowX: 'hidden',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}>
                <Box>
                    <Stack direction={'column'} gap={'20px'}>
                        <Stack
                            direction="row"
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            gap={'16px'}
                            flexWrap={'wrap'}
                        >
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                gap={'14px'}
                                sx={{
                                    backgroundColor: '#F4F6F8',
                                    borderRadius: '5px',
                                    padding: '10px 16px',
                                    flex: 1
                                }}>
                                <Iconify width={20} height={20} icon="eva:calendar-outline"/>
                                <Box>
                                    <Typography sx={{
                                        color: '#7B7B7B',
                                        fontSize: '11px',
                                    }}>
                                        {globalDictionary.get('crm_lead_details_created_at')}
                                    </Typography>
                                    <Typography sx={{
                                        fontSize: '15px',
                                        fontWeight: '700'
                                    }}>
                                        {taskCreatedAt}
                                    </Typography>
                                </Box>
                            </Stack>
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                gap={'14px'}
                                sx={{
                                    backgroundColor: '#F4F6F8',
                                    borderRadius: '5px',
                                    padding: '10px 16px',
                                    flex: 1
                                }}>
                                <Iconify width={20} height={20} icon="material-symbols:update"/>
                                <Box>
                                    <Typography sx={{
                                        color: '#7B7B7B',
                                        fontSize: '11px',
                                    }}>
                                        {globalDictionary.get('crm_lead_details_updated_at')}
                                    </Typography>
                                    <Typography sx={{
                                        fontSize: '15px',
                                        fontWeight: '700'
                                    }}>
                                        {taskUpdatedAt}
                                    </Typography>
                                </Box>
                            </Stack>
                        </Stack>

                        <FormControl variant="outlined">
                            <InputLabel id="select-lead-status-label">
                                {globalDictionary.get('status')}
                            </InputLabel>
                            <Select
                                variant="outlined"
                                labelId="select-lead-status-label"
                                id="select-lead-status"
                                label={globalDictionary.get('status')}
                                fullWidth={true}
                                value={task.status}
                                disabled={change(task)}
                                name={'status'}
                                onChange={(e) => {
                                    moveTask({
                                        destination: {droppableId: e.target.value, index: 0},
                                        source: {
                                            droppableId: task.status,
                                            index: board.columns[task.status].taskIds.indexOf(task.id),
                                        },
                                        draggableId: task.id,
                                        board,
                                    });
                                    task.status = e.target.value;
                                }}
                            >
                                {
                                    leadStatuses.getAllValueLabel(language).map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.label}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Stack>
                    <Divider sx={{
                        mt: '25px',
                        mb: '20px'
                    }}/>
                    <Stack direction={'column'}>
                        <Typography sx={{
                            color: '#7B7B7B',
                            fontSize: '11px',
                        }}>
                            {globalDictionary.get('crm_lead_details_title')}
                        </Typography>
                        <Typography sx={{
                            fontSize: '28px',
                            fontWeight: '700',
                            mt: '-5px'
                        }}>{customerName}</Typography>
                        {
                            task.building_id?._id && (
                                <Box sx={{
                                    mt: '10px',
                                    backgroundColor: '#F4F6F8',
                                    borderRadius: '5px',
                                    padding: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '15px'
                                }}>
                                    <ImageChecker
                                        src={getMainPhoto(objectPhotos)?.url}
                                        width={'50px'}
                                        height={'50px'}
                                        style={{
                                            borderRadius: '5px',
                                            objectFit: 'cover'
                                        }}/>
                                    <Box sx={{
                                        flex: 1
                                    }}>
                                        <Typography sx={{
                                            lineHeight: '150%',
                                            fontWeight: '700',
                                        }}>
                                            {task.building_id?.name}
                                        </Typography>
                                        <Typography sx={{
                                            fontSize: '11px',
                                            lineHeight: '150%',
                                        }}>
                                            {task.building_id?.address?.country ? countries.get(task.building_id?.address?.country) : '-'}
                                        </Typography>
                                    </Box>
                                    <Link to={`/${objectType}/${task.building_id?._id}`} style={{
                                        color: '#021228'
                                    }}>
                                        <Image src={arrowUpRightFromSquareIcon} width={24} height={24}/>
                                    </Link>
                                </Box>
                            )
                        }
                    </Stack>
                    <Stack direction={'column'} mt={'24px'} gap={'20px'}>
                        <Stack direction="row" alignItems={'center'} gap={'22px'}>
                            <StyledLabel>{globalDictionary.get('crm_email')}:</StyledLabel>
                            <Typography sx={{
                                color: '#00F',
                                fontSize: '15px',
                                textDecoration: 'underline'
                            }}>
                                {
                                    customerEmail ? <a href={`mailto:${customerEmail}`}>{customerEmail}</a> : '-'
                                }
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems={'center'} gap={'22px'}>
                            <StyledLabel>{globalDictionary.get('crm_phone')}:</StyledLabel>
                            <Typography sx={{
                                color: 'black',
                                fontSize: '15px',
                            }}>
                                {
                                    customerPhone ? <a href={`tel:${customerPhone}`}>{customerPhone}</a> : '-'
                                }
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems={'center'} gap={'22px'}>
                            <StyledLabel>{`${globalDictionary.get('crm_lead_details_budget')}:`}</StyledLabel>
                            <Typography sx={{
                                color: 'black',
                                fontSize: '15px',
                                fontWeight: '700'
                            }}>
                                {
                                    customerBudget ? `${customerBudget} €` : '-'
                                }
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems={'center'} gap={'22px'}>
                            <StyledLabel>{`${globalDictionary.get('crm_lead_details_country')}:`}</StyledLabel>
                            <Typography sx={{
                                color: 'black',
                                fontSize: '15px',
                            }}>
                                {customerCountry ? countries.get(customerCountry) : '-'}
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems={'center'} gap={'10px'}>
                            <StyledLabel>{`${globalDictionary.get('crm_lead_details_client_messenger')}:`}</StyledLabel>
                            {
                                customerMessenger ?
                                    <Image src={messengers[customerMessenger]} alt={'contact point'} width={30}
                                           height={30}/> : '-'
                            }
                        </Stack>
                        <Stack direction="row" alignItems={'center'} gap={'22px'}>
                            <StyledLabel>
                                {`${globalDictionary.get('crm_lead_details_client_message')}:`}
                            </StyledLabel>
                            <Typography sx={{
                                color: 'black',
                                fontSize: '15px',
                            }}>
                                {customerComments}
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems={'center'} gap={'22px'}>
                            <StyledLabel>
                                {globalDictionary.get('crm_lead_details_broker_message')}
                            </StyledLabel>
                            <Typography sx={{
                                color: 'black',
                                fontSize: '15px',
                            }}>
                                {brokerComments}
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems={'center'} gap={'22px'}>
                            <StyledLabel>
                                {globalDictionary.get('crm_lead_details_conditions')}
                            </StyledLabel>
                            <Typography sx={{
                                color: 'black',
                                fontSize: '15px',
                            }}>
                                {proposedTerms}
                            </Typography>
                        </Stack>
                    </Stack>
                    <LeadChat task={task}/>
                </Box>
            </Box>
        );
    };

    return (
        <Drawer
            open={openDetails}
            onClose={onCloseDetails}
            anchor="right"
            slotProps={{
                backdrop: {invisible: true},
            }}
            PaperProps={{
                sx: {
                    width: {
                        xs: 1,
                        sm: 480,
                    },
                    paddingTop: '64px',
                    height: '100%',
                },
            }}
        >
            <Stack
                sx={{
                    padding: '30px 25px',
                    flex: 1,
                }}
            >
                {renderDetails()}
            </Stack>
        </Drawer>
    );
}

KanbanDetails.propTypes = {
    onCloseDetails: PropTypes.func,
    openDetails:
    PropTypes.bool,
    task:
    PropTypes.object,
};
