import {observer} from 'mobx-react-lite';
import {useStore} from 'Providers';
import React, {useEffect, useState} from 'react';
import {GremService} from 'services';
import {Box, Button, Typography} from '@mui/material';
import Kanban from './kanban-view';
import {ModalWindow, Page, SendLeadModal, FinalizeLeadModal} from "components";
import Image from "mui-image";
import leadInIcon from 'assets/icons/lead-in.svg';
import leadOutIcon from 'assets/icons/lead-out.svg';
import globalDictionary from "assets/translations/globalDictionary";
import {snackActions} from "utils/SnackBarUtils";
import {useWebsocketStore} from "Providers";
import {useNavigate} from "react-router-dom";
import Switch from "@mui/material/Switch";

const KanbanBoard = observer(() => {
    const {auth} = useStore();
    const websocket = useWebsocketStore();
    const [board, setBoard] = useState({
        columns: {
            new: {
                id: 'new',
                name: 'New',
                taskIds: [],
            },
            'in progress': {
                id: 'in progress',
                name: 'In Progress',
                taskIds: [],
            },
            processing: {
                id: 'processing',
                name: 'Processing',
                taskIds: [],
            },
            agreement: {
                id: 'agreement',
                name: 'Agreement',
                taskIds: [],
            },
            closed: {
                id: 'closed',
                name: 'closed',
                taskIds: [],
            },
            'not target': {
                id: 'not target',
                name: 'Not target',
                taskIds: [],
            },
            failed: {
                id: 'failed',
                name: 'Failed',
                taskIds: [],
            },
        },
        tasks: {},
        ordered: ['new', 'in progress', 'processing', 'agreement', 'closed', 'not target', 'failed'],
    });
    const [loading, setLoading] = useState(false);
    const gremService = new GremService();
    const [openAddLeadModal, setOpenAddLeadModal] = useState(false);
    const [finalizedLead, setFinalizedLead] = useState(null);

    const [columnSize, setColumnSize] = useState(localStorage.getItem('kanbanSize') ?? 'large');
    const navigate = useNavigate();
    const isTaskMove = (task) => {
        const {reporter: {id: reporterId} = {}, status} = task || {};

        switch (task.type){
            case 'transfer':{
                return auth?.user?._id === task?.sender?.userId
            }
            case 'contract' : {
                return auth?.user?._id === task?.sender?.userId || status === 'closed'
            }
        }
        // if(task === 'transfer' && auth?.user?._id !== task?.recipient?.userId) return true;
        // return auth?.user?._id === reporterId && status === 'closed';

    };

    useEffect(() => {
        getContractLeads();
        if (websocket.notification) {
            websocket.notification.newItemShowed('crm')
        }
        return () => {
            if (websocket?.notification) {
                websocket.notification.newItemShowedBadge('crm')
            }
        }
    }, []);

    const getContractLeads = async () => {
        const response = await gremService.getLeads();
        if (response.code !== 200) {
            setLoading(false);
            return;
        }

        const leads = response.data;
        const updatedBoard = {...board};
        updatedBoard.tasks = {};
        for (let prop in updatedBoard.columns) {
            updatedBoard.columns[prop].taskIds = [];
        }
        leads.forEach((lead) => {
            updatedBoard.columns[lead.status].taskIds.push(lead._id);
            updatedBoard.tasks[lead._id.toString()] = {
                id: lead._id,
                status: lead.status,
                reporter: {
                    id: lead.recipient.userId,
                    name: `${lead.recipient.fname} ${lead.recipient.lname}`,
                    avatarUrl: lead.recipient.img,
                },
                building_id: lead.building_id,
                contractId: lead.contract,
                customerName: lead.customerName,
                customerContact: lead.customerContact,
                customerComments: lead.customerComments,
                brokerComments: lead.brokerComments,
                proposedTerms: lead.proposedTerms,
                commission: lead.commission,
                pdfDocumentLink: lead.pdfDocumentLink,
                createdAt: lead.createdAt,
                updatedAt: lead.updatedAt,
                targetType: lead.targetType,
                customerMessenger: lead.customerMessenger,
                customerBudget: lead.customerBudget,
                customerCountry: lead.customerCountry,
                isOutcome: auth.user._id.toString() === lead.sender.userId.toString(),
                sender:lead.sender,
                recipient:lead.recipient,
                type:lead.type
            };
        });

        setBoard(updatedBoard);
        setLoading(false);
    };

    const updateContractLead = async (leadId, status, isFinalize = false) => {

            if (!leadId || !status) return;

            if (status === 'closed' && !isFinalize && board.tasks[leadId]?.building_id) {
                setFinalizedLead(board.tasks[leadId]);
                return;
            }

            const response = await gremService.updateContractLead(board.tasks[leadId].contractId, leadId, {
                status,
            });
            if (response.code !== 200) {
                snackActions.error(response?.errors?.[0]?.message ??'Failed to update lead');
                return false
            }

            const updatedBoard = {...board};
            const oldStatus = updatedBoard.tasks[leadId].status;
            updatedBoard.tasks[leadId].status = status;
            const oldColumn = updatedBoard.columns[oldStatus];
            const newColumn = updatedBoard.columns[status];
            oldColumn.taskIds = oldColumn.taskIds.filter(id => id !== leadId);
            newColumn.taskIds.push(leadId);
            setBoard(updatedBoard);

            return response;

    };

    const toggleLeadModal = () => {
        if(!auth.isVerified || !auth.isCommunity){
            navigate('/my/profile/verification')
        }
        if (openAddLeadModal) getContractLeads();
        setOpenAddLeadModal(!openAddLeadModal);
    }

    const closeFinalizeModal = (isSuccess = false) => {
        //if(isSuccess) updateContractLead(finalizedLead.id, 'closed', true);
        setFinalizedLead(null);
    }
    const toggleColumnSize = () => {
        localStorage.setItem('kanbanSize', columnSize === 'large' ? 'small' : 'large')
        setColumnSize((prevSize) => (prevSize === 'large' ? 'small' : 'large'));
    };
    return (
        <Page
            title={globalDictionary.get('crm_title')}
            showBackButton={true}
            containerStyle={{
                backgroundColor: 'transparent',
            }}
        >
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '20px',
                flexWrap: 'wrap',
            }}>
                <Box sx={{
                    backgroundColor: '#F0E8D9',
                    borderRadius: '5px',
                    padding: '14px 20px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '40px',
                    maxWidth: '600px',
                    flexWrap: 'wrap',
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '15px',
                    }}>
                        <Image src={leadInIcon} alt={'lead in'} width={18} height={18}/>
                        <Typography sx={{
                            fontSize: '16px',

                        }}>
                            {globalDictionary.get('crm_lead_income')}
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '15px',
                    }}>
                        <Image src={leadOutIcon} alt={'lead out'} width={18} height={18}/>
                        <Typography sx={{
                            fontSize: '16px',

                        }}>
                            {globalDictionary.get('crm_lead_outcome')}
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '15px',
                    }}>
                        <Typography
                            alignItems={'center'}>
                            <Switch
                                name={'columnSize'}
                                checked={columnSize === 'large'}
                                onChange={toggleColumnSize}
                            />
                            {columnSize === 'large' ?
                                globalDictionary.get('large') :
                                globalDictionary.get('small')}
                        </Typography>
                    </Box>
                </Box>
                    <Button variant={'contained'} onClick={toggleLeadModal}>
                        <Typography>{globalDictionary.get('lead_modal_title')}</Typography>
                    </Button>

            </Box>

            <Box mt={'15px'}>
                <Kanban
                    board={board}
                    boardLoading={loading}
                    boardEmpty={board.length}
                    setBoard={setBoard}
                    updateContractLead={updateContractLead}
                    isTaskMove={isTaskMove}
                    columnSize={columnSize}
                />
            </Box>

            {openAddLeadModal && (
                <ModalWindow
                    closeModal={toggleLeadModal}
                    dictionaryKey={'lead_form_title'}
                    modalStyles={{
                        width: '100%',
                        maxWidth: '645px',
                    }}
                >
                    <SendLeadModal toggleModal={toggleLeadModal} type={'transfer'}/>
                </ModalWindow>
            )}

            {!!finalizedLead && (
                <ModalWindow
                    closeModal={closeFinalizeModal}
                    dictionaryKey={'crm_finalize_title'}
                    modalStyles={{
                        width: '100%',
                        maxWidth: '645px',
                    }}
                >
                    <FinalizeLeadModal lead={finalizedLead} toggleModal={closeFinalizeModal}/>
                </ModalWindow>
            )}
        </Page>
    );
});

export default KanbanBoard;
