import {History} from './NavigationHistoryUtils';
import countries from '../assets/translations/countries';
import {useEffect} from "react";
import languageStore from "../assets/translations/languageStore";

export const setUserInfoToLocalStorage = (user) => {
    if (user === null) {
        localStorage.removeItem('user');
        return;
    }

    const enabledFactoring = user.factoring
        ? Object.keys(user.factoring).filter((key) => user.factoring[key] === true)
        : ['email'];

    const userInfo = {
        userName: user.userName,
        userToken: user.userToken,
        userId: user.userId,
        commId: user.commItem ? user.commItem._id : user.commId,
        type: user.commItem ? user.commItem.type : null,
        img: user.commItem ? user.commItem.img : null,
        chats: user.chats,
        factoring: enabledFactoring,
    };

    localStorage.setItem('user', JSON.stringify(userInfo));
};

export const getActiveLanguageFromLS = () => {

    return localStorage.getItem('language') || 'eng';
};

export const setActiveLanguageToLS = (language) => {
    localStorage.setItem('language', language);
};

export const setDrawerMenuToLS = (drawerMenu) => {
    localStorage.setItem('drawerMenu', drawerMenu);
};

export const getDrawerMenuFromLS = () => {
    return localStorage.getItem('drawerMenu');
};

export const getFactoringList = () => {
    const userJSON = localStorage.getItem('user');
    const user = userJSON ? JSON.parse(userJSON) : {};

    if (user.factoring && typeof user.factoring === 'object') {
        return Array.from(user.factoring.values());
    } else {
        return [];
    }
};

export const setFactoringList = (item) => {
    const userJSON = localStorage.getItem('user');
    let user = userJSON ? JSON.parse(userJSON) : {};
    let factoringSet = new Set(user.factoring);

    if (factoringSet.has(item)) {
        factoringSet.delete(item);
    } else {
        factoringSet.add(item);
    }

    user.factoring = Array.from(factoringSet);
    localStorage.setItem('user', JSON.stringify(user));
};

export const getUserInfoFromLocalStorage = () => {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
};

export const getUserToken = () => {
    const user = getUserInfoFromLocalStorage();
    return user ? user.userToken : null;
};

export const getUserId = () => {
    const user = getUserInfoFromLocalStorage();
    return user ? user.userId : null;
};

export const checkRoles = (roles = [], initRoles = []) => {
    return roles.some((role) => initRoles.includes(role));
};

export const checkIsSpecialist = (
    roles = [],
    initRoles = [
        'Appraiser',
        'Architect',
        'Broker',
        'Buyer',
        'Developer',
        'Investor',
        'Notary',
        'Seller',
    ]
) => {
    return roles.some((role) => initRoles.includes(role));
};

export const getBase64FromFile = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
};

export const getBase64FromURL = (url, callback) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
        if (this.status === 200) {
            const reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            };
            reader.readAsDataURL(xhr.response);
        } else {
            callback('');
        }
    };
    xhr.onerror = () => {
        callback('');
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
};

export const convertImageToBase64 = async (url) => {
    try {
        // Загрузить изображение по ссылке
        const response = await fetch(url, {
            headers: {
                'x-www-access': getUserToken(),
            },
        });
        const blob = await response.blob();

        // Прочитать содержимое Blob и преобразовать в base64
        const reader = new FileReader();
        const base64Promise = new Promise((resolve, reject) => {
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
        });
        reader.readAsDataURL(blob);
        const base64 = await base64Promise;

        return base64;
    } catch (error) {
        console.error('Ошибка при конвертации изображения в base64:', error);
        throw error;
    }
};

export const navigateToSecondaryAuth = () => {
    History.navigate('/my/profile/secondary');
};

export const isCommunity = (user) => {
    const flags = user.flags;
    return flags.confirm && flags.isCommunity && flags.isVerified;
};

export const maximizerPhoto = (url = '') => {
    url = Array.isArray(url) ? url[0] : url;
    const splittedUrl = url.split('.');
    if (splittedUrl.length === 1) {
        return url;
    }

    const imageName = splittedUrl[splittedUrl.length - 2].concat('_max');
    splittedUrl[splittedUrl.length - 2] = imageName;
    return splittedUrl.join('.');
};

export const removeEmptyPropertiesFromObject = (object) => {
    return Object.fromEntries(
        Object.entries(object).filter(
            ([_, v]) => v !== '' && (Array.isArray(v) ? v.length > 0 : true) && v !== 0
        )
    );
};

export const numberFormatter = (number, {
    locale = 'en-US',
    maximumFractionDigits = 2,
} = {}) => {
    if (!number || number === 0) return 0;

    const formattedNumber = new Intl.NumberFormat(locale, {
        maximumFractionDigits,
    }).format(number);

    return isNaN(parseFloat(formattedNumber)) ? '-' : formattedNumber;
};

export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getFormattedLocation = (data, language = getActiveLanguageFromLS()) => {
    let fullAddressParts = [];
    ['country', 'city', 'street', 'buildNum'].map(async (property) => {
        if (data[property] !== undefined && data[property].trim() !== '') {
            if (property === 'country') {
                const language3Code = language === 'ru' ? 'rus' : 'eng';
                const country = countries.get(data[property], language3Code) || data[property];

                fullAddressParts.push(capitalize(country));
                return;
            }

            fullAddressParts.push(capitalize(data[property]));
        }
    });

    return fullAddressParts.join(', ');
};

export const getSupportStatusColor = (status) => {
    if (status === 'resolved') {
        return '#219653';
    } else if (status === 'fail' || status === 'cancelled') {
        return '#E5494D';
    } else {
        return '#C5A465';
    }
};

export const getStatusColor = (type, status) => {
    status = status.toLowerCase();
    type = type.toLowerCase();

    switch (type) {
        case 'support':
            return getSupportStatusColor(status);
    }
};

export const convertTimestampToRomanFormat = (timestamp) => {
    const monthNames = ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "XI", "XII"];

    const date = new Date(timestamp);
    const year = date.getFullYear();
    const monthIndex = date.getMonth();
    const romanMonth = monthNames[monthIndex];

    if (!romanMonth || !year) return null;

    return `${romanMonth} ${year}`;
}

export const checkAvailableForUser = (user, roles = []) => {
    const isCommunityUser = isCommunity(user);
    return (isCommunityUser && checkRoles(user?.commItem?.type, roles)
    );
}

export const getMainPhoto = (photos) => {
    if (!photos || !photos.length) {
        return null;
    }

    return photos.find((photo) => photo.isMain) || photos[0];
}

export const findAndAppendFilesToFormData = (data, formData, currentPath = '') => {
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const value = data[key];
            const path = currentPath ? `${currentPath}.${key}` : key;
            if (value instanceof File) {
                formData.append(path, value);
            } else if (typeof value === 'object') {
                findAndAppendFilesToFormData(value, formData, path);
            }
        }
    }

    return formData;
}

export const getContentWithoutHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
}

export const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
}

export const getProfileData = (infoType, user) => {
    try {
        const {companyName, name: companyNameOld, contact, fname, lname, seoName, avatar, commId} = user;
        const {fname: contactFname, lname: contactLname} = contact || {};

        switch (infoType) {
            case 'name':
                return companyName || companyNameOld ||
                    (contactFname && contactLname && `${contactFname} ${contactLname}`) ||
                    (fname && lname && `${fname} ${lname}`) ||
                    seoName ||
                    '';
            case 'avatar':
                return commId?.logo?.length > 0 ? commId.logo[0].url : (avatar ? avatar : '');
            default:
                return null;
        }
    } catch (error) {
        console.error(`Error getting info type "${infoType} of profile":`, error);
        return null;
    }
}

export const getFormattedPrice = (
    price,
    config
) => {
    const { leftPart = '€', rightPart = '' } = config || {};

    if (price?.from) {
        return `${leftPart}${numberFormatter(price.from)} ${rightPart}`;
    }

    if (typeof price === 'number') {
        return `${leftPart}${numberFormatter(price)} ${price.toString().length < 5 ? '${rightPart}' : ''}`;
    } else {
        return '-';
    }
}
export const isObjectEmpty = (obj) => Object.keys(obj).length === 0;

export const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
};

export const getLocale = () => {
    switch (languageStore.activeLanguage) {
        case 'rus': {
            return 'ru-RU';
        }
        case 'ukr': {
            return 'uk-UA';
        }
        case 'deu': {
            return 'de-DE';
        }
        case 'eng': {
            return 'en-GB';
        }
    }

}
