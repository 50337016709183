import {observer} from "mobx-react-lite";
import {ModalWindow} from "../../../../../components";
import {useState} from "react";
import {Box, Button, Divider, Typography} from "@mui/material";
import Image from "mui-image";
import UserConfirm from './icons/user-confirm.svg'
import CommunityVerification from './icons/community-verification.svg'
import {useFormStore} from "../../../../../components/shared/DraftForm/FormStore";
import {useNavigate} from "react-router-dom";
import {useStore} from "../../../../../Providers";
import globalDictionary from "assets/translations/globalDictionary";

const VerificationModal = observer(() => {
    const {auth} = useStore();
    const userStore = useFormStore();
    const navigate = useNavigate();
    const [open, setOpen] = useState(true);
    const closeModal = () => {
        setOpen(false)
        auth.changeProfileTab({}, 'info')
        navigate('/my/profile/info')
    }
    return (
        <>
            {open &&
                <ModalWindow closeModal={closeModal} modalStyles={{maxWidth:'950px'}}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent:'space-around',
                            width:'100%',
                            gap:'20px',
                            color:'black',
                            marginTop:'30px'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                                gap:'15px',
                                flex: 1,
                                justifyContent: 'space-between',
                                maxWidth:'340px'
                            }}
                        >

                            <Image width={'85px'} height={'85px'} src={UserConfirm}/>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'left',
                                width:'100%',
                                gap:'15px'
                            }}>
                                <Typography
                                    sx={{
                                        fontSize:'20px',
                                        lineHeight:'normal',
                                    }}
                                    variant={"body1"}>
                                {globalDictionary.get('profile_verification_user_modal_secondary_success')}
                            </Typography>
                                <Box>
                                <Typography
                                    sx={{
                                        marginRight:'20px',
                                        marginBottom:'10px'
                                    }}
                                    variant="body2">
                                    {globalDictionary.get('profile_verification_user_modal_secondary_available')}
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        textAlign:'left',
                                        gap:'5px',
                                        marginLeft:'45px',
                                    }}
                                >

                                    <Typography
                                        sx={{
                                            display: 'list-item'
                                        }}
                                        variant="body2">
                                        {globalDictionary.get('profile_verification_user_modal_secondary_buy')}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            display: 'list-item'
                                        }}
                                        variant="body2">
                                        {globalDictionary.get('profile_verification_user_modal_secondary_watch')}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            display: 'list-item'
                                        }}
                                        variant="body2">
                                        {globalDictionary.get('profile_verification_user_modal_secondary_request')}
                                    </Typography>
                                </Box>
                                </Box>
                            </Box>

                            <Button
                                fullWidth
                                sx={{
                                    marginTop:'40px'
                                }}
                                variant={'contained'}
                                onClick={closeModal}
                            >
                                {globalDictionary.get('profile_verification_user_modal_secondary_profile')}
                            </Button>
                        </Box>
                        <Box sx={{display:'flex'}}>
                            <Divider orientation="vertical" flexItem variant="middle" color={'#CFCFCF'} sx={{width: '1px'}}/>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                textAlign: 'center',
                                flex: 1,
                                gap:'15px',
                                maxWidth:'340px'
                            }}
                        >

                            <Image width={'85px'} height={'85px'} src={CommunityVerification}/>

                            <Typography variant="body1"
                                        sx={{
                                            fontSize:'20px',
                                            lineHeight:'normal'
                                        }}
                            >
                                {globalDictionary.get('profile_verification_user_modal_secondary_community_ask')}
                            </Typography>

                            <Box
                                 sx={{
                                     display: 'flex',
                                     flexDirection: 'column',
                                     height:'100%'
                                 }}
                            >

                                <Typography variant="body2">
                                    {globalDictionary.get('profile_verification_user_modal_secondary_community_need')}
                                </Typography>
                            </Box>
                            <Button
                                fullWidth
                                variant={'contained'}
                                onClick={userStore.startCommunity}
                            >
                                {globalDictionary.get('profile_verification_user_modal_secondary_community_start')}
                            </Button>
                        </Box>
                    </Box>
                </ModalWindow>
            }
        </>
    )


})

export default VerificationModal;
