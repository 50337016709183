import React, {useCallback, useState} from 'react';
import {
    Button,
    Card,
    Checkbox,
    Chip,
    Divider,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Typography
} from '@mui/material';
import {Link} from 'react-router-dom';
import {MoreVert} from '@mui/icons-material';
import style from './ComplexListItem.module.css';
import {convertTimestampToRomanFormat, getFormattedLocation, numberFormatter} from 'utils/index';
import globalDictionary from 'assets/translations/globalDictionary';
import {snackActions} from 'utils/SnackBarUtils';
import {ImageChecker} from 'components';
import {ComplexService} from "services";
import {getMainPhoto, getFormattedPrice} from "utils";

const ComplexTags = ({complexStatus, isMyComplex}) => {
    if (!isMyComplex) return false;

    return (
        <Stack
            direction="column"
            sx={{
                position: 'absolute',
                maxHeight: '200px',
                top: '15px',
                left: '15px',
                zIndex: 1000,
            }}
            spacing={1}
        >
            <Chip
                label={complexStatus.title}
                style={{
                    backgroundColor: complexStatus.backgroundColor,
                    color: complexStatus.color,
                    padding: '5px 10px',
                    borderRadius: '5px',
                    fontSize: '12px',
                    textTransform: 'uppercase',
                    border: 'none',
                }}
            />
        </Stack>
    );
}

function ComplexListItem({complex: complexItem = {}, isMyComplex = false, fetchComplexes}) {
    const complexService = new ComplexService();
    const [anchorEl, setAnchorEl] = useState(null);
    const [complex, setComplex] = useState({
        ...complexItem,
    });

    const {
        name = '',
        price = '',
        complexPhotos = [],
        contract = {},
        dateConstruction,
        address = {},
        configs = {},
        _id: complexId = '',
    } = complex || {};

    const objectLink = configs.user?.isDraft ? `/complex/form/${complex._id}` : `/complex/${complex._id}`;
    const objectImage = getMainPhoto(complexPhotos)?.url;
    const constructionDate = dateConstruction ?
        convertTimestampToRomanFormat(new Date(isNaN(dateConstruction) ? Date.parse(dateConstruction) : +dateConstruction))
        : '';

    const handleClick = useCallback((e) => setAnchorEl(e.currentTarget), []);
    const handleContextMenuClose = useCallback(() => setAnchorEl(null), []);
    const copyComplexLinkToClipboard = useCallback(() => {
        const link = `https://${process.env.REACT_APP_CABINET_URL}/complex/${complexId}`;
        navigator.clipboard.writeText(link).then(() => {
            snackActions.success(globalDictionary.get('properties_share_success'));
            handleContextMenuClose();
        });
    }, [complexId, handleContextMenuClose]);

    let complexStatus = {
        title: globalDictionary.get('object_status_not_available'),
        backgroundColor: '#EB5757',
        color: 'white'
    };

    if (configs?.user?.isAvailable) {
        complexStatus = {
            title: globalDictionary.get('object_status_available'),
            backgroundColor: '#219653',
            color: 'white'
        };
    }

    if (configs?.user?.isSold) {
        complexStatus = {
            title: globalDictionary.get('object_status_sold'),
            backgroundColor: '#EB5757',
            color: '#FFFFFF'
        };
    }

    if (configs?.user?.isDraft) {
        complexStatus = {
            title: globalDictionary.get('object_status_draft'),
            backgroundColor: '#F2C94C',
            color: '#021228'
        };
    }

    const deleteDraft = async () => {
        try {
            const result = await complexService.deleteComplexDraft(complexId);
            if (result.code !== 200) {
                if (result?.errors && result.errors.length === 0) {
                    throw new Error(globalDictionary.get('object_status_update_error'));
                } else {
                    result.errors.map(error => snackActions.error(error.message))
                }
            } else {
                fetchComplexes(true, 1);
            }
        } catch (error) {
            snackActions.error(error.message);
        }
    }

    const toggleComplexFlags = async (flag, level = 'user') => {
        try {
            const payload = {
                [flag]: !complex.configs[level][flag],
                _id: complexId
            }

            const result = await complexService.updateComplexFlags(payload);
            if (result.code !== 200) {
                if (result?.errors && result.errors.length === 0) {
                    throw new Error(globalDictionary.get('object_status_update_error'));
                } else {
                    result.errors.map(error => snackActions.error(error.message))
                }
            }
            if (result.code === 200) {
                setComplex((prev) => (
                    {
                        ...prev,
                        configs: {
                            [level]: {
                                ...configs[level],
                                [flag]: !prev.configs[level][flag]
                            }
                        },
                    }
                ));
            }
        } catch (error) {
            snackActions.error(error.message);
        }
    };

    const contextMenuItems = [
        {
            to: objectLink,
            state: {
                activeTab:'overview',
                isEdit:true
            },
            content: globalDictionary.get('properties_list_item_edit'),
            showCondition: isMyComplex,
        },
        {
            onClick: () => {
                copyComplexLinkToClipboard();
            },
            content: globalDictionary.get('properties_menu_share'),
            showCondition: !configs?.user?.isDraft && configs?.user?.isAvailable,
        },
        {
            content: (
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    gap={'5px'}
                    onClick={deleteDraft}
                >
                    {globalDictionary.get('object_list_item_delete_draft')}
                </Stack>
            ),
            showCondition: isMyComplex && configs?.user?.isDraft,
        },
        {
            content: (
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    gap={'5px'}
                    onClick={() => toggleComplexFlags('isSold')}
                >
                    <Checkbox checked={configs?.user?.isSold}/>
                    {globalDictionary.get('object_list_item_sold')}
                </Stack>
            ),
            showCondition: isMyComplex && !configs?.user?.isDraft,
        },
        {
            content: (
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    gap={'5px'}
                    onClick={() => toggleComplexFlags('isAvailable')}
                >
                    <Checkbox checked={configs?.user?.isAvailable}/>
                    {globalDictionary.get('object_list_item_available')}
                </Stack>
            ),
            showCondition: isMyComplex && !configs?.user?.isDraft,
        },
    ];

    return (
        <Card
            sx={{
                alignItems: 'space-between',
                height: '100%',
                '&:hover a > button': {
                    backgroundColor: '#D1B684'
                },
                '&:hover h2': {
                    color: '#C5A465'
                }
            }}
        >
            <ComplexTags complexStatus={complexStatus} isMyComplex={isMyComplex}/>
            <IconButton
                onClick={handleClick}
                sx={{
                    position: 'absolute',
                    top: '15px',
                    right: '15px',
                    zIndex: 1000,
                    width: '30px',
                    height: '30px',
                    backgroundColor: 'rgba(255, 255, 255, 0.60)',
                    borderRadius: '25px'
                }}
                size="large"
            >
                <MoreVert
                    sx={{
                        width: '15px',
                        height: '15px',
                        color: '#021228',
                    }}
                />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleContextMenuClose}
            >
                {contextMenuItems.map(
                    (menuItem, index) =>
                        menuItem.showCondition && (
                            <MenuItem
                                key={`complex-list-item-context-${index}`}
                                onClick={menuItem.onClick}
                                state={menuItem?.state}
                                component={menuItem.to ? Link : undefined}
                                to={menuItem.to}
                                style={{width: '100%'}}
                            >
                                {menuItem.content}
                            </MenuItem>
                        )
                )}
            </Menu>

            <Link
                to={objectLink}
                style={{
                    overflow: 'hidden',
                }}
            >
                <ImageChecker
                    src={objectImage}
                    alt={name}
                    variant={'rounded'}
                    sx={{
                        width: '100%',
                        height: 200,
                        borderRadius: 0,
                        borderBottomStyle: 'solid',
                        borderBottomWidth: '4px',
                        borderColor: isMyComplex ? complexStatus.backgroundColor : '#BDBDBD',
                    }}
                    imgProps={{
                        objectfit: 'cover',
                    }}
                    text={name}
                />
            </Link>

            <Grid container gridColumn columns={1} pt={'11px'}>
                <Grid item xs={12}>
                    <Typography
                        component={'h2'}
                        fontSize={'20px'}
                        fontWeight={700}
                        lineHeight={'24px'}
                        sx={{
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                            minHeight: '24px',
                            transition: 'color 0.1s',
                        }}
                    >
                        {name}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography fontSize={'14px'}
                                sx={{
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 1,
                                    minHeight: '16px',
                                }}
                    >
                        {getFormattedLocation(address)}
                    </Typography>
                </Grid>
                <Grid item xs={12} width={'100%'} gridRow>
                    <Divider sx={{mt: '15px'}}/>
                    <Grid container gridRow spacing={1} pt={1} minHeight={'60px'}>
                        <Grid item xs={12} md={6} lg={4}>
                            <Typography
                                variant={'subtitle1'}
                            >
                                {globalDictionary.get('cost_from')}
                            </Typography>
                            <div className={style.priceInfo}>
                                <Typography
                                    fontWeight={700}
                                    fontSize={'14px'}
                                    lineHeight={'120%'}
                                >
                                    {getFormattedPrice(price)}
                                </Typography>
                            </div>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <Typography
                                variant={'subtitle1'}>{globalDictionary.get('complex_expluatation_date')}</Typography>
                            <Typography
                                fontWeight={700}
                                fontSize={'14px'}
                                lineHeight={'120%'}
                            >
                                {
                                    constructionDate || '-'
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Typography
                                variant={'subtitle1'}>{globalDictionary.get('brokerage_commission')}</Typography>
                            {contract?.sideOneReward?.value ? (
                                <Typography fontWeight={700} fontSize={'14px'} lineHeight={'120%'}>
                                    {contract.sideOneReward.value.hasOwnProperty('$numberDecimal')
                                        ? contract.sideOneReward.value.$numberDecimal
                                        : contract.sideOneReward.value}
                                    %
                                </Typography>
                            ) : (
                                <Typography fontWeight={700}>-</Typography>
                            )}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container mt={'10px'}>
                    <Grid item xs={12}>
                        <Link to={objectLink}>
                            <Button
                                size="small"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                fullWidth
                            >
                                {globalDictionary.get('properties_list_item_more')}
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
}

export default ComplexListItem;
