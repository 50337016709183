import { History } from '../utils/NavigationHistoryUtils';
import { snackActions } from '../utils/SnackBarUtils';
import { setUserInfoToLocalStorage } from '../utils/index';
import {Error} from "../pages";
import {useNavigate} from "react-router-dom";

export const validateResponseData = (responseData) => {
  const { errors = [] } = responseData;
  if(!responseData.hasOwnProperty('errors')) return responseData
  // if ((Array.isArray(errors) && errors.length === 0)) return responseData;
  // if(Object.keys(errors).length === 0) return responseData;
  if (Array.isArray(errors)) {
    const authError = errors.some((error) => error.errorCode === 404003);

    if (authError) {
      setUserInfoToLocalStorage(null);
      snackActions.warning('Your session has expired. Please log in again.');
      History.navigate('/auth/logout');
    } else {


    }
  }

  //History.navigate('/*', { state: { code: responseData.code, title: Array.isArray(responseData.errors) ? responseData.errors[0].message:responseData.errors.message } });
  return responseData;
};
