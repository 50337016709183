// src/components/Badge.js
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { observer } from "mobx-react-lite";
import PropTypes from 'prop-types';
import { useWebsocketStore } from "../../../Providers";

const Badge = observer(({ path, subPath, size, variant, position, content, badgeStyle, contentStyle }) => {
    const websocket = useWebsocketStore();
    const count = websocket?.notification?.badges?.get(path) || 0;
    const subCount = subPath ? websocket?.notification?.counter?.get(subPath) || 0 : 0;

    const theme = useTheme();
    // console.log(path,websocket?.notification?.badges,count)
    // console.log(subPath,websocket?.notification?.counter,subCount)
    // Если не передан content и оба счетчика равны 0 или меньше, возвращаем null
    if (count <= 0 && subCount <= 0) {
        return null;
    }

    const width = {
        xs: size === 'large' ? '20px' : '15px',
        sm: size === 'large' ? '25px' : '18px',
        md: size === 'large' ? '31px' : '22px',
        lg: size === 'large' ? '35px' : '25px',
    };

    const fontSize = {
        xs: size === 'large' ? '8px' : '7px',
        sm: size === 'large' ? '9px' : '8px',
        md: size === 'large' ? '10px' : '9px',
        lg: size === 'large' ? '11px' : '10px',
    };

    const backgroundColor = variant === 'error' ? '#E5494D' :
        variant === 'info' ? '#17a2b8' :
            variant === 'success' ? '#28a745' :
                '#007bff';

    const positionStyle = {
        position: 'absolute',
        ...position // Используем объект position для установки стилей
    };

    return (
        <Box
            sx={{
                width: width,
                height: '18px',
                borderRadius: '16px',
                background: backgroundColor,
                padding: '1px 0px 0px 0px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...positionStyle,
                transition: 'width 0.3s ease, height 0.3s ease, border-radius 0.3s ease',
                ...badgeStyle, // Применение пользовательских стилей для баджа
            }}
        >
            <Typography
                sx={{
                    color: 'white',
                    fontSize: fontSize,
                    fontWeight: '700',
                    lineHeight: '140%',
                    ...contentStyle, // Применение пользовательских стилей для контента
                }}
            >
                {content ? content : (count > 0 ? (count > 99 ? '99+' : count) : (subCount > 0 ? (subCount > 99 ? '99+' : subCount) : ''))}
            </Typography>
        </Box>
    );
});

Badge.propTypes = {
    path: PropTypes.string.isRequired,
    subPath: PropTypes.string,
    size: PropTypes.oneOf(['small', 'large']),
    variant: PropTypes.oneOf(['error', 'info', 'success']),
    position: PropTypes.object, // Новый проп для передачи позиции
    content: PropTypes.string, // Новый проп для передачи текста
    badgeStyle: PropTypes.object, // Новый проп для пользовательских стилей баджа
    contentStyle: PropTypes.object, // Новый проп для пользовательских стилей контента
};

Badge.defaultProps = {
    size: 'small',
    variant: 'error',
    position: { top: '0px', right: '0px' }, // Значения по умолчанию для позиции
    badgeStyle: {}, // Пустые стили по умолчанию для баджа
    contentStyle: {}, // Пустые стили по умолчанию для контента
};

export default Badge;
