import formErrorDictionary from "../assets/translations/errors/formErrorDictionary";

export function validatePriceRange (from,to) {
    let min = parseFloat(from) ?? 0;
    let max = parseFloat(to) ?? 9999999999.99;
    if (max< min) {
        return `${formErrorDictionary.get('max_range')} ${min}`
    }
    if (min>max) {
        return `${formErrorDictionary.get('min_range')} ${max}`
    }
    return false
}
export function validateAreaRange (from,to) {
    let min = parseFloat(from) ?? 0;
    let max = parseFloat(to) ?? 1000000.00;
    if (max< min) {
        return `${formErrorDictionary.get('max_range')} ${min}`
    }
    if (min>max) {
        return `${formErrorDictionary.get('min_range')} ${max}`
    }
    return false
}