import React from 'react';
import languageStore from "./languageStore";

const globalDictionary = {
    dictionary: {
        layout_cabinet_drawer_menu_toggle: {
            eng: 'Toggle menu',
            rus: 'Свернуть меню',
            ukr: 'Згорнути меню',
            deu: 'Menü umschalten',
        },
        layout_cabinet_drawer_menu_chat: {
            eng: 'My messages',
            rus: 'Мои сообщения',
            ukr: 'Мої повідомлення',
            deu: 'Meine Nachrichten',
        },
        layout_cabinet_drawer_menu_request: {
            eng: 'Smart-request',
            rus: 'Smart-заявка',
            ukr: 'Smart-заявка',
            deu: 'Smart-Anfrage',
        },
        layout_cabinet_drawer_menu_adv: {
            eng: 'Advertising cabinet',
            rus: 'Рекламный кабинет',
            ukr: 'Рекламний кабінет',
            deu: 'Werbekabine',
        },
        layout_cabinet_drawer_menu_crm: {
            eng: 'CRM system',
            rus: 'CRM-система',
            ukr: 'CRM-система',
            deu: 'CRM-System',
        },
        layout_cabinet_drawer_menu_ref: {
            eng: 'Referral system',
            rus: 'Реферальная система',
            ukr: 'Реферальна система',
            deu: 'Empfehlungsprogramm',
        },
        layout_cabinet_drawer_menu_convert: {
            eng: 'Exchange',
            rus: 'Обменник',
            ukr: 'Обмінник',
            deu: 'Wechsler',
        },
        layout_cabinet_drawer_menu_wallet: {
            eng: 'My wallet',
            rus: 'Мой кошелек',
            ukr: 'Мій гаманець',
            deu: 'Mein Portemonnaie',
        },
        projects_list_item_view: {
            eng: 'View',
            rus: 'Обзор',
            ukr: 'Огляд',
            deu: 'Mehr sehen',
        },
        projects_list_item_share: {
            eng: 'Share',
            rus: 'Поделиться',
            ukr: 'Поділиться',
            deu: 'Teilen',
        },
        projects_list_item_link_clipboard: {
            eng: 'Link copied to clipboard',
            rus: 'Ссылка скопирована в буфер обмена',
            ukr: 'Посилання скопійовано в буфер обміну',
            deu: 'Link wurde in die Zwischenablage kopiert',
        },
        profile_wallet_button_staking: {
            eng: 'Staking',
            rus: 'Стейкинг',
            ukr: 'Стейкінг',
            deu: 'Staking',
        },
        created_at: {
            eng: 'Created at',
            rus: 'Создано',
            ukr: 'Створено',
            deu: 'Erstellt am',
        },
        type: {
            eng: 'Type',
            rus: 'Тип',
            ukr: 'Тип',
            deu: 'Typ',
        },
        area_translate: {
            eng: 'Area',
            rus: 'Площадь',
            ukr: 'Площа',
            deu: 'Fläche',
        },

        asset: {
            eng: 'Asset',
            rus: 'Актив',
            ukr: 'Актив',
            deu: 'Vermögenswert',
        },
        deposit_method_translation: {
            eng: 'Deposit method',
            rus: 'Способ внесения',
            ukr: 'Спосіб внесення',
            deu: 'Einzahlungsmethode',
        },
        bank_transfer_translation: {
            eng: 'Bank Transfer',
            rus: 'Банковский Перевод',
            ukr: 'Банківський Переказ',
            deu: 'Banküberweisung',
        },
        min_deposit_amount_translation: {
            eng: 'Min. deposit amount',
            rus: 'Мин. сумма депозита',
            ukr: 'Мін. сума депозиту',
            deu: 'Mindesteinzahlungsbetrag',
        },
        but_no_less_than_translation: {
            eng: 'but no less than',
            rus: 'но не менее чем',
            ukr: 'але не менше ніж',
            deu: 'aber nicht weniger als',
        },
        transaction_fee_translation: {
            eng: 'Transaction fee:',
            rus: 'Комиссия за транзакцию:',
            ukr: 'Комісія за транзакцію:',
            deu: 'Transaktionsgebühr:',
        },
        to_pay_translation: {
            eng: 'To pay:',
            rus: 'К оплате:',
            ukr: 'До сплати:',
            deu: 'Zu zahlen:',
        },
        min_withdraw_amount_translation: {
            eng: 'Min. withdraw amount',
            rus: 'Мин. сумма для вывода',
            ukr: 'Мін. сума для виведення',
            deu: 'Mindestabhebungsbetrag',
        },
        add_new_ticket_translation: {
            eng: 'Add a new ticket',
            rus: 'Добавить новый тикет',
            ukr: 'Додати новий тiкет',
            deu: 'Ein neues Ticket hinzufügen',
        },
        create_new_ticket_form_translation: {
            eng: 'Please fill out the form below to create a new ticket. We will get back to you as soon as possible',
            rus: 'Пожалуйста, заполните форму ниже, чтобы создать новый тикет. Мы свяжемся с вами как можно скорее',
            ukr: "Будь ласка, заповніть форму нижче, щоб створити новий тікет. Ми зв'яжемося з вами якнайшвидше",
            deu: 'Bitte füllen Sie das untenstehende Formular aus, um ein neues Ticket zu erstellen. Wir werden uns so schnell wie möglich bei Ihnen melden',
        },
        category_translation: {
            eng: 'Category',
            rus: 'Категория',
            ukr: 'Категорія',
            deu: 'Kategorie',
        },
        support_add_category_finance: {
            eng: 'Finance',
            rus: 'Финансы',
            ukr: 'Фінанси',
            deu: 'Finanzen',
        },
        support_add_category_technical: {
            eng: 'Technical',
            rus: 'Сбои в работе',
            ukr: 'Технічні проблеми',
            deu: 'Technisch',
        },
        support_add_category_general: {
            eng: 'General',
            rus: 'Общий',
            ukr: 'Загальний',
            deu: 'Allgemein',
        },
        send_ticket_translation: {
            eng: 'Send Ticket',
            rus: 'Отправить Заявку',
            ukr: 'Відправити Заявку',
            deu: 'Ticket Senden',
        },
        content_translation: {
            eng: 'Content',
            rus: 'Содержание',
            ukr: 'Зміст',
            deu: 'Inhalt',
        },
        title_translation: {
            eng: 'Title',
            rus: 'Заголовок',
            ukr: 'Заголовок',
            deu: 'Titel',
        },
        not_yet_registered_translation: {
            eng: 'You are not yet registered',
            rus: 'Вы еще не зарегистрированы',
            ukr: 'Ви ще не зареєстровані',
            deu: 'Sie sind noch nicht registriert',
        },
        not_registered_with_provider_translation: {
            eng: 'You are not yet registered with this provider. Please confirm the agreement to continue',
            rus: 'Вы еще не зарегистрированы у этого провайдера. Пожалуйста, подтвердите соглашение, чтобы продолжить',
            ukr: 'Ви ще не зареєстровані у цього провайдера. Будь ласка, підтвердіть угоду, щоб продовжити',
            deu: 'Sie sind bei diesem Anbieter noch nicht registriert. Bitte bestätigen Sie die Vereinbarung, um fortzufahren',
        },
        agree_terms_conditions_translation: {
            eng: 'I agree with the Terms and Conditions',
            rus: 'Я согласен с Условиями и Положениями',
            ukr: 'Я згоден з Умовами та Положеннями',
            deu: 'Ich stimme den Allgemeinen Geschäftsbedingungen zu',
        },
        beneficiary_translation: {
            eng: 'Beneficiary',
            rus: 'Бенефициар',
            ukr: 'Бенефіціар',
            deu: 'Begünstigter',
        },
        amount: {
            eng: 'Amount',
            rus: 'Сумма',
            ukr: 'Сума',
            deu: 'Betrag',
        },
        coming_soon: {
            eng: 'Coming soon',
            rus: 'Скоро будет доступно',
            ukr: 'Скоро буде доступно',
            deu: 'Demnächst verfügbar',
        },
        exchange_rate: {
            eng: 'Exchange rate',
            rus: 'Обменный курс',
            ukr: 'Обмінний курс',
            deu: 'Wechselkurs',
        },
        m2c: {
            eng: 'M2C',
            rus: 'M2C',
            ukr: 'M2C',
            deu: 'M2C',
        },
        history: {
            eng: 'History',
            rus: 'История',
            ukr: 'Історія',
            deu: 'Geschichte',
        },
        status: {
            eng: 'Status',
            rus: 'Статус',
            ukr: 'Статус',
            deu: 'Status',
        },
        completed_at: {
            eng: 'Completed at',
            rus: 'Завершено',
            ukr: 'Завершено',
            deu: 'Abgeschlossen am',
        },
        transaction_history: {
            eng: 'Transaction History',
            rus: 'История транзакций',
            ukr: 'Історія транзакцій',
            deu: 'Transaktionsverlauf',
        },
        export_transactions_history_success: {
            eng: 'Transactions history exported successfully',
            rus: 'История транзакций успешно экспортирована',
            ukr: 'Історія транзакцій успішно експортована',
            deu: 'Transaktionsverlauf erfolgreich exportiert',
        },
        contracts_history: {
            eng: 'Contracts History',
            rus: 'История контрактов',
            ukr: 'Історія контрактів',
            deu: 'Vertragsverlauf',
        },
        blocked: {
            eng: 'Blocked',
            rus: 'Заблокировано',
            ukr: 'Заблоковано',
            deu: 'Gesperrt',
        },
        maximum_transaction_amount: {
            eng: 'Maximum Transaction Amount',
            rus: 'Максимальная сумма транзакции',
            ukr: 'Максимальна сума транзакції',
            deu: 'Maximaler Transaktionsbetrag',
        },

        create_crypto_wallet: {
            eng: 'Create Crypto Wallet',
            rus: 'Создать криптовалютный кошелек',
            ukr: 'Створити криптовалютний гаманець',
            deu: 'Krypto-Wallet erstellen',
        },

        export_transactions_history: {
            eng: 'Export transactions history',
            rus: 'Экспортировать историю транзакций',
            ukr: 'Експортувати історію транзакцій',
            deu: 'Transaktionsverlauf exportieren',
        },
        profile_wallet_button_deposit: {
            eng: 'Deposit',
            rus: 'Депозит',
            ukr: 'Депозит',
            deu: 'Einzahlung',
        },
        profile_wallet_button_withdraw: {
            eng: 'Withdraw',
            rus: 'Вывести',
            ukr: 'Вивести',
            deu: 'Ausgabe',
        },
        profile_wallet_stellar_address: {
            eng: 'Your wallet address',
            rus: 'Адрес вашего кошелька',
            ukr: 'Адреса вашого гаманця',
            deu: 'Ihre Brieftaschenadresse',
        },
        profile_wallet_stellar_address_explorer: {
            eng: 'Show my wallet in stellar explorer',
            rus: 'Показать кошелек в stellar explorer',
            ukr: 'Показати гаманець у stellar explorer',
            deu: 'Brieftasche im Stellar-Explorer anzeigen',
        },
        layout_cabinet_header_wallet_deposit: {
            eng: 'Deposit',
            rus: 'Депозит',
            ukr: 'Депозит',
            deu: 'Einzahlung',
        },
        layout_cabinet_header_wallet: {
            eng: 'Wallet',
            rus: 'Кошелек',
            ukr: 'Гаманець',
            deu: 'Brieftasche',
        },
        layout_cabinet_header_wallet_overview: {
            eng: 'Wallet',
            rus: 'Кошелек',
            ukr: 'Гаманець',
            deu: 'Brieftasche',
        },
        layout_cabinet_header_wallet_history: {
            eng: 'History',
            rus: 'История',
            ukr: 'Історія',
            deu: 'Geschichte',
        },
        requests_edit_title: {
            eng: 'Edit request',
            rus: 'Редактировать запрос',
            ukr: 'Редагувати запит',
            deu: 'Bearbeiten einer Anfrage',
        },
        button_to_old_menu: {
            eng: 'Old cabinet',
            rus: 'Старый кабинет',
            ukr: 'Старий кабінет',
            deu: 'alter Schrank',
        },
        profile_advertising_support: {
            eng: 'Feedback form',
            rus: 'Форма обратной связи',
            ukr: "Форма зворотного зв'язку",
            deu: 'Feedback-Formular',
        },
        profile_advertising_info: {
            eng: 'The GREM platform has its own marketing agency. You can order the promotion of your properties by writing to us via the feedback form',
            rus: 'Платформа GREM имеет собственное маркетинговое агентство. Вы можете заказать  продвижение ваших объектов недвижимости, написав нам через форму обратной связи',
            ukr: "Платформа GREM має власне маркетингове агентство. Ви можете замовити просування ваших об'єктів нерухомості, написавши нам через форму зворотного зв'язку",
            deu: 'Die GREM-Plattform verfügt über eine eigene Marketing-Agentur. Sie können die Werbung für Ihre Immobilien bestellen, indem Sie uns über das Feedback-Formular schreiben',
        },
        properties_list_button_complex_add: {
            eng: 'Add residential complex',
            rus: 'Добавить жилой комплекс',
            ukr: 'Додати житловий комплекс',
            deu: 'Dodate die Wohnsiedlung',
        },
        properties_menu_view: {
            eng: 'Owerview',
            rus: 'Обзор',
            ukr: 'Огляд',
            deu: 'Übersicht',
        },
        property_list_item_menu_view: {
            eng: 'View',
            rus: 'Обзор',
            ukr: 'Огляд',
            deu: 'Mehr sehen',
        },
        requests_add_success: {
            eng: 'Request has been successfully added',
            rus: 'Запрос успешно добавлен',
            ukr: 'Запит успішно додано',
            deu: 'Anfrage wurde erfolgreich hinzugefügt',
        },
        properties_share_success: {
            eng: 'Link copied to clipboard',
            rus: 'Ссылка скопирована в буфер обмена',
            ukr: 'Посилання скопійовано в буфер обміну',
            deu: 'Link in die Zwischenablage kopiert',
        },
        property_add_common_sold_title: {
            eng: 'Is sold?',
            rus: 'Продано?',
            ukr: 'Продано?',
            deu: 'Ist verkauft?',
        },
        property_add_common_sold: {
            eng: 'Sold',
            rus: 'Продано',
            ukr: 'Продано',
            deu: 'Verkauft',
        },
        property_menu_sold: {
            eng: 'Sold',
            rus: 'Продано',
            ukr: 'Продано',
            deu: 'Verkauft',
        },
        property_add_common_not_sold: {
            eng: 'Not sold',
            rus: 'Не продано',
            ukr: 'Не продано',
            deu: 'Nicht verkauft',
        },
        property_add_common_available_title: {
            eng: 'Is available?',
            rus: 'Доступен?',
            ukr: 'Доступний?',
            deu: 'Ist verfügbar?',
        },
        property_add_common_available: {
            eng: 'Available',
            rus: 'Доступен',
            ukr: 'Доступний',
            deu: 'Verfügbar',
        },
        property_menu_available: {
            eng: 'Available',
            rus: 'Доступен',
            ukr: 'Доступний',
            deu: 'Verfügbar',
        },
        property_add_common_not_available: {
            eng: 'Not available',
            rus: 'Не доступен',
            ukr: 'Недоступний',
            deu: 'Nicht verfügbar',
        },
        property_edit_title: {
            eng: 'Edit property',
            rus: 'Редактировать объект недвижимости',
            ukr: "Редагувати об'єкт нерухомості",
            deu: 'Eigenschaft bearbeiten',
        },
        complex_edit_title: {
            eng: 'Edit complex',
            rus: 'Редактировать комплекс ',
            ukr: 'Редагувати комплекс ',
            deu: 'Komplex bearbeiten',
        },
        property_item_author_contact: {
            eng: 'Contact',
            rus: 'Контакт',
            ukr: 'Контакт',
            deu: 'Kontakt',
        },
        property_item_tab_overview: {
            eng: 'Overview',
            rus: 'Обзор',
            ukr: 'Огляд',
            deu: 'Übersicht',
        },
        property_item_tab_technical: {
            eng: 'Technical description',
            rus: 'Техническое описание',
            ukr: 'Технічний опис',
            deu: 'Technische Beschreibung',
        },
        property_item_tab_technical_second: {
            eng: 'Technical Specifications',
            rus: 'Технические показатели',
            ukr: 'Технічні характеристики',
            deu: 'Technische Spezifikationen',
        },
        property_item_tab_documents: {
            eng: 'Documents',
            rus: 'Документы',
            ukr: 'Документи',
            deu: 'Dokumente',
        },
        property_item_tab_investment: {
            eng: 'Investment attractiveness (soon)',
            rus: 'Инвестиционная привлекательность (скоро)',
            ukr: 'Інвестиційна привабливість (скоро)',
            deu: 'Investmentattraktivität (bald)',
        },
        property_item_overview_description: {
            eng: 'Description:',
            rus: 'Описание:',
            ukr: 'Опис:',
            deu: 'Beschreibung:',
        },
        property_item_overview_location: {
            eng: 'Location',
            rus: 'Расположение',
            ukr: 'Розташування',
            deu: 'Standort',
        },
        property_item_technical_type: {
            eng: 'Type of property:',
            rus: 'Тип недвижимости:',
            ukr: 'Тип нерухомості:',
            deu: 'Art der Eigenschaft:',
        },
        complex_item_technical_type: {
            eng: 'Type of complex:',
            rus: 'Тип комплекса:',
            ukr: 'Тип комплексу:',
            deu: 'Art des Komplexes:',
        },

        property_item_technical_description: {
            eng: 'Apartment description:',
            rus: 'Описание квартиры:',
            ukr: 'Опис квартири:',
            deu: 'Wohnungsbeschreibung:',
        },
        property_item_technical_conditions: {
            eng: 'Conditions:',
            rus: 'Состояние:',
            ukr: 'Стан:',
            deu: 'Bedingungen:',
        },
        property_item_technical_sm: {
            eng: '(sq.m.)',
            rus: '(кв.м.)',
            ukr: '(кв.м.)',
            deu: '(qm)',
        },
        property_item_technical_total_area: {
            eng: 'Total area:',
            rus: 'Общая площадь:',
            ukr: 'Загальна площа:',
            deu: 'Gesamtfläche:',
        },
        property_item_technical_living_space: {
            eng: 'Living space:',
            rus: 'Жилая площадь:',
            ukr: 'Житлова площа:',
            deu: 'Wohnfläche:',
        },
        property_item_technical_kitchen_size: {
            eng: 'Kitchen size:',
            rus: 'Площадь кухни:',
            ukr: 'Площа кухні:',
            deu: 'Küchengröße:',
        },
        property_item_technical_floor: {
            eng: 'Floor:',
            rus: 'Этаж:',
            ukr: 'Поверх:',
            deu: 'Boden:',
        },
        condition_translate: {
            eng: 'Condition',
            rus: 'Состояние',
            ukr: 'Стан',
            deu: 'Zustand',
        },
        number_translate: {
            eng: 'Number',
            rus: 'Номер',
            ukr: 'Номер',
            deu: 'Nummer',
        },

        property_item_technical_storeys: {
            eng: 'Number of storeys:',
            rus: 'Этажность:',
            ukr: 'Поверховість:',
            deu: 'Anzahl der Stockwerke:',
        },
        property_item_technical_rooms: {
            eng: 'Rooms:',
            rus: 'Комнаты:',
            ukr: 'Кімнати:',
            deu: 'Zimmer:',
        },
        property_item_technical_balcony: {
            eng: 'Balcony:',
            rus: 'Балконы:',
            ukr: 'Балкони:',
            deu: 'Balkon:',
        },
        property_item_technical_ceiling_height: {
            eng: 'Ceiling height:',
            rus: 'Высота потолка:',
            ukr: 'Висота стелі:',
            deu: 'Deckenhöhe:',
        },
        property_item_technical_wall_material: {
            eng: 'Wall material:',
            rus: 'Материал стен:',
            ukr: 'Матеріал стін:',
            deu: 'Wandmaterial:',
        },
        property_item_technical_utility: {
            eng: 'Utility system:',
            rus: 'Коммунальные системы:',
            ukr: 'Комунальні системи:',
            deu: 'Versorgungssystem:',
        },
        property_item_technical_windows_view: {
            eng: 'Window view',
            rus: 'Вид из окна',
            ukr: 'Вид з вікна',
            deu: 'Fensteransicht',
        },
        property_item_documents_photo: {
            eng: 'Documents:',
            rus: 'Документы:',
            ukr: 'Документи:',
            deu: 'Dokumente:',
        },
        property_item_documents_exclusive_agreements: {
            eng: 'Exclusive agreements:',
            rus: 'Эксклюзивные соглашения:',
            ukr: 'Ексклюзивні угоди:',
            deu: 'Exklusivvereinbarungen:',
        },
        property_add_title: {
            eng: 'Adding real estate property',
            rus: 'Добавление объекта недвижимости',
            ukr: "Додавання нерухомості",
            deu: 'Hinzufügen von Immobilienobjekt',
        },
        property_add_step_common: {
            eng: 'Common',
            rus: 'Общее',
            ukr: 'Загальні',
            deu: 'Gemeinsam',
        },
        property_add_step_technical: {
            eng: 'Technical',
            rus: 'Технические',
            ukr: 'Технічні',
            deu: 'Technisch',
        },
        property_add_step_financial: {
            eng: 'Financial',
            rus: 'Финансовые',
            ukr: 'Фінансові',
            deu: 'Finanzen',
        },
        property_add_step_photos: {
            eng: 'Photos',
            rus: 'Фотографии',
            ukr: 'Фотографії',
            deu: 'Fotos',
        },
        property_add_step_documents: {
            eng: 'Documents',
            rus: 'Документы',
            ukr: 'Документи',
            deu: 'Dokumente',
        },
        property_add_send: {
            eng: 'Send',
            rus: 'Отправить',
            ukr: 'Надіслати',
            deu: 'Senden',
        },
        property_add_next: {
            eng: 'Next',
            rus: 'Далее',
            ukr: 'Далі',
            deu: 'Weiter',
        },

        property_add_back: {
            eng: 'Back',
            rus: 'Назад',
            ukr: 'Назад',
            deu: 'Zurück',
        },
        property_add_common_title: {
            eng: 'Main parameters',
            rus: 'Основные параметры',
            ukr: 'Основні параметри',
            deu: 'Hauptparameter',
        },
        property_add_common_number: {
            eng: 'Object Code',
            rus: 'Код объекта',
            ukr: "Код об'єкта",
            deu: 'Objektcode',
        },
        property_add_common_number_placeholder: {
            eng: 'Enter number of the property',
            rus: 'Введите номер объекта',
            ukr: 'Введіть номер власності',
            deu: 'Geben Sie die Nummer der Immobilie ein',
        },
        property_add_common_number_help: {
            eng: 'Here you can write a code of the property object. For example, a code in your database or elsewhere',
            rus: 'Здесь вы можете написать код объекта свойства. Например, код в вашей базе данных или в другом месте.',
            ukr: "Тут ви можете написати код об'єкта. Наприклад, код у вашій базі даних або деінде",
            deu: 'Hier können Sie einen Code des Eigenschaftsobjekts schreiben. Zum Beispiel ein Code in Ihrer Datenbank oder anderswo.',
        },
        property_add_common_name: {
            eng: 'Name of the property',
            rus: 'Название объекта',
            ukr: "Назва об'єкта",
            deu: 'Name der Eigenschaft',
        },
        complex_add_common_name: {
            eng: 'Name of the complex',
            rus: 'Имя комплекса',
            ukr: 'Назва комплесу',
            deu: 'Komplexer Name',
        },
        property_add_common_name_placeholder: {
            eng: 'Enter name of the property',
            rus: 'Введите имя объекта',
            ukr: "Введіть назву об'єкту",
            deu: 'Geben Sie den Namen der Eigenschaft ein',
        },
        property_add_common_type: {
            eng: 'Type of property',
            rus: 'Тип объекта',
            ukr: "Тип об'єкту",
            deu: 'Art der Eigenschaft',
        },
        property_technical_typeBuilding: {
            eng: 'Type of property',
            rus: 'Тип объекта',
            ukr: "Тип об'єкту",
            deu: 'Art der Eigenschaft',
        },
        property_add_common_type_help: {
            eng: 'Select property type',
            rus: 'Выберите тип объекта',
            ukr: "Виберіть тип об'єкту",
            deu: 'Eigenschaftstyp auswählen',
        },
        property_add_common_residential_type: {
            eng: 'Residential type',
            rus: 'Тип жилья',
            ukr: 'Житловий тип',
            deu: 'Wohntyp',
        },
        property_technical_residential_type: {
            eng: 'Residential type',
            rus: 'Тип жилья',
            ukr: 'Житловий тип',
            deu: 'Wohntyp',
        },
        property_add_common_residential_type_help: {
            eng: 'Select residential type',
            rus: 'Выберите тип жилья',
            ukr: 'Виберіть тип житлового приміщення',
            deu: 'Wohntyp auswählen',
        },
        property_add_common_commercial_type: {
            eng: 'Commercial type',
            rus: 'Коммерческий тип',
            ukr: 'Комерційний тип',
            deu: 'Handelstyp',
        },
        property_technical_commercial_type: {
            eng: 'Commercial type',
            rus: 'Коммерческий тип',
            ukr: 'Комерційний тип',
            deu: 'Handelstyp',
        },
        property_add_common_commercial_type_help: {
            eng: 'Select commercial type',
            rus: 'Выберите коммерческий тип',
            ukr: 'Виберіть комерційний тип',
            deu: 'Gewerbetyp auswählen',
        },
        property_add_common_industrial_type: {
            eng: 'Industrial type',
            rus: 'Индустриальный тип',
            ukr: 'Індустріальний тип',
            deu: 'Industrietyp',
        },
        property_technical_industrial_type: {
            eng: 'Industrial type',
            rus: 'Индустриальный тип',
            ukr: 'Індустріальний тип',
            deu: 'Industrietyp',
        },
        property_add_common_industrial_type_help: {
            eng: 'Select industrial type',
            rus: 'Выберите индустриальный тип',
            ukr: 'Виберіть індустріальний тип',
            deu: 'Industrietyp auswählen',
        },
        property_add_common_conditions_type: {
            eng: 'Conditions type',
            rus: 'Состояние объекта',
            ukr: "Стан об'єкту",
            deu: 'Bedingungstyp',
        },
        property_technical_conditions_type: {
            eng: 'Conditions type',
            rus: 'Состояние объекта',
            ukr: "Стан об'єкту",
            deu: 'Bedingungstyp',
        },
        property_add_common_conditions_type_help: {
            eng: 'Select conditions type',
            rus: 'Укажите состояние объекта',
            ukr: "Вкажіть стан об'єкту",
            deu: 'Bedingungstyp auswählen',
        },
        property_add_common_disposition_rooms: {
            eng: 'Disposition of rooms',
            rus: 'Расположение комнат',
            ukr: 'Планування кімнат',
            deu: 'Disposition of rooms',
        },
        property_add_common_conditions_disposition_rooms_help: {
            eng: 'Select disposition of rooms',
            rus: 'Выберите расположение комнат',
            ukr: 'Виберіть розташування кімнат',
            deu: 'Raumverteilung wählen',
        },
        property_add_common_disposition_rooms_walkthrough: {
            eng: 'Walkthrough room',
            rus: 'Комната обхода',
            ukr: 'Прохідна кімната',
            deu: 'Durchgangsraum',
        },
        property_add_common_disposition_rooms_separate: {
            eng: 'Separate rooms',
            rus: 'Отдельные комнаты',
            ukr: 'Окремі кімнати',
            deu: 'Getrennte Räume',
        },
        property_add_common_disposition_rooms_interconnecting: {
            eng: 'Interconnecting parallel rooms',
            rus: 'Соединяющиеся параллельные комнаты',
            ukr: "З'єднання паралельних кімнат",
            deu: 'Verbindung paralleler Räume',
        },
        property_add_common_apartment_description: {
            eng: 'Apartment description',
            rus: 'Описание квартиры',
            ukr: 'Опис квартири',
            deu: 'Wohnungsbeschreibung',
        },
        property_add_common_conditions_apartment_description_help: {
            eng: 'Select apartment description',
            rus: 'Выберите описание квартиры',
            ukr: 'Виберіть опис квартири',
            deu: 'Wohnungsbeschreibung auswählen',
        },
        property_add_common_description: {
            eng: 'Description',
            rus: 'Описание',
            ukr: 'Опис',
            deu: 'Beschreibung',
        },
        property_add_common_description_help: {
            eng: 'Enter description',
            rus: 'Введите описание',
            ukr: 'Введіть опис',
            deu: 'Beschreibung eingeben',
        },
        property_add_common_infrastructure_description: {
            eng: 'Infrastructure description',
            rus: 'Описание инфраструктуры',
            ukr: 'Опис інфраструктури',
            deu: 'Infrastruktur Beschreibung',
        },
        property_add_common_infrastructure_description_help: {
            eng: 'Enter infrastructure description',
            rus: 'Введите описание инфраструктуры',
            ukr: 'Введіть опис інфраструктури',
            deu: 'Geben Sie die Infrastruktur-Beschreibung ein',
        },
        property_add_common_parking_description: {
            eng: 'Parking description',
            rus: 'Описание парковки',
            ukr: 'Опис парковки',
            deu: 'Parkplatzbeschreibung',
        },
        property_add_common_parking_description_help: {
            eng: 'Enter parking description',
            rus: 'Введите описание парковки',
            ukr: 'Введіть опис парковки',
            deu: 'Geben Sie die Parkplatzbeschreibung ein',
        },
        property_add_common_territory_description: {
            eng: 'Territory description',
            rus: 'Описание территории',
            ukr: 'Опис території',
            deu: 'Geländebeschreibung',
        },
        property_add_common_territory_description_help: {
            eng: 'Enter territory description',
            rus: 'Введите описание территории',
            ukr: 'Введіть опис території',
            deu: 'Geben Sie die Geländebeschreibung ein',
        },
        property_add_common_landmark: {
            eng: 'Landmarks',
            rus: 'Ориентиры',
            ukr: 'Орієнтири',
            deu: 'Sehenswürdigkeiten',
        },
        property_technical_landmark: {
            eng: 'Landmarks',
            rus: 'Ориентиры',
            ukr: 'Орієнтири',
            deu: 'Sehenswürdigkeiten',
        },
        property_add_common_landmark_placeholder: {
            eng: 'Enter Landmarks',
            rus: 'Введите ориентиры',
            ukr: 'Введіть орієнтири',
            deu: 'Orientierungspunkte eingeben',
        },
        property_add_common_landmark_help: {
            eng: 'Orienting point, some well-known or easily seen object, that is located nearby',
            rus: 'Ориентиры, какой-нибудь хорошо известный или хорошо заметный объект, находящийся поблизости',
            ukr: "Орієнтири, якийсь добре відомий або легко видимий об'єкт, який знаходиться поблизу",
            deu: 'Orientierungspunkt, ein bekanntes oder leicht zu sehendes Objekt, das sich in der Nähe befindet',
        },
        property_add_common_location_title: {
            eng: 'In order to choose an address of the object, please fill in the address in the map field below',
            rus: 'Чтобы выбрать адрес объекта, пожалуйста, заполните адрес в поле карты ниже',
            ukr: "Щоб вибрати адресу об'єкта, будь ласка, заповніть адресу в полі карти нижче",
            deu: 'Um eine Adresse des Objekts auszuwählen, geben Sie bitte die Adresse in das Kartenfeld unten ein',
        },
        complex_add_common_location_title: {
            eng: 'In order to choose an address of the complex, please fill in the address in the map field below',
            rus: 'Чтобы выбрать адрес комплекса, пожалуйста, заполните адрес в поле карты ниже',
            ukr: 'Щоб вибрати адресу комплексу, будь ласка, заповніть адресу в полі карти нижче',
            deu: 'Um eine Adresse des Komplexes auszuwählen, geben Sie bitte die Adresse in das Kartenfeld unten ein',
        },
        property_add_common_country: {
            eng: 'Country',
            rus: 'Страна',
            ukr: 'Країна',
            deu: 'Land',
        },
        property_add_common_country_placeholder: {
            eng: 'Enter сountry',
            rus: 'Введите страну ',
            ukr: 'Введіть країну',
            deu: 'Land eingeben',
        },
        property_add_common_city: {
            eng: 'City',
            rus: 'Город',
            ukr: 'місто',
            deu: 'Stadt',
        },
        property_add_common_city_placeholder: {
            eng: 'Enter city',
            rus: 'Введите город',
            ukr: 'Введіть місто',
            deu: 'Stadt eingeben',
        },
        property_add_common_district: {
            eng: 'District',
            rus: 'Район',
            ukr: 'Район',
            deu: 'Bezirk',
        },
        property_add_common_district_placeholder: {
            eng: 'Enter district',
            rus: 'Введите район',
            ukr: 'Введіть район',
            deu: 'Bezirk eingeben',
        },
        property_add_common_street: {
            eng: 'Street',
            rus: 'Улица',
            ukr: 'Вулиця',
            deu: 'Straße',
        },
        property_add_common_street_placeholder: {
            eng: 'Enter street',
            rus: 'Введите улицу',
            ukr: 'Введіть вулицю',
            deu: 'Straße eingeben',
        },
        property_add_common_house: {
            eng: 'House number',
            rus: 'Номер дома',
            ukr: 'номер будинку',
            deu: 'Hausnummer',
        },
        property_add_common_house_placeholder: {
            eng: 'Enter house number',
            rus: 'Введите номер дома',
            ukr: 'Введіть номер будинку',
            deu: 'Hausnummer eingeben',
        },
        property_add_technical_title: {
            eng: 'Technical specifications',
            rus: 'Технические характеристики',
            ukr: 'Технічні характеристики',
            deu: 'Technische Daten',
        },
        property_add_technical_building_type: {
            eng: 'Type of the building',
            rus: 'Тип здания',
            ukr: 'Тип будівлі',
            deu: 'Typ des Gebäudes',
        },
        property_add_technical_building_type_placeholder: {
            eng: 'Enter type of the building',
            rus: 'Введите тип здания',
            ukr: 'Введіть тип будівлі',
            deu: 'Typ des Gebäudes eingeben',
        },
        property_add_technical_wall: {
            eng: 'Wall material',
            rus: 'Материал стены',
            ukr: 'Матеріал стін',
            deu: 'Wandmaterial',
        },
        property_add_technical_wall_placeholder: {
            eng: 'Enter wall material',
            rus: 'Введите материал стены',
            ukr: 'Введіть матеріал стіни',
            deu: 'Wandmaterial eingeben',
        },
        property_add_technical_storeys: {
            eng: 'Number of storeys',
            rus: 'Этажность',
            ukr: 'Кількість поверхів',
            deu: 'Anzahl der Stockwerke',
        },
        property_add_technical_storeys_placeholder: {
            eng: 'Enter number of storeys',
            rus: 'Введите количество этажей',
            ukr: 'Введіть кількість поверхів',
            deu: 'Geschosszahl eingeben',
        },
        property_add_technical_floor: {
            eng: 'Floor',
            rus: 'Этаж',
            ukr: 'Поверх',
            deu: 'Boden',
        },
        property_add_technical_floor_placeholder: {
            eng: 'Enter floor',
            rus: 'Введите этаж',
            ukr: 'Введіть поверх',
            deu: 'Etage eingeben',
        },
        property_add_technical_year_сonstruction: {
            eng: 'Year of construction',
            rus: 'Год постройки',
            ukr: 'Рік побудови',
            deu: 'Baujahr',
        },
        property_add_technical_year_сonstruction_placeholder: {
            eng: 'Enter year of construction',
            rus: 'Введите год постройки',
            ukr: 'Введіть рік будівництва',
            deu: 'Baujahr eingeben',
        },
        property_add_technical_designated_use: {
            eng: 'Designated Use',
            rus: 'Целевое назначение',
            ukr: 'Призначення',
            deu: 'Zweckbestimmung',
        },
        property_add_technical_designated_use_placeholder: {
            eng: 'Enter designated use',
            rus: 'Введите указанное использование',
            ukr: 'Введіть призначення',
            deu: 'Verwendungszweck eintragen',
        },
        property_add_technical_space_all: {
            eng: 'Total area (sq.m.)',
            rus: 'Общая площадь (кв.м.)',
            ukr: 'Загальна площа (кв.м.)',
            deu: 'Gesamtfläche (qm)',
        },
        property_add_technical_space_all_placeholder: {
            eng: 'Enter total area (sq.m.)',
            rus: 'Введите общую площадь (кв.м.)',
            ukr: 'Введіть загальну площу (кв.м.)',
            deu: 'Gesamtfläche eingeben (qm)',
        },
        property_add_technical_space_land: {
            eng: 'Land plot area (sq.m.)',
            rus: 'Площадь земельного участка (кв.м.)',
            ukr: 'Площа земельної ділянки (кв.м.)',
            deu: 'Grundstücksfläche (qm)',
        },
        property_add_technical_space_land_placeholder: {
            eng: 'Enter land plot area (sq.m.)',
            rus: 'Введите площадь земельного участка (кв.м.)',
            ukr: 'Введіть площу земельної ділянки (кв.м.)',
            deu: 'Grundstücksfläche eingeben (qm)',
        },
        property_add_technical_space_effective: {
            eng: 'Effective area (sq.m.)',
            rus: 'Эффективная площадь (кв.м.)',
            ukr: 'Ефективна площа (кв.м.)',
            deu: 'Nutzfläche (qm)',
        },
        property_add_technical_space_effective_placeholder: {
            eng: 'Enter effective area (sq.m.)',
            rus: 'Введите эффективную площадь (кв.м.)',
            ukr: 'Введіть ефективну площу (кв.м.)',
            deu: 'Nutzfläche (qm) eingeben',
        },
        property_add_technical_space_auxiliary: {
            eng: 'Additional area (sq.m.)',
            rus: 'Дополнительная площадь (кв.м.)',
            ukr: 'Додаткова площа (кв.м.)',
            deu: 'Nutzfläche (qm)',
        },
        property_add_technical_space_auxiliary_placeholder: {
            eng: 'Enter effective area (sq.m.)',
            rus: 'Введите дополнительную площадь (кв.м.)',
            ukr: 'Введіть додаткову площу (кв.м.)',
            deu: 'Nutzfläche (qm) eingeben',
        },
        property_add_technical_space_living: {
            eng: 'Living space (sq.m.)',
            rus: 'Жилая площадь (кв.м.)',
            ukr: 'Житлова площа (кв.м.)',
            deu: 'Wohnfläche (qm)',
        },
        property_add_technical_space_living_placeholder: {
            eng: 'Enter living space (sq.m.)',
            rus: 'Введите жилую площадь (кв.м.)',
            ukr: 'Введіть житлову площу (кв.м.)',
            deu: 'Wohnfläche eingeben (qm)',
        },
        property_add_technical_space_kitchen: {
            eng: 'Kitchen size (sq.m.)',
            rus: 'Площадь кухни (кв.м.)',
            ukr: 'Розмір кухні (кв.м.)',
            deu: 'Küchengröße (qm)',
        },
        property_add_technical_space_kitchen_placeholder: {
            eng: 'Enter kitchen size (sq.m.)',
            rus: 'Введите размер кухни (кв.м.)',
            ukr: 'Введіть розмір кухні (кв.м.)',
            deu: 'Küchengröße eingeben (qm)',
        },
        property_add_technical_ceiling_height: {
            eng: 'Ceiling height (m)',
            rus: 'Высота потолка (м)',
            ukr: 'Висота стелі (м)',
            deu: 'Deckenhöhe (m)',
        },
        property_add_technical_ceiling_height_placeholder: {
            eng: 'Enter ceiling height (m)',
            rus: 'Введите высоту потолка (м)',
            ukr: 'Введіть висоту стелі (м)',
            deu: 'Deckenhöhe eingeben (m)',
        },
        property_add_technical_rooms: {
            eng: 'Number of rooms',
            rus: 'Количество комнат',
            ukr: 'Кількість кімнат',
            deu: 'Anzahl der Zimmer',
        },
        property_add_technical_balcony: {
            eng: 'Presence of balcony',
            rus: 'Наличие балкона',
            ukr: 'Наявність балкона',
            deu: 'Vorhandensein eines Balkons',
        },
        property_add_technical_windows: {
            eng: 'Number of windows',
            rus: 'Количество окон',
            ukr: 'Кількість вікон',
            deu: 'Anzahl der Fenster',
        },
        property_add_technical_windows_placeholder: {
            eng: 'Enter number of windows',
            rus: 'Введите количество окон',
            ukr: 'Введіть кількість вікон',
            deu: 'Anzahl der Fenster eingeben',
        },
        property_add_technical_bathrooms: {
            eng: 'Number of bathrooms',
            rus: 'Количество ванных комнат',
            ukr: 'Кількість ванних кімнат',
            deu: 'Anzahl der Badezimmer',
        },
        property_technical_bathrooms: {
            eng: 'Number of bathrooms',
            rus: 'Количество ванных комнат',
            ukr: 'Кількість ванних кімнат',
            deu: 'Anzahl der Badezimmer',
        },
        property_add_technical_bathrooms_placeholder: {
            eng: 'Enter number of bathrooms',
            rus: 'Введите количество ванных комнат',
            ukr: 'Введіть кількість ванних кімнат',
            deu: 'Anzahl Badezimmer eingeben',
        },
        property_add_technical_windows_view: {
            eng: 'Window view',
            rus: 'Вид из окна',
            ukr: 'Вид з вікна',
            deu: 'Fensteransicht',
        },
        property_technical_windows_view: {
            eng: 'Window view',
            rus: 'Вид из окна',
            ukr: 'Вид з вікна',
            deu: 'Fensteransicht',
        },
        property_add_technical_windows_view_placeholder: {
            eng: 'Enter window view',
            rus: 'Введите вид окна',
            ukr: 'Укажіть вид з вікна',
            deu: 'Fensteransicht öffnen',
        },
        property_add_technical_interior_features: {
            eng: 'Interior features',
            rus: 'Внутренние особенности',
            ukr: "Особливості інтер'єру",
            deu: 'Innenausstattung',
        },
        property_technical_interior_features: {
            eng: 'Interior features',
            rus: 'Внутренние особенности',
            ukr: "Особливості інтер'єру",
            deu: 'Innenausstattung',
        },
        property_add_technical_interior_features_placeholder: {
            eng: 'Enter interior features',
            rus: 'Введите элементы интерьера',
            ukr: "Введіть особливості інтер'єру",
            deu: 'Interieurmerkmale eingeben',
        },
        property_add_technical_parking_area: {
            eng: 'Availability of Parking',
            rus: 'Наличие парковки',
            ukr: 'Наявність парковки',
            deu: 'Verfügbarkeit von Parkplätzen',
        },
        property_add_technical_parking_area_placeholder: {
            eng: 'Enter parking area',
            rus: 'Укажите зону парковки',
            ukr: 'Укажіть зону паркування',
            deu: 'Parkbereich betreten',
        },
        property_add_technical_utility: {
            eng: 'Utility system',
            rus: 'Коммуникации',
            ukr: 'Комунальні системи',
            deu: 'Versorgungssystem',
        },
        property_technical_utility: {
            eng: 'Utility system',
            rus: 'Коммуникации',
            ukr: 'Комунальні системи',
            deu: 'Versorgungssystem',
        },
        property_add_technical_utility_help: {
            eng: 'Select utility system',
            rus: 'Выберите коммунальные системы',
            ukr: 'Виберіть комунальні системи',
            deu: 'Versorgungssystem wählen',
        },
        property_add_technical_equipment: {
            eng: 'Equipment',
            rus: 'Оборудование',
            ukr: 'Обладнання',
            deu: 'Ausrüstung',
        },
        property_add_technical_equipment_placeholder: {
            eng: 'Enter equipment',
            rus: 'Введите оборудование',
            ukr: 'Введіть обладнання',
            deu: 'Ausrüstung eingeben',
        },
        property_add_financial_title: {
            eng: 'Financial indicators',
            rus: 'Финансовые показатели',
            ukr: 'Фінансові показники',
            deu: 'Finanzkennzahlen',
        },
        property_add_financial_price: {
            eng: 'Price',
            rus: 'Цена',
            ukr: 'Ціна',
            deu: 'Preis',
        },
        addNews_translate: {
            eng: 'Add News',
            rus: 'Добавить Новость',
            ukr: 'Додати Новину',
            deu: 'Nachrichten Hinzufügen',
        },
        typeOfApartment_translate: {
            eng: 'Type of Apartment',
            rus: 'Тип Квартиры',
            ukr: 'Тип Квартири',
            deu: 'Art der Wohnung',
        },
        studio_translate: {
            eng: 'Studio',
            rus: 'Студия',
            ukr: 'Студія',
            deu: 'Studio',
        },
        convertibleStudio_translate: {
            eng: 'Convertible Studio',
            rus: 'Трансформируемая Студия',
            ukr: 'Трансформована Студія',
            deu: 'Umwandelbares Studio',
        },
        apartament_translate: {
            eng: 'Apartment',
            rus: 'Апартаменты',
            ukr: 'Апартаменти',
            deu: 'Apartment',
        },
        oneBedroom_translate: {
            eng: '1-Bedroom',
            rus: '1-Комнатная Квартира',
            ukr: '1-Кімнатна Квартира',
            deu: '1-Zimmer-Wohnung',
        },
        twoBedroom_translate: {
            eng: '2-Bedroom',
            rus: '2-Комнатная Квартира',
            ukr: '2-Кімнатна Квартира',
            deu: '2-Zimmer-Wohnung',
        },
        threeBedroom_translate: {
            eng: '3-Bedroom',
            rus: '3-Комнатная Квартира',
            ukr: '3-Кімнатна Квартира',
            deu: '3-Zimmer-Wohnung',
        },
        fourBedroom_translate: {
            eng: '4-Bedroom',
            rus: '4-Комнатная Квартира',
            ukr: '4-Кімнатна Квартира',
            deu: '4-Zimmer-Wohnung',
        },
        fiveBedroom_translate: {
            eng: '5-Bedroom',
            rus: '5-Комнатная Квартира',
            ukr: '5-Кімнатна Квартира',
            deu: '5-Zimmer-Wohnung',
        },
        penthouse_translate: {
            eng: 'Penthouse',
            rus: 'Пентхаус',
            ukr: 'Пентхаус',
            deu: 'Penthouse',
        },
        utilitySystems_translate: {
            eng: 'Utility Systems',
            rus: 'Коммунальные Системы',
            ukr: 'Комунальні Системи',
            deu: 'Versorgungssysteme',
        },
        property_add_financial_price_placeholder: {
            eng: 'Enter price',
            rus: 'Введите цену',
            ukr: 'Введіть ціну',
            deu: 'Preis eingeben',
        },
        property_add_financial_currency: {
            eng: 'Currency',
            rus: 'Валюта',
            ukr: 'Валюта',
            deu: 'Währung',
        },
        property_add_financial_currency_help: {
            eng: 'Select currency',
            rus: 'Выберите валюту',
            ukr: 'Виберіть валюту',
            deu: 'Währung wählen',
        },
        property_add_main_photo_title: {
            eng: 'Main photo of the property',
            rus: 'Главное фото объекта',
            ukr: 'Головне фото власності',
            deu: 'Hauptfoto des Immobilie',
        },
        property_add_photos_title: {
            eng: 'Photos of the property',
            rus: 'Фотографии объекта',
            ukr: 'Фото власності',
            deu: 'Fotos der Immobilie',
        },
        complex_add_photos_title: {
            eng: 'Photos of the complex*',
            rus: 'Фотографии комплекса*',
            ukr: 'Фото комплексу*',
            deu: 'Fotos des Komplexes*',
        },
        image_uploader_title: {
            eng: 'Photos',
            rus: 'Фотографии',
            ukr: 'Фото',
            deu: 'Fotos',
        },
        image_uploader_clear: {
            eng: 'Clear all',
            rus: 'Очистить все',
            ukr: 'Очистити все',
            deu: 'Alle löschen',
        },
        image_uploader_drop: {
            eng: 'Drop image files to upload (or click)',
            rus: 'Перетащите файлы изображений для загрузки (или нажмите)',
            ukr: 'Перетягніть файли зображень для завантаження (або натисніть)',
            deu: 'Bilddateien zum Hochladen ablegen (oder klicken)',
        },
        image_uploader_formats: {
            eng: '(jpg, jpeg, png, webp)',
            rus: '(jpg, jpeg, png, webp)',
            ukr: '(jpg, jpeg, png, webp)',
            deu: '(jpg, jpeg, png, webp)',
        },
        image_uploader_file_count: {
            eng: 'Maximum count of files',
            rus: 'Максимальное количество файлов',
            ukr: 'Максимальна кількість файлів',
            deu: 'Maximale Anzahl an Dateien',
        },
        image_uploader_file_count_alarm: {
            eng: 'Maximum count of files reached',
            rus: 'Максимальное количество файлов достигнуто',
            ukr: 'Максимальна кількість файлів досягнута',
            deu: 'Maximale Anzahl an Dateien erreicht',
        },
        image_uploader_file_count_min: {
            eng: 'Minimum count of files',
            rus: 'Минимальное количество файлов',
            ukr: 'Мінімальна кількість файлів',
            deu: 'Mindestanzahl an Dateien',
        },
        image_uploader_file_count_min_warning: {
            eng: 'Minimum count of files',
            rus: 'Минимальное количество файлов',
            ukr: 'Мінімальна кількість файлів',
            deu: 'Mindestanzahl an Dateien',
        },
        image_uploader_file_size: {
            eng: 'Maximum size of each image',
            rus: 'Максимальный размер каждого изображения',
            ukr: 'Максимальний розмір кожного зображення',
            deu: 'Maximale Größe jedes Bildes',
        },
        property_add_loader_clear: {
            eng: 'Clear all',
            rus: 'Очистить все',
            ukr: 'Очистити все',
            deu: 'Alle löschen',
        },
        property_add_loader_drop: {
            eng: 'Drop image files to upload (or click)',
            rus: 'Перетащите файлы изображений для загрузки (или нажмите)',
            ukr: 'Перетягніть файли зображень для завантаження (або натисніть)',
            deu: 'Bilddateien zum Hochladen ablegen (oder klicken)',
        },
        add_translate: {
            eng: 'Add',
            rus: 'Добавить',
            ukr: 'Додати',
            deu: 'Hinzufügen',
        },
        constructionProgress_translate: {
            eng: 'Construction Progress',
            rus: 'Ход Строительства',
            ukr: 'Хід Будівництва',
            deu: 'Baufortschritt',
        },
        constructionNews_translate: {
            eng: 'Construction News',
            rus: 'Новости Строительства',
            ukr: 'Новини Будівництва',
            deu: 'Bau Nachrichten',
        },
        property_add_loader_formats: {
            eng: '(jpg, jpeg, png, gif)',
            rus: '(jpg, jpeg, png, gif)',
            ukr: '(jpg, jpeg, png, gif)',
            deu: '(jpg, jpeg, png, gif)',
        },
        property_add_loader_file_count: {
            eng: 'Maximum number of files',
            rus: 'Максимальное количество файлов',
            ukr: 'Максимальна кількість файлів',
            deu: 'Maximale Anzahl an Dateien',
        },
        property_add_loader_file_count_min: {
            eng: 'Minimum number of files',
            rus: 'Минимальное количество файлов',
            ukr: 'Мінімальна кількість файлів',
            deu: 'Mindestanzahl an Dateien',
        },
        property_add_loader_file_size: {
            eng: 'Maximum size of each image',
            rus: 'Максимальный размер каждого изображения',
            ukr: 'Максимальний розмір кожного зображення',
            deu: 'Maximale Größe jedes Bildes',
        },
        property_add_documents_title: {
            eng: 'Agency Agreement (or Exclusive Agreement)',
            rus: 'Агентский договор (или договор эксклюзива)',
            ukr: 'Агентський договір (або договір ексклюзиву)',
            deu: 'Maklervertrag (oder Exklusivvertrag)',
        },
        property_add_documents_subtitle: {
            eng: 'Images of documents',
            rus: 'Изображения документов',
            ukr: 'Зображення документів',
            deu: 'Bilder von Dokumenten',
        },
        property_add_documents_agreements_title: {
            eng: 'Exclusive agreements',
            rus: 'Эксклюзивные соглашения',
            ukr: 'Ексклюзивні угоди',
            deu: 'Exklusivvereinbarungen',
        },
        requests_add_title: {
            eng: 'Add request',
            rus: 'Добавить заявку',
            ukr: 'Додати заявку',
            deu: 'Anfrage absenden',
        },
        requests_add_tab_property: {
            eng: 'Property',
            rus: 'Недвижимость',
            ukr: 'Нерухомість',
            deu: 'Eigenschaft',
        },
        requests_add_tab_specialist: {
            eng: 'Specialist',
            rus: 'Специалист',
            ukr: 'Фахівець',
            deu: 'Spezialist',
        },
        requests_add_property_text: {
            eng: 'The user can create an application for choosing property that is of interest to him. This application is sent to all brokers registered on the platform according to the specified search options.',
            rus: 'Пользователь может создать заявку на выбор интересующей его недвижимости. Данная заявка рассылается всем брокерам, зарегистрированным на платформе по указанным параметрам поиска.',
            ukr: 'Користувач може створити заявку на вибір нерухомості, що його цікавить. Ця заявка надсилається всім брокерам, зареєстрованим на платформі за вказаними параметрами пошуку.',
            deu: 'Der Benutzer kann eine Anwendung erstellen, um Immobilien auszuwählen, die ihn interessieren. Dieser Antrag wird an alle auf der Plattform registrierten Makler gemäß den angegebenen Suchoptionen gesendet.',
        },
        requests_add_property_type: {
            eng: 'Type of property',
            rus: 'Тип недвижимости',
            ukr: 'Тип нерухомості',
            deu: 'Art der Eigenschaft',
        },
        requests_add_property_country: {
            eng: 'Country',
            rus: 'Страна',
            ukr: 'Країна',
            deu: 'Land',
        },
        requests_add_property_sub_type: {
            eng: 'Type',
            rus: 'Тип',
            ukr: 'Тип',
            deu: 'Typ',
        },
        requests_add_property_city: {
            eng: 'City',
            rus: 'Город',
            ukr: 'Місто',
            deu: 'Stadt',
        },
        requests_add_property_district: {
            eng: 'District',
            rus: 'Округ',
            ukr: 'Округ',
            deu: 'Bezirk',
        },
        requests_add_property_space_from: {
            eng: 'Space (sq.m.) from:',
            rus: 'Площадь (кв.м.) от:',
            ukr: 'Площа (кв.м.) від:',
            deu: 'Fläche (qm) von:',
        },
        requests_add_property_space_to: {
            eng: 'Space (sq.m.) to:',
            rus: 'Площадь (кв.м.) до:',
            ukr: 'Площа (кв.м.) до:',
            deu: 'Fläche (qm) bis:',
        },
        requests_add_property_price_from: {
            eng: 'Price from (EURO):',
            rus: 'Цена от (EURO):',
            ukr: 'Ціна від (EURO):',
            deu: 'Preis ab (EURO):',
        },
        requests_add_property_price_to: {
            eng: 'Price to (EURO):',
            rus: 'Цена до (EURO):',
            ukr: 'Ціна до (EURO):',
            deu: 'Preis bis (EURO):',
        },
        requests_add_property_submit: {
            eng: 'Submit request',
            rus: 'Отправить заявку',
            ukr: 'Надіслати заявку',
            deu: 'Anfrage senden',
        },
        requests_add_specialist_text: {
            eng: 'The user can create an application for choosing necessary specialists. This application is sent to all specialists registered on the platform.',
            rus: 'Пользователь может создать заявку на подбор необходимых специалистов. Данная заявка рассылается всем специалистам, зарегистрированным на платформе.',
            ukr: 'Користувач може створити заявку на вибір необхідних фахівців. Ця заявка надсилається всім фахівцям, зареєстрованим на платформі.',
            deu: 'Der Benutzer kann eine Anwendung zur Auswahl der erforderlichen Spezialisten erstellen. Dieser Antrag wird an alle auf der Plattform registrierten Spezialisten versendet.',
        },
        requests_add_specialist_role: {
            eng: 'Role',
            rus: 'Роль',
            ukr: 'Роль',
            deu: 'Rolle',
        },
        requests_add_specialist_country: {
            eng: 'Country',
            rus: 'Страна',
            ukr: 'Країна',
            deu: 'Land',
        },
        requests_add_specialist_city: {
            eng: 'City',
            rus: 'Город',
            ukr: 'Місто',
            deu: 'Stadt',
        },
        requests_add_specialist_intentions: {
            eng: 'Intentions',
            rus: 'Намерения',
            ukr: 'Наміри',
            deu: 'Absichten',
        },
        requests_add_specialist_submit: {
            eng: 'Submit request',
            rus: 'Отправить заявку',
            ukr: 'Надіслати заявку',
            deu: 'Anfrage senden',
        },
        profile_edit_title: {
            eng: 'Edit your profile',
            rus: 'Редактировать профиль',
            ukr: 'Редагувати профіль',
            deu: 'Profil bearbeiten',
        },
        complex_overview: {
            eng: 'Complex Overview',
            rus: 'Обзор Комплексов',
            ukr: 'Огляд Комплексів',
            deu: 'Komplexer Überblick',
        },
        requests_list_title_real_estate: {
            eng: 'Real estate application',
            rus: 'Заявка на недвижимость',
            ukr: 'Заявка на нерухомість',
            deu: 'Immobilien-Anfrage',
        },
        requests_list_title_specialist: {
            eng: 'Application for a specialist',
            rus: 'Заявка на специалиста',
            ukr: 'Заявка на спеціаліста',
            deu: 'Anfrage für einen Spezialisten',
        },
        requests_list_add_button: {
            eng: 'Add request',
            rus: 'Добавить заявку',
            ukr: 'Додати заяву',
            deu: 'Anfrage hinzufügen',
        },
        requests_list_tab_property: {
            eng: 'Property',
            rus: 'Недвижимость',
            ukr: 'Нерухомість',
            deu: 'Eigenschaft',
        },
        requests_list_tab_specialist: {
            eng: 'Specialist',
            rus: 'Специалист',
            ukr: 'Фахівець',
            deu: 'Spezialist',
        },
        requests_list_not_found: {
            eng: 'Nothing was found for your request',
            rus: 'По вашему запросу ничего не найдено',
            ukr: 'За вашим запитом нічого не знайдено',
            deu: 'Für Ihre Anfrage wurde nichts gefunden',
        },
        requests_specialist_description: {
            eng: 'Description',
            rus: 'Описание',
            ukr: 'Опис',
            deu: 'Beschreibung',
        },
        profile_secondary_form_education: {
            eng: 'Licenses and Educations',
            rus: 'Лицензии и образование',
            ukr: 'Ліцензії та освіта',
            deu: 'Lizenzen und Ausbildungen',
        },
        profile_secondary_form_education_sub_licenses: {
            eng: 'Licenses',
            rus: 'Лицензии',
            ukr: 'Ліцензії',
            deu: 'Lizenzen',
        },
        profile_secondary_form_education_sub_education: {
            eng: 'Education',
            rus: 'Образование',
            ukr: 'Освіта',
            deu: 'Bildung',
        },
        profile_secondary_form_education_sub_education_number: {
            eng: 'Certificate of education serial number',
            rus: 'Серийный номер свидетельства об образовании',
            ukr: 'Серійний номер свідоцтва про освіту',
            deu: 'Seriennummer des Bildungszertifikats',
        },
        profile_secondary_form_education_sub_education_faculty: {
            eng: 'University faculty',
            rus: 'Университетский факультет',
            ukr: 'Університетський факультет',
            deu: 'Universitätsfakultät',
        },
        profile_secondary_form_education_sub_education_speciality: {
            eng: 'Speciality',
            rus: 'Специальность',
            ukr: 'Спеціальність',
            deu: 'Spezialität',
        },
        profile_secondary_form_education_sub_education_start: {
            eng: 'Starting date',
            rus: 'Дата начала',
            ukr: 'Дата початку',
            deu: 'Startdatum',
        },
        profile_secondary_form_education_sub_education_end: {
            eng: 'Date of graduation',
            rus: 'Дата выпуска',
            ukr: 'Дата випуску',
            deu: 'Abschlussdatum',
        },
        profile_secondary_form_education_sub_education_scan: {
            eng: 'Upload dimploma scan',
            rus: 'Загрузить скан диплома',
            ukr: 'Завантажити скан диплому',
            deu: 'Diplomscan hochladen',
        },
        profile_secondary_form_education_sub_education_add: {
            eng: 'Add',
            rus: 'Добавить',
            ukr: 'Додати',
            deu: 'Hinzufügen',
        },
        profile_secondary_form_education_sub_education_remove: {
            eng: 'Delete',
            rus: 'Удалить',
            ukr: 'Видалити',
            deu: 'Löschen',
        },
        news_article_message: {
            eng: 'Read this article on GREM platform',
            rus: 'Читать эту статью на платформе GREM',
            ukr: 'Читати цю статтю на платформі GREM',
            deu: 'Diesen Artikel auf der GREM-Plattform lesen',
        },
        news_article_share: {
            eng: 'Share:',
            rus: 'Поделиться:',
            ukr: 'Поділитися:',
            deu: 'Teilen:',
        },
        properties_menu_share: {
            eng: 'Share',
            rus: 'Поделиться',
            ukr: 'Поділитися',
            deu: 'Teilen',
        },
        mark_all_as_read_translation: {
            eng: 'Mark all as read',
            rus: 'Отметить все как прочитанные',
            ukr: 'Позначити всі як прочитані',
            deu: 'Alle als gelesen markieren',
        },
        type_here_translation: {
            eng: 'Type here...',
            rus: 'Введите здесь...',
            ukr: 'Введіть тут...',
            deu: 'Hier eingeben...',
        },
        send_message_translation: {
            eng: 'Send message',
            rus: 'Отправить сообщение',
            ukr: 'Надіслати повідомлення',
            deu: 'Nachricht senden',
        },
        profile_settings_password_title: {
            eng: 'Your password',
            rus: 'Ваш пароль',
            ukr: 'Ваш пароль',
            deu: 'Ihr Passwort',
        },
        profile_settings_password_changed: {
            eng: 'Password changed',
            rus: 'Пароль изменен',
            ukr: 'Пароль змінено',
            deu: 'Passwort geändert'
        },
        profile_settings_password_change_button: {
            eng: 'Change password',
            rus: 'Изменить пароль',
            ukr: 'Змінити пароль',
            deu: 'Passwort ändern',
        },
        profile_settings_password_current: {
            eng: 'Current password',
            rus: 'Текущий пароль',
            ukr: 'Поточний пароль',
            deu: 'Aktuelles Passwort',
        },
        profile_settings_password_new: {
            eng: 'New password',
            rus: 'Новый пароль',
            ukr: 'Новий пароль',
            deu: 'Neues Passwort',
        },
        layout_error_title: {
            eng: 'Something went wrong...',
            rus: 'Что-то пошло не так...',
            ukr: 'Щось пішло не так...',
            deu: 'Etwas ist schief gelaufen...',
        },
        layout_error_reset_button: {
            eng: 'Try again',
            rus: 'Попробовать снова',
            ukr: 'Спробувати знову',
            deu: 'Erneut versuchen',
        },
        notification_title: {
            eng: 'Notifications',
            rus: 'Нотификации',
            ukr: 'Сповіщення',
            deu: 'Benachrichtigungen',
        },
        profile_settings_password_new_repeat: {
            eng: 'Repeat password',
            rus: 'Повторите пароль',
            ukr: 'Повторіть пароль',
            deu: 'Passwort wiederholen',
        },
        profile_settings_password_change: {
            eng: 'Change',
            rus: 'Изменить',
            ukr: 'Змінити',
            deu: 'Ändern',
        },
        profile_settings_notification_2fa: {
            eng: 'Two-factor authentication',
            rus: 'Двухфакторная аутентификация',
            ukr: 'Двофакторна аутентифікація',
            deu: 'Zwei-Faktor-Authentifizierung',
        },
        profile_settings_notification_main: {
            eng: 'Receive notifications from the GREM platform',
            rus: 'Получать уведомления от платформы GREM',
            ukr: 'Отримувати сповіщення від платформи GREM',
            deu: 'Benachrichtigungen von der GREM-Plattform erhalten',
        },
        profile_settings_notification_actions: {
            eng: 'Event notifications',
            rus: 'Уведомления о событиях',
            ukr: 'Сповіщення про події',
            deu: 'Benachrichtigungen über Ereignisse',
        },
        profile_settings_notification_news: {
            eng: 'News notifications',
            rus: 'Уведомления о новостях',
            ukr: 'Сповіщення про новини',
            deu: 'Benachrichtigungen über Neuigkeiten',
        },
        profile_settings_sessions_title: {
            eng: 'Active sessions',
            rus: 'Активные сессии',
            ukr: 'Активні сесії',
            deu: 'Aktive Sitzungen',
        },
        profile_settings_sessions_close_all: {
            eng: 'Close sessions on other devices',
            rus: 'Завершить сеансы на других устройствах',
            ukr: 'Завершити сеанси на інших пристроях',
            deu: 'Sitzungen auf anderen Geräten beenden',
        },
        profile_settings_sessions_current: {
            eng: 'Current session',
            rus: 'Текущий сеанс',
            ukr: 'Поточний сеанс',
            deu: 'Aktuelle Sitzung',
        },
        layout_lang_menu_eng: {
            eng: 'ENG',
            rus: 'ENG',
            ukr: 'ENG',
            deu: 'ENG',
        },
        layout_lang_menu_ukr: {
            eng: 'UKR',
            rus: 'UKR',
            ukr: 'UKR',
            deu: 'UKR',
        },
        layout_lang_menu_rus: {
            eng: 'RUS',
            rus: 'RUS',
            ukr: 'RUS',
            deu: 'RUS',
        },
        layout_lang_menu_deu: {
            eng: 'DEU',
            rus: 'DEU',
            ukr: 'DEU',
            deu: 'DEU',
        },
        layout_auth_header_logo: {
            eng: 'GREM Logo',
            rus: 'Логотип GREM',
            ukr: 'Логотип GREM',
            deu: 'GREM-Logo',
        },
        layout_auth_header_signin: {
            eng: 'Sign in',
            rus: 'Вход в личный кабинет',
            ukr: 'Вхід в особистий кабінет',
            deu: 'Anmeldung',
        },
        layout_auth_header_signup: {
            eng: 'Sign up',
            rus: 'регистрация на платформе',
            ukr: 'Реєстрація на платформі',
            deu: 'Registrierung auf der Plattform',
        },
        layout_cabinet_header_logo: {
            eng: 'GREM Logo',
            rus: 'Логотип GREM',
            ukr: 'Логотип GREM',
            deu: 'GREM-Logo',
        },
        layout_cabinet_header_profile_menu_my: {
            eng: 'My profile',
            rus: 'Мой профиль',
            ukr: 'Мій профіль',
            deu: 'Mein Profil',
        },
        layout_cabinet_header_profile_menu_exit: {
            eng: 'Exit',
            rus: 'Выход',
            ukr: 'Вихід',
            deu: 'Beenden',
        },
        layout_footer_about: {
            eng: 'About us',
            rus: 'О нас',
            ukr: 'Про нас',
            deu: 'Über uns',
        },
        layout_footer_projects: {
            eng: 'Development projects',
            rus: 'Девелоперские проекты',
            ukr: 'Девелоперські проекти',
            deu: 'Entwicklungsprojekte',
        },
        layout_footer_complex: {
            eng: 'New building supermarket',
            rus: 'Супермаркет-новостроек',
            ukr: 'Супермаркет-новобудов',
            deu: 'Neubau-Supermarkt',
        },
        layout_footer_property: {
            eng: 'Real estate',
            rus: 'Недвижимость',
            ukr: 'Нерухомість',
            deu: 'Eigenschaft',
        },
        layout_footer_community: {
            eng: 'Community',
            rus: 'Сообщество',
            ukr: 'Спільнота',
            deu: 'Gemeinschaft',
        },
        layout_footer_requests: {
            eng: 'Requests',
            rus: 'Смарт-заявка',
            ukr: 'Смарт-заявка',
            deu: 'Smart-Anfrage',
        },
        layout_footer_articles: {
            eng: 'News',
            rus: 'Новости',
            ukr: 'Новини',
            deu: 'Nachrichten',
        },
        layout_footer_contacts: {
            eng: 'Contacts',
            rus: 'Контакты',
            ukr: 'Контакти',
            deu: 'Kontakte',
        },
        layout_cabinet_drawer_menu_contact: {
            eng: 'Contacts',
            rus: 'Контакты',
            ukr: 'Контакти',
            deu: 'Kontakte',
        },
        layout_cabinet_drawer_menu_knowledge_base: {
            eng: 'Knowledge base',
            rus: 'База знаний',
            ukr: 'База знань',
            deu: 'Wissensbasis',
        },
        layout_cabinet_drawer_menu_community: {
            eng: 'Community',
            rus: 'Сообщество',
            ukr: 'Спільнота',
            deu: 'Gemeinschaft',
        },
        layout_cabinet_drawer_menu_properties: {
            eng: 'Real estate',
            rus: 'Недвижимость',
            ukr: 'Нерухомість',
            deu: 'Eigenschaft',
        },
        layout_cabinet_drawer_menu_projects: {
            eng: 'Investment projects',
            rus: 'Инвестиционные проекты',
            ukr: 'Інвестиційні проекти',
            deu: 'Investitionsvorhaben',
        },
        layout_cabinet_drawer_menu_exchange: {
            eng: 'Exchange',
            rus: 'Обмен',
            ukr: 'Обмін',
            deu: 'Austausch',
        },
        layout_cabinet_drawer_menu_distribution: {
            eng: 'Distribution',
            rus: 'Распределение',
            ukr: 'Розподіл',
            deu: 'Verteilung',
        },
        layout_cabinet_drawer_menu_transfer: {
            eng: 'Transfer',
            rus: 'Перевод',
            ukr: 'Переказ',
            deu: 'Übertragung',
        },
        total_amount_translation: {
            eng: 'Total amount',
            rus: 'Общая сумма',
            ukr: 'Загальна сума',
            deu: 'Gesamtbetrag',
        },
        withdraw_types_translation: {
            eng: 'Withdraw types',
            rus: 'Типы вывода',
            ukr: 'Типи виведення',
            deu: 'Auszahlungstypen',
        },
        withdraw_fiat_translation: {
            eng: 'Withdraw Fiat',
            rus: 'Вывести Фиат',
            ukr: 'Вивести Фіат',
            deu: 'Fiat Abheben',
        },
        withdraw_crypto_translation: {
            eng: 'Withdraw Crypto',
            rus: 'Вывести Криптовалюту',
            ukr: 'Вивести Криптовалюту',
            deu: 'Krypto Abheben',
        },
        withdraw_method_translation: {
            eng: 'Withdraw method',
            rus: 'Способ вывода',
            ukr: 'Спосіб виведення',
            deu: 'Auszahlungsmethode',
        },
        withdraw_crypto_to_wallet_translation: {
            eng: 'Withdraw crypto currency to your external wallet',
            rus: 'Вывести криптовалюту на ваш внешний кошелек',
            ukr: 'Вивести криптовалюту на ваш зовнішній гаманець',
            deu: 'Kryptowährung auf Ihr externes Wallet abheben',
        },
        withdraw_fiat_to_wallet_translation: {
            eng: 'Withdraw fiat currency to your external wallet',
            rus: 'Вывести фиатную валюту на ваш внешний кошелек',
            ukr: 'Вивести фіатну валюту на ваш зовнішній гаманець',
            deu: 'Fiat-Währung auf Ihr externes Wallet abheben',
        },
        deposit_types_translation: {
            eng: 'Deposit types',
            rus: 'Типы депозитов',
            ukr: 'Типи депозитів',
            deu: 'Einlagearten',
        },
        deposit_fiat_translation: {
            eng: 'Deposit fiat currency via bank transfer, credit card, or other payment methods.',
            rus: 'Внесите фиатную валюту через банковский перевод, кредитную карту или другие способы оплаты.',
            ukr: 'Внесіть фіатну валюту через банківський переказ, кредитну картку або інші способи оплати.',
            deu: 'Hinterlegen Sie Fiat-Währung per Banküberweisung, Kreditkarte oder andere Zahlungsmethoden.',
        },
        crypto_deposit_address_translation: {
            eng: 'Get the deposit address for BTC, ETH, or any other crypto and deposit via the blockchain.',
            rus: 'Получите адрес для депозита BTC, ETH или любой другой криптовалюты и внесите депозит через блокчейн.',
            ukr: 'Отримайте адресу для депозиту BTC, ETH або будь-якої іншої криптовалюти та внесіть депозит через блокчейн.',
            deu: 'Holen Sie sich die Einzahlungsadresse für BTC, ETH oder eine andere Krypto und zahlen Sie über die Blockchain ein.',
        },
        deposits_fiat_translation: {
            eng: 'Deposit Fiat',
            rus: 'Пополнить Фиат',
            ukr: 'Поповнити Фіат',
            deu: 'Fiat Einzahlen',
        },
        layout_cabinet_drawer_menu_complexes: {
            eng: 'New building supermarket',
            rus: 'Супермаркет новостроек',
            ukr: 'Супермаркет новобудов',
            deu: 'Supermarkt für Neubauten',
        },
        layout_cabinet_drawer_menu_requests: {
            eng: 'Smart requests',
            rus: 'Смарт-заявки',
            ukr: 'Смарт-заявки',
            deu: 'Smart Tickets',
        },
        layout_cabinet_drawer_menu_news: {
            eng: 'News',
            rus: 'Новости',
            ukr: 'Новини',
            deu: 'Neuigkeiten',
        },
        layout_cabinet_drawer_menu_support: {
            eng: 'Support',
            rus: 'Поддержка',
            ukr: 'Підтримка',
            deu: 'Unterstützung',
        },
        new_ticket: {
            eng: 'Create ticket',
            rus: 'Создать тикет',
            ukr: 'Створити тікет',
            deu: 'Ticket erstellen',
        },
        new_ticket_info: {
            eng: 'Create tickets for quick issue resolution',
            rus: 'Создавайте тикеты для быстрого решения вопросов',
            ukr: 'Створюйте квитки для швидкого вирішення питань',
            deu: 'Erstellen Sie Tickets zur schnellen Lösung von Problemen',
        },
        add_a_comment_translation: {
            eng: 'Add a comment:',
            rus: 'Добавить комментарий:',
            ukr: 'Додати коментар:',
            deu: 'Einen Kommentar hinzufügen:',
        },
        close_ticket_translation: {
            eng: 'Close Ticket',
            rus: 'Закрыть тикет',
            ukr: 'Закрити тiкет',
            deu: 'Ticket schließen',
        },
        comment_translation: {
            eng: 'Comment',
            rus: 'Комментарий',
            ukr: 'Коментар',
            deu: 'Kommentar',
        },
        no_comments_yet_translation: {
            eng: 'No comments yet',
            rus: 'Пока нет комментариев',
            ukr: 'Ще немає коментарів',
            deu: 'Noch keine Kommentare',
        },
        created_at_translation: {
            eng: 'created at',
            rus: 'создано в',
            ukr: 'створено о',
            deu: 'erstellt am',
        },
        updated_at_translation: {
            eng: 'Updated at',
            rus: 'Дата обновления',
            ukr: 'Дата оновлення',
            deu: 'Datum der Aktualisierung',
        },
        updated_translation: {
            eng: 'Updated',
            rus: 'Обновлено',
            ukr: 'Оновлено',
            deu: 'Aktualisiert',
        },

        auth_login_social: {
            eng: 'Quick login via social networks',
            rus: 'Быстрый вход через социальные сети',
            ukr: 'Швидкий вхід через соціальні мережі',
            deu: 'Schnelle Anmeldung über soziale Netzwerke',
        },
        auth_forgot_title: {
            eng: 'Restore access',
            rus: 'Восстановление доступа',
            ukr: 'Відновлення доступу',
            deu: 'Zugriff wiederherstellen',
        },
        auth_forgot_remember: {
            eng: 'I remembered my password!',
            rus: 'Я вспомнил свой пароль!',
            ukr: 'Я згадав свій пароль!',
            deu: 'Ich habe mich an mein Passwort erinnert!',
        },
        auth_forgot_send: {
            eng: 'Submit',
            rus: 'Отправить',
            ukr: 'Надіслати',
            deu: 'Senden',
        },
        auth_forgot_new_title: {
            eng: 'Change password',
            rus: 'Изменение пароля',
            ukr: 'Зміна пароля',
            deu: 'Passwort ändern',
        },
        auth_forgot_new_pass: {
            eng: 'Enter new password',
            rus: 'Введите новый пароль',
            ukr: 'Введіть новий пароль',
            deu: 'Neues Passwort eingeben',
        },
        auth_forgot_new_pass_repeat: {
            eng: 'Confirm new password',
            rus: 'Подтвердите новый пароль',
            ukr: 'Підтвердіть новий пароль',
            deu: 'Neues Passwort bestätigen',
        },
        auth_forgot_new_pass_help_min_len: {
            eng: 'Minimum 8 characters',
            rus: 'Минимум 8 символов',
            ukr: 'Мінімум 8 символів',
            deu: 'Mindestens 8 Zeichen',
        },
        auth_forgot_new_remember: {
            eng: 'I remembered my password!',
            rus: 'Я вспомнил свой пароль!',
            ukr: 'Я згадав свій пароль!',
            deu: 'Ich habe mich an mein Passwort erinnert!',
        },
        auth_forgot_new_send: {
            eng: 'SUBMIT',
            rus: 'ОТПРАВИТЬ',
            ukr: 'НАДСЛАТИ',
            deu: 'SENDEN',
        },
        auth_forgot_message_title: {
            eng: 'Check your email!',
            rus: 'Проверьте email!',
            ukr: 'Перевірте email!',
            deu: 'Prüfen Sie Ihre E-Mail!',
        },
        auth_forgot_message_text_1: {
            eng: 'A link to reset your password has been sent to your email.',
            rus: 'Ссылка на восстановление пароля была отправлена Вам на почту.',
            ukr: 'Посилання на відновлення пароля було надіслано Вам на пошту.',
            deu: 'Ein Link zum Zurücksetzen Ihres Passworts wurde an Ihre E-Mail gesendet.',
        },
        auth_forgot_message_text_2: {
            eng: 'Go to it and set a new password.',
            rus: 'Перейдите по ней и задайте новый пароль.',
            ukr: 'Перейдіть по ній і введіть новий пароль.',
            deu: 'Gehen Sie dorthin und legen Sie ein neues Passwort fest.',
        },
        auth_forgot_message_text_3: {
            eng: "Don't forget to check your spam folder",
            rus: 'Не забудьте проверить папку "Спам"',
            ukr: 'Не забудьте перевірити папку "Спам"',
            deu: 'Vergessen Sie nicht, Ihren Spam-Ordner zu überprüfen',
        },
        auth_forgot_message_text_4: {
            eng: 'Return to the platform',
            rus: 'вернуться на платформу',
            ukr: 'повернутися на платформу',
            deu: 'Zurück zur Plattform',
        },
        auth_forgot_message_grem_logo: {
            eng: 'GREM logo',
            rus: 'GREM лого',
            ukr: 'GREM лого',
            deu: 'GREM-Logo',
        },
        auth_login_title: {
            eng: 'Login to your account',
            rus: 'Вход в личный кабинет',
            ukr: 'Увійти в особистий кабінет',
            deu: 'Anmelden in Ihr Konto',
        },
        auth_login_resend_email: {
            eng: 'Resend email',
            rus: 'Отправить письмо повторно',
            ukr: 'Надіслати листа повторно',
            deu: 'E-Mail erneut senden',
        },
        auth_login_email: {
            eng: 'Your email',
            rus: 'Ваша почта',
            ukr: 'Ваша пошта',
            deu: 'Ihre E-Mail',
        },
        auth_login_password: {
            eng: 'Your password',
            rus: 'Ваш пароль',
            ukr: 'Ваш пароль',
            deu: 'Ihr Passwort',
        },
        auth_login_forget_password: {
            eng: 'Forgot your password?',
            rus: 'Забыли пароль?',
            ukr: 'Забули пароль?',
            deu: 'Passwort vergessen?',
        },
        auth_login_remember_me: {
            eng: 'Remember me',
            rus: 'Запомнить меня',
            ukr: "Запам'ятати мене",
            deu: 'An mich erinnern',
        },
        auth_login_send: {
            eng: 'Log in my personal cabinet',
            rus: 'Вход в личный кабинет',
            ukr: 'Увійти в особистий кабінет',
            deu: 'In mein persönliches Kabinett einloggen',
        },
        auth_logout_title: {
            eng: 'You have successfully logged out of your account',
            rus: 'Вы успешно вышли из Вашей учётной записи',
            ukr: 'Ви успішно вийшли з Вашого облікового запису',
            deu: 'Sie haben sich erfolgreich von Ihrem Konto abgemeldet',
        },
        auth_logout_text_1: {
            eng: 'Thank you for using our platform.',
            rus: 'Благодарим Вас за использование нашей платформы.',
            ukr: 'Дякую Вам за використання нашої платформи.',
            deu: 'Vielen Dank, dass Sie unsere Plattform nutzen.',
        },
        auth_logout_text_2: {
            eng: 'We hope to see you again.',
            rus: 'Надеемся увидеть Вас снова.',
            ukr: 'Сподіваємося побачити Вас знов.',
            deu: 'Wir hoffen, Sie wiederzusehen.',
        },
        auth_logout_text_3: {
            eng: 'Click here to return to the platform',
            rus: 'Нажмите сюда, чтобы вернуться на платформу',
            ukr: 'Натисніть сюди, щоб повернутися на платформу',
            deu: 'Klicken Sie hier, um zur Plattform zurückzukehren',
        },
        auth_logout_grem_logo: {
            eng: 'GREM logo',
            rus: 'GREM лого',
            ukr: 'GREM лого',
            deu: 'GREM-Logo',
        },
        auth_register_success_title: {
            eng: 'Thank you for being with us',
            rus: 'Спасибо, что вы с нами',
            ukr: 'Дякую за те, що ви з нами',
            deu: 'Danke, dass Sie bei uns sind',
        },
        auth_register_success_grem_logo: {
            eng: 'GREM logo',
            rus: 'GREM лого',
            ukr: 'GREM лого',
            deu: 'GREM-Logo',
        },
        auth_register_success_text_1: {
            eng: 'The first step of your registration was successful!',
            rus: 'Первый этап вашей регистрации прошел успешно!',
            ukr: 'Перший етап вашої реєстрації пройшов успішно!',
            deu: 'Der erste Schritt Ihrer Registrierung war erfolgreich!',
        },
        auth_register_success_text_2: {
            eng: 'To activate your account, follow the link from the email received after registering on the platform.',
            rus: 'Для активации аккаунта перейдите по ссылке из электронного письма, полученного после регистрации на платформе.',
            ukr: 'Для активації облікового запису перейдіть за посиланням з електронного листа, отриманого після реєстрації на платформі.',
            deu: 'Um Ihr Konto zu aktivieren, folgen Sie dem Link in der E-Mail, die Sie nach der Registrierung auf der Plattform erhalten haben.',
        },
        auth_register_success_text_3: {
            eng: "If you can't find the email in your inbox, please check your spam folder",
            rus: 'Если вы не можете найти письмо во входящих, проверьте папку «Спам».',
            ukr: 'Якщо Ви не можете знайти лист у папці "Вхідні", перевірте папку "Спам"',
            deu: 'Wenn Sie die E-Mail nicht in Ihrem Posteingang finden können, überprüfen Sie bitte Ihren Spam-Ordner',
        },
        auth_register_success_text_4: {
            eng: 'TO MAIN',
            rus: 'НА ГЛАВНУЮ',
            ukr: 'НА ГОЛОВНУ',
            deu: 'ZUR HAUPSACHE',
        },
        page_development_status: {
            eng: 'The page is under development.',
            rus: 'Страница находится в разработке',
            ukr: 'Сторінка знаходиться у розробці.',
            deu: 'Die Seite befindet sich in Entwicklung.',
        },
        page_improvement_message: {
            eng: 'We are currently working on improving this page. We apologize for any inconvenience. Thank you for your understanding and patience!',
            rus: 'Мы в настоящее время работаем над улучшением этой страницы. Приносим извинения за неудобства. Благодарим вас за понимание и терпение!',
            ukr: 'Ми зараз працюємо над покращенням цієї сторінки. Приносимо вибачення за незручності. Дякуємо вам за розуміння та терпіння!',
            deu: 'Wir arbeiten derzeit an der Verbesserung dieser Seite. Wir entschuldigen uns für die Unannehmlichkeiten. Vielen Dank für Ihr Verständnis und Ihre Geduld!',
        },
        auth_register_confirm_title: {
            eng: 'Congratulations!',
            rus: 'Поздравляем!',
            ukr: 'Вітаємо!',
            deu: 'Herzlichen Glückwunsch!',
        },
        auth_register_confirm_grem_logo: {
            eng: 'GREM logo',
            rus: 'GREM лого',
            ukr: 'GREM лого',
            deu: 'GREM-Logo',
        },
        auth_register_confirm_text_1: {
            eng: 'Registration confirmation successful!',
            rus: 'Подтверждение регистрации прошло успешно!',
            ukr: 'Підтвердження реєстрації пройшло успішно!',
            deu: 'Registrierungsbestätigung erfolgreich!',
        },
        auth_register_confirm_text_2: {
            eng: 'You can now login to the platform using your credentials',
            rus: 'Теперь вы можете войти на платформу, используя свои данные',
            ukr: 'Тепер ви можете увійти на платформу, використовуючи свої дані',
            deu: 'Sie können sich jetzt mit Ihren Zugangsdaten bei der Plattform anmelden',
        },
        auth_register_confirm_button: {
            eng: 'Click here to return to the platform',
            rus: 'Нажмите сюда, чтобы вернуться на платформу',
            ukr: 'Натисніть сюди, щоб повернутися на платформу',
            deu: 'Klicken Sie hier, um zur Plattform zurückzukehren',
        },
        auth_register_text: {
            eng: 'Welcome! Become part of the community in a few simple steps',
            rus: 'Добро пожаловать! Станьте частью сообщества в несколько простых шагов',
            ukr: 'Ласкаво просимо! Станьте частиною спільноти за декілька простих кроків',
            deu: 'Willkommen! Werden Sie in wenigen einfachen Schritten Teil der Gemeinschaft',
        },

        auth_main_text: {
            eng: 'Registration on the GREM platform',
            ukr: 'Реєстрація на платформі GREM',
            deu: 'Registrierung auf der GREM-Plattform',
            rus: 'Регистрация на платформе GREM',
        },
        auth_register_helptext_1: {
            eng: 'Enter contact information and confirm email',
            rus: 'Укажите контактные данные и подтвердите почту',
            ukr: 'Вкажіть контактні дані та підтвердіть пошту',
            deu: 'Geben Sie Kontaktinformationen ein und bestätigen Sie die E-Mail',
        },
        auth_register_helptext_2: {
            eng: 'In your account, select the appropriate role and fill in your personal information. Your profile will be sent for moderation.',
            rus: 'В личном кабинете выберите подходящую роль и заполните персональную информацию. Ваш профиль отправится на модерацию.',
            ukr: 'У вашому особистому кабінеті виберіть відповідну роль та заповніть персональну інформацію. Ваш профіль відправиться на модерацію.',
            deu: 'Wählen Sie in Ihrem Konto die entsprechende Rolle aus und füllen Sie Ihre persönlichen Informationen aus. Ihr Profil wird zur Moderation gesendet.',
        },
        auth_register_helptext_3: {
            eng: 'When opening a wallet or entering into a digital contract, we will ask you to complete the KYC procedure, as we give access to our financial instruments. We will go through this stage later.',
            rus: 'При открытии кошелька или заключении цифрового контракта мы попросим вас пройти процедуру KYC, поскольку мы даем доступ к нашим финансовым инструментам. Этот этап мы с вами пройдем позже',
            ukr: 'При відкритті гаманця або укладанні цифрового контракту ми попросимо вас пройти процедуру KYC, оскільки ми надаємо доступ до наших фінансових інструментів. Цей етап ми з вами пройдемо пізніше.',
            deu: 'Beim Öffnen einer Brieftasche oder beim Abschluss eines digitalen Vertrags werden wir Sie bitten, das KYC-Verfahren abzuschließen, da wir Zugang zu unseren Finanzinstrumenten gewähren. Wir werden diese Phase später durchlaufen.',
        },
        auth_register_helptext_4: {
            eng: 'Any questions?',
            rus: 'Любые вопросы?',
            ukr: 'Постали питання?',
            deu: 'Noch Fragen?',
        },
        auth_register_helptext_5: {
            eng: 'Contact us using the feedback form',
            rus: 'Свяжитесь с нами, используя форму обратной связи',
            ukr: "Зв'яжіться з нами за допомогою форми зворотнього зв'язку",
            deu: 'Kontaktieren Sie uns über das Feedback-Formular',
        },
        auth_register_helptext_4_link: {
            eng: 'Ask a question',
            rus: 'Задать вопрос',
            ukr: 'Задати питання',
            deu: 'Eine Frage stellen',
        },
        auth_register_title: {
            eng: 'Account registration',
            rus: 'Регистрация аккаунта',
            ukr: 'Реєстрація облікового запису',
            deu: 'Kontoregistrierung',
        },
        auth_register_form_input_email: {
            eng: 'Enter email',
            rus: 'Введите email',
            ukr: 'Введіть email',
            deu: 'E-Mail eingeben',
        },
        auth_register_form_password: {
            eng: 'Password',
            rus: 'Пароль',
            ukr: 'Пароль',
            deu: 'Passwort',
        },
        auth_register_form_input_password: {
            eng: 'Enter password',
            rus: 'Введите пароль',
            ukr: 'Введіть пароль',
            deu: 'Passwort eingeben',
        },
        auth_register_form_password_help_limit_min: {
            eng: 'Minimum 8 characters',
            rus: 'Минимум 8 символов',
            ukr: 'Мінімум 8 символів',
            deu: 'Mindestens 8 Zeichen',
        },
        auth_register_form_repeat_password: {
            eng: 'Confirm password',
            rus: 'Подтвердите пароль',
            ukr: 'Підтвердіть пароль',
            deu: 'Passwort bestätigen',
        },
        auth_register_form_repeat_password_help_limit_min: {
            eng: 'Minimum 8 characters',
            rus: 'Минимум 8 символов',
            ukr: 'Мінімум 8 символів',
            deu: 'Mindestens 8 Zeichen',
        },
        auth_register_form_fname: {
            eng: 'Name',
            rus: 'Имя',
            ukr: "Ім'я",
            deu: 'Name',
        },
        auth_register_form_input_fname: {
            eng: 'Enter name',
            rus: 'Введите имя',
            ukr: "Введіть ім'я",
            deu: 'Namen eingeben',
        },
        auth_register_form_lname: {
            eng: 'Last name',
            rus: 'Фамилия',
            ukr: 'Прізвище',
            deu: 'Nachname',
        },
        auth_register_form_input_lname: {
            eng: 'Enter last name',
            rus: 'Введите фамилию',
            ukr: 'Введіть прізвище',
            deu: 'Nachnamen eingeben',
        },
        auth_register_form_partner: {
            eng: 'Affiliate code (if any)',
            rus: 'Партнерский код (при наличии)',
            ukr: 'Партнерський код (за наявності)',
            deu: 'Affiliate-Code (falls vorhanden)',
        },
        auth_register_form_input_partner: {
            eng: 'Enter affiliate code',
            rus: 'Введите партнерский код',
            ukr: 'Введіть код партнерства',
            deu: 'Affiliate-Code eingeben',
        },
        auth_register_form_your_partner: {
            eng: 'You were invited by:',
            rus: 'Вас пригласил(а):',
            ukr: 'Вас запросив(а):',
            deu: 'Sie wurden eingeladen von:',
        },
        auth_register_form_accept: {
            eng: 'I agree with',
            rus: 'Я согласен(на) с',
            ukr: 'Я згоден(на) з',
            deu: 'Ich stimme zu',
        },
        auth_register_form_accept_terms: {
            eng: 'Read the Terms and Conditions',
            rus: 'Ознакомьтесь с Положениями и условиями',
            ukr: 'Ознайомтеся з Положеннями та умовами',
            deu: 'Allgemeine Geschäftsbedingungen lesen',
        },
        auth_register_form_terms: {
            eng: ' Terms and Conditions',
            rus: ' Условиями использования',
            ukr: ' Положення та умови',
            deu: ' Allgemeine Geschäftsbedingungen',
        },
        auth_register_form_privacy_policy: {
            eng: 'Privacy Policy',
            rus: 'Политикой конфиденциальности',
            ukr: 'Політикою конфіденційності',
            deu: 'Datenschutzerklärung',
        },
        auth_register_form_accept_privacy_policy: {
            eng: 'Read the Privacy Policy',
            rus: 'Ознакомьтесь с Политикой конфиденциальности',
            ukr: 'Ознайомтеся з Політикою конфіденційності',
            deu: 'Lesen Sie die Datenschutzrichtlinie',
        },
        auth_register_form_send: {
            eng: 'Register',
            rus: 'Зарегистрироваться',
            ukr: 'Зареєструватися',
            deu: 'Registrieren',
        },
        auth_register_social: {
            eng: 'Quick registration via social networks',
            rus: 'Быстрая регистрация через социальные сети',
            ukr: 'Швидка реєстрація через соціальні мережі',
            deu: 'Schnelle Registrierung über soziale Netzwerke',
        },
        support_form_success: {
            eng: 'Form submitted successfully',
            rus: 'Форма успешно отправлена',
            ukr: 'Форму успішно відправлено',
            deu: 'Formular erfolgreich übermittelt',
        },
        support_title: {
            eng: 'Contacts',
            rus: 'Контакты',
            ukr: 'Контакти',
            deu: 'Kontakte',
        },
        support_form_title: {
            eng: 'Fast and quality communication directly',
            rus: 'Быстрая и качественная коммуникация напрямую',
            ukr: 'Швидка та якісна комунікація безпосередньо',
            deu: 'Schnelle und qualitativ hochwertige Kommunikation direkt',
        },
        support_form_name: {
            eng: 'Enter your full name',
            rus: 'Укажите ФИО',
            ukr: 'Вкажіть ПІБ',
            deu: 'Geben Sie Ihren vollständigen Namen ein',
        },
        support_form_email: {
            eng: 'Email',
            rus: 'Электронная почта',
            ukr: 'Електронна пошта',
            deu: 'Email',
        },
        support_form_message: {
            eng: 'Your message',
            rus: 'Ваше сообщение',
            ukr: 'Ваше повідомлення',
            deu: 'Ihre Nachricht',
        },
        support_form_send: {
            eng: 'Submit',
            rus: 'Отправить',
            ukr: 'Надіслати',
            deu: 'Senden',
        },
        support_form_error: {
            eng: 'An error occurred while sending the form',
            rus: 'Произошла ошибка при отправке формы',
            ukr: 'Під час відправлення форми виникла помилка',
            deu: 'Beim Senden des Formulars ist ein Fehler aufgetreten',
        },
        support_info_telegram: {
            eng: 'Customer Support:',
            rus: 'Поддержка клиентов:',
            ukr: 'Підтримка клієнтів:',
            deu: 'Kundenbetreuung:',
        },
        support_info_email: {
            eng: 'Email',
            rus: 'Электронная почта',
            ukr: 'Електронна пошта',
            deu: 'Email',
        },
        refresh: {
            eng: 'Refresh',
            rus: 'Обновить',
            ukr: 'Оновити',
            deu: 'Aktualisierung',
        },
        error_title: {
            eng: 'Something went wrong',
            rus: 'Что-то пошло не так',
            ukr: 'Щось пішло не так',
            deu: 'Etwas ist schief gelaufen',
        },
        page_error_message: {
            eng: "This page does not exist. Let's return to the main page and find what you were looking for.",
            rus: 'Эта страница не существует. Давайте вернемся на главную страницу и найдем то, что вы искали',
            ukr: 'Ця сторінка не існує. Повернімося на головну сторінку та знайдемо те, що ви шукали.',
            deu: 'Diese Seite existiert nicht. Lassen Sie uns zur Hauptseite zurückkehren und finden, wonach Sie gesucht haben.',
        },
        error_main: {
            eng: 'Error',
            rus: 'Ошибка',
            ukr: 'Помилка',
            deu: 'Fehler',
        },
        error_link_back: {
            eng: 'Back',
            rus: 'Назад',
            ukr: 'Назад',
            deu: 'Zurück',
        },
        error_link_main: {
            eng: 'Home',
            rus: 'Главная',
            ukr: 'Головна',
            deu: 'Zuhause',
        },
        error_link_signin: {
            eng: 'Login',
            rus: 'Вход',
            ukr: 'Вхід',
            deu: 'Anmelden',
        },
        error_link_signup: {
            eng: 'Registration',
            rus: 'Регистрация',
            ukr: 'Реєстрація',
            deu: 'Registrierung',
        },
        coming_soon_title: {
            eng: 'Soon',
            rus: 'Вскоре',
            ukr: 'Незабаром',
            deu: 'Bald',
        },
        coming_soon_link_back: {
            eng: 'Back',
            rus: 'Назад',
            ukr: 'Назад',
            deu: 'Zurück',
        },
        coming_soon_link_main: {
            eng: 'Home',
            rus: 'Главная',
            ukr: 'Головна',
            deu: 'Zuhause',
        },
        coming_soon_link_signin: {
            eng: 'Login',
            rus: 'Вход',
            ukr: 'Вхід',
            deu: 'Anmelden',
        },
        coming_soon_link_signup: {
            eng: 'Registration',
            rus: 'Регистрация',
            ukr: 'Реєстрація',
            deu: 'Registrierung',
        },
        profile_title: {
            eng: 'My profile',
            rus: 'Мой профиль',
            ukr: 'Мій профіль',
            deu: 'Mein Profil',
        },
        profile_tabs_profile: {
            eng: 'Profile',
            rus: 'Профиль',
            ukr: 'Профіль',
            deu: 'Profil',
        },
        profile_tabs_properties: {
            eng: 'Property',
            rus: 'Недвижимость',
            ukr: 'Нерухомість',
            deu: 'Eigenschaft',
        },
        profile_tabs_projects: {
            eng: 'Projects',
            rus: 'Проекты',
            ukr: 'Проекти',
            deu: 'Projekte',
        },
        profile_tabs_requests: {
            eng: 'Requests',
            rus: 'Заявки',
            ukr: 'Заявки',
            deu: 'Anwendungen',
        },
        profile_tabs_partners: {
            eng: 'Partner system',
            rus: 'Партнерская система',
            ukr: 'Партнерська система',
            deu: 'Partnersystem',
        },
        profile_tabs_rating: {
            eng: 'Rating',
            rus: 'Рейтинг',
            ukr: 'Рейтинг',
            deu: 'Bewertung',
        },
        profile_tabs_wallet: {
            eng: 'Wallet',
            rus: 'Кошелек',
            ukr: 'Гаманець',
            deu: 'Brieftasche',
        },
        profile_tabs_settings: {
            eng: 'Settings',
            rus: 'Настройки',
            ukr: 'Налаштування',
            deu: 'Einstellungen',
        },
        maximum_amount: {
            eng: 'Maximum Amount',
            rus: 'Максимальная Сумма',
            ukr: 'Максимальна Сума',
            deu: 'Maximalbetrag',
        },
        return_to_tickets: {
            eng: 'Return to Tickets',
            rus: 'Вернуться к Тикетам',
            ukr: 'Повернутися до Тікетiв',
            deu: 'Zurück zu Tickets',
        },

        profile_tabs_adv: {
            eng: 'Advertising cabinet',
            rus: 'Рекламный кабинет',
            ukr: 'Рекламний кабінет',
            deu: 'Werbeschrank',
        },
        profile_kyc_warning_text: {
            eng: 'You need to pass KYC to interact with finance on the platform',
            rus: 'Для взаимодействия с финансами на платформе вам необходимо пройти KYC',
            ukr: 'Для взаємодії з фінансами на платформі вам необхідно пройти KYC',
            deu: 'Sie müssen KYC bestehen, um mit Finanzen auf der Plattform zu interagieren',
        },
        profile_kyc_warning_button: {
            eng: 'Pass KYC',
            rus: 'Пройти KYC',
            ukr: 'Пройти KYC',
            deu: 'KYC bestehen',
        },
        profile_verification_status_reject_title: {
            eng: 'Profile did not pass moderation',
            rus: 'Профиль не прошел модерацию',
            ukr: 'Профіль не пройшов модерацію',
            deu: 'Profil nicht moderiert',
        },
        profile_verification_status_reject_text: {
            eng: 'During the verification process, some inconsistencies were found in the data provided by you. The GREM platform strives to ensure a high standard of quality. For any questions, you can contact customer support',
            rus: 'В процессе проверки выявлены некоторые несоответствия в предоставленных вами данных. Платформа GREM старается обеспечить высокий стандарт качества. По любым вопросам вы можете обратиться в службу поддержки клиентов',
            ukr: 'Під час перевірки виявлені деякі несоответстві в наданих вами даних. Платформа GREM намагається забезпечити високий стандарт якості. З будь-яких питань ви можете звернутися в службу підтримки клієнтів',
            deu: 'Während der Überprüfung wurden einige Unstimmigkeiten in den von Ihnen bereitgestellten Daten festgestellt. Die GREM-Plattform bemüht sich, einen hohen Qualitätsstandard zu gewährleisten. Bei Fragen können Sie sich an den Kundendienst wenden',
        },
        profile_verification_status_pending_title: {
            eng: 'Your profile is under moderation',
            rus: 'Ваш профиль отправлен на проверку',
            ukr: 'Ваш профіль відправлений на перевірку',
            deu: 'Ihr Profil wird moderiert',
        },
        profile_verification_status_pending_text: {
            eng: 'The verification process may take up to 24 hours. We appreciate your patience and are ready to answer all your questions as the verification process is completed. Thank you for trusting the GREM platform!',
            rus: 'Процесс проверки может занять до 24 часов. Мы ценим ваше терпение и готовы ответить на все ваши вопросы по мере завершения проверки. Благодарим за доверие к платформе GREM!',
            ukr: 'Процес перевірки може зайняти до 24 годин. Ми цінуємо ваше терпіння і готові відповісти на всі ваші питання по мірі завершення перевірки. Дякуємо за довіру до платформи GREM!',
            deu: 'Der Überprüfungsprozess kann bis zu 24 Stunden dauern. Wir schätzen Ihre Geduld und sind bereit, alle Ihre Fragen zu beantworten, sobald der Überprüfungsprozess abgeschlossen ist. Vielen Dank für Ihr Vertrauen in die GREM-Plattform!',
        },
        profile_verification_wait_text: {
            eng: 'Your account is already being moderated and all the main functions of the platform will be available to you soon',
            rus: 'Ваш аккаунт уже проходит модерацию и все основные функции платформы вскоре будут доступны для вас',
            ukr: 'Ваш обліковий запис вже проходить модерацію і всі основні функції платформи незабаром будуть доступні для вас',
            deu: 'Ihr Konto wird bereits moderiert und alle Hauptfunktionen der Plattform werden Ihnen bald zur Verfügung stehen',
        },
        profile_verification_wait_button: {
            eng: 'Contact technical support',
            rus: 'Написать в техническую поддержку',
            ukr: 'Написати на технічну підтримку',
            deu: 'Technischen Support kontaktieren',
        },
        profile_verification_text: {
            eng: 'We invite you to fill in the data in your profile about yourself, after which all the main functions of the site will be available to you, except for financial ones',
            rus: 'Приглашаем вас заполнить данные в профиле о себе, после чего вам будут доступны все основные функции сайта, кроме финансовых',
            ukr: 'Запрошуємо вас заповнити дані у профілі про себе, після чого вам будуть доступні всі основні функції сайту, крім фінансових',
            deu: 'Wir laden Sie ein, die Daten in Ihrem Profil über sich selbst einzugeben, danach stehen Ihnen alle Hauptfunktionen der Website zur Verfügung, mit Ausnahme der finanziellen',
        },
        profile_broker_agreement_info_title_1: {
            eng: 'Agreement on',
            rus: 'Договор о',
            ukr: 'Договір про',
            deu: 'Vertrag über',
        },
        profile_broker_agreement_info_title_2: {
            eng: 'payment',
            rus: 'выплате',
            ukr: 'виплаті',
            deu: 'Auszahlung',
        },
        profile_broker_agreement_info_title_3: {
            eng: 'of commission',
            rus: 'комиссии',
            ukr: 'комісії',
            deu: 'Provision',
        },
        profile_broker_agreement_info_text_1: {
            eng: 'To transfer potential clients (leads) to the GREM platform, you need to conclude',
            rus: 'Для передачи потенциальных клиентов (лидов) платформе GREM, необходимо заключить',
            ukr: 'Для передачі потенційних клієнтів (лідів) платформі GREM, необхідно укласти',
            deu: 'Um potenzielle Kunden (Leads) an die GREM-Plattform zu übertragen, müssen Sie abschließen',
        },
        profile_broker_agreement_info_text_2: {
            eng: 'a commission payment agreement',
            rus: 'договор о выплате комиссии',
            ukr: 'договір про виплату комісії',
            deu: 'einen Provisionszahlungsvertrag',
        },
        profile_broker_agreement_info_text_3: {
            eng: ', where you are guaranteed to receive a commission payment in the event of a successful completion of a deal with a potential client (lead) transferred by you.',
            rus: ', где вам гарантируется выплата комиссионного вознаграждения в случае успешного завершения сделки с переданным вами потенциальным клиентом (лидом).',
            ukr: ', де вам гарантується виплата комісійного винагородження в разі успішного завершення угоди з переданим вами потенційним клієнтом (лідом).',
            deu: ', bei dem Ihnen im Falle eines erfolgreichen Abschlusses eines Geschäfts mit einem von Ihnen übertragenen potenziellen Kunden (Lead) eine Provisionszahlung garantiert wird.',
        },
        profile_broker_agreement_info_action_button: {
            eng: 'Sign agreement',
            rus: 'Подписать договор',
            ukr: 'Підписати договір',
            deu: 'Vertrag unterzeichnen',
        },
        profile_broker_agreement_sign_alert_1: {
            eng: 'Platform GREM',
            rus: 'Платформа GREM',
            ukr: 'Платформа GREM',
            deu: 'Plattform GREM',
        },
        profile_broker_agreement_sign_alert_2: {
            eng: 'guarantees',
            rus: 'гарантирует',
            ukr: 'гарантує',
            deu: 'garantiert',
        },
        profile_broker_agreement_sign_alert_3: {
            eng: 'you will be paid a commission on the successful completion of a transaction with a prospective client (lead) referred by you.',
            rus: 'вам выплату комиссии при успешном завершении сделки с переданным вами потенциальным клиентом (лидом).',
            ukr: 'вам виплату комісії при успішному завершенні угоди з переданим вами потенційним клієнтом (лідом).',
            deu: 'eine Provisionszahlung bei erfolgreichem Abschluss eines Geschäfts mit einem von Ihnen übertragenen potenziellen Kunden (Lead).',
        },
        profile_broker_agreement_sign_text: {
            eng: 'To participate in partner sales through the GREM platform and transfer your potential clients (leads), you need to conclude a commission agreement.',
            rus: 'Для участия в партнерских продажах через платформу GREM и передачи ваших потенциальных клиентов (лидов) требуется заключить договор о комиссии.',
            ukr: 'Для участі в партнерських продажах через платформу GREM та передачі ваших потенційних клієнтів (лідів) потрібно укласти договір про комісію.',
            deu: 'Um am Partnerverkauf über die GREM-Plattform teilzunehmen und Ihre potenziellen Kunden (Leads) zu übertragen, müssen Sie einen Provisionsvertrag abschließen.',
        },
        profile_broker_agreement_sign_sub: {
            eng: 'To sign an agreement, enter your data:',
            rus: 'Для оформления договора, внесите свои данные:',
            ukr: 'Для оформлення договору, внесіть свої дані:',
            deu: 'Geben Sie Ihre Daten ein, um einen Vertrag zu unterzeichnen:',
        },
        profile_broker_agreement_sign_field_fname: {
            eng: 'Your first name',
            rus: 'Ваше имя',
            ukr: 'Ваше ім\'я',
            deu: 'Ihr Name',
        },
        profile_broker_agreement_sign_field_lname: {
            eng: 'Your last name',
            rus: 'Ваша фамилия',
            ukr: 'Ваше прізвище',
            deu: 'Ihr Nachname',
        },
        profile_broker_agreement_sign_field_passport: {
            eng: 'Passport number',
            rus: 'Номер паспорта',
            ukr: 'Номер паспорту',
            deu: 'Passnummer',
        },
        profile_broker_agreement_sign_button: {
            eng: 'Sign agreement',
            rus: 'Подписать договор',
            ukr: 'Підписати договір',
            deu: 'Vertrag unterzeichnen',
        },
        profile_broker_agreement_success_title_1: {
            eng: 'Congratulations! You have joined the agreement on',
            rus: 'Поздравляем! Вы присоединились к договору о',
            ukr: 'Вітаємо! Ви приєдналися до договору про',
            deu: 'Herzlichen Glückwunsch! Sie haben den Vertrag über',
        },
        profile_broker_agreement_success_title_2: {
            eng: 'commission',
            rus: 'выплате',
            ukr: 'виплаті',
            deu: 'Provision',
        },
        profile_broker_agreement_success_title_3: {
            eng: 'commissions',
            rus: 'комиссии',
            ukr: 'комісії',
            deu: 'Provision',
        },
        profile_broker_agreement_success_text: {
            eng: 'This agreement provides mutually beneficial cooperation, defining the conditions and transparency in the distribution of commission rewards. We are confident that this partnership will lead to successful and mutually beneficial deals!',
            rus: 'Этот договор обеспечивает взаимовыгодное сотрудничество, определяя условия и прозрачность в распределении комиссионных вознаграждений. Мы уверены, что данное партнерство приведет к успешным и взаимовыгодным сделкам!',
            ukr: 'Цей договір забезпечує взаємовигідне співробітництво, визначаючи умови та прозорість у розподілі комісійних винагород. Ми впевнені, що це партнерство призведе до успішних та взаємовигідних угод!',
            deu: 'Dieser Vertrag gewährleistet eine gegenseitig vorteilhafte Zusammenarbeit, definiert die Bedingungen und Transparenz bei der Verteilung von Provisionsbelohnungen. Wir sind zuversichtlich, dass diese Partnerschaft zu erfolgreichen und gegenseitig vorteilhaften Geschäften führen wird!',
        },
        profile_broker_agreement_success_button: {
            eng: 'Read the agreement',
            rus: 'Прочитать договор',
            ukr: 'Прочитати договір',
            deu: 'Vertrag lesen',
        },
        profile_broker_agreement_sign_form_text: {
            eng: 'The amount of commission paid, which you will receive upon successful closing of the transaction, is individual for each property and is reflected on the property card.',
            rus: 'Сумма выплачиваемого комиссионного вознаграждения, которое вы получите при успешном закрытии сделки, индивидуальна для каждого объекта недвижимости и отражена на карточке объекта.',
            ukr: 'Сума виплачуваного комісійного винагородження, яке ви отримаєте при успішному закритті угоди, індивідуальна для кожного об\'єкта нерухомості та відображена на картці об\'єкта.',
            deu: 'Die Höhe der gezahlten Provision, die Sie bei einem erfolgreichen Abschluss eines Geschäfts erhalten, ist für jedes Immobilienobjekt individuell und wird auf der Objektkarte angezeigt.',
        },
        profile_verification_button: {
            eng: 'Pass moderation',
            rus: 'Пройти модерацию',
            ukr: 'Пройти модерацію',
            deu: 'Moderation bestehen',
        },
        profile_main_title: {
            eng: 'Basic info',
            rus: 'Основная информация',
            ukr: 'Основна інформація',
            deu: 'Basisinfo',
        },
        profile_main_views: {
            eng: 'The number of times your profile has been viewed',
            rus: 'Количество просмотров вашего профиля',
            ukr: 'Кількість переглядів вашого профілю',
            deu: 'Die Anzahl der Aufrufe Ihres Profils',
        },
        profile_main_community_clicks: {
            eng: 'Number of clicks on your profile from the community page',
            rus: 'Количество кликов по вашему профилю с страницы сообщества',
            ukr: 'Кількість кліків за вашим профілем зі сторінки спільноти',
            deu: 'Anzahl der Klicks auf Ihr Profil von der Community-Seite',
        },
        profile_main_edit_button: {
            eng: 'Edit profile',
            rus: 'Редактировать профиль',
            ukr: 'Редагувати профіль',
            deu: 'Profil bearbeiten',
        },
        profile_main_edit_title: {
            eng: 'Edit Profile',
            rus: 'Редактирование профиля',
            ukr: 'Редагування профілю',
            deu: 'Profil bearbeiten',
        },
        profile_main_add_role: {
            eng: 'Add role',
            rus: 'Добавление роли',
            ukr: 'Додавання ролі',
            deu: 'Rolle hinzufügen',
        },
        profile_main_verification_button: {
            eng: 'Pass moderation',
            rus: 'Пройти модерацию',
            ukr: 'Пройти модерацію',
            deu: 'Moderation bestehen',
        },
        profile_main_location: {
            eng: 'Location',
            rus: 'Расположение',
            ukr: 'Розташування',
            deu: 'Standort',
        },
        profile_main_business_country: {
            eng: 'Countries of doing business',
            rus: 'Страны ведения бизнеса',
            ukr: 'Країни ведення бізнесу',
            deu: 'Länder der Geschäftstätigkeit',
        },
        profile_main_specializations: {
            eng: 'Specializations',
            rus: 'Специализации',
            ukr: 'Спеціалізації',
            deu: 'Spezialisierungen',
        },
        specializations: {
            eng: 'Specializations',
            rus: 'Специализации',
            ukr: 'Спеціалізації',
            deu: 'Spezialisierungen',
        },
        profile_main_completed_projects: {
            eng: 'Number of completed projects',
            rus: 'Количество завершенных проектов',
            ukr: 'Кількість завершених проектів',
            deu: 'Anzahl abgeschlossener Projekte',
        },
        profile_main_experience: {
            eng: 'Years of experience',
            rus: 'Годы опыта',
            ukr: 'Роки досвіду',
            deu: 'Jahrelange Erfahrung',
        },
        profile_main_max_transaction_amount: {
            eng: 'Max trade amount',
            rus: 'Максимальная сумма сделки',
            ukr: 'Максимальна сума угоди',
            deu: 'Maximaler Handelsbetrag',
        },
        profile_main_total_constructed_square_meters: {
            eng: 'Total built area, square meters',
            rus: 'Общая площадь построенного, квадратные метры',
            ukr: 'Загальна площа збудованого, квадратні метри',
            deu: 'Gesamt bebaute Fläche, Quadratmeter',
        },
        profile_main_associations: {
            eng: 'Associations',
            rus: 'Ассоциации',
            ukr: 'Асоціації',
            deu: 'Assoziationen',
        },
        profile_main_association: {
            eng: 'Association',
            rus: 'Ассоциация',
            ukr: 'Асоціація',
            deu: 'Assoziation',
        },
        profile_main_company_name: {
            eng: 'Company name',
            rus: 'Название компании',
            ukr: 'Назва компанії',
            deu: 'Firmenname',
        },
        profile_main_company_number: {
            eng: 'Company Number',
            rus: 'Номер компании',
            ukr: 'Номер компанії',
            deu: 'Firmennummer',
        },
        profile_main_foundation_year: {
            eng: 'Year of founding',
            rus: 'Год основания',
            ukr: 'Рік заснування',
            deu: 'Gründungsjahr',
        },
        profile_main_members_number: {
            eng: 'Number of employees',
            rus: 'Количество сотрудников',
            ukr: 'Кількість співробітників',
            deu: 'Anzahl der Mitarbeiter',
        },
        profile_main_branches_number: {
            eng: 'Number of branches',
            rus: 'Количество филиалов',
            ukr: 'Кількість філій',
            deu: 'Anzahl der Filialen',
        },
        profile_main_services: {
            eng: 'Services',
            rus: 'Сервисы',
            ukr: 'Сервіси',
            deu: 'Dienste',
        },
        profile_main_clients: {
            eng: 'Clients',
            rus: 'Клиенты',
            ukr: 'Клієнти',
            deu: 'Kunden',
        },
        profile_main_subject_law: {
            eng: 'Subject of the right',
            rus: 'Субъект права',
            ukr: "Суб'єкт права",
            deu: 'Gegenstand des Rechts',
        },
        profile_main_tax_resident_USA: {
            eng: 'US tax resident',
            rus: 'Налоговый резидент США',
            ukr: 'Податковий резидент США',
            deu: 'US-Steuerinländer',
        },
        profile_main_qualified_specialist: {
            eng: 'Qualified Person',
            rus: 'Квалифицированный специалист',
            ukr: 'Кваліфікований спеціаліст',
            deu: 'Qualifizierte Person',
        },
        profile_main_desired_investment_period: {
            eng: 'Desired investment period (years)',
            rus: 'Желаемый период инвестиций (лет)',
            ukr: 'Бажаний період інвестицій (років)',
            deu: 'Gewünschter Investitionszeitraum (Jahre)',
        },
        profile_main_desired_profitability: {
            eng: 'Desired return (%)',
            rus: 'Желаемая доходность (%)',
            ukr: 'Бажана доходність (%)',
            deu: 'Gewünschte Rendite (%)',
        },
        profile_main_description: {
            eng: 'Description',
            rus: 'Описание',
            ukr: 'Опис',
            deu: 'Beschreibung',
        },
        profile_main_contact_title: {
            eng: 'Contact person',
            rus: 'Контактное лицо',
            ukr: 'Контактна особа',
            deu: 'Ansprechpartner',
        },
        profile_main_contact_position: {
            eng: 'Position',
            rus: 'Должность',
            ukr: 'Посада',
            deu: 'Position',
        },
        profile_main_educational_title: {
            eng: 'Education',
            rus: 'Образование',
            ukr: 'Освіта',
            deu: 'Bildung',
        },
        profile_main_educational_faculty: {
            eng: 'Faculty',
            rus: 'Факультет',
            ukr: 'Факультет',
            deu: 'Fakultät',
        },
        profile_main_educational_period: {
            eng: 'Training period',
            rus: 'Период обучения',
            ukr: 'Період навчання',
            deu: 'Trainingszeitraum',
        },
        profile_main_educational_specialty: {
            eng: 'Specialty',
            rus: 'Специальность',
            ukr: 'Спеціальність',
            deu: 'Spezialität',
        },
        profile_main_educational_document_number: {
            eng: 'Document number',
            rus: 'Номер документа',
            ukr: 'Номер документа',
            deu: 'Dokumentnummer',
        },
        profile_main_license_title: {
            eng: 'Licenses',
            rus: 'Лицензии',
            ukr: 'Ліцензії',
            deu: 'Lizenzen',
        },
        profile_main_license_number: {
            eng: 'License Name',
            rus: 'Название лицензии',
            ukr: 'Назва ліцензії',
            deu: 'Lizenzname',
        },
        profile_stepper_item_email: {
            eng: 'Mail confirmation',
            rus: 'Подтверждение почты',
            ukr: 'Підтвердження пошти',
            deu: 'E-Mail-Bestätigung',
        },
        profile_stepper_item_verification: {
            eng: 'Profile moderation',
            rus: 'Модерация профиля',
            ukr: 'Модерація профілю',
            deu: 'Profilmoderation',
        },
        profile_stepper_item_kyc: {
            eng: 'KYC pass',
            rus: 'Прохождение KYC',
            ukr: 'Проходження KYC',
            deu: 'KYC-Pass',
        },
        profile_project_item_type: {
            eng: 'Type',
            rus: 'Тип',
            ukr: 'Тип',
            deu: 'Typ',
        },
        profile_project_item_payback_period: {
            eng: 'Payback period',
            rus: 'Срок окупаемости',
            ukr: 'Термін окупності',
            deu: 'Amortisationszeit',
        },
        profile_project_item_total_investment: {
            eng: 'Total invested',
            rus: 'Всего вложено',
            ukr: 'Усього вкладено',
            deu: 'Gesamtinvestition',
        },
        profile_project_item_more: {
            eng: 'Details',
            rus: 'Подробнее',
            ukr: 'Докладніше',
            deu: 'Details',
        },
        profile_property_item_selled: {
            eng: 'Sold out',
            rus: 'Продано',
            ukr: 'Продано',
            deu: 'Ausverkauft',
        },
        profile_property_item_type: {
            eng: 'Type',
            rus: 'Тип',
            ukr: 'Тип',
            deu: 'Typ',
        },
        profile_property_item_floor: {
            eng: 'Floor',
            rus: 'Этаж',
            ukr: 'Поверх',
            deu: 'Boden',
        },
        profile_property_item_sq: {
            eng: 'sq. m',
            rus: 'кв. м',
            ukr: 'кв. м',
            deu: 'sq. m',
        },
        profile_property_item_more: {
            eng: 'Details',
            rus: 'Подробнее',
            ukr: 'Докладніше',
            deu: 'Details',
        },
        profile_property_item_edit: {
            eng: 'Edit',
            rus: 'Редактировать',
            ukr: 'Редагувати',
            deu: 'Bearbeiten',
        },
        profile_wallet_table_method: {
            eng: 'Method',
            rus: 'Метод',
            ukr: 'Метод',
            deu: 'Methode',
        },
        profile_wallet_table_time: {
            eng: 'Time',
            rus: 'Время',
            ukr: 'Час',
            deu: 'Zeit',
        },
        profile_wallet_table_state: {
            eng: 'Status',
            rus: 'Состояние',
            ukr: 'Стан',
            deu: 'Status',
        },
        profile_wallet_balance: {
            eng: 'Your total M2C balance',
            rus: 'Ваш общий баланс M2C',
            ukr: 'Ваш загальний баланс M2C',
            deu: 'Ihr Gesamtsaldo M2C',
        },
        profile_wallet_history: {
            eng: 'Wallet history',
            rus: 'История кошелька',
            ukr: 'Історія гаманця',
            deu: 'Wallet-Verlauf',
        },
        profile_wallet_partners: {
            eng: 'Partners',
            rus: 'Партнеры',
            ukr: 'Партнери',
            deu: 'Partner',
        },
        profile_partners_share_text: {
            eng: 'I invite you to register in the real estate community',
            rus: 'Приглашаю вас зарегистрироваться в сообществе специалистов рынка недвижимости',
            ukr: 'Запрошую вас зареєструватися у співтоваристві фахівців ринку нерухомості',
            deu: 'Ich lade Sie ein, sich in der Immobilien-Community zu registrieren',
        },
        profile_partners_table_status: {
            eng: 'Status',
            rus: 'Статус',
            ukr: 'Статус',
            deu: 'Status',
        },
        profile_partners_table_member: {
            eng: 'Participant',
            rus: 'Участник',
            ukr: 'Учасник',
            deu: 'Teilnehmer',
        },
        profile_partners_table_bonuses: {
            eng: 'Bonuses',
            rus: 'Бонусы',
            ukr: 'Бонуси',
            deu: 'Boni',
        },
        profile_partners_table_date: {
            eng: 'Date of invitation',
            rus: 'Дата приглашения',
            ukr: 'Дата запрошення',
            deu: 'Datum der Einladung',
        },
        profile_partners_table_level: {
            eng: 'Level',
            rus: 'Уровень',
            ukr: 'Рівень',
            deu: 'Stufe',
        },
        profile_partners_copy_success: {
            eng: 'Copied successfully',
            rus: 'Скопировано успешно',
            ukr: 'Скопійовано успішно',
            deu: 'Erfolgreich kopiert',
        },
        profile_partners_email_error: {
            eng: "Enter a friend's email",
            rus: 'Укажите email друга',
            ukr: 'Вкажіть email друга',
            deu: 'Geben Sie die E-Mail-Adresse eines Freundes ein',
        },
        profile_partners_email_success: {
            eng: 'Invitation sent successfully',
            rus: 'Приглашение успешно отправлено',
            ukr: 'Запрошення успішно надіслано',
            deu: 'Einladung erfolgreich versendet',
        },
        profile_partners_title: {
            eng: 'Invite a friend',
            rus: 'Пригласите друга',
            ukr: 'Запросіть друга',
            deu: 'Freunde einladen',
        },
        profile_partners_variant_email: {
            eng: 'Via email',
            rus: 'По email',
            ukr: 'По email',
            deu: 'Per E-Mail',
        },
        profile_partners_variant_email_friend: {
            eng: "Your friend's email",
            rus: 'Email вашего друга',
            ukr: 'Email вашого друга',
            deu: 'E-Mail deines Freundes',
        },
        profile_partners_variant_messenger: {
            eng: 'Via messengers',
            rus: 'С помощью мессенджеров',
            ukr: 'За допомогою месенджерів',
            deu: 'Über Messenger',
        },
        profile_partners_variant_link: {
            eng: 'Affiliate link',
            rus: 'По партнерской ссылке',
            ukr: 'За партнерським посиланням',
            deu: 'Affiliate-Link',
        },
        profile_partners_variant_link_your_link: {
            eng: 'Your affiliate link',
            rus: 'Ваша партнерская ссылка',
            ukr: 'Ваше партнерське посилання',
            deu: 'Ihr Affiliate-Link',
        },
        profile_partners_variant_qr: {
            eng: 'With QR code',
            rus: 'С помощью QR кода',
            ukr: 'За допомогою QR-коду',
            deu: 'Mit QR-Code',
        },
        profile_partners_variant_qr_download: {
            eng: 'Download',
            rus: 'Скачать',
            ukr: 'Завантажити',
            deu: 'Herunterladen',
        },
        profile_partners_your_level: {
            eng: 'Your Level:',
            rus: 'Ваш уровень:',
            ukr: 'Ваш рівень:',
            deu: 'Ihr Level:',
        },
        profile_partners_level_1: {
            eng: 'Your first level partners are:',
            rus: 'Ваши партнеры первого уровня:',
            ukr: 'Ваші партнери першого рівня:',
            deu: 'Ihre Partner der ersten Ebene sind:',
        },
        profile_partners_level_2: {
            eng: 'Your second level partners are:',
            rus: 'Ваши партнеры второго уровня:',
            ukr: 'Ваші партнери другого рівня:',
            deu: 'Ihre Partner der zweiten Ebene sind:',
        },
        profile_partners_level_3: {
            eng: 'Your third level partners are:',
            rus: 'Ваши партнеры третьего уровня:',
            ukr: 'Ваші партнери третього рівня:',
            deu: 'Ihre Partner der dritten Ebene sind:',
        },
        profile_partners_your_bonuses: {
            eng: 'Your bonuses:',
            rus: 'Ваши бонусы:',
            ukr: 'Ваші бонуси:',
            deu: 'Ihre Boni:',
        },
        profile_partners_your_bonuses_available: {
            eng: 'Available:',
            rus: 'Доступно:',
            ukr: 'Доступно:',
            deu: 'Verfügbar:',
        },
        profile_partners_your_partners: {
            eng: 'Your partners',
            rus: 'Ваши партнеры',
            ukr: 'Ваші партнери',
            deu: 'Ihre Partner',
        },
        profile_partners_partners: {
            eng: 'Partners',
            rus: 'Партнеры',
            ukr: 'Партнери',
            deu: 'Partner',
        },
        profile_rating_table_date: {
            eng: 'Date',
            rus: 'Дата',
            ukr: 'Дата',
            deu: 'Datum',
        },
        profile_rating_table_rating: {
            eng: 'Rating change',
            rus: 'Изменение рейтинга',
            ukr: 'Зміна рейтингу',
            deu: 'Bewertungsänderung',
        },
        profile_rating_table_description: {
            eng: 'Description',
            rus: 'Описание',
            ukr: 'Опис',
            deu: 'Beschreibung',
        },
        profile_rating_dates_error: {
            eng: 'Please enter a valid date range',
            rus: 'Укажите правильный диапазон дат',
            ukr: 'Вкажіть правильний діапазон дат',
            deu: 'Bitte geben Sie einen gültigen Datumsbereich ein',
        },
        profile_rating_dates_success: {
            eng: 'Absent period set',
            rus: 'Период отсутствия установлен',
            ukr: 'Період відсутності встановлено',
            deu: 'Abwesenheitszeit eingestellt',
        },
        profile_rating_your_rating: {
            eng: 'Your rating',
            rus: 'Ваш рейтинг',
            ukr: 'Ваш рейтинг',
            deu: 'Ihre Bewertung',
        },
        profile_rating_free_title: {
            eng: 'Here you can pause actions with your rating',
            rus: 'Здесь вы можете приостановить действия с вашим рейтингом',
            ukr: 'Тут ви можете зупинити дії з вашим рейтингом',
            deu: 'Hier können Sie Aktionen mit Ihrer Bewertung pausieren',
        },
        profile_rating_free_from: {
            eng: 'From',
            rus: 'От',
            ukr: 'Від',
            deu: 'Von',
        },
        profile_rating_free_to: {
            eng: 'Before',
            rus: 'До',
            ukr: 'До',
            deu: 'Vorher',
        },
        profile_rating_free_send: {
            eng: 'Submit',
            rus: 'Отправить',
            ukr: 'Надіслати',
            deu: 'Senden',
        },
        profile_rating_free_dates: {
            eng: 'Absent Period:',
            rus: 'Период отсутствия:',
            ukr: 'Період відсутності:',
            deu: 'Abwesenheitszeitraum:',
        },
        profile_rating_free_status_available: {
            eng: 'Absent Period:',
            rus: 'Период отсутствия:',
            ukr: 'Період відсутності:',
            deu: 'Abwesenheitszeitraum:',
        },
        profile_rating_history: {
            eng: 'History of accruals',
            rus: 'История начислений',
            ukr: 'Історія нарахувань',
            deu: 'Rückstellungshistorie',
        },
        profile_projects_filter_country: {
            eng: 'Country',
            rus: 'Страна',
            ukr: 'Країна',
            deu: 'Land',
        },
        profile_projects_filter_name: {
            eng: 'Project Name',
            rus: 'Название проекта',
            ukr: 'Назва проекту',
            deu: 'Projektname',
        },
        profile_projects_filter_input_name: {
            eng: 'Enter project name',
            rus: 'Введите назване проекта',
            ukr: 'Введіть назву проекту',
            deu: 'Projektname eingeben',
        },
        profile_projects_filter_button: {
            eng: 'Search',
            rus: 'Поиск',
            ukr: 'Пошук',
            deu: 'Suche',
        },
        profile_projects_not_found: {
            eng: 'Projects not found',
            rus: 'Проекты не найдены',
            ukr: 'Проекти не знайдено',
            deu: 'Projekte nicht gefunden',
        },
        profile_properties_filter_country: {
            eng: 'Country',
            rus: 'Страна',
            ukr: 'Країна',
            deu: 'Land',
        },
        profile_properties_filter_name: {
            eng: 'Property name',
            rus: 'Название объекта',
            ukr: "Назва об'єкта",
            deu: 'Eigenschaftsname',
        },
        profile_properties_filter_input_name: {
            eng: 'Enter property name',
            rus: 'Введите назване объекта',
            ukr: "Введіть назву об'єкта",
            deu: 'Eigenschaftsnamen eingeben',
        },
        profile_properties_filter_input_price: {
            eng: 'Enter price',
            rus: 'Введите цену',
            ukr: 'Введіть ціну',
            deu: 'Preis eingeben',
        },
        profile_properties_filter_price_from: {
            eng: 'Price from',
            rus: 'Цена от',
            ukr: 'Ціна від',
            deu: 'Preis ab',
        },
        profile_properties_filter_price_to: {
            eng: 'Price to',
            rus: 'Цена до',
            ukr: 'Ціна до',
            deu: 'Preis bis',
        },
        profile_properties_filter_input_area: {
            eng: 'Enter area',
            rus: 'Введите площадь',
            ukr: 'Введіть площу',
            deu: 'Gebiet eingeben',
        },
        profile_properties_filter_area_from: {
            eng: 'Area from',
            rus: 'Площадь от',
            ukr: 'Площа від',
            deu: 'Gebiet von',
        },
        profile_properties_filter_area_to: {
            eng: 'Area to',
            rus: 'Площадь до',
            ukr: 'Площа до',
            deu: 'Bereich bis',
        },
        profile_properties_filter_type: {
            eng: 'Property Type',
            rus: 'Тип недвижимости',
            ukr: 'Тип нерухомості',
            deu: 'Eigenschaftstyp',
        },
        profile_properties_filter_button: {
            eng: 'Search',
            rus: 'Поиск',
            ukr: 'Пошук',
            deu: 'Suche',
        },
        profile_properties_not_found: {
            eng: 'No objects found',
            rus: 'Объекты не найдены',
            ukr: "Об'єкти не знайдено",
            deu: 'Keine Objekte gefunden',
        },
        profile_roles_title: {
            eng: 'Select role',
            rus: 'Выберите роль',
            ukr: 'Виберіть роль',
            deu: 'Rolle auswählen',
        },
        profile_roles_specialist: {
            eng: 'Specialist',
            rus: 'Специалист',
            ukr: 'Спеціаліст',
            deu: 'Spezialist',
        },
        profile_roles_specialist_text: {
            eng: 'To proceed with registration, select one or more roles on the platform that best suits your professional activity. Each role has its own unique opportunities, meeting the modern requirements and criteria of the global real estate market',
            rus: 'Для продолжения регистрации выберите одну или несколько ролей на платформе, которые максимально соответствуют вашей профессиональной деятельности. Каждая роль имеет свои уникальные возможности, отвечая современным требованиям и критериям мирового рынка недвижимости',
            ukr: 'Для продовження реєстрації виберіть одну або кілька ролей на платформі, які максимально відповідають вашій професійній діяльності. Кожна роль має свої унікальні можливості, відповідаючи сучасним вимогам та критеріям світового ринку нерухомості',
            deu: 'Um mit der Registrierung fortzufahren, wählen Sie eine oder mehrere Rollen auf der Plattform aus, die am besten zu Ihrer beruflichen Tätigkeit passt. Jede Rolle hat ihre eigenen einzigartigen Möglichkeiten und erfüllt die modernen Anforderungen und Kriterien des globalen Immobilienmarktes.',
        },
        profile_roles_select: {
            eng: 'Select',
            rus: 'Выбрать',
            ukr: 'Вибрати',
            deu: 'Auswählen',
        },
        profile_roles_company: {
            eng: 'Company',
            rus: 'Компания',
            ukr: 'Компанія',
            deu: 'Firma',
        },
        profile_roles_company_text: {
            eng: 'Depending on the direction of your activity, as well as on the format of doing business, select the type of company that you are most suited to',
            rus: 'В зависимости от направления вашей деятельности, а также от формата ведения бизнеса выберите тип компании, которому Вы соответствуете больше всего',
            ukr: 'Залежно від напряму вашої діяльності, а також від формату ведення бізнесу виберіть тип компанії, якій Ви найбільше відповідаєте',
            deu: 'Wählen Sie je nach Ausrichtung Ihrer Tätigkeit sowie je nach Geschäftsform die Unternehmensform aus, für die Sie am besten geeignet sind',
        },
        profile_roles_organization: {
            eng: 'Public organization',
            rus: 'Общественная организация',
            ukr: 'Громадська організація',
            deu: 'Öffentliche Organisation',
        },
        profile_roles_organization_text: {
            eng: 'Own passive income network',
            rus: 'Собственный нетворк, приносящий пассивный доход',
            ukr: 'Власний нетворк, що приносить пасивний дохід',
            deu: 'Eigenes passives Einkommensnetzwerk',
        },
        profile_role_company_title: {
            eng: 'Select role',
            rus: 'Выберите роль',
            ukr: 'Виберіть роль',
            deu: 'Rolle auswählen',
        },
        profile_role_company_text_1: {
            eng: 'We follow the latest trends in the real estate market and try to provide maximum choice for our users.',
            rus: 'Мы следуем новейшим тенденциям рынка недвижимости и стараемся предоставлять максимум выбора для наших пользователей.',
            ukr: 'Ми дотримуємося найновіших тенденцій ринку нерухомості та намагаємось надавати максимум вибору для наших користувачів.',
            deu: 'Wir folgen den neuesten Trends auf dem Immobilienmarkt und versuchen, unseren Nutzern die größtmögliche Auswahl zu bieten.',
        },
        profile_role_company_text_2: {
            eng: 'Depending on the direction of your activity, as well as the format of doing business, select the type of company that you are most suited to.',
            rus: 'В зависимости от направления вашей деятельности, а также от формата ведения бизнеса выберите тип компании, которому Вы соответствуете больше всего.',
            ukr: 'Залежно від напряму вашої діяльності, а також від формату ведення бізнесу виберіть тип компанії, якій Ви найбільше відповідаєте.',
            deu: 'Wählen Sie je nach Ausrichtung Ihrer Tätigkeit sowie Geschäftsform die Unternehmensform, die am besten zu Ihnen passt.',
        },
        profile_role_company_text_3: {
            eng: 'There are 3 options to choose from: development company, management company and commercial company',
            rus: 'Доступно 3 варианта на выбор: девелоперская компания, управляющая компания и коммерческая компания',
            ukr: 'Доступно 3 варіанти на вибір: девелоперська компанія, керуюча компанія та комерційна компанія',
            deu: 'Es stehen 3 Optionen zur Auswahl: Entwicklungsgesellschaft, Verwaltungsgesellschaft und Handelsgesellschaft',
        },
        profile_role_company_dev_title: {
            eng: 'Development company',
            rus: 'Девелоперская компания',
            ukr: 'Девелоперська компанія',
            deu: 'Entwicklungsunternehmen',
        },
        profile_role_company_dev_text: {
            eng: 'Security of transactions thanks to the digital contract. Personalized landing page with personalized links. Targeted advertising to a relevant audience. Access to information about potential customers. Automatic fixation of transactions in the blockchain registry. Access to investors from all over the world. Quick launch of campaigns to attract investments. The rating system of the project, which increases the confidence of the investor. Project promo page for promotion. Opportunity to introduce yourself to clients from all over the world. Savings on advertising and customer search',
            rus: 'Безопасность сделок благодаря цифровому контракту. Персонализированный лендинг-пейдж с именной ссылокй. Таргетированная реклама на релевантную аудиторию. Доступ к информации о потенциальных клиентах. Автоматическая фиксация сделок в блокчейн реестре. Доступ к инвесторам со всего мира. Быстрый запуск кампаний по привлечению инвестиций. Рейтинговая система проекта, увеличивающая доверие инвестора. Промо-страница проекта для продвижения. Возможность представить себя клиентам со всего мира. Экономия на рекламе и поиска клиентов',
            ukr: 'Безпека угод завдяки цифровому контракту. Персоналізований лендінг-пейдж із іменним посиланням. Таргетована реклама на релевантну аудиторію. Доступ до інформації про потенційних клієнтів. Автоматична фіксація угод у блокчейн реєстрі. Доступ до інвесторів з усього світу. Швидкий запуск кампаній із залучення інвестицій. Рейтингова система проекту збільшує довіру інвестора. Промо-сторінка проекту для просування. Можливість уявити себе клієнтам з усього світу. Економія на рекламі та пошуку клієнтів',
            deu: 'Transaktionssicherheit dank digitalem Vertrag. Personalisierte Landingpage mit personalisierten Links. Gezielte Werbung für eine relevante Zielgruppe. Zugriff auf Informationen über potenzielle Kunden. Automatische Fixierung von Transaktionen in der Blockchain-Registrierung. Zugang zu Investoren aus der ganzen Welt. Schneller Start von Kampagnen, um Investitionen anzuziehen. Das Bewertungssystem des Projekts, das das Vertrauen des Investors erhöht. Projekt-Werbeseite für Werbung. Gelegenheit, sich Kunden aus der ganzen Welt vorzustellen. Einsparungen bei Werbung und Kundensuche',
        },
        profile_role_company_select: {
            eng: 'Select',
            rus: 'Выбрать',
            ukr: 'Вибрати',
            deu: 'Auswählen',
        },
        profile_role_company_mn_title: {
            eng: 'Management company',
            rus: 'Управляющая компания',
            ukr: 'Керуюча компанія',
            deu: 'Verwaltungsgesellschaft',
        },
        profile_role_company_mn_text: {
            eng: 'Security of transactions thanks to the digital contract. Personalized landing page with personalized links. Targeted advertising to a relevant audience. Access to information about potential customers. Automatic fixation of transactions in the blockchain registry. Opportunity to introduce yourself to clients from all over the world. Savings on advertising and customer search',
            rus: 'Безопасность сделок благодаря цифровому контракту. Персонализированный лендинг-пейдж с именной ссылокй. Таргетированная реклама на релевантную аудиторию. Доступ к информации о потенциальных клиентах. Автоматическая фиксация сделок в блокчейн реестре. Возможность представить себя клиентам со всего мира. Экономия на рекламе и поиска клиентов',
            ukr: 'Безпека угод завдяки цифровому контракту. Персоналізований лендінг-пейдж із іменним посиланням. Таргетована реклама на релевантну аудиторію. Доступ до інформації про потенційних клієнтів. Автоматична фіксація угод у блокчейн реєстрі. Можливість уявити себе клієнтам з усього світу. Економія на рекламі та пошуку клієнтів',
            deu: 'Transaktionssicherheit dank digitalem Vertrag. Personalisierte Landingpage mit personalisierten Links. Gezielte Werbung für eine relevante Zielgruppe. Zugriff auf Informationen über potenzielle Kunden. Automatische Fixierung von Transaktionen in der Blockchain-Registrierung. Gelegenheit, sich Kunden aus der ganzen Welt vorzustellen. Einsparungen bei Werbung und Kundensuche',
        },
        profile_role_company_cn_title: {
            eng: 'Commercial company',
            rus: 'Коммерческая компания',
            ukr: 'Комерційна компанія',
            deu: 'Handelsgesellschaft',
        },
        profile_role_company_cn_text: {
            eng: 'Security of transactions thanks to the digital contract. Personalized landing page with personalized links. Targeted advertising to a relevant audience. Access to information about potential customers. Automatic fixation of transactions in the blockchain registry. Opportunity to introduce yourself to clients from all over the world. Savings on advertising and customer search',
            rus: 'Безопасность сделок благодаря цифровому контракту. Персонализированный лендинг-пейдж с именной ссылокй. Таргетированная реклама на релевантную аудиторию. Доступ к информации о потенциальных клиентах. Автоматическая фиксация сделок в блокчейн реестре. Возможность представить себя клиентам со всего мира. Экономия на рекламе и поиска клиентов',
            ukr: 'Безпека угод завдяки цифровому контракту. Персоналізований лендінг-пейдж із іменним посиланням. Таргетована реклама на релевантну аудиторію. Доступ до інформації про потенційних клієнтів. Автоматична фіксація угод у блокчейн реєстрі. Можливість уявити себе клієнтам з усього світу. Економія на рекламі та пошуку клієнтів',
            deu: 'Transaktionssicherheit dank digitalem Vertrag. Personalisierte Landingpage mit personalisierten Links. Gezielte Werbung für eine relevante Zielgruppe. Zugriff auf Informationen über potenzielle Kunden. Automatische Fixierung von Transaktionen in der Blockchain-Registrierung. Gelegenheit, sich Kunden aus der ganzen Welt vorzustellen. Einsparungen bei Werbung und Kundensuche',
        },
        profile_role_second_progress_title: {
            eng: 'Profile verification in progress',
            rus: 'Проверка профиля в процессе',
            ukr: 'Перевірка профілю в процесі',
            deu: 'Profilüberprüfung läuft',
        },
        profile_role_second_progress_text_1: {
            eng: 'Thank you for filling in the additional information about yourself. Soon, after checking your profile, you will be able to access all the main features of the site.',
            rus: 'Спасибо за заполнение дополнительных данных о себе. Вскоре, после проверки вашего профиля, вам будут доступны все основные функции сайта.',
            ukr: 'Дякую за заповнення додаткових даних про себе. Незабаром після перевірки вашого профілю вам будуть доступні всі основні функції сайту.',
            deu: 'Vielen Dank, dass Sie die zusätzlichen Informationen über sich selbst ausgefüllt haben. Nachdem Sie Ihr Profil überprüft haben, können Sie bald auf alle Hauptfunktionen der Website zugreifen.',
        },
        profile_role_second_progress_text_2: {
            eng: 'Profile verification takes 24 hours on average',
            rus: 'В среднем проверка профиля проходит в течении 24-х часов',
            ukr: 'У середньому перевірка профілю проходить протягом 24-х годин',
            deu: 'Profilüberprüfung dauert durchschnittlich 24 Stunden',
        },
        profile_role_second_progress_button_help: {
            eng: 'Contact technical support',
            rus: 'Написать в техническую поддержку',
            ukr: 'Написати на технічну підтримку',
            deu: 'Technischen Support kontaktieren',
        },
        role_appraiser: {
            eng: 'Appraiser',
            rus: 'Оценщик',
            ukr: 'Оцінювач',
            deu: 'Bewerter',
        },
        role_architect: {
            eng: 'Architect',
            rus: 'Архитектор',
            ukr: 'Архітектор',
            deu: 'Architekt',
        },
        role_broker: {
            eng: 'Broker',
            rus: 'Брокер',
            ukr: 'Брокер',
            deu: 'Makler',
        },
        role_buyer: {
            eng: 'Buyer',
            rus: 'Покупатель',
            ukr: 'Покупець',
            deu: 'Kunde',
        },
        role_developer: {
            eng: 'Builder',
            rus: 'Застройщик',
            ukr: 'Забудовник',
            deu: 'Erbauer',
        },
        role_investor: {
            eng: 'Investor',
            rus: 'Инвестор',
            ukr: 'Інвестор',
            deu: 'Anleger',
        },
        role_notary: {
            eng: 'Notary',
            rus: 'Нотариус',
            ukr: 'Нотаріус',
            deu: 'Notar',
        },
        role_seller: {
            eng: 'Owner',
            rus: 'Cобственник',
            ukr: 'Власник',
            deu: 'Verkäufer',
        },
        profile_role_specialist_title: {
            eng: 'Select one or more roles',
            rus: 'Выберите одну или несколько ролей',
            ukr: 'Виберіть одну або кілька ролей',
            deu: 'Wählen Sie eine oder mehrere Rollen aus',
        },
        profile_role_specialist_next: {
            eng: 'Next',
            rus: 'Далее',
            ukr: 'Далі',
            deu: 'Weiter',
        },
        profile_role_specialist_text_1: {
            eng: 'GREM - the world of fast, transparent and secure real estate transactions in any country in the world. We offer all the necessary tools for convenient work and maximum results.',
            rus: 'GREM - мир быстрых, прозрачных и безопасных сделок с недвижимостью в любой стране мира. Мы предлагаем все необходимые инструменты для удобной работы и максимальных результатов.',
            ukr: 'GREM - світ швидких, прозорих та безпечних операцій з нерухомістю у будь-якій країні світу. Ми пропонуємо всі необхідні інструменти для зручної роботи та максимальних результатів.',
            deu: 'GREM - die Welt der schnellen, transparenten und sicheren Immobilientransaktionen in jedem Land der Welt. Wir bieten alle notwendigen Werkzeuge für komfortables Arbeiten und maximale Ergebnisse.',
        },
        profile_role_specialist_text_2: {
            eng: 'To proceed with registration, select one or more roles on the platform that best suits your professional activity. Each role has its own unique opportunities, meeting the modern requirements and criteria of the global real estate market.',
            rus: 'Для продолжения регистрации выберите одну или несколько ролей на платформе, которые максимально соответствуют вашей профессиональной деятельности. Каждая роль имеет свои уникальные возможности, отвечая современным требованиям и критериям мирового рынка недвижимости.',
            ukr: 'Для продовження реєстрації виберіть одну або кілька ролей на платформі, які максимально відповідають вашій професійній діяльності. Кожна роль має свої унікальні можливості, відповідаючи сучасним вимогам та критеріям світового ринку нерухомості.',
            deu: 'Um mit der Registrierung fortzufahren, wählen Sie eine oder mehrere Rollen auf der Plattform aus, die am besten zu Ihrer beruflichen Tätigkeit passt. Jede Rolle hat ihre eigenen einzigartigen Möglichkeiten und erfüllt die modernen Anforderungen und Kriterien des globalen Immobilienmarktes.',
        },
        profile_role_specialist_text_3: {
            eng: 'Combining several roles, you expand the circle of your professional activities, clients and partners, developing several lines of business at the same time. Thus, the functionality of your profile increases significantly. You can buy and sell objects, implement development projects, invest funds or engage in appraisal activities. At the same time, if the task at hand requires the simplest and fastest solution, you can always stop at one thing.',
            rus: 'Комбинируя несколько ролей, вы расширяете круг своей профессиональной деятельности, клиентов и партнеров, развивая несколько направлений ведения бизнеса одновременно. Таким образом функционал Вашего профиля существенно возрастает. Вы можете покупать и продавать объекты, реализовывать девелоперские проекты, инвестировать средства или заниматься оценочной деятельностью. В то же время, если поставленная задача требует простого и наиболее быстрого решения – всегда можно остановиться на чем-то одном.',
            ukr: "Комбінуючи кілька ролей, ви розширюєте коло своєї професійної діяльності, клієнтів та партнерів, розвиваючи кілька напрямків ведення бізнесу одночасно. Таким чином функціонал Вашого профілю суттєво зростає. Ви можете купувати та продавати об'єкти, реалізовувати девелоперські проекти, інвестувати кошти чи займатися оціночною діяльністю. У той же час, якщо поставлене завдання вимагає простого і найшвидшого вирішення – завжди можна зупинитися на чомусь одному.",
            deu: 'Durch die Kombination mehrerer Rollen erweitern Sie den Kreis Ihrer beruflichen Aktivitäten, Kunden und Partner und entwickeln gleichzeitig mehrere Geschäftsfelder. Dadurch erhöht sich die Funktionalität Ihres Profils deutlich. Sie können Objekte kaufen und verkaufen, Entwicklungsprojekte umsetzen, Gelder anlegen oder Gutachtertätigkeiten ausüben. Gleichzeitig kann man, wenn die anstehende Aufgabe die einfachste und schnellste Lösung erfordert, immer bei einer Sache stehen bleiben.',
        },
        profile_role_specialist_text_4: {
            eng: 'Get out of the box, unleash your full potential!',
            rus: 'Избавьтесь от рамок, раскройте свой потенциал на полную!',
            ukr: 'Позбудьтеся рамок, розкрийте свій потенціал на повну!',
            deu: 'Raus aus der Box, entfesseln Sie Ihr volles Potenzial!',
        },
        profile_role_specialist_appraiser_text_1: {
            eng: 'Security of transactions thanks to the digital contract',
            rus: 'Безопасность сделок благодаря цифровому контракту',
            ukr: 'Безпека угод завдяки цифровому контракту',
            deu: 'Transaktionssicherheit dank digitalem Vertrag',
        },
        profile_role_specialist_appraiser_text_2: {
            eng: 'Own passive income network',
            rus: 'Собственный нетворк, приносящий пассивный доход',
            ukr: 'Власний нетворк, що приносить пасивний дохід',
            deu: 'Eigenes passives Einkommensnetzwerk',
        },
        profile_role_specialist_appraiser_text_3: {
            eng: 'Personalized landing page with named link',
            rus: 'Персонализированный лендинг-пейдж с именной ссылкой',
            ukr: 'Персоналізований лендинг-пейдж із іменним посиланням',
            deu: 'Personalisierte Zielseite mit benanntem Link',
        },
        profile_role_specialist_appraiser_text_4: {
            eng: 'Access lead information',
            rus: 'Доступ к информации о потенциальных клиентах',
            ukr: 'Доступ до інформації про потенційних клієнтів',
            deu: 'Auf Lead-Informationen zugreifen',
        },
        profile_role_specialist_appraiser_text_5: {
            eng: 'Comfortable work without being tied to the office',
            rus: 'Удобная работа без привязки к офису',
            ukr: "Зручна робота без прив'язки до офісу",
            deu: 'Komfortables Arbeiten ohne Bürobindung',
        },
        profile_role_specialist_appraiser_text_6: {
            eng: 'Ability to introduce yourself to clients from all over the world',
            rus: 'Возможность представить себя клиентам со всего мира',
            ukr: 'Можливість уявити себе клієнтам з усього світу',
            deu: 'Fähigkeit, sich Kunden aus der ganzen Welt vorzustellen',
        },
        profile_role_specialist_appraiser_text_7: {
            eng: 'Improved status and prestige in global and local markets',
            rus: 'Улучшение статуса и престижа на мировом и местном рынках',
            ukr: 'Поліпшення статусу та престижу на світовому та місцевому ринках',
            deu: 'Verbesserter Status und Prestige auf globalen und lokalen Märkten',
        },
        profile_role_specialist_appraiser_text_8: {
            eng: 'Ability to receive international orders',
            rus: 'Возможность получать международные заказы',
            ukr: 'Можливість отримувати міжнародні замовлення',
            deu: 'Fähigkeit, internationale Bestellungen zu empfangen',
        },
        profile_role_specialist_architect_text_1: {
            eng: 'Ability to introduce yourself to clients from all over the world',
            rus: 'Возможность представить себя клиентам со всего мира',
            ukr: 'Можливість уявити себе клієнтам з усього світу',
            deu: 'Fähigkeit, sich Kunden aus der ganzen Welt vorzustellen',
        },
        profile_role_specialist_architect_text_2: {
            eng: 'Ability to receive international orders',
            rus: 'Возможность получать международные заказы',
            ukr: 'Можливість отримувати міжнародні замовлення',
            deu: 'Fähigkeit, internationale Bestellungen zu empfangen',
        },
        profile_role_specialist_architect_text_3: {
            eng: 'Access lead information',
            rus: 'Доступ к информации о потенциальных клиентах',
            ukr: 'Доступ до інформації про потенційних клієнтів',
            deu: 'Auf Lead-Informationen zugreifen',
        },
        profile_role_specialist_architect_text_4: {
            eng: 'Improved status and prestige in global and local markets',
            rus: 'Улучшение статуса и престижа на мировом и местном рынках',
            ukr: 'Поліпшення статусу та престижу на світовому та місцевому ринках',
            deu: 'Verbesserter Status und Prestige auf globalen und lokalen Märkten',
        },
        profile_role_specialist_broker_text_1: {
            eng: 'Access clients and property from all over the world',
            rus: 'Доступ к клиентам и собственности со всего мира',
            ukr: 'Доступ до клієнтів та власності з усього світу',
            deu: 'Zugriff auf Kunden und Eigentum aus der ganzen Welt',
        },
        profile_role_specialist_broker_text_2: {
            eng: 'Security of transactions thanks to the digital contract',
            rus: 'Безопасность сделок благодаря цифровому контракту',
            ukr: 'Безпека угод завдяки цифровому контракту',
            deu: 'Transaktionssicherheit dank digitalem Vertrag',
        },
        profile_role_specialist_broker_text_3: {
            eng: 'Own passive income network',
            rus: 'Собственный нетворк, приносящий пассивный доход',
            ukr: 'Власний нетворк, що приносить пасивний дохід',
            deu: 'Eigenes passives Einkommensnetzwerk',
        },
        profile_role_specialist_broker_text_4: {
            eng: 'Personalized landing page with named link',
            rus: 'Персонализированный лендинг-пейдж с именной ссылкой',
            ukr: 'Персоналізований лендинг-пейдж із іменним посиланням',
            deu: 'Personalisierte Zielseite mit benanntem Link',
        },
        profile_role_specialist_broker_text_5: {
            eng: 'Targeted ads to a relevant audience',
            rus: 'Таргетированная реклама на релевантную аудиторию',
            ukr: 'Таргетована реклама на релевантну аудиторію',
            deu: 'Gezielte Anzeigen an eine relevante Zielgruppe',
        },
        profile_role_specialist_broker_text_6: {
            eng: 'Access lead information',
            rus: 'Доступ к информации о потенциальных клиентах',
            ukr: 'Доступ до інформації про потенційних клієнтів',
            deu: 'Auf Lead-Informationen zugreifen',
        },
        profile_role_specialist_broker_text_7: {
            eng: 'Regular data update 24/7',
            rus: 'Регулярное обновление данных 24/7',
            ukr: 'Регулярне оновлення даних 24/7',
            deu: 'Regelmäßige Datenaktualisierung rund um die Uhr',
        },
        profile_role_specialist_broker_text_8: {
            eng: 'Comfortable work without being tied to the office',
            rus: 'Удобная работа без привязки к офису',
            ukr: "Зручна робота без прив'язки до офісу",
            deu: 'Komfortables Arbeiten ohne Bürobindung',
        },
        profile_role_specialist_buyer_text_1: {
            eng: 'Access real estate from all over the world',
            rus: 'Доступ к недвижимости со всего мира',
            ukr: 'Доступ до нерухомості з усього світу',
            deu: 'Zugriff auf Immobilien aus der ganzen Welt',
        },
        profile_role_specialist_buyer_text_2: {
            eng: 'Preparation of all types of transactions online',
            rus: 'Подготовка всех видов сделок онлайн',
            ukr: 'Підготовка всіх видів операцій онлайн',
            deu: 'Vorbereitung aller Arten von Transaktionen online',
        },
        profile_role_specialist_buyer_text_3: {
            eng: 'Online Dispute Resolution in International Arbitration',
            rus: 'Разрешение споров онлайн в международном арбитраже',
            ukr: 'Вирішення спорів онлайн у міжнародному арбітражі',
            deu: 'Online-Streitbeilegung in internationalen Schiedsverfahren',
        },
        profile_role_specialist_buyer_text_4: {
            eng: 'Transaction security and transparency',
            rus: 'Безопасность и прозрачность сделки',
            ukr: 'Безпека та прозорість угоди',
            deu: 'Transaktionssicherheit und -transparenz',
        },
        profile_role_specialist_buyer_text_5: {
            eng: 'Expert advice available',
            rus: 'Возможность получения консультации экспертов',
            ukr: 'Можливість отримання консультації експертів',
            deu: 'Expertenberatung verfügbar',
        },
        profile_role_specialist_developer_text_1: {
            eng: 'Access to investors from all over the world',
            rus: 'Доступ к инвесторам со всего мира',
            ukr: 'Доступ до інвесторів з усього світу',
            deu: 'Zugang zu Investoren aus aller Welt',
        },
        profile_role_specialist_developer_text_2: {
            eng: 'Quick start fundraising campaigns',
            rus: 'Быстрый запуск кампаний по привлечению инвестиций',
            ukr: 'Швидкий запуск кампаній із залучення інвестицій',
            deu: 'Schnellstart-Spendenkampagnen',
        },
        profile_role_specialist_developer_text_3: {
            eng: 'Project rating system that increases investor confidence',
            rus: 'Рейтинговая система проекта, увеличивающая доверие инвестора',
            ukr: 'Рейтингова система проекту, яка збільшує довіру інвестора',
            deu: 'Projektbewertungssystem, das das Vertrauen der Investoren stärkt',
        },
        profile_role_specialist_developer_text_4: {
            eng: 'Security of transactions thanks to the digital contract',
            rus: 'Безопасность сделок благодаря цифровому контракту',
            ukr: 'Безпека угод завдяки цифровому контракту',
            deu: 'Transaktionssicherheit dank digitalem Vertrag',
        },
        profile_role_specialist_developer_text_5: {
            eng: 'Possibility of attracting a strategic investor',
            rus: 'Возможность привлечения стратегического инвестора',
            ukr: 'Можливість залучення стратегічного інвестора',
            deu: 'Möglichkeit, einen strategischen Investor zu gewinnen',
        },
        profile_role_specialist_developer_text_6: {
            eng: 'Promo - project page for promotion',
            rus: 'Промо - страница проекта для продвижения',
            ukr: 'Промо - сторінка проекту для просування',
            deu: 'Promo - Projektseite zur Promotion',
        },
        profile_role_specialist_developer_text_7: {
            eng: 'Involvement of global experts and consultants in project development',
            rus: 'Вовлечение мировых экспертов и консультантов в разработку проектов',
            ukr: 'Залучення світових експертів та консультантів до розробки проектів',
            deu: 'Einbindung globaler Experten und Berater in die Projektentwicklung',
        },
        profile_role_specialist_investor_text_1: {
            eng: 'The ability to invest in real estate in any country in the world',
            rus: 'Возможность инвестировать в недвижимость в любой стране мира',
            ukr: 'Можливість інвестувати в нерухомість у будь-якій країні світу',
            deu: 'Die Fähigkeit, in Immobilien in jedem Land der Welt zu investieren',
        },
        profile_role_specialist_investor_text_2: {
            eng: 'Low investment risks (projects pass expert council)',
            rus: 'Низкие инвестиционные риски (проекты проходят экспертный совет)',
            ukr: 'Низькі інвестиційні ризики (проекти проходять експертну раду)',
            deu: 'Geringe Investitionsrisiken (Projekte bestehen Expertenrat)',
        },
        profile_role_specialist_investor_text_3: {
            eng: 'Option to select investment type',
            rus: 'Возможность выбрать тип инвестиций',
            ukr: 'Можливість вибрати тип інвестицій',
            deu: 'Option zur Auswahl des Anlagetyps',
        },
        profile_role_specialist_investor_text_4: {
            eng: 'Minimum Entry Threshold',
            rus: 'Минимальный порог входа',
            ukr: 'Мінімальний поріг входу',
            deu: 'Mindesteintrittsschwelle',
        },
        profile_role_specialist_investor_text_5: {
            eng: 'Online Dispute Resolution in International Arbitration',
            rus: 'Разрешение споров онлайн в международном арбитраже',
            ukr: 'Вирішення спорів онлайн у міжнародному арбітражі',
            deu: 'Online-Streitbeilegung in internationalen Schiedsverfahren',
        },
        profile_role_specialist_investor_text_6: {
            eng: 'Ability to get expert advice',
            rus: 'Возможность получения консультации специалиста',
            ukr: 'Можливість отримання консультації спеціаліста',
            deu: 'Fähigkeit, Expertenrat einzuholen',
        },
        profile_role_specialist_investor_text_7: {
            eng: 'Automatically fix transactions in the blockchain registry',
            rus: 'Автоматическая фиксация сделок в блокчейн реестре',
            ukr: 'Автоматична фіксація операцій у блокчейн реєстрі',
            deu: 'Transaktionen automatisch in der Blockchain-Registrierung korrigieren',
        },
        profile_role_specialist_notary_text_1: {
            eng: 'Ability to introduce yourself to clients from all over the world',
            rus: 'Возможность представить себя клиентам со всего мира',
            ukr: 'Можливість уявити себе клієнтам з усього світу',
            deu: 'Fähigkeit, sich Kunden aus der ganzen Welt vorzustellen',
        },
        profile_role_specialist_notary_text_2: {
            eng: 'Comfortable work without being tied to the office',
            rus: 'Удобная работа без привязки к офису',
            ukr: "Зручна робота без прив'язки до офісу",
            deu: 'Komfortables Arbeiten ohne Bürobindung',
        },
        profile_role_specialist_notary_text_3: {
            eng: 'Fulfillment of foreign orders in the local market',
            rus: 'Выполнение зарубежных заказов на местном рынке',
            ukr: 'Виконання закордонних замовлень на місцевому ринку',
            deu: 'Erfüllung von Auslandsaufträgen auf dem lokalen Markt',
        },
        profile_role_specialist_notary_text_4: {
            eng: 'Savings on advertising and customer search',
            rus: 'Экономия на рекламе и поиске клиентов',
            ukr: 'Економія на рекламі та пошуку клієнтів',
            deu: 'Einsparungen bei Werbung und Kundensuche',
        },
        profile_role_specialist_notary_text_5: {
            eng: 'Access lead information',
            rus: 'Доступ к информации о потенциальных клиентах',
            ukr: 'Доступ до інформації про потенційних клієнтів',
            deu: 'Auf Lead-Informationen zugreifen',
        },
        profile_role_specialist_notary_text_6: {
            eng: 'Improved status and prestige in global and local markets',
            rus: 'Улучшение статуса и престижа на мировом и местном рынках',
            ukr: 'Поліпшення статусу та престижу на світовому та місцевому ринках',
            deu: 'Verbesserter Status und Prestige auf globalen und lokalen Märkten',
        },
        profile_role_specialist_seller_text_1: {
            eng: 'Access to customers worldwide',
            rus: 'Доступ к покупателям по всему миру',
            ukr: 'Доступ до покупців по всьому світу',
            deu: 'Zugang zu Kunden weltweit',
        },
        profile_role_specialist_seller_text_2: {
            eng: 'Access to real estate specialists',
            rus: 'Доступ к специалистам рынка недвижимости',
            ukr: 'Доступ до фахівців ринку нерухомості',
            deu: 'Zugang zu Immobilienspezialisten',
        },
        profile_role_specialist_seller_text_3: {
            eng: 'Security of transactions thanks to the digital contract',
            rus: 'Безопасность сделок благодаря цифровому контракту',
            ukr: 'Безпека угод завдяки цифровому контракту',
            deu: 'Transaktionssicherheit dank digitalem Vertrag',
        },
        profile_role_specialist_seller_text_4: {
            eng: 'Speed up the process of selling an object',
            rus: 'Ускорение процесса продажи объекта',
            ukr: "Прискорення процесу продажу об'єкта",
            deu: 'Beschleunigen Sie den Verkaufsprozess eines Objekts',
        },
        profile_role_specialist_seller_text_5: {
            eng: 'Property Promo Page',
            rus: 'Промо-страница объекта недвижимости',
            ukr: "Промо-сторінка об'єкту нерухомості",
            deu: 'Immobilien-Werbeseite',
        },
        profile_secondary_step_professional: {
            eng: 'Professional',
            rus: 'Профессиональные',
            ukr: 'Професійні',
            deu: 'Profi',
        },
        profile_secondary_step_contact: {
            eng: 'Contact person',
            rus: 'Контактное лицо',
            ukr: 'Контактна особа',
            deu: 'Ansprechpartner',
        },
        profile_secondary_step_finance: {
            eng: 'Financials',
            rus: 'Финансовые показатели',
            ukr: 'Фінансові показники',
            deu: 'Finanzen',
        },
        profile_secondary_step_licence: {
            eng: 'License and Education',
            rus: 'Лицензия и образование',
            ukr: 'Ліцензія та освіта',
            deu: 'Lizenz und Ausbildung',
        },
        profile_secondary_title: {
            eng: 'Enter your information',
            rus: 'Укажите информацию о себе',
            ukr: 'Вкажіть інформацію про себе',
            deu: 'Geben Sie Ihre Informationen ein',
        },
        profile_secondary_form_back: {
            eng: 'Previous Step',
            rus: 'Предыдущий шаг',
            ukr: 'Попередній крок',
            deu: 'Vorheriger Schritt',
        },
        profile_secondary_form_next: {
            eng: 'Next',
            rus: 'Далее',
            ukr: 'Далі',
            deu: 'Weiter',
        },
        profile_secondary_form_send: {
            eng: 'Submit',
            rus: 'Отправить',
            ukr: 'Надіслати',
            deu: 'Senden',
        },
        submit: {
            eng: 'Submit',
            rus: 'Отправить',
            ukr: 'Надіслати',
            deu: 'Senden',
        },
        profile_secondary_form_prof_title: {
            eng: '1. Professional Options',
            rus: '1. Профессиональные параметры',
            ukr: '1. Професійні параметри',
            deu: '1. Berufliche Möglichkeiten',
        },
        profile_secondary_form_prof_img_load: {
            eng: 'Upload profile photo',
            rus: 'Загрузить фотографию для профиля',
            ukr: 'Завантажити фотографію для профілю',
            deu: 'Profilfoto hochladen',
        },
        profile_secondary_form_prof_role: {
            eng: 'Roles:',
            rus: 'Роли:',
            ukr: 'Ролі:',
            deu: 'Rollen:',
        },
        profile_secondary_form_prof_specializations: {
            eng: 'Specializations',
            rus: 'Специализации',
            ukr: 'Спеціалізації',
            deu: 'Spezialisierungen',
        },
        profile_secondary_form_prof_help_specializations: {
            eng: 'Specify at least one specialization',
            rus: 'Укажите хотя бы одну специализацию',
            ukr: 'Вкажіть хоча б одну спеціалізацію',
            deu: 'Mindestens eine Spezialisierung angeben',
        },
        profile_secondary_form_prof_country: {
            eng: 'Country of residence',
            rus: 'Страна проживания',
            ukr: 'Країна проживання',
            deu: 'Land des Wohnsitzes',
        },
        profile_secondary_form_prof_help_country: {
            eng: 'Enter your country of residence',
            rus: 'Укажите вашу страну проживания',
            ukr: 'Вкажіть вашу країну проживання',
            deu: 'Geben Sie Ihr Wohnsitzland ein',
        },
        profile_secondary_form_prof_business_country: {
            eng: 'Countries of doing business',
            rus: 'Страны ведения бизнеса',
            ukr: 'Країни ведення бізнесу',
            deu: 'Länder der Geschäftstätigkeit',
        },
        profile_secondary_form_prof_help_business_country: {
            eng: 'Please select at least one country',
            rus: 'Уажите хотя бы одну страну',
            ukr: 'Зважте хоча б одну країну',
            deu: 'Bitte mindestens ein Land auswählen',
        },
        profile_secondary_form_prof_max_transaction_amount: {
            eng: 'Maximum trade amount (EURO equivalent)',
            rus: 'Максимальная сумма сделки (EURO эквивалент)',
            ukr: 'Максимальна сума угоди (EURO еквівалент)',
            deu: 'Maximaler Handelsbetrag (EURO-Gegenwert)',
        },
        profile_secondary_form_prof_input_max_transaction_amount: {
            eng: 'Enter the maximum trade amount (EURO equivalent)',
            rus: 'Введите максимальную сумму сделки (EURO эквивалент)',
            ukr: 'Введіть максимальну суму угоди (EURO еквівалент)',
            deu: 'Geben Sie den maximalen Handelsbetrag ein (EURO-Gegenwert)',
        },
        profile_secondary_form_prof_input_help_max_transaction_amount: {
            eng: 'Enter the maximum trade amount (EURO equivalent)',
            rus: 'Введите максимальную сумму сделки (EURO эквивалент)',
            ukr: 'Введіть максимальну суму угоди (EURO еквівалент)',
            deu: 'Geben Sie den maximalen Handelsbetrag ein (EURO-Gegenwert)',
        },
        profile_secondary_form_prof_biggest_projects: {
            eng: 'Name of the largest completed project',
            rus: 'Название крупнейшего завершенного проекта',
            ukr: 'Назва найбільшого завершеного проекту',
            deu: 'Name des größten abgeschlossenen Projekts',
        },
        profile_secondary_form_prof_input_biggest_projects: {
            eng: 'Enter the name of the largest completed project',
            rus: 'Введите название крупнейшего завершенного проекта',
            ukr: 'Введіть назву найбільшого завершеного проекту',
            deu: 'Geben Sie den Namen des größten abgeschlossenen Projekts ein',
        },
        profile_secondary_form_prof_input_help_biggest_projects: {
            eng: 'Enter the name of the largest completed project',
            rus: 'Введите название крупнейшего завершенного проекта',
            ukr: 'Введіть назву найбільшого завершеного проекту',
            deu: 'Geben Sie den Namen des größten abgeschlossenen Projekts ein',
        },
        profile_secondary_form_prof_total_constructed: {
            eng: 'Total built area, square meters',
            rus: 'Общая площадь построенного, квадратные метры',
            ukr: 'Загальна площа збудованого, квадратні метри',
            deu: 'Gesamt bebaute Fläche, Quadratmeter',
        },
        profile_secondary_form_prof_input_total_constructed: {
            eng: 'Enter the total built area, square meters',
            rus: 'Введите общую площадь построенного, квадратные метры',
            ukr: 'Введіть загальну площу збудованого, квадратні метри',
            deu: 'Geben Sie die bebaute Gesamtfläche ein, Quadratmeter',
        },
        profile_secondary_form_prof_input_help_total_constructed: {
            eng: 'Enter the total built area, square meters',
            rus: 'Введите общую площадь построенного, квадратные метры',
            ukr: 'Введіть загальну площу збудованого, квадратні метри',
            deu: 'Geben Sie die bebaute Gesamtfläche ein, Quadratmeter',
        },
        profile_secondary_form_prof_completed_projects: {
            eng: 'Number of completed projects',
            rus: 'Количество завершенных проектов',
            ukr: 'Кількість завершених проектів',
            deu: 'Anzahl abgeschlossener Projekte',
        },
        profile_secondary_form_prof_input_completed_projects: {
            eng: 'Enter the number of completed projects',
            rus: 'Введите количество завершенных проектов',
            ukr: 'Введіть кількість завершених проектів',
            deu: 'Geben Sie die Anzahl abgeschlossener Projekte ein',
        },
        profile_secondary_form_prof_input_help_completed_projects: {
            eng: 'Enter the number of completed projects',
            rus: 'Введите количество завершенных проектов',
            ukr: 'Введіть кількість завершених проектів',
            deu: 'Geben Sie die Anzahl abgeschlossener Projekte ein',
        },
        profile_secondary_form_prof_investment_period: {
            eng: 'Desired investment period (years)',
            rus: 'Желаемый период инвестиций (лет)',
            ukr: 'Бажаний період інвестицій (років)',
            deu: 'Gewünschter Investitionszeitraum (Jahre)',
        },
        profile_secondary_form_prof_input_investment_period: {
            eng: 'Enter desired investment period (years)',
            rus: 'Введите желаемый период инвестиций (лет)',
            ukr: 'Введіть бажаний період інвестицій (років)',
            deu: 'Gewünschten Anlagezeitraum (Jahre) eingeben',
        },
        profile_secondary_form_prof_input_help_investment_period: {
            eng: 'Enter desired investment period (years)',
            rus: 'Введите желаемый период инвестиций (лет)',
            ukr: 'Введіть бажаний період інвестицій (років)',
            deu: 'Gewünschten Anlagezeitraum (Jahre) eingeben',
        },
        profile_secondary_form_prof_desired_profitability: {
            eng: 'Desired return (%)',
            rus: 'Желаемая доходность (%)',
            ukr: 'Бажана доходність (%)',
            deu: 'Gewünschte Rendite (%)',
        },
        profile_secondary_form_prof_input_desired_profitability: {
            eng: 'Enter desired yield (%)',
            rus: 'Введите желаемую доходность (%)',
            ukr: 'Введіть бажану прибутковість (%)',
            deu: 'Gewünschten Ertrag (%) eingeben',
        },
        profile_secondary_form_prof_input_help_desired_profitability: {
            eng: 'Enter desired yield (%)',
            rus: 'Введите желаемую доходность (%)',
            ukr: 'Введіть бажану прибутковість (%)',
            deu: 'Gewünschten Ertrag (%) eingeben',
        },
        profile_secondary_form_prof_company_name: {
            eng: 'Company name',
            rus: 'Название компании',
            ukr: 'Назва компанії',
            deu: 'Firmenname',
        },
        profile_secondary_form_prof_input_company_name: {
            eng: 'Enter company name',
            rus: 'Введите название компании',
            ukr: 'Введіть назву компанії',
            deu: 'Firmenname eingeben',
        },
        profile_secondary_form_prof_input_help_company_name: {
            eng: 'Enter company name',
            rus: 'Введите название компании',
            ukr: 'Введіть назву компанії',
            deu: 'Firmenname eingeben',
        },
        profile_secondary_form_prof_foundation_year: {
            eng: 'Year of foundation of the company',
            rus: 'Год основания компании',
            ukr: 'Рік заснування компанії',
            deu: 'Gründungsjahr des Unternehmens',
        },
        profile_secondary_form_prof_input_foundation_year: {
            eng: 'Enter the year the company was founded',
            rus: 'Введите год основания компании',
            ukr: 'Введіть рік заснування компанії',
            deu: 'Geben Sie das Gründungsjahr des Unternehmens ein',
        },
        profile_secondary_form_prof_input_help_foundation_year: {
            eng: 'Enter the year the company was founded',
            rus: 'Укажите год основания компании',
            ukr: 'Вкажіть рік заснування компанії',
            deu: 'Geben Sie das Gründungsjahr des Unternehmens ein',
        },
        profile_secondary_form_prof_experience: {
            eng: 'Years of experience',
            rus: 'Годы опыта',
            ukr: 'Роки досвіду',
            deu: 'Jahrelange Erfahrung',
        },
        profile_secondary_form_prof_input_experience: {
            eng: 'Enter years of experience',
            rus: 'Укажите годы опыта',
            ukr: 'Вкажіть роки досвіду',
            deu: 'Erfahrungsjahre eintragen',
        },
        profile_secondary_form_prof_input_help_experience: {
            eng: 'Enter the number of years of experience in your field',
            rus: 'Укажите количество лет опыта работы в вашей сфере',
            ukr: 'Вкажіть кількість років досвіду роботи у вашій сфері',
            deu: 'Geben Sie die Anzahl der Jahre Erfahrung in Ihrem Bereich ein',
        },
        profile_secondary_form_prof_company_number: {
            eng: 'Company number',
            rus: 'Номер компании',
            ukr: 'Номер компанії',
            deu: 'Firmennummer',
        },
        profile_secondary_form_prof_input_company_number: {
            eng: 'Enter company number',
            rus: 'Укажите номер компании',
            ukr: 'Вкажіть номер компанії',
            deu: 'Unternehmensnummer eingeben',
        },
        profile_secondary_form_prof_input_help_company_number: {
            eng: 'Enter company number',
            rus: 'Укажите номер компании',
            ukr: 'Вкажіть номер компанії',
            deu: 'Unternehmensnummer eingeben',
        },
        profile_secondary_form_prof_registration_number: {
            eng: 'Registration number',
            rus: 'Регистрационный номер',
            ukr: 'Реєстраційний номер',
            deu: 'Registrierungsnummer',
        },
        profile_secondary_form_prof_input_registration_number: {
            eng: 'Enter registration number',
            rus: 'Укажите регистрационный номер',
            ukr: 'Вкажіть реєстраційний номер',
            deu: 'Registriernummer eingeben',
        },
        profile_secondary_form_prof_input_help_registration_number: {
            eng: 'Enter registration number',
            rus: 'Укажите регистрационный номер',
            ukr: 'Вкажіть реєстраційний номер',
            deu: 'Registriernummer eingeben',
        },
        profile_secondary_form_prof_members_number: {
            eng: 'Number of employees',
            rus: 'Количество сотрудников',
            ukr: 'Кількість співробітників',
            deu: 'Anzahl der Mitarbeiter',
        },
        profile_secondary_form_prof_input_members_number: {
            eng: 'Enter the number of employees',
            rus: 'Укажите количество сотрудников',
            ukr: 'Вкажіть кількість співробітників',
            deu: 'Geben Sie die Anzahl der Mitarbeiter ein',
        },
        profile_secondary_form_prof_input_help_members_number: {
            eng: 'Enter the number of employees',
            rus: 'Укажите количество сотрудников',
            ukr: 'Вкажіть кількість співробітників',
            deu: 'Geben Sie die Anzahl der Mitarbeiter ein',
        },
        profile_secondary_form_prof_branches_number: {
            eng: 'Number of branches',
            rus: 'Количество филиалов',
            ukr: 'Кількість філій',
            deu: 'Anzahl der Filialen',
        },
        profile_secondary_form_prof_input_branches_number: {
            eng: 'Enter the number of branches',
            rus: 'Укажите количество филиалов',
            ukr: 'Вкажіть кількість філій',
            deu: 'Geben Sie die Anzahl der Zweige ein',
        },
        profile_secondary_form_prof_input_help_branches_number: {
            eng: 'Enter the number of branches',
            rus: 'Укажите количество филиалов',
            ukr: 'Вкажіть кількість філій',
            deu: 'Geben Sie die Anzahl der Zweige ein',
        },
        profile_secondary_form_prof_services: {
            eng: 'Your services',
            rus: 'Ваши сервисы',
            ukr: 'Ваші сервіси',
            deu: 'Ihre Dienste',
        },
        profile_secondary_form_prof_input_services: {
            eng: 'Specify your services',
            rus: 'Укажите ваши сервисы',
            ukr: 'Вкажіть ваші сервіси',
            deu: 'Spezifizieren Sie Ihre Dienste',
        },
        profile_secondary_form_prof_input_help_services: {
            eng: 'Specify the services your company provides',
            rus: 'Укажите услуги, которые предоставляет ваша компания',
            ukr: 'Вкажіть послуги, які надає ваша компанія',
            deu: 'Geben Sie die Dienstleistungen an, die Ihr Unternehmen anbietet',
        },
        profile_secondary_form_prof_clients: {
            eng: 'Number of clients',
            rus: 'Количество клиентов',
            ukr: 'Кількість клієнтів',
            deu: 'Anzahl der Kunden',
        },
        profile_secondary_form_prof_input_clients: {
            eng: 'Enter the number of clients',
            rus: 'Укажите количество клиентов',
            ukr: 'Вкажіть кількість клієнтів',
            deu: 'Geben Sie die Anzahl der Kunden ein',
        },
        profile_secondary_form_prof_input_help_clients: {
            eng: 'Enter the number of clients',
            rus: 'Укажите количество клиентов',
            ukr: 'Вкажіть кількість клієнтів',
            deu: 'Geben Sie die Anzahl der Kunden ein',
        },
        profile_secondary_form_prof_promocode: {
            eng: 'Promo code',
            rus: 'Промокод',
            ukr: 'Промокод',
            deu: 'Gutscheincode',
        },
        profile_secondary_form_prof_input_promocode: {
            eng: 'Enter promo code',
            rus: 'Укажите промокод',
            ukr: 'Вкажіть промокод',
            deu: 'Gutscheincode eingeben',
        },
        profile_secondary_form_prof_input_help_promocode: {
            eng: 'If you have a registration promo code, enter it',
            rus: 'Если у вас есть промокод на регистрацию, укажите его',
            ukr: 'Якщо у вас є промокод на реєстрацію, вкажіть його',
            deu: 'Wenn Sie einen Registrierungs-Promo-Code haben, geben Sie ihn ein',
        },
        profile_secondary_form_prof_check_spec: {
            eng: 'Qualified Person',
            rus: 'Квалифицированный специалист',
            ukr: 'Кваліфікований спеціаліст',
            deu: 'Qualifizierte Person',
        },
        profile_secondary_form_prof_check_spec_yes: {
            eng: 'Yes',
            rus: 'Да',
            ukr: 'Так',
            deu: 'Ja',
        },
        profile_secondary_form_prof_check_spec_no: {
            eng: 'No',
            rus: 'Нет',
            ukr: 'Ні',
            deu: 'Nein',
        },
        profile_secondary_form_prof_check_resident: {
            eng: 'US tax resident',
            rus: 'Налоговый резидент США',
            ukr: 'Податковий резидент США',
            deu: 'US-Steuerinländer',
        },
        profile_secondary_form_prof_check_resident_yes: {
            eng: 'Yes',
            rus: 'Да',
            ukr: 'Так',
            deu: 'Ja',
        },
        profile_secondary_form_prof_check_resident_no: {
            eng: 'No',
            rus: 'Нет',
            ukr: 'Ні',
            deu: 'Nein',
        },
        profile_secondary_form_prof_check_subject_law: {
            eng: 'Subject of the right',
            rus: 'Субъект права',
            ukr: "Суб'єкт права",
            deu: 'Gegenstand des Rechts',
        },
        profile_secondary_form_prof_check_subject_law_person: {
            eng: 'Individual',
            rus: 'Физическое лицо',
            ukr: 'Фізична особа',
            deu: 'Individuell',
        },
        profile_secondary_form_prof_check_subject_law_company: {
            eng: 'Company',
            rus: 'Компания',
            ukr: 'Компанія',
            deu: 'Firma',
        },
        profile_secondary_form_prof_description: {
            eng: 'Describe your intentions',
            rus: 'Опишите ваши намерения',
            ukr: 'Опишіть ваші наміри',
            deu: 'Beschreiben Sie Ihre Absichten',
        },
        profile_secondary_form_prof_input_description: {
            eng: 'Describe your intentions',
            rus: 'Опишите ваши намерения',
            ukr: 'Опишіть ваші наміри',
            deu: 'Beschreiben Sie Ihre Absichten',
        },
        profile_secondary_form_prof_input_help_description: {
            eng: 'Describe your intentions',
            rus: 'Опишите ваши намерения',
            ukr: 'Опишіть ваші наміри',
            deu: 'Beschreiben Sie Ihre Absichten',
        },
        profile_secondary_form_prof_license_title: {
            eng: '2. Licenses',
            rus: '2. Лицензии',
            ukr: '2. Ліцензії',
            deu: '2. Lizenzen',
        },
        profile_secondary_form_prof_license_number: {
            eng: 'License serial number',
            rus: 'Серийный номер лицензии',
            ukr: 'Серійний номер ліцензії',
            deu: 'Lizenzseriennummer',
        },
        profile_secondary_form_prof_input_license_number: {
            eng: 'Enter license serial number',
            rus: 'Укажите серийный номер лицензии',
            ukr: 'Вкажіть серійний номер ліцензії',
            deu: 'Lizenzseriennummer eingeben',
        },
        profile_secondary_form_prof_input_help_license_number: {
            eng: 'Enter license serial number',
            rus: 'Укажите серийный номер лицензии',
            ukr: 'Вкажіть серійний номер ліцензії',
            deu: 'Lizenzseriennummer eingeben',
        },
        profile_secondary_form_prof_license_load: {
            eng: 'Upload license scan',
            rus: 'Загрузить скан лицензии',
            ukr: 'Завантажити скан ліцензії',
            deu: 'Lizenzscan hochladen',
        },
        profile_secondary_form_prof_license_add: {
            eng: 'Add',
            rus: 'Добавить',
            ukr: 'Додати',
            deu: 'Hinzufügen',
        },
        profile_secondary_form_prof_license_remove: {
            eng: 'Delete',
            rus: 'Удалить',
            ukr: 'Видалити',
            deu: 'Löschen',
        },
        profile_secondary_form_prof_control_title: {
            eng: '3. Control',
            rus: '3. Управление',
            ukr: '3. Управління',
            deu: '3. Kontrolle',
        },
        profile_secondary_form_prof_management_objects_quantity: {
            eng: 'Number of control objects',
            rus: 'Количество объектов управления',
            ukr: "Кількість об'єктів керування",
            deu: 'Anzahl der Kontrollobjekte',
        },
        profile_secondary_form_prof_input_management_objects_quantity: {
            eng: 'Specify the number of control objects',
            rus: 'Укажите количество объектов управления',
            ukr: "Вкажіть кількість об'єктів керування",
            deu: 'Geben Sie die Anzahl der Kontrollobjekte an',
        },
        profile_secondary_form_prof_input_help_management_objects_quantity: {
            eng: 'Specify the number of control objects',
            rus: 'Укажите количество объектов управления',
            ukr: "Вкажіть кількість об'єктів керування",
            deu: 'Geben Sie die Anzahl der Kontrollobjekte an',
        },
        profile_secondary_form_prof_management_objects_value: {
            eng: 'Value of objects in management (EURO equivalent)',
            rus: 'Стоимость объектов в управлении (EURO эквивалент)',
            ukr: "Вартість об'єктів управління(EURO еквівалент)",
            deu: 'Wert der verwalteten Objekte (EURO-Gegenwert)',
        },
        profile_secondary_form_prof_input_management_objects_value: {
            eng: 'Specify the value of objects in management (EURO equivalent)',
            rus: 'Укажите стоимость объектов в управлении (EURO эквивалент)',
            ukr: "Вкажіть вартість об'єктів управління(EURO еквівалент)",
            deu: 'Geben Sie den Wert der verwalteten Objekte an (EURO-Gegenwert)',
        },
        profile_secondary_form_prof_input_help_management_objects_value: {
            eng: 'Enter the value of the objects in management',
            rus: 'Укажите стоимость объектов в управлении',
            ukr: "Вкажіть вартість об'єктів в управлінні",
            deu: 'Geben Sie den Wert der verwalteten Objekte ein',
        },
        profile_secondary_form_prof_portfolio: {
            eng: 'Object Portfolio',
            rus: 'Портфолио объектов',
            ukr: "Портфоліо об'єктів",
            deu: 'Objektportfolio',
        },
        profile_secondary_form_prof_input_portfolio: {
            eng: 'Enter Portfolio of Objects',
            rus: 'Укажите портфолио объектов',
            ukr: "Вкажіть портфоліо об'єктів",
            deu: 'Portfolio von Objekten eingeben',
        },
        profile_secondary_form_prof_input_help_portfolio: {
            eng: 'Enter Portfolio of Objects',
            rus: 'Укажите портфолио объектов',
            ukr: "Вкажіть портфоліо об'єктів",
            deu: 'Portfolio von Objekten eingeben',
        },
        profile_secondary_form_prof_own_tenant_base: {
            eng: 'Having your own base of tenants',
            rus: 'Наличие собственной базы арендаторов',
            ukr: 'Наявність власної бази орендарів',
            deu: 'Einen eigenen Mieterstamm haben',
        },
        profile_secondary_form_prof_input_own_tenant_base: {
            eng: 'Having your own base of tenants',
            rus: 'Наличие собственной базы арендаторов',
            ukr: 'Наявність власної бази орендарів',
            deu: 'Einen eigenen Mieterstamm haben',
        },
        profile_secondary_form_prof_input_help_own_tenant_base: {
            eng: 'Having your own base of tenants',
            rus: 'Наличие собственной базы арендаторов',
            ukr: 'Наявність власної бази орендарів',
            deu: 'Einen eigenen Mieterstamm haben',
        },
        profile_secondary_form_prof_contact_title: {
            eng: 'Contact person',
            rus: 'Контактное лицо',
            ukr: 'Контактна особа',
            deu: 'Ansprechpartner',
        },
        profile_secondary_form_prof_contact_fname: {
            eng: 'Name',
            rus: 'Имя',
            ukr: "Ім'я",
            deu: 'Name',
        },
        profile_secondary_form_prof_input_contact_fname: {
            eng: 'Enter name',
            rus: 'Введите имя',
            ukr: "Введіть ім'я",
            deu: 'Namen eingeben',
        },
        profile_secondary_form_prof_input_help_contact_fname: {
            eng: 'Enter name',
            rus: 'Введите имя',
            ukr: "Введіть ім'я",
            deu: 'Namen eingeben',
        },
        profile_secondary_form_prof_contact_lname: {
            eng: 'Last name',
            rus: 'Фамилия',
            ukr: 'Прізвище',
            deu: 'Nachname',
        },
        profile_secondary_form_prof_input_contact_lname: {
            eng: 'Enter last name',
            rus: 'Введите фамилию',
            ukr: 'Введіть прізвище',
            deu: 'Nachnamen eingeben',
        },
        profile_secondary_form_prof_input_help_contact_lname: {
            eng: 'Enter last name',
            rus: 'Введите фамилию',
            ukr: 'Введіть прізвище',
            deu: 'Nachnamen eingeben',
        },
        profile_secondary_form_prof_contact_position: {
            eng: 'Position',
            rus: 'Должность',
            ukr: 'Посада',
            deu: 'Position',
        },
        profile_secondary_form_prof_input_contact_position: {
            eng: 'Specify position',
            rus: 'Укажите должность',
            ukr: 'Вкажіть посаду',
            deu: 'Position angeben',
        },
        profile_secondary_form_prof_input_help_contact_position: {
            eng: 'Specify position',
            rus: 'Укажите должность',
            ukr: 'Вкажіть посаду',
            deu: 'Position angeben',
        },
        profile_secondary_form_prof_contact_phone: {
            eng: 'Phone number',
            rus: 'Номер телефона',
            ukr: 'Номер телефону',
            deu: 'Telefonnummer',
        },
        profile_secondary_form_prof_input_contact_phone: {
            eng: 'Enter phone number',
            rus: 'Введите номер телефона',
            ukr: 'Введіть номер телефону',
            deu: 'Telefonnummer eingeben',
        },
        profile_secondary_form_prof_input_help_contact_phone: {
            eng: 'Enter phone number',
            rus: 'Введите номер телефона',
            ukr: 'Введіть номер телефону',
            deu: 'Telefonnummer eingeben',
        },
        profile_secondary_form_prof_contact_email: {
            eng: 'Email',
            rus: 'Email',
            ukr: 'Email',
            deu: 'E-Mail',
        },
        profile_secondary_form_prof_input_contact_email: {
            eng: 'Enter email',
            rus: 'Введите email',
            ukr: 'Введіть email',
            deu: 'E-Mail eingeben',
        },
        profile_secondary_form_prof_input_help_contact_email: {
            eng: 'Enter email',
            rus: 'Введите email',
            ukr: 'Введіть email',
            deu: 'E-Mail eingeben',
        },
        profile_secondary_form_finance: {
            eng: 'Financials',
            rus: 'Финансовые показатели',
            ukr: 'Фінансові показники',
            deu: 'Finanzen',
        },
        profile_secondary_form_finance_completed_projects: {
            eng: 'Number of completed projects',
            rus: 'Количество завершенных проектов',
            ukr: 'Кількість завершених проектів',
            deu: 'Anzahl abgeschlossener Projekte',
        },
        profile_secondary_form_finance_input_completed_projects: {
            eng: 'Enter the number of completed projects',
            rus: 'Укажите количество завершенных проектов',
            ukr: 'Вкажіть кількість завершених проектів',
            deu: 'Geben Sie die Anzahl abgeschlossener Projekte ein',
        },
        profile_secondary_form_finance_input_help_completed_projects: {
            eng: 'Enter the number of completed projects',
            rus: 'Укажите количество завершенных проектов',
            ukr: 'Вкажіть кількість завершених проектів',
            deu: 'Geben Sie die Anzahl abgeschlossener Projekte ein',
        },
        profile_secondary_form_finance_biggest_projects: {
            eng: 'Name of the largest completed project',
            rus: 'Название крупнейшего завершенного проекта',
            ukr: 'Назва найбільшого завершеного проекту',
            deu: 'Name des größten abgeschlossenen Projekts',
        },
        profile_secondary_form_finance_input_biggest_projects: {
            eng: 'Enter the name of the largest completed project',
            rus: 'Укажите название крупнейшего завершенного проекта',
            ukr: 'Вкажіть назву найбільшого завершеного проекту',
            deu: 'Geben Sie den Namen des größten abgeschlossenen Projekts ein',
        },
        profile_secondary_form_finance_input_help_biggest_projects: {
            eng: 'Name of the largest completed project',
            rus: 'Название крупнейшего завершенного проекта',
            ukr: 'Назва найбільшого завершеного проекту',
            deu: 'Name des größten abgeschlossenen Projekts',
        },
        profile_secondary_form_finance_total_constructed: {
            eng: 'Total built area, square meters',
            rus: 'Общая площадь построенного, квадратные метры',
            ukr: 'Загальна площа збудованого, квадратні метри',
            deu: 'Gesamt bebaute Fläche, Quadratmeter',
        },
        profile_secondary_form_finance_input_total_constructed: {
            eng: 'Enter the total built area, square meters',
            rus: 'Укажите общую площадь построенного, квадратные метры',
            ukr: 'Вкажіть загальну площу збудованого, квадратні метри',
            deu: 'Geben Sie die bebaute Gesamtfläche ein, Quadratmeter',
        },
        profile_secondary_form_finance_input_help_total_constructed: {
            eng: 'Enter the total built area, square meters',
            rus: 'Укажите общую площадь построенного, квадратные метры',
            ukr: 'Вкажіть загальну площу збудованого, квадратні метри',
            deu: 'Geben Sie die bebaute Gesamtfläche ein, Quadratmeter',
        },
        profile_secondary_form_finance_own_working_capital: {
            eng: 'Private working capital (EURO equivalent)',
            rus: 'Частный оборотный капитал (EURO эквивалент)',
            ukr: 'Приватний оборотний капітал (EURO еквівалент)',
            deu: 'Privates Betriebskapital (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_input_own_working_capital: {
            eng: 'Enter private working capital (EURO equivalent)',
            rus: 'Укажите частный оборотный капитал (EURO эквивалент)',
            ukr: 'Вкажіть приватний оборотний капітал (EURO еквівалент)',
            deu: 'Privates Betriebskapital eingeben (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_input_help_own_working_capital: {
            eng: 'Enter private working capital (EURO equivalent)',
            rus: 'Укажите частный оборотный капитал (EURO эквивалент)',
            ukr: 'Вкажіть приватний оборотний капітал (EURO еквівалент)',
            deu: 'Privates Betriebskapital eingeben (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_total_assets: {
            eng: 'Total assets (EURO equivalent)',
            rus: 'Общая сумма активов (EURO эквивалент)',
            ukr: 'Загальна сума активів (EURO еквівалент)',
            deu: 'Gesamtvermögen (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_input_total_assets: {
            eng: 'Enter the total amount of assets (EURO equivalent)',
            rus: 'Укажите общую сумму активов (EURO эквивалент)',
            ukr: 'Вкажіть загальну суму активів (EURO еквівалент)',
            deu: 'Geben Sie den Gesamtbetrag des Vermögens ein (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_input_help_total_assets: {
            eng: 'Enter the total amount of assets (EURO equivalent)',
            rus: 'Укажите общую сумму активов (EURO эквивалент)',
            ukr: 'Вкажіть загальну суму активів (EURO еквівалент)',
            deu: 'Geben Sie den Gesamtbetrag des Vermögens ein (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_retained_earnings: {
            eng: 'Retained earnings (EURO equivalent)',
            rus: 'Нераспределенная прибыль (EURO эквивалент)',
            ukr: 'Нерозподілений прибуток (EURO еквівалент)',
            deu: 'Gewinnrücklagen (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_input_retained_earnings: {
            eng: 'Enter retained earnings (EURO equivalent)',
            rus: 'Укажите нераспределенную прибыль (EURO эквивалент)',
            ukr: 'Вкажіть нерозподілений прибуток (EURO еквівалент)',
            deu: 'Einbehaltene Gewinne eingeben (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_input_help_retained_earnings: {
            eng: 'Enter retained earnings (EURO equivalent)',
            rus: 'Укажите нераспределенную прибыль (EURO эквивалент)',
            ukr: 'Вкажіть нерозподілений прибуток (EURO еквівалент)',
            deu: 'Einbehaltene Gewinne eingeben (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_earnings_before_tax: {
            eng: 'Earnings before interest and taxes (EURO equivalent)',
            rus: 'Прибыль до вычета процентов и налогов (EURO эквивалент)',
            ukr: 'Прибуток до вирахування відсотків та податків (EURO еквівалент)',
            deu: 'Ergebnis vor Zinsen und Steuern (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_input_earnings_before_tax: {
            eng: 'Enter earnings before interest and taxes (EURO equivalent)',
            rus: 'Укажите прибыль до вычета процентов и налогов (EURO эквивалент)',
            ukr: 'Вкажіть прибуток до вирахування відсотків та податків (EURO еквівалент)',
            deu: 'Gewinn vor Zinsen und Steuern eingeben (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_input_help_earnings_before_tax: {
            eng: 'Enter earnings before interest and taxes (EURO equivalent)',
            rus: 'Укажите прибыль до вычета процентов и налогов (EURO эквивалент)',
            ukr: 'Вкажіть прибуток до вирахування відсотків та податків (EURO еквівалент)',
            deu: 'Gewinn vor Zinsen und Steuern eingeben (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_market_value_equity: {
            eng: 'Market value of capital (EURO equivalent)',
            rus: 'Рыночная стоимость капитала (EURO эквивалент)',
            ukr: 'Ринкова вартість капіталу (EURO еквівалент)',
            deu: 'Marktwert des Kapitals (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_input_market_value_equity: {
            eng: 'Specify the market value of capital (EURO equivalent)',
            rus: 'Укажите рыночную стоимость капитала (EURO эквивалент)',
            ukr: 'Вкажіть ринкову вартість капіталу (EURO еквівалент)',
            deu: 'Geben Sie den Marktwert des Kapitals an (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_input_help_market_value_equity: {
            eng: 'Specify the market value of capital (EURO equivalent)',
            rus: 'Укажите рыночную стоимость капитала (EURO эквивалент)',
            ukr: 'Вкажіть ринкову вартість капіталу (EURO еквівалент)',
            deu: 'Geben Sie den Marktwert des Kapitals an (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_book_value: {
            eng: 'Carrying value of total liabilities (EURO equivalent)',
            rus: 'Балансовая стоимость совокупных обязательств (EURO эквивалент)',
            ukr: "Балансова вартість сукупних зобов'язань(EURO еквівалент)",
            deu: 'Buchwert der gesamten Verbindlichkeiten (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_input_book_value: {
            eng: 'Enter the carrying amount of the total liabilities (EURO equivalent)',
            rus: 'Укажите балансовую стоимость совокупных обязательств (EURO эквивалент)',
            ukr: "Вкажіть балансову вартість сукупних зобов'язань(EURO еквівалент)",
            deu: 'Geben Sie den Buchwert der gesamten Verbindlichkeiten ein (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_input_help_book_value: {
            eng: 'Enter the carrying amount of the total liabilities (EURO equivalent)',
            rus: 'Укажите балансовую стоимость совокупных обязательств (EURO эквивалент)',
            ukr: "Вкажіть балансову вартість сукупних зобов'язань(EURO еквівалент)",
            deu: 'Geben Sie den Buchwert der gesamten Verbindlichkeiten ein (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_sales: {
            eng: 'Profit before tax (EURO equivalent)',
            rus: 'Прибыль до налогообложения (EURO эквивалент)',
            ukr: 'Прибуток оподаткування (EURO еквівалент)',
            deu: 'Gewinn vor Steuern (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_input_sales: {
            eng: 'Specify profit before tax (EURO equivalent)',
            rus: 'Укажите прибыль до налогообложения (EURO эквивалент)',
            ukr: 'Вкажіть прибуток до оподаткування (EURO еквівалент)',
            deu: 'Gewinn vor Steuern angeben (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_input_help_sales: {
            eng: 'Specify profit before tax (EURO equivalent)',
            rus: 'Укажите прибыль до налогообложения (EURO эквивалент)',
            ukr: 'Вкажіть прибуток до оподаткування (EURO еквівалент)',
            deu: 'Gewinn vor Steuern angeben (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_current_obligations: {
            eng: 'Current liabilities (EURO equivalent)',
            rus: 'Текущие обязательства (EURO эквивалент)',
            ukr: "Поточні зобов'язання(EURO еквівалент)",
            deu: 'Kurzfristige Verbindlichkeiten (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_input_current_obligations: {
            eng: 'Specify current liabilities (EURO equivalent)',
            rus: 'Укажите текущие обязательства (EURO эквивалент)',
            ukr: "Вкажіть поточні зобов'язання (EURO еквівалент)",
            deu: 'Kurzfristige Verbindlichkeiten angeben (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_input_help_current_obligations: {
            eng: 'Specify current liabilities (EURO equivalent)',
            rus: 'Укажите текущие обязательства (EURO эквивалент)',
            ukr: "Вкажіть поточні зобов'язання(EURO еквівалент)",
            deu: 'Kurzfristige Verbindlichkeiten angeben (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_amount_obligations: {
            eng: 'Amount of obligations (EURO equivalent)',
            rus: 'Сумма обязательств (EURO эквивалент)',
            ukr: "Сума зобов'язань(EURO еквівалент)",
            deu: 'Betrag der Verpflichtungen (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_input_amount_obligations: {
            eng: 'Enter the amount of the obligation (EURO equivalent)',
            rus: 'Укажите сумму обязательств (EURO эквивалент)',
            ukr: "Вкажіть суму зобов' язань(EURO еквівалент)",
            deu: 'Geben Sie den Verpflichtungsbetrag ein (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_input_help_amount_obligations: {
            eng: 'Enter the amount of the obligation (EURO equivalent)',
            rus: 'Укажите сумму обязательств (EURO эквивалент)',
            ukr: "Вкажіть суму зобов'язань(EURO еквівалент)",
            deu: 'Geben Sie den Verpflichtungsbetrag ein (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_total_current_assets: {
            eng: 'Total for current assets (EURO equivalent)',
            rus: 'Итог по оборотным активам (EURO эквивалент)',
            ukr: 'Підсумок оборотних активів (EURO еквівалент)',
            deu: 'Summe des Umlaufvermögens (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_input_total_current_assets: {
            eng: 'Indicate the total for current assets (EURO equivalent)',
            rus: 'Укажите итог по оборотным активам (EURO эквивалент)',
            ukr: 'Вкажіть результат за оборотними активами (EURO еквівалент)',
            deu: 'Geben Sie den Gesamtbetrag für das Umlaufvermögen an (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_input_help_total_current_assets: {
            eng: 'Indicate the total for current assets (EURO equivalent)',
            rus: 'Укажите итог по оборотным активам (EURO эквивалент)',
            ukr: 'Вкажіть результат за оборотними активами (EURO еквівалент)',
            deu: 'Geben Sie den Gesamtbetrag für das Umlaufvermögen an (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_total_current_liabilities: {
            eng: 'Total current liabilities (EURO equivalent)',
            rus: 'Итог по краткосрочным обязательствам (EURO эквивалент)',
            ukr: "Підсумок щодо короткострокових зобов'язань(EURO еквівалент)",
            deu: 'Summe der kurzfristigen Verbindlichkeiten (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_input_total_current_liabilities: {
            eng: 'Indicate the total for short-term liabilities (EURO equivalent)',
            rus: 'Укажите итог по краткосрочным обязательствам (EURO эквивалент)',
            ukr: "Вкажіть результат за короткостроковими зобов'язаннями(EURO еквівалент)",
            deu: 'Geben Sie den Gesamtbetrag für kurzfristige Verbindlichkeiten an (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_input_help_total_current_liabilities: {
            eng: 'Indicate the total for short-term liabilities (EURO equivalent)',
            rus: 'Укажите итог по краткосрочным обязательствам (EURO эквивалент)',
            ukr: "Вкажіть результат за короткостроковими зобов'язаннями(EURO еквівалент)",
            deu: 'Geben Sie den Gesamtbetrag für kurzfristige Verbindlichkeiten an (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_net_income_before_taxes: {
            eng: 'Net profit before tax (EURO equivalent)',
            rus: 'Чистая прибыль до налогообложения (EURO эквивалент)',
            ukr: 'Чистий прибуток до оподаткування (EURO еквівалент)',
            deu: 'Nettogewinn vor Steuern (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_input_net_income_before_taxes: {
            eng: 'Enter net income before tax (EURO equivalent)',
            rus: 'Укажите чистую прибыль до налогообложения (EURO эквивалент)',
            ukr: 'Вкажіть чистий прибуток до оподаткування (EURO еквівалент)',
            deu: 'Nettoeinkommen vor Steuern eingeben (EURO-Gegenwert)',
        },
        profile_secondary_form_finance_input_help_net_income_before_taxes: {
            eng: 'Enter net income before tax (EURO equivalent)',
            rus: 'Укажите чистую прибыль до налогообложения (EURO эквивалент)',
            ukr: 'Вкажіть чистий прибуток до оподаткування (EURO еквівалент)',
            deu: 'Nettoeinkommen vor Steuern eingeben (EURO-Gegenwert)',
        },
        profile_secondary_form_license: {
            eng: 'License and education',
            rus: 'Лицензия и образование',
            ukr: 'Ліцензія та освіта',
            deu: 'Lizenz und Ausbildung',
        },
        properties_list_item_selled: {
            eng: 'Sold out',
            rus: 'Продано',
            ukr: 'Продано',
            deu: 'Ausverkauft',
        },
        properties_list_item_type: {
            eng: 'Type',
            rus: 'Тип',
            ukr: 'Тип',
            deu: 'Typ',
        },
        properties_list_item_sqm: {
            eng: 'sq. m',
            rus: 'кв. м',
            ukr: 'кв. м',
            deu: 'sq. m',
        },
        properties_list_item_floor: {
            eng: 'Floor',
            rus: 'Этаж',
            ukr: 'Поверх',
            deu: 'Boden',
        },
        properties_list_item_more: {
            eng: 'Details',
            rus: 'Подробнее',
            ukr: 'Докладніше',
            deu: 'Details',
        },
        properties_list_item_edit: {
            eng: 'Edit',
            rus: 'Редактировать',
            ukr: 'Редагувати',
            deu: 'Bearbeiten',
        },
        projects_list_item_type: {
            eng: 'Type',
            rus: 'Тип',
            ukr: 'Тип',
            deu: 'Typ',
        },
        projects_list_item_payback: {
            eng: 'Payback period',
            rus: 'Срок окупаемости',
            ukr: 'Термін окупності',
            deu: 'Amortisationszeit',
        },
        projects_list_item_total_investment: {
            eng: 'Total invested',
            rus: 'Всего вложено',
            ukr: 'Усього вкладено',
            deu: 'Gesamtinvestition',
        },
        projects_list_item_more: {
            eng: 'Details',
            rus: 'Подробнее',
            ukr: 'Докладніше',
            deu: 'Details',
        },
        properties_list_title: {
            eng: 'Property Overview',
            rus: 'Обзор объектов недвижимости',
            ukr: "Огляд об'єктів нерухомості",
            deu: 'Eigenschaftsübersicht',
        },
        properties_list_button_add: {
            eng: 'Add property',
            rus: 'Добавить объект недвижимости',
            ukr: "Додати об'єкт нерухомості",
            deu: 'Eigenschaft hinzufügen',
        },
        properties_list_button_show_all: {
            eng: 'All properties',
            rus: 'Вся недвижимость',
            ukr: 'Вся нерухомість',
            deu: 'Alle Eigenschaften',
        },
        properties_list_button_show_my: {
            eng: 'My property',
            rus: 'Моя недвижимость',
            ukr: 'Моя нерухомість',
            deu: 'Mein Eigentum',
        },
        properties_list_filter_country: {
            eng: 'Country',
            rus: 'Страна',
            ukr: 'Країна',
            deu: 'Land',
        },
        properties_list_filter_name: {
            eng: 'Property name',
            rus: 'Название объекта',
            ukr: "Назва об'єкта",
            deu: 'Eigenschaftsname',
        },
        properties_list_filter_input_name: {
            eng: 'Enter property name',
            rus: 'Введите назване объекта',
            ukr: "Введіть назву об'єкта",
            deu: 'Eigenschaftsnamen eingeben',
        },
        properties_list_filter_price: {
            eng: 'Enter price',
            rus: 'Введите цену',
            ukr: 'Введіть ціну',
            deu: 'Preis eingeben',
        },
        properties_list_filter_price_from: {
            eng: 'Price from',
            rus: 'Цена от',
            ukr: 'Ціна від',
            deu: 'Preis ab',
        },
        properties_list_filter_price_to: {
            eng: 'Price to',
            rus: 'Цена до',
            ukr: 'Ціна до',
            deu: 'Preis bis',
        },
        properties_list_filter_area: {
            eng: 'Enter area',
            rus: 'Введите площадь',
            ukr: 'Введіть площу',
            deu: 'Gebiet eingeben',
        },
        properties_list_filter_area_from: {
            eng: 'Area from',
            rus: 'Площадь от',
            ukr: 'Площа від',
            deu: 'Gebiet von',
        },
        properties_list_filter_area_to: {
            eng: 'Area to',
            rus: 'Площадь до',
            ukr: 'Площа до',
            deu: 'Bereich bis',
        },
        properties_list_filter_type: {
            eng: 'Property type',
            rus: 'Тип недвижимости',
            ukr: 'Тип нерухомості',
            deu: 'Eigenschaftstyp',
        },
        properties_list_filter_search: {
            eng: 'Search',
            rus: 'Поиск',
            ukr: 'Пошук',
            deu: 'Suche',
        },
        properties_list_not_found: {
            eng: 'No objects found',
            rus: 'Объекты не найдены',
            ukr: "Об'єкти не знайдено",
            deu: 'Keine Objekte gefunden',
        },
        properties_complex_list_not_found: {
            eng: 'Nothing was found for your request',
            rus: 'По вашему запросу ничего не найдено',
            ukr: 'За вашим запитом нічого не знайдено',
            deu: 'Für Ihre Anfrage wurde nichts gefunden',
        },
        not_found: {
            eng: 'Nothing was found for your request',
            rus: 'По вашему запросу ничего не найдено',
            ukr: 'За вашим запитом нічого не знайдено',
            deu: 'Für Ihre Anfrage wurde nichts gefunden',
        },
        projects_list_title: {
            eng: 'Project Overview',
            rus: 'Обзор проектов',
            ukr: 'Огляд проектів',
            deu: 'Projektübersicht',
        },
        projects_list_button_add: {
            eng: 'Add project',
            rus: 'Добавить проект',
            ukr: 'Додати проект',
            deu: 'Projekt hinzufügen',
        },
        projects_list_button_all: {
            eng: 'All projects',
            rus: 'Все проекты',
            ukr: 'Всі проекти',
            deu: 'Alle Projekte',
        },
        projects_list_button_my: {
            eng: 'My projects',
            rus: 'Мои проекты',
            ukr: 'Мої проекти',
            deu: 'Meine Projekte',
        },
        filter_country: {
            eng: 'Country',
            rus: 'Страна',
            ukr: 'Країна',
            deu: 'Land',
        },
        filter_name: {
            eng: 'Project name',
            rus: 'Название проекта',
            ukr: 'Назва проекту',
            deu: 'Projektname',
        },
        filter_input_name: {
            eng: 'Enter project name',
            rus: 'Введите назване проекта',
            ukr: 'Введіть назву проекту',
            deu: 'Projektname eingeben',
        },
        filter_search: {
            eng: 'Search',
            rus: 'Поиск',
            ukr: 'Пошук',
            deu: 'Suche',
        },
        community_list_item_more: {
            eng: 'More',
            rus: 'Подробнее',
            ukr: 'Докладніше',
            deu: 'Mehr',
        },
        community_list_title: {
            eng: 'Community',
            rus: 'Сообщество',
            ukr: 'Спільнота',
            deu: 'Gemeinschaft',
        },
        community_list_filter_input_name: {
            eng: 'Name',
            rus: 'Имя',
            ukr: "Ім'я",
            deu: 'Name',
        },
        community_list_filter_country: {
            eng: 'Country of business',
            rus: 'Страна ведения бизнеса',
            ukr: 'Країна ведення бізнесу',
            deu: 'Geschäftsland',
        },
        community_list_filter_types: {
            eng: 'Types',
            rus: 'Роль',
            ukr: 'Роль',
            deu: 'Typen',
        },
        community_list_filter_search: {
            eng: 'Search',
            rus: 'Поиск',
            ukr: 'Пошук',
            deu: 'Suche',
        },
        community_list_not_found: {
            eng: 'Nothing was found for your request',
            rus: 'По вашему запросу ничего не найдено',
            ukr: 'За вашим запитом нічого не знайдено',
            deu: 'Für Ihre Anfrage wurde nichts gefunden',
        },
        community_list_filter_toggle_community: {
            eng: 'Communities',
            rus: 'Сообщество',
            ukr: 'Спільнота',
            deu: 'Gemeinschaft',
        },
        community_list_filter_toggle_company: {
            eng: 'Companies',
            rus: 'Компании',
            ukr: 'Компанії',
            deu: 'Unternehmen',
        },
        community_item_tab_main: {
            eng: 'Main',
            rus: 'Главная',
            ukr: 'Головна',
            deu: 'Haupt',
        },
        community_item_tab_education: {
            eng: 'Education & license',
            rus: 'Образование и лицензия',
            ukr: 'Освіта та ліцензія',
            deu: 'Bildung & Lizenz',
        },
        community_item_tab_properties: {
            eng: 'Properties',
            rus: 'Объекты недвижимости',
            ukr: "Об'єкти нерухомості",
            deu: 'Eigenschaften',
        },
        community_item_tab_projects: {
            eng: 'Projects',
            rus: 'Проекты',
            ukr: 'Проекти',
            deu: 'Projekte',
        },
        community_item_tab_requests: {
            eng: 'Requests',
            rus: 'Заявки',
            ukr: 'Заявки',
            deu: 'Anwendungen',
        },
        community_item_main_last_activity: {
            eng: 'Last activity',
            rus: 'Последняя активность',
            ukr: 'Остання активність',
            deu: 'Letzte Aktivität',
        },
        community_item_main_country: {
            eng: 'Country',
            rus: 'Страна',
            ukr: 'Країна',
            deu: 'Land',
        },
        community_item_main_send_message: {
            eng: 'Send message',
            rus: 'Отправить сообщение',
            ukr: 'Надіслати повідомлення',
            deu: 'Nachricht senden',
        },
        news_list_title: {
            eng: 'News',
            rus: 'Новости',
            ukr: 'Новини',
            deu: 'Neuigkeiten',
        },
        news_list_social_title: {
            eng: 'Read us in social:',
            rus: 'Читайте нас в социальных сетях:',
            ukr: 'Читайте нас у соціальних мережах:',
            deu: 'Lesen Sie uns in sozialen Netzwerken:',
        },
        news_list_tab_news: {
            eng: 'News',
            rus: 'Новости',
            ukr: 'Новини',
            deu: 'Neuigkeiten',
        },
        news_news_filter_title: {
            eng: 'News search',
            rus: 'Поиск новостей',
            ukr: 'Пошук новин',
            deu: 'Nachrichtensuche',
        },
        news_news_filter_find: {
            eng: 'Find',
            rus: 'Найти',
            ukr: 'Знайти',
            deu: 'Finden',
        },
        news_news_not_found: {
            eng: 'Nothing was found for your request',
            rus: 'По вашему запросу ничего не найдено',
            ukr: 'За вашим запитом нічого не знайдено',
            deu: 'Für Ihre Anfrage wurde nichts gefunden',
        },
        news_news_last_title: {
            eng: 'Last news',
            rus: 'Последние новости',
            ukr: 'Останні новини',
            deu: 'Letzte Nachricht',
        },
        news_news_all_title: {
            eng: 'All news',
            rus: 'Все новости',
            ukr: 'Всі новини',
            deu: 'Alle Nachrichten',
        },
        modal_copy_success: {
            eng: 'Copied successfully',
            rus: 'Скопировано успешно',
            ukr: 'Скопійовано успішно',
            deu: 'Erfolgreich kopiert',
        },
        modal_title_wallet: {
            eng: 'Your wallet address',
            rus: 'Адрес вашего кошелька',
            ukr: 'Адреса вашого гаманця',
            deu: 'Ihre Brieftaschenadresse',
        },
        modal_title_contract: {
            eng: 'Contract address',
            rus: 'Адрес контракта',
            ukr: 'Адреса контракту',
            deu: 'Vertragsadresse',
        },
        my_address_in_contract_translation: {
            eng: 'My address in the contract',
            rus: 'Мой адрес в контракте',
            ukr: 'Моя адреса в контракті',
            deu: 'Meine Adresse im Vertrag',
        },
        total_m2c_translation: {
            eng: 'Total M2C',
            rus: 'Всего M2C',
            ukr: 'Загальний M2C',
            deu: 'Gesamt M2C',
        },
        modal_title_deposit_address: {
            eng: 'Deposit address',
            rus: 'Адрес депозита',
            ukr: 'Адреса депозиту',
            deu: 'Die Einzahlungsadresse',
        },
        modal_title_deposit_memo: {
            eng: 'Memo',
            rus: 'Memo',
            ukr: 'Memo',
            deu: 'Memo',
        },
        projects_add_common_landing: {
            eng: 'Desired subdomain',
            rus: 'Желаемый субдомен',
            ukr: 'Бажаний субдомен',
            deu: 'Gewünschte Subdomain',
        },
        projects_add_common_landing_placeholder: {
            eng: 'Desired subdomain',
            rus: 'Желаемый субдомен',
            ukr: 'Бажаний субдомен',
            deu: 'Gewünschte Subdomain',
        },
        projects_add_common_landing_helper: {
            eng: 'For each new investment project GREM presents a personal landing page',
            rus: 'Для каждого нового инвестиционного проекта GREM предостовляет персональную посадочную страницу',
            ukr: 'Для кожного нового інвестиційного проекту GREM надає персональну посадочну сторінку',
            deu: 'Für jedes neue Investitionsprojekt präsentiert GREM eine persönliche Landingpage',
        },
        projects_add_max_photo: {
            eng: 'You have uploaded the maximum number of photos!',
            rus: 'Вы загрузили максимальное количество изображений!',
            ukr: 'Ви завантажили максимальну кількість зображень!',
            deu: 'Sie haben die maximale Anzahl von Fotos hochgeladen!',
        },
        Key_not_founded: {
            eng: 'Error: Text not found',
            rus: 'Ошибка: Текст не найден',
            ukr: 'Помилка: Текст не знайдено',
            deu: 'Fehler: Text nicht gefunden',
        },
        projects_add_common_title: {
            eng: 'Common properties',
            rus: 'Общиее описание',
            ukr: 'Загальний опис',
            deu: 'Allgemeine Eigenschaften',
        },
        projects_add_common_stage: {
            eng: 'Construction stage:',
            rus: 'Стадия строительства:',
            ukr: 'Етап будівництва:',
            deu: 'Bauabschnitt:',
        },
        projects_add_common_stage_placeholder: {
            eng: 'Enter % construction phase',
            rus: 'Введите % стадии строительства',
            ukr: 'Введіть % етапу будівництва',
            deu: 'Prozentuale Bauphasen',
        },
        projects_add_common_name: {
            eng: 'Name of project',
            rus: 'Название проекта',
            ukr: 'Назва проекту',
            deu: 'Projektname',
        },
        projects_add_common_name_placeholder: {
            eng: 'Enter an official name of the project',
            rus: 'Введите официальное название проекта',
            ukr: 'Введіть офіційну назву проекту',
            deu: 'Geben Sie einen offiziellen Namen des Projekts ein',
        },

        projects_add_common_company: {
            eng: 'Construction company',
            rus: 'Строительная компания',
            ukr: 'Будівельна компанія',
            deu: 'Bauunternehmen',
        },
        projects_add_common_company_placeholder: {
            eng: 'Construction company',
            rus: 'Строительная компания',
            ukr: 'Будівельна компанія',
            deu: 'Bauunternehmen',
        },
        projects_add_common_author: {
            eng: 'Author of the project',
            rus: 'Автор проекта',
            ukr: 'Автор проекту',
            deu: 'Autor des Projekts',
        },
        projects_add_common_author_placeholder: {
            eng: 'Enter the author(s) of the project',
            rus: 'Введите автора/ов проекта',
            ukr: 'Введіть автора/ів проекту',
            deu: 'Geben Sie den/die Autor(en) des Projekts ein',
        },
        projects_add_common_architects: {
            eng: 'The main architects of the project',
            rus: 'Основные архитекторы проекта',
            ukr: 'Основні архітектори проекту',
            deu: 'Die Hauptarchitekten des Projekts',
        },
        projects_add_common_architects_placeholder: {
            eng: 'Write the name(s) of the main architect(s) of the project',
            rus: 'Напишите имя (имена) основных архитекторов проекта',
            ukr: "Напишіть ім'я (імена) головних архітекторів проекту",
            deu: 'Schreiben Sie den/die Namen des/der Hauptarchitekten des Projekts auf',
        },
        projects_add_common_concept: {
            eng: 'Concept',
            rus: 'Концепция',
            ukr: 'Концепція',
            deu: 'Konzept',
        },
        projects_add_common_advantages: {
            eng: 'Advantages of the project',
            rus: 'Преимущества проекта',
            ukr: 'Переваги проекту',
            deu: 'Vorteile des Projekts',
        },
        projects_add_common_efficiency: {
            eng: 'Social efficiency',
            rus: 'Социальная эффективность',
            ukr: 'Соціальна ефективність',
            deu: 'Soziale Effizienz',
        },
        projects_add_common_country: {
            eng: 'Country',
            rus: 'Страна',
            ukr: 'Країна',
            deu: 'Land',
        },
        projects_add_common_map_placeholder: {
            eng: 'Auto-select country from map',
            rus: 'Автоматический выбор страны с карты',
            ukr: 'Автоматичний вибір країни з карти',
            deu: 'Automatische Auswahl des Landes aus der Karte',
        },
        projects_add_common_map_street_placeholder: {
            eng: 'Auto-select from map',
            rus: 'Автоматический выбор с карты',
            ukr: 'Автоматичний вибір з карти',
            deu: 'Automatische Auswahl aus der Karte',
        },
        projects_add_common_city: {
            eng: 'City',
            rus: 'Город',
            ukr: 'Місто',
            deu: 'Stadt',
        },
        projects_add_common_district_placeholder: {
            eng: 'District',
            rus: 'Район',
            ukr: 'Район',
            deu: 'Stadtteil',
        },
        projects_add_common_district: {
            eng: 'District',
            rus: 'Район',
            ukr: 'Район',
            deu: 'Stadtteil',
        },
        projects_add_common_street: {
            eng: 'Street',
            rus: 'Улица',
            ukr: 'Вулиця',
            deu: 'Straße',
        },
        projects_add_common_house: {
            eng: 'House number',
            rus: 'Номер дома',
            ukr: 'Номер будинку',
            deu: 'Hausnummer',
        },
        projects_add_common_house_placeholder: {
            eng: 'House number',
            rus: 'Номер дома',
            ukr: 'Номер будинку',
            deu: 'Hausnummer',
        },
        projects_add_technical_type: {
            eng: 'Type of property',
            rus: 'Тип недвижимости',
            ukr: 'Тип нерухомості',
            deu: 'Art der Immobilie',
        },
        projects_add_technical_storeys: {
            eng: 'Number of storeys',
            rus: 'Количество этажей',
            ukr: 'Кількість поверхів',
            deu: 'Anzahl der Stockwerke',
        },
        projects_add_technical_storeys_placeholder: {
            eng: 'Total number of storeys in the building',
            rus: 'Общее количество этажей в здании',
            ukr: 'Загальна кількість поверхів у будівлі',
            deu: 'Gesamtzahl der Etagen im Gebäude',
        },
        projects_add_technical_term: {
            eng: 'Construction completion term',
            rus: 'Срок завершения строительства',
            ukr: 'Термін завершення будівництва',
            deu: 'Bauabschlussfrist',
        },
        projects_add_technical_term_help: {
            eng: 'Specify the completion date of construction',
            rus: 'Укажите срок завершения строительства',
            ukr: 'Вкажіть термін завершення будівництва',
            deu: 'Geben Sie das Fertigstellungsdatum des Baus an',
        },
        projects_add_technical_area_plot: {
            eng: 'Land plot area (sq.m.)',
            rus: 'Площадь земельного участка (кв. м)',
            ukr: 'Площа земельної ділянки (кв. м)',
            deu: 'Grundstücksfläche (qm)',
        },
        property_technical_area_plot: {
            eng: 'Land plot area',
            rus: 'Площадь земельного участка',
            ukr: 'Площа земельної ділянки',
            deu: 'Grundstücksfläche',
        },
        projects_add_technical_area_plot_placeholder: {
            eng: 'The total number of square meters of the land plot that will be at your disposal',
            rus: 'Общее количество квадратных метров земельного участка, которым вы сможете распорядиться',
            ukr: 'Загальна кількість квадратних метрів земельної ділянки, якою ви матимете в своєму розпорядженні',
            deu: 'Die Gesamtzahl der Quadratmeter des Grundstücks, die Ihnen zur Verfügung stehen werden',
        },
        projects_add_technical_area_effective: {
            eng: 'Effective area (sq.m.)',
            rus: 'Эффективная площадь (кв. м)',
            ukr: 'Ефективна площа (кв. м)',
            deu: 'Effektive Fläche (qm)',
        },
        projects_add_technical_area_effective_placeholder: {
            eng: 'This is area from which you can earn income. Landing of staircases, WCs, etc. are not counted as an effective area',
            rus: 'Это площадь, с которой вы можете получать доход. Площади подъездов, туалетов и т. д. не считаются эффективной площадью',
            ukr: "Це площа, з якої ви можете отримувати дохід. Під'їзди, туалети тощо не враховуються як ефективна площа",
            deu: 'Dies ist der Bereich, aus dem Sie Einkommen erzielen können. Treppenabsätze, Toiletten usw. zählen nicht als effektiver Bereich.',
        },
        projects_add_technical_area_total: {
            eng: 'Total construction area (sq.m.)',
            rus: 'Общая площадь строительства (кв. м)',
            ukr: 'Загальна площа будівництва (кв. м)',
            deu: 'Gesamte Baufläche (qm)',
        },
        projects_add_technical_area_building: {
            eng: 'Building area (sq.m.)',
            rus: 'Площадь здания (кв. м)',
            ukr: 'Площа будівлі (кв. м)',
            deu: 'Gebäudefläche (qm)',
        },
        projects_add_technical_area_office: {
            eng: 'Office area (sq.m.)',
            rus: 'Площадь офиса (кв. м)',
            ukr: 'Площа офісу (кв. м)',
            deu: 'Bürofläche (qm)',
        },
        projects_add_technical_area_comercial: {
            eng: 'Commercial area (sq.m.)',
            rus: 'Торговая площадь (кв. м)',
            ukr: 'Торгова площа (кв. м)',
            deu: 'Gewerbefläche (qm)',
        },
        projects_add_technical_area_hotel: {
            eng: 'Hotel area (sq.m.)',
            rus: 'Площадь отеля (кв. м)',
            ukr: 'Площа готелю (кв. м)',
            deu: 'Hotelfläche (qm)',
        },
        projects_add_technical_area_parking: {
            eng: 'Parking area (sq.m.)',
            rus: 'Площадь парковки (кв. м)',
            ukr: 'Площа парковки (кв. м)',
            deu: 'Parkfläche (qm)',
        },
        projects_add_technical_number_aparts: {
            eng: 'Number of apartments',
            rus: 'Количество квартир',
            ukr: 'Кількість квартир',
            deu: 'Anzahl der Wohnungen',
        },
        projects_add_technical_number_rooms: {
            eng: 'Number of rooms',
            rus: 'Количество комнат',
            ukr: 'Кількість кімнат',
            deu: 'Anzahl der Zimmer',
        },
        conditionOfApartment_translate: {
            eng: 'Condition of an Apartment',
            rus: 'Состояние Квартиры',
            ukr: 'Стан Квартири',
            deu: 'Zustand der Wohnung',
        },

        projects_add_technical_space: {
            eng: 'Living space (sq.m.)',
            rus: 'Жилая площадь (кв. м)',
            ukr: 'Житлова площа (кв. м)',
            deu: 'Wohnfläche (qm)',
        },
        projects_add_technical_bedrooms_one: {
            eng: 'One bedroom',
            rus: 'Одна спальня',
            ukr: 'Одна спальня',
            deu: 'Ein Schlafzimmer',
        },
        projects_add_technical_bedrooms_two: {
            eng: 'Two bedrooms',
            rus: 'Две спальни',
            ukr: 'Дві спальні',
            deu: 'Zwei Schlafzimmer',
        },
        projects_add_technical_bedrooms_three: {
            eng: 'Three bedrooms',
            rus: 'Три спальни',
            ukr: 'Три спальні',
            deu: 'Drei Schlafzimmer',
        },
        projects_add_technical_bedrooms_four: {
            eng: 'Four bedrooms',
            rus: 'Четыре спальни',
            ukr: 'Чотири спальні',
            deu: 'Vier Schlafzimmer',
        },
        projects_add_technical_bedrooms_five: {
            eng: 'Five bedrooms',
            rus: 'Пять спален',
            ukr: "П'ять спалень",
            deu: 'Fünf Schlafzimmer',
        },
        projects_add_technical_penthouses: {
            eng: 'Penthouses',
            rus: 'Пентхаусы',
            ukr: 'Пентхауси',
            deu: 'Penthäuser',
        },
        projects_add_technical_villas: {
            eng: 'Villas',
            rus: 'Виллы',
            ukr: 'Вілли',
            deu: 'Villen',
        },
        projects_add_technical_parking_onground: {
            eng: 'Onground parking (sq.m.)',
            rus: 'Наземная парковка (кв. м)',
            ukr: 'Наземна парковка (кв. м)',
            deu: 'Parken überirdisch (qm)',
        },
        projects_add_technical_parking_underground: {
            eng: 'Underground parking (sq.m.)',
            rus: 'Подземная парковка (кв. м)',
            ukr: 'Підземна парковка (кв. м)',
            deu: 'Parken unterirdisch (qm)',
        },
        projects_add_technical_parking_total: {
            eng: 'Number of parking spaces',
            rus: 'Количество парковочных мест',
            ukr: 'Кількість парковочних місць',
            deu: 'Anzahl der Parkplätze',
        },
        projects_add_financial_sources_own: {
            eng: 'Financing sources (own funds)',
            rus: 'Источники финансирования (собственные средства)',
            ukr: 'Джерела фінансування (власні кошти)',
            deu: 'Finanzierungsquellen (eigene Mittel)',
        },
        projects_add_financial_sources_bank: {
            eng: 'Financing sources (bank loans)',
            rus: 'Источники финансирования (банковские кредиты)',
            ukr: 'Джерела фінансування (банківські кредити)',
            deu: 'Finanzierungsquellen (Bankkredite)',
        },
        projects_add_financial_sources_platform: {
            eng: 'Financing sources (investments attracted on the platform)',
            rus: 'Источники финансирования (привлеченные инвестиции на платформе)',
            ukr: 'Джерела фінансування (залучені інвестиції на платформі)',
            deu: 'Finanzierungsquellen (auf der Plattform gewonnene Investitionen)',
        },
        projects_add_financial_sources_preliminary: {
            eng: 'Financing sources (preliminary payments)',
            rus: 'Источники финансирования (предварительные платежи)',
            ukr: 'Джерела фінансування (попередні платежі)',
            deu: 'Finanzierungsquellen (vorläufige Zahlungen)',
        },
        projects_add_financial_sources_total: {
            eng: 'Total investment',
            rus: 'Общий объем инвестиций',
            ukr: 'Загальний обсяг інвестицій',
            deu: 'Gesamtinvestitionen',
        },
        projects_add_financial_sources_currency: {
            eng: 'Currency',
            rus: 'Валюта',
            ukr: 'Валюта',
            deu: 'Währung',
        },
        projects_add_financial_settlement: {
            eng: 'Settlement account',
            rus: 'Расчетный счет',
            ukr: 'Розрахунковий рахунок',
            deu: 'Abwicklungskonto',
        },
        projects_add_financial_bank: {
            eng: 'Bank representative',
            rus: 'Представитель банка',
            ukr: 'Представник банку',
            deu: 'Bankvertreter',
        },
        projects_add_square_warning: {
            eng: 'To add a token, fill in the field: Total construction area.',
            rus: 'Для добавления токена заполните поле: Общая площадь строительства.',
            ukr: 'Для додавання токена заповніть поле: Загальна площа будівництва.',
            deu: "Um einen Token hinzuzufügen, füllen Sie das Feld 'Gesamtfläche des Baus' aus.",
        },
        projects_add_financial_votes: {
            eng: 'Minimum number of votes of the expert commission',
            rus: 'Минимальное количество голосов экспертной комиссии',
            ukr: 'Мінімальна кількість голосів експертної комісії',
            deu: 'Mindestanzahl der Stimmen der Expertenkommission',
        },
        projects_add_financial_irr: {
            eng: 'Internal Rate of Return (IRR), %',
            rus: 'Внутренняя норма доходности (IRR), %',
            ukr: 'Внутрішня норма доходності (IRR), %',
            deu: 'Interne Zinsfuß (IRR), %',
        },
        projects_add_financial_mirr: {
            eng: 'Modified Internal Rate of Return (MIRR), %',
            rus: 'Измененная внутренняя норма прибыли (MIRR), %',
            ukr: 'Змінена внутрішня норма прибутку (MIRR), %',
            deu: 'Modifizierte interne Rendite (MIRR), %',
        },
        projects_add_financial_payback: {
            eng: 'Payback period, year(s)',
            rus: 'Период окупаемости, год(ы)',
            ukr: 'Період окупності, рік(и)',
            deu: 'Amortisationszeitraum, Jahr(e)',
        },
        projects_add_financial_discountedPayback: {
            eng: 'Discounted payback period, year(s)',
            rus: 'Период окупаемости с учетом дисконтирования, год(ы)',
            ukr: 'Період окупності з урахуванням дисконту, рік(и)',
            deu: 'Abgezinster Amortisationszeitraum, Jahr(e)',
        },
        projects_add_financial_npv: {
            eng: 'Net present value (NPV), EURO',
            rus: 'Чистая приведенная стоимость (ЧПС), EURO',
            ukr: 'Чиста приведена вартість (ЧПВ), EURO',
            deu: 'Nettogegenwartswert (NGW), EURO',
        },
        projects_add_financial_arr: {
            eng: 'Accounting rate of return (ARR), %',
            rus: 'Бухгалтерская норма доходности (ARR), %',
            ukr: 'Бухгалтерська норма доходності (ARR), %',
            deu: 'Buchhalterische Rendite (ARR), %',
        },
        projects_add_financial_profitability: {
            eng: 'Profitability index',
            rus: 'Индекс доходности',
            ukr: 'Індекс доходності',
            deu: 'Rentabilitätsindex',
        },
        projects_add_financial_profit: {
            eng: 'Project total profit, EURO',
            rus: 'Общая прибыль проекта, EURO',
            ukr: 'Загальний прибуток проекту, EURO',
            deu: 'Gesamtgewinn des Projekts, EURO',
        },
        projects_add_financial_loan_title: {
            eng: 'Loan token - This type of token is based on an electronic contract fixing the monetary contribution of an investor (developer token holder) to a specific development project with the obligation to return this deposit and interest on it after a specified period in the future. The agreement is concluded directly between the developer (acts as a borrower) and the investor - the platform user (acts as a lender)',
            rus: 'Токен займа - Этот тип токена основан на электронном контракте, который закрепляет денежный вклад инвестора (владельца токена разработчика) в конкретный проект развития с обязательством вернуть этот депозит и проценты по нему после определенного периода в будущем. Соглашение заключается непосредственно между разработчиком (действующим в качестве заемщика) и инвестором - пользователем платформы (действующим в качестве кредитора)',
            ukr: "Позичковий токен - Цей тип токена ґрунтується на електронному контракті, що закріплює грошовий внесок інвестора (власника токена розробника) в конкретний проект розвитку з обов'язком повернути цей депозит та відсотки на нього після визначеного періоду у майбутньому. Угода укладається безпосередньо між розробником (який виступає у ролі позичальника) та інвестором - користувачем платформи (який виступає у ролі кредитора)",
            deu: 'Kredit-Token - Diese Art von Token basiert auf einem elektronischen Vertrag, der den Geldbeitrag eines Investors (Entwickler-Token-Inhabers) zu einem bestimmten Entwicklungsprojekt festlegt. Nach Ablauf eines festgelegten Zeitraums in der Zukunft besteht die Verpflichtung, diese Einzahlung und die darauf anfallenden Zinsen zurückzugeben. Die Vereinbarung wird direkt zwischen dem Entwickler (als Kreditnehmer) und dem Investor - dem Plattformbenutzer (als Kreditgeber) - abgeschlossen',
        },
        projects_add_financial_loan_total: {
            eng: 'Total number of tokens',
            rus: 'Общее количество токенов',
            ukr: 'Загальна кількість токенів',
            deu: 'Gesamtzahl der Token',
        },
        projects_add_financial_loan_rate: {
            eng: 'Rate of return, %',
            rus: 'Ставка доходности, %',
            ukr: 'Ставка доходності, %',
            deu: 'Rendite, %',
        },
        projects_add_financial_loan_amount: {
            eng: 'Minimum amount of investments for start, EURO',
            rus: 'Минимальная сумма инвестиций для старта, EURO',
            ukr: 'Мінімальна сума інвестицій для старту, EURO',
            deu: 'Mindestinvestitionssumme zum Start, EURO',
        },
        projects_add_financial_loan_start: {
            eng: 'Start of token sale',
            rus: 'Начало продажи токенов',
            ukr: 'Початок продажу токенів',
            deu: 'Start des Tokenverkaufs',
        },
        projects_add_financial_loan_end: {
            eng: 'End of token sale',
            rus: 'Окончание продажи токенов',
            ukr: 'Закінчення продажу токенів',
            deu: 'Ende des Tokenverkaufs',
        },
        projects_add_financial_loan_period: {
            eng: 'Payment period',
            rus: 'Период платежей',
            ukr: 'Період платежів',
            deu: 'Zahlungsperiode',
        },
        projects_add_financial_loan_quantity: {
            eng: 'Payments quantity',
            rus: 'Количество платежей',
            ukr: 'Кількість платежів',
            deu: 'Anzahl der Zahlungen',
        },
        projects_add_financial_square_title: {
            eng: "Square meter token - which is based on an electronic contract that allows you to fix the investor's contribution to the project from 1 square meter (centimeter). They are cumulative and further can be exchanged for newly built real estate",
            rus: 'Токен за квадратный метр - основан на электронном контракте, который позволяет зафиксировать вклад инвестора в проект от 1 квадратного метра (сантиметра). Они накапливаются и в дальнейшем могут быть обменены на недвижимость, только что построенную',
            ukr: 'Токен за квадратний метр - що базується на електронному контракті, що дозволяє зафіксувати внесок інвестора до проекту від 1 квадратного метра (сантиметра). Вони накопичуються і подальше можуть бути обмінені на новозбудовану нерухомість',
            deu: 'Quadratmeter-Token - basierend auf einem elektronischen Vertrag, der es ermöglicht, den Beitrag des Investors zum Projekt ab 1 Quadratmeter (Zentimeter) festzulegen. Sie werden kumuliert und können später gegen neu gebaute Immobilien eingetauscht werden',
        },
        projects_add_financial_square_total: {
            eng: 'Number of square meters for investment',
            rus: 'Количество квадратных метров для инвестиций',
            ukr: 'Кількість квадратних метрів для інвестицій',
            deu: 'Anzahl der Quadratmeter für Investitionen',
        },
        projects_add_financial_square_price: {
            eng: 'Base price per sq.m., EURO',
            rus: 'Базовая цена за кв.м., EURO',
            ukr: 'Базова ціна за кв.м., EURO',
            deu: 'Grundpreis pro qm, EURO',
        },
        projects_add_financial_square_start: {
            eng: 'Start of token sale',
            rus: 'Начало продажи токенов',
            ukr: 'Початок продажу токенів',
            deu: 'Start des Tokenverkaufs',
        },
        projects_add_financial_square_end: {
            eng: 'End of token sale',
            rus: 'Окончание продажи токенов',
            ukr: 'Закінчення продажу токенів',
            deu: 'Ende des Tokenverkaufs',
        },
        projects_add_financial_unit_title: {
            eng: 'Unit token - based on an electronic contract according to which the buyer (holder of the unit token) has the primary right to obtain ownership rights for a certain number of square meters (finished unit) in a specific project',
            rus: 'Токен единицы - основан на электронном контракте, согласно которому покупатель (владелец токена единицы) имеет первоочередное право на получение прав собственности на определенное количество квадратных метров (готовой единицы) в конкретном проекте',
            ukr: 'Токен одиниці - заснований на електронному контракті, згідно з яким покупець (власник токена одиниці) має пріоритетне право на отримання прав власності на певну кількість квадратних метрів (готової одиниці) в конкретному проекті',
            deu: 'Einheitstoken - basierend auf einem elektronischen Vertrag, gemäß dem der Käufer (Inhaber des Einheitstokens) das vorrangige Recht hat, Eigentumsrechte für eine bestimmte Anzahl von Quadratmetern (fertige Einheit) in einem bestimmten Projekt zu erlangen',
        },
        projects_add_financial_unit_start: {
            eng: 'Start of token sale',
            rus: 'Начало продажи токенов',
            ukr: 'Початок продажу токенів',
            deu: 'Start des Tokenverkaufs',
        },
        projects_add_financial_unit_end: {
            eng: 'End of token sale',
            rus: 'Окончание продажи токенов',
            ukr: 'Закінчення продажу токенів',
            deu: 'Ende des Tokenverkaufs',
        },
        projects_add_financial_unit_aparts_total: {
            eng: 'Number of the property',
            rus: 'Количество объектов недвижимости',
            ukr: "Кількість об'єктів нерухомості",
            deu: 'Anzahl der Immobilien',
        },
        projects_add_financial_unit_aparts_total_placeholder: {
            eng: 'Enter the number of real estate properties',
            rus: 'Введите количество объектов недвижимости',
            ukr: "Введіть кількість об'єктів нерухомості",
            deu: 'Geben Sie die Anzahl der Immobilien ein',
        },
        projects_add_financial_unit_aparts_floor: {
            eng: 'Floor',
            rus: 'Этаж',
            ukr: 'Поверх',
            deu: 'Stockwerk',
        },
        projects_add_financial_unit_aparts_price: {
            eng: 'Initial price per square meter',
            rus: 'Начальная цена за квадратный метр',
            ukr: 'Початкова ціна за квадратний метр',
            deu: 'Anfangspreis pro Quadratmeter',
        },
        projects_add_financial_unit_aparts_currency: {
            eng: 'Currency',
            rus: 'Валюта',
            ukr: 'Валюта',
            deu: 'Währung',
        },
        projects_add_financial_unit_aparts_type: {
            eng: 'Type of property',
            rus: 'Тип недвижимости',
            ukr: 'Тип нерухомості',
            deu: 'Art der Immobilie',
        },
        projects_add_financial_unit_aparts_type_description: {
            eng: 'Description',
            rus: 'Описание',
            ukr: 'Опис',
            deu: 'Beschreibung',
        },
        projects_add_financial_unit_aparts_type_total: {
            eng: 'Total area (sq.m.)',
            rus: 'Общая площадь (кв.м.)',
            ukr: 'Загальна площа (кв.м.)',
            deu: 'Gesamtfläche (qm)',
        },
        projects_add_financial_unit_aparts_type_spaceLiving: {
            eng: 'Living space (sq.m.)',
            rus: 'Жилая площадь (кв.м.)',
            ukr: 'Житлова площа (кв.м.)',
            deu: 'Wohnfläche (qm)',
        },
        projects_add_financial_unit_aparts_type_kitchen: {
            eng: 'Kitchen size:',
            rus: 'Площадь кухни:',
            ukr: 'Площа кухні:',
            deu: 'Küchengröße:',
        },
        projects_add_financial_unit_aparts_type_ceiling: {
            eng: 'Ceiling height (m)',
            rus: 'Высота потолка (м)',
            ukr: 'Висота стелі (м)',
            deu: 'Deckenhöhe (m)',
        },
        projects_add_financial_unit_aparts_type_rooms: {
            eng: 'Number of rooms',
            rus: 'Количество комнат',
            ukr: 'Кількість кімнат',
            deu: 'Anzahl der Zimmer',
        },
        projects_add_financial_unit_aparts_type_roomsArea: {
            eng: 'Area of rooms (sq.m.)',
            rus: 'Площадь комнат (кв.м.)',
            ukr: 'Площа кімнат (кв.м.)',
            deu: 'Fläche der Zimmer (qm)',
        },
        projects_add_financial_unit_aparts_type_balcony: {
            eng: 'Balcony',
            rus: 'Балкон',
            ukr: 'Балкон',
            deu: 'Balkon',
        },
        projects_add_financial_unit_aparts_type_numberWindows: {
            eng: 'Number of windows',
            rus: 'Количество окон',
            ukr: 'Кількість вікон',
            deu: 'Anzahl der Fenster',
        },
        projects_add_financial_unit_aparts_type_windowView: {
            eng: 'Window view',
            rus: 'Вид из окна',
            ukr: 'Вид з вікна',
            deu: 'Fensteransicht',
        },
        projects_add_financial_unit_aparts_type_utilitySystem: {
            eng: 'Utility system',
            rus: 'Коммунальные системы',
            ukr: 'Комунальні системи',
            deu: 'Versorgungssystem',
        },
        projects_add_show: {
            eng: 'Show',
            rus: 'Показать',
            ukr: 'Показати',
            deu: 'Anzeigen',
        },
        projects_add_hide: {
            eng: 'Hide',
            rus: 'Скрыть',
            ukr: 'Приховати',
            deu: 'Ausblenden',
        },
        projects_add_unit_add: {
            eng: 'Add Unit*',
            rus: 'Добавить Unit*',
            ukr: 'Додати Unit*',
            deu: 'Hinzufügen Unit*',
        },
        projects_add_unit_save: {
            eng: 'Save',
            rus: 'Сохранить',
            ukr: 'Зберегти',
            deu: 'Speichern',
        },
        save: {
            eng: 'Save',
            rus: 'Сохранить',
            ukr: 'Зберегти',
            deu: 'Speichern',
        },
        projects_add_unit_close: {
            eng: 'Close',
            rus: 'Закрыть',
            ukr: 'Закрити',
            deu: 'Schließen',
        },
        projects_add_technical_area_total_placeholder: {
            eng: 'Total number of square meters of the whole building',
            rus: 'Общая площадь всего здания',
            ukr: 'Загальна площа всього будинку',
            deu: 'Gesamte Grundfläche des gesamten Gebäudes',
        },
        projects_add_technical_area_building_placeholder: {
            eng: 'The number of square meters on a land plot which will be occupied by the building',
            rus: 'Количество квадратных метров на земельном участке, которые будет занимать здание',
            ukr: 'Кількість квадратних метрів на земельній ділянці, які буде займати будівля',
            deu: 'Die Anzahl der Quadratmeter auf einem Grundstück, die vom Gebäude eingenommen werden',
        },
        projects_add_technical_number_aparts_placeholder: {
            eng: 'Enter total number of apartments in the building',
            rus: 'Введите общее количество квартир в здании',
            ukr: 'Введіть загальну кількість квартир у будинку',
            deu: 'Geben Sie die Gesamtzahl der Wohnungen im Gebäude ein',
        },
        projects_add_technical_space_placeholder: {
            eng: 'Total living space of all apartments in the building',
            rus: 'Общая жилая площадь всех квартир в здании',
            ukr: 'Загальна житлова площа всіх квартир у будинку',
            deu: 'Gesamte Wohnfläche aller Wohnungen im Gebäude',
        },
        projects_add_technical_bedrooms_one_placeholder: {
            eng: 'The number of one bedroom apartments in the project',
            rus: 'Количество квартир с одной спальней в проекте',
            ukr: 'Кількість квартир з однією спальнею у проекті',
            deu: 'Anzahl der Einzimmerwohnungen im Projekt',
        },
        projects_add_technical_bedrooms_two_placeholder: {
            eng: 'The number of two-bedroom apartments in the project',
            rus: 'Количество двухспальных квартир в проекте',
            ukr: 'Кількість двоспальних квартир у проекті',
            deu: 'Anzahl der Zwei-Zimmer-Wohnungen im Projekt',
        },
        projects_add_technical_bedrooms_three_placeholder: {
            eng: 'The number of three-bedroom apartments in the project',
            rus: 'Количество трехспальных квартир в проекте',
            ukr: 'Кількість трьоспальних квартир у проекті',
            deu: 'Anzahl der Drei-Zimmer-Wohnungen im Projekt',
        },
        projects_add_technical_bedrooms_four_placeholder: {
            eng: 'The number of four-bedroom apartments in the project',
            rus: 'Количество четырехспальных квартир в проекте',
            ukr: 'Кількість чотирьохспальних квартир у проекті',
            deu: 'Anzahl der Vier-Zimmer-Wohnungen im Projekt',
        },
        projects_add_technical_bedrooms_five_placeholder: {
            eng: 'The number of five-bedroom apartments in the project',
            rus: 'Количество пятиспальных квартир в проекте',
            ukr: "Кількість п'ятиспальних квартир у проекті",
            deu: 'Anzahl der Fünf-Zimmer-Wohnungen im Projekt',
        },
        projects_add_technical_penthouses_placeholder: {
            eng: 'The number of penthouses in the project',
            rus: 'Количество пентхаусов в проекте',
            ukr: 'Кількість пентхаусів у проекті',
            deu: 'Anzahl der Penthouse-Wohnungen im Projekt',
        },
        projects_add_technical_villas_placeholder: {
            eng: 'The number of villas in the project',
            rus: 'Количество вилл в проекте',
            ukr: 'Кількість вілл у проекті',
            deu: 'Anzahl der Villen im Projekt',
        },
        projects_add_technical_area_office_placeholder: {
            eng: 'Total area, which will be occupied by offices',
            rus: 'Общая площадь, занимаемая офисами',
            ukr: 'Загальна площа, яку займатимуть офіси',
            deu: 'Gesamtfläche, die von Büros belegt wird',
        },
        projects_add_technical_area_comercial_placeholder: {
            eng: 'An area occupied by businesses which sell, rent, trade, store goods, or provide services',
            rus: 'Площадь, занимаемая бизнесами, которые продают, сдают в аренду, торгуют, хранят товары или предоставляют услуги',
            ukr: 'Площа, яку займають підприємства, які продають, здають в оренду, торгують, зберігають товари або надають послуги',
            deu: 'Fläche, die von Unternehmen belegt wird, die Waren verkaufen, vermieten, handeln, lagern oder Dienstleistungen erbringen',
        },
        projects_add_technical_area_hotel_placeholder: {
            eng: 'An area, occupied by hotel real estate',
            rus: 'Площадь, занимаемая гостиничной недвижимостью',
            ukr: 'Площа, яку займає готельна нерухомість',
            deu: 'Fläche, die von Hotelimmobilien belegt wird',
        },
        projects_add_technical_number_rooms_placeholder: {
            eng: 'Total number of hotel suites',
            rus: 'Общее количество номеров в отеле',
            ukr: 'Загальна кількість номерів у готелі',
            deu: 'Gesamtanzahl der Hotelzimmer',
        },
        projects_add_technical_area_parking_placeholder: {
            eng: 'An area, occupied by parking spaces',
            rus: 'Площадь, занимаемая парковочными местами',
            ukr: 'Площа, яку займають парковочні місця',
            deu: 'Fläche, die von Parkplätzen belegt wird',
        },
        projects_add_technical_parking_onground_placeholder: {
            eng: 'An area, occupied by onground parking spaces',
            rus: 'Площадь, занимаемая наземными парковочными местами',
            ukr: 'Площа, яку займають наземні парковочні місця',
            deu: 'Fläche, die von oberirdischen Parkplätzen belegt wird',
        },
        projects_add_technical_parking_underground_placeholder: {
            eng: 'An area, occupied by underground parking spaces',
            rus: 'Площадь, занимаемая подземными парковочными местами',
            ukr: 'Площа, яку займають підземні парковочні місця',
            deu: 'Fläche, die von Tiefgaragenstellplätzen belegt wird',
        },
        projects_add_technical_parking_total_placeholder: {
            eng: 'Enter total number of parking spaces',
            rus: 'Введите общее количество парковочных мест',
            ukr: 'Введіть загальну кількість парковочних місць',
            deu: 'Geben Sie die Gesamtanzahl der Parkplätze ein',
        },
        projects_add_financial_sources_own_placeholder: {
            eng: 'Enter the amount of your own funds, that you use to finance the project',
            rus: 'Введите сумму собственных средств, которые вы используете для финансирования проекта',
            ukr: 'Введіть суму власних коштів, які ви використовуєте для фінансування проекту',
            deu: 'Geben Sie den Betrag Ihrer eigenen Mittel ein, den Sie zur Finanzierung des Projekts verwenden',
        },
        projects_add_financial_sources_bank_placeholder: {
            eng: 'Enter the amount of bank loans, that are used to finance the project',
            rus: 'Введите сумму банковских кредитов, которые используются для финансирования проекта',
            ukr: 'Введіть суму банківських кредитів, які використовуються для фінансування проекту',
            deu: 'Geben Sie den Betrag der Bankkredite ein, die zur Finanzierung des Projekts verwendet werden',
        },
        projects_add_financial_sources_platform_placeholder: {
            eng: 'Enter the amount of investments, attracted on the platform for financing the project, min value: 10 000.',
            rus: 'Введите сумму привлеченных инвестиций на платформе для финансирования проекта, минимальное значение: 10 000.',
            ukr: 'Введіть суму привернутих інвестицій на платформі для фінансування проекту, мінімальне значення: 10 000.',
            deu: 'Geben Sie den Betrag der auf der Plattform für die Finanzierung des Projekts eingeworbenen Investitionen ein, Mindestwert: 10.000.',
        },
        projects_add_financial_sources_preliminary_placeholder: {
            eng: 'Enter the amount of preliminary payments, that were used to finance the project',
            rus: 'Введите сумму предварительных платежей, которые были использованы для финансирования проекта',
            ukr: 'Введіть суму попередніх платежів, які були використані для фінансування проекту',
            deu: 'Geben Sie den Betrag der Vorabzahlungen ein, die zur Finanzierung des Projekts verwendet wurden',
        },
        projects_add_financial_sources_total_placeholder: {
            eng: 'Total investment',
            rus: 'Общий объем инвестиций',
            ukr: 'Загальний обсяг інвестицій',
            deu: 'Gesamtinvestition',
        },
        projects_add_financial_sources_currency_placeholder: {
            eng: 'Select currency',
            rus: 'Выберите валюту',
            ukr: 'Виберіть валюту',
            deu: 'Währung auswählen',
        },
        projects_add_financial_settlement_placeholder: {
            eng: 'Settlement account',
            rus: 'Расчетный счет',
            ukr: 'Розрахунковий рахунок',
            deu: 'Abwicklungskonto',
        },
        projects_add_financial_bank_placeholder: {
            eng: 'Name, contacts of the bank representative',
            rus: 'Имя, контакты представителя банка',
            ukr: "Ім'я, контакти представника банку",
            deu: 'Name, Kontakte des Bankvertreters',
        },
        projects_add_financial_votes_placeholder: {
            eng: 'Here you determine a minimum number of expert votes for your project',
            rus: 'Здесь вы определяете минимальное количество экспертных голосов для вашего проекта',
            ukr: 'Тут ви визначаєте мінімальну кількість експертних голосів для вашого проекту',
            deu: 'Hier legen Sie eine Mindestanzahl von Expertenstimmen für Ihr Projekt fest',
        },
        projects_add_financial_irr_placeholder: {
            eng: 'To fill in this field see the business plan of the project',
            rus: 'Для заполнения этого поля ознакомьтесь с бизнес-планом проекта',
            ukr: 'Для заповнення цього поля ознайомтесь з бізнес-планом проекту',
            deu: 'Um dieses Feld auszufüllen, sehen Sie sich den Geschäftsplan des Projekts an',
        },
        projects_add_financial_mirr_placeholder: {
            eng: 'To fill in this field see the business plan of the project',
            rus: 'Для заполнения этого поля ознакомьтесь с бизнес-планом проекта',
            ukr: 'Для заповнення цього поля ознайомтесь з бізнес-планом проекту',
            deu: 'Um dieses Feld auszufüllen, sehen Sie sich den Geschäftsplan des Projekts an',
        },
        projects_add_financial_payback_placeholder: {
            eng: 'To fill in this field see the business plan of the project',
            rus: 'Для заполнения этого поля ознакомьтесь с бизнес-планом проекта',
            ukr: 'Для заповнення цього поля ознайомтесь з бізнес-планом проекту',
            deu: 'Um dieses Feld auszufüllen, sehen Sie sich den Geschäftsplan des Projekts an',
        },
        projects_add_financial_discountedPayback_placeholder: {
            eng: 'To fill in this field see the business plan of the project',
            rus: 'Для заполнения этого поля ознакомьтесь с бизнес-планом проекта',
            ukr: 'Для заповнення цього поля ознайомтесь з бізнес-планом проекту',
            deu: 'Um dieses Feld auszufüllen, sehen Sie sich den Geschäftsplan des Projekts an',
        },
        projects_add_financial_npv_placeholder: {
            eng: 'To fill in this field see the business plan of the project',
            rus: 'Для заполнения этого поля ознакомьтесь с бизнес-планом проекта',
            ukr: 'Для заповнення цього поля ознайомтесь з бізнес-планом проекту',
            deu: 'Um dieses Feld auszufüllen, sehen Sie sich den Geschäftsplan des Projekts an',
        },
        projects_add_financial_arr_placeholder: {
            eng: 'To fill in this field see the business plan of the project',
            rus: 'Для заполнения этого поля ознакомьтесь с бизнес-планом проекта',
            ukr: 'Для заповнення цього поля ознайомтесь з бізнес-планом проекту',
            deu: 'Um dieses Feld auszufüllen, sehen Sie sich den Geschäftsplan des Projekts an',
        },
        projects_add_financial_profitability_placeholder: {
            eng: 'To fill in this field see the business plan of the project',
            rus: 'Для заполнения этого поля ознакомьтесь с бизнес-планом проекта',
            ukr: 'Для заповнення цього поля ознайомтесь з бізнес-планом проекту',
            deu: 'Um dieses Feld auszufüllen, sehen Sie sich den Geschäftsplan des Projekts an',
        },
        projects_add_financial_profit_placeholder: {
            eng: 'To fill in this field see the business plan of the project',
            rus: 'Для заполнения этого поля ознакомьтесь с бизнес-планом проекта',
            ukr: 'Для заповнення цього поля ознайомтесь з бізнес-планом проекту',
            deu: 'Um dieses Feld auszufüllen, sehen Sie sich den Geschäftsplan des Projekts an',
        },
        projects_add_financial_square_total_placeholder: {
            eng: 'Determine total number of square meters, that you want to allocate for investment collection',
            rus: 'Определите общее количество квадратных метров, которые вы хотите выделить для сбора инвестиций',
            ukr: 'Визначте загальну кількість квадратних метрів, які ви хочете виділити для збору інвестицій',
            deu: 'Bestimmen Sie die Gesamtzahl der Quadratmeter, die Sie für die Investitionssammlung zuweisen möchten',
        },
        projects_add_financial_square_price_placeholder: {
            eng: 'Base price per sq.m.',
            rus: 'Базовая цена за кв. м.',
            ukr: 'Базова ціна за кв. м.',
            deu: 'Grundpreis pro Quadratmeter',
        },
        projects_add_financial_square_start_help: {
            eng: 'Set date of start token sale',
            rus: 'Установите дату начала продажи токенов',
            ukr: 'Встановіть дату початку продажу токенів',
            deu: 'Legen Sie das Datum des Beginns des Token-Verkaufs fest',
        },
        projects_add_financial_square_end_help: {
            eng: 'Set date of end token sale',
            rus: 'Установите дату окончания продажи токенов',
            ukr: 'Встановіть дату завершення продажу токенів',
            deu: 'Legen Sie das Datum des Endes des Token-Verkaufs fest',
        },
        projects_add_financial_unit_start_help: {
            eng: 'Set date of start token sale',
            rus: 'Установите дату начала продажи токенов',
            ukr: 'Встановіть дату початку продажу токенів',
            deu: 'Legen Sie das Datum des Beginns des Token-Verkaufs fest',
        },
        projects_add_financial_unit_end_help: {
            eng: 'Set date of end token sale',
            rus: 'Установите дату окончания продажи токенов',
            ukr: 'Встановіть дату завершення продажу токенів',
            deu: 'Legen Sie das Datum des Endes des Token-Verkaufs fest',
        },
        projects_add_financial_unit_aparts_floor_placeholder: {
            eng: 'Floor number',
            rus: 'Номер этажа',
            ukr: 'Номер поверху',
            deu: 'Stockwerksnummer',
        },
        projects_add_financial_unit_aparts_price_placeholder: {
            eng: 'Price per apartment',
            rus: 'Цена за квартиру',
            ukr: 'Ціна за квартиру',
            deu: 'Preis pro Wohnung',
        },
        projects_add_financial_unit_aparts_type_help: {
            eng: 'Select type of property',
            rus: 'Выберите тип недвижимости',
            ukr: 'Виберіть тип нерухомості',
            deu: 'Wählen Sie den Immobilientyp aus',
        },
        projects_add_financial_unit_aparts_type_total_placeholder: {
            eng: 'Total area',
            rus: 'Общая площадь',
            ukr: 'Загальна площа',
            deu: 'Gesamtfläche',
        },
        projects_add_financial_unit_aparts_type_spaceLiving_placeholder: {
            eng: 'Living space',
            rus: 'Жилая площадь',
            ukr: 'Житлова площа',
            deu: 'Wohnfläche',
        },
        projects_add_financial_unit_aparts_type_kitchen_placeholder: {
            eng: 'Kitchen size',
            rus: 'Площадь кухни',
            ukr: 'Площа кухні',
            deu: 'Küchengröße',
        },
        property_technical_kitchen: {
            eng: 'Kitchen size',
            rus: 'Площадь кухни',
            ukr: 'Площа кухні',
            deu: 'Küchengröße',
        },
        projects_add_financial_unit_aparts_type_effectiveArea: {
            eng: 'Effective area',
            rus: 'Площадь помещений',
            ukr: 'Площа приміщень',
            deu: 'Effektive Fläche',
        },
        projects_add_financial_unit_aparts_type_auxiliaryArea: {
            eng: 'Auxiliary area',
            rus: 'Вспомогательная площадь',
            ukr: 'Допоміжна площа',
            deu: 'Nebenfläche',
        },
        projects_add_financial_unit_aparts_type_effectiveArea_placeholder: {
            eng: 'Effective area',
            rus: 'Площадь помещений',
            ukr: 'Площа приміщень',
            deu: 'Effektive Fläche',
        },
        projects_add_financial_unit_aparts_type_auxiliaryArea_placeholder: {
            eng: 'Auxiliary area',
            rus: 'Вспомогательная площадь',
            ukr: 'Допоміжна площа',
            deu: 'Nebenfläche',
        },
        projects_add_financial_unit_aparts_type_ceiling_placeholder: {
            eng: 'Ceiling height',
            rus: 'Высота потолка',
            ukr: 'Висота стелі',
            deu: 'Deckenhöhe',
        },
        property_technical_ceilingHeight: {
            eng: 'Ceiling height',
            rus: 'Высота потолка',
            ukr: 'Висота стелі',
            deu: 'Deckenhöhe',
        },
        projects_add_financial_unit_aparts_type_rooms_placeholder: {
            eng: 'Rooms',
            rus: 'Количество комнат',
            ukr: 'Кількість кімнат',
            deu: 'Zimmeranzahl',
        },
        property_technical_rooms: {
            eng: 'Rooms',
            rus: 'Количество комнат',
            ukr: 'Кількість кімнат',
            deu: 'Zimmeranzahl',
        },
        projects_add_financial_unit_aparts_type_roomsArea_placeholder: {
            eng: 'Area of rooms',
            rus: 'Площадь комнат',
            ukr: 'Площа кімнат',
            deu: 'Zimmerfläche',
        },
        projects_add_financial_unit_aparts_type_balcony_placeholder: {
            eng: 'Balcony',
            rus: 'Балкон',
            ukr: 'Балкон',
            deu: 'Balkon',
        },
        projects_add_financial_unit_aparts_type_numberWindows_placeholder: {
            eng: 'Number of windows',
            rus: 'Количество окон',
            ukr: 'Кількість вікон',
            deu: 'Anzahl der Fenster',
        },
        property_technical_numberWindows: {
            eng: 'Number of windows',
            rus: 'Количество окон',
            ukr: 'Кількість вікон',
            deu: 'Anzahl der Fenster',
        },
        projects_add_financial_unit_aparts_type_numberOfBathrooms: {
            eng: 'Number of bathrooms',
            rus: 'Количество ванных комнат',
            ukr: 'Кількість ванних кімнат',
            deu: 'Anzahl der Badezimmer',
        },
        projects_add_financial_unit_aparts_type_numberOfBathrooms_placeholder: {
            eng: 'Number of bathrooms',
            rus: 'Количество ванных комнат',
            ukr: 'Кількість ванних кімнат',
            deu: 'Anzahl der Badezimmer',
        },
        projects_add_financial_unit_aparts_type_windowView_placeholder: {
            eng: 'Window view',
            rus: 'Вид из окна',
            ukr: 'Вид з вікна',
            deu: 'Fensterblick',
        },
        projects_add_documents_agreement: {
            eng: 'Investment agreement',
            rus: 'Инвестиционное соглашение',
            ukr: 'Інвестиційна угода',
            deu: 'Investitionsvereinbarung',
        },
        projects_add_step_common: {
            eng: 'Common',
            rus: 'Общие',
            ukr: 'Загальне',
            deu: 'Allgemein',
        },
        projects_add_step_technical: {
            eng: 'Technical',
            rus: 'Технические',
            ukr: 'Технічне',
            deu: 'Technisch',
        },
        projects_add_step_financial: {
            eng: 'Financial',
            rus: 'Финансовые',
            ukr: 'Фінансове',
            deu: 'Finanziell',
        },
        projects_add_step_images: {
            eng: 'Photos',
            rus: 'Фото',
            ukr: 'Фотографії',
            deu: 'Fotos',
        },
        projects_add_step_documents: {
            eng: 'Documents',
            rus: 'Документы',
            ukr: 'Документи',
            deu: 'Dokumente',
        },
        projects_add_technical_title: {
            eng: 'Technical specifications',
            rus: 'Технические характеристики',
            ukr: 'Технічні характеристики',
            deu: 'Technische Spezifikationen',
        },
        projects_add_main_photo_title: {
            eng: 'Main photo',
            rus: 'Главное фото',
            ukr: 'Головне фото',
            deu: 'Hauptfoto',
        },
        projects_add_images_title: {
            eng: 'Photos',
            rus: 'Фото',
            ukr: 'Фотографії',
            deu: 'Fotos',
        },
        projects_add_documents_title: {
            eng: 'Investment agreeement',
            rus: 'Инвестиционный договор',
            ukr: 'Інвестиційний договір',
            deu: 'Investitionsvereinbarung',
        },
        projects_add_common_location_title: {
            eng: 'In order to choose an address of the object, please fill in the address in the map field below',
            rus: 'Чтобы выбрать адрес объекта, пожалуйста, заполните адрес в поле карты ниже',
            ukr: "Щоб вибрати адресу об'єкта, будь ласка, заповніть адресу в полі карти нижче",
            deu: 'Um eine Adresse des Objekts auszuwählen, geben Sie bitte die Adresse in das Kartenfeld unten ein',
        },
        projects_add_financial_title: {
            eng: 'Financial indicators',
            rus: 'Финансовые показатели',
            ukr: 'Фінансові показники',
            deu: 'Finanzkennzahlen',
        },
        projects_add_common_type: {
            eng: 'Type of property',
            rus: 'Тип объекта',
            ukr: "Тип об'єкту",
            deu: 'Art der Eigenschaft',
        },
        projects_add_common_type_help: {
            eng: 'Select property type',
            rus: 'Выберите тип объекта',
            ukr: "Виберіть тип об'єкту",
            deu: 'Eigenschaftstyp auswählen',
        },
        projects_add_loader_drop: {
            eng: 'Drop image files to upload (or click)',
            rus: 'Перетащите файлы изображений для загрузки (или нажмите)',
            ukr: 'Перетягніть файли зображень для завантаження (або натисніть)',
            deu: 'Bilddateien zum Hochladen ablegen (oder klicken)',
        },
        projects_add_loader_formats: {
            eng: '(jpg, jpeg, png, gif)',
            rus: '(jpg, jpeg, png, gif)',
            ukr: '(jpg, jpeg, png, gif)',
            deu: '(jpg, jpeg, png, gif)',
        },
        projects_add_loader_file_count: {
            eng: 'Maximum number of files',
            rus: 'Максимальное количество файлов',
            ukr: 'Максимальна кількість файлів',
            deu: 'Maximale Anzahl an Dateien',
        },
        projects_add_loader_file_size: {
            eng: 'Maximum size of each image',
            rus: 'Максимальный размер каждого изображения',
            ukr: 'Максимальний розмір кожного зображення',
            deu: 'Maximale Größe jedes Bildes',
        },
        projects_add_loader_clear: {
            eng: 'Clear all',
            rus: 'Очистить все',
            ukr: 'Очистити все',
            deu: 'Alle löschen',
        },
        projects_add_documents_subtitle: {
            eng: 'Images of documents',
            rus: 'Изображения документов',
            ukr: 'Зображення документів',
            deu: 'Bilder von Dokumenten',
        },
        projects_add_documents_ownership: {
            eng: 'Ownership documents',
            rus: 'Документы о собственности',
            ukr: 'Документи власності',
            deu: 'Eigentumsdokumente',
        },
        projects_add_documents_project: {
            eng: 'Project documentation',
            rus: 'Документация проекта',
            ukr: 'Документація проекту',
            deu: 'Projektdokumentation',
        },
        projects_add_documents_restrictions: {
            eng: 'Restrictions',
            rus: 'Ограничения',
            ukr: 'Обмеження',
            deu: 'Einschränkungen',
        },
        projects_add_documents_building: {
            eng: 'Building permit',
            rus: 'Разрешение на строительство',
            ukr: 'Дозвіл на будівництво',
            deu: 'Baugenehmigung',
        },
        projects_add_documents_business: {
            eng: 'Business plan',
            rus: 'Бизнес-план',
            ukr: 'Бізнес-план',
            deu: 'Geschäftsplan',
        },
        projects_add_documents_diligence: {
            eng: 'Due diligence',
            rus: 'Проверка юридической чистоты',
            ukr: 'Перевірка юридичної чистоти',
            deu: 'Due Diligence',
        },
        projects_add_documents_warning_text: {
            eng: 'All documents must be represented in English.',
            rus: 'Все документы должны быть представлены на английском языке.',
            ukr: 'Всі документи повинні бути представлені англійською мовою.',
            deu: 'Alle Dokumente müssen in englischer Sprache vorgelegt werden.',
        },
        projects_add_documents_warning_text_apost: {
            eng: 'All enabling documentation must be apostilled.',
            rus: 'Все разрешительные документы должны быть апостилированы.',
            ukr: 'Всі дозвільні документи повинні бути апостильовані.',
            deu: 'Alle beglaubigten Unterlagen müssen apostilliert sein.',
        },
        projects_add_documents_warning_title: {
            eng: 'Warning!',
            rus: 'Внимание!',
            ukr: 'Увага!',
            deu: 'Warnung!',
        },
        projects_add_financial_unit_edit: {
            eng: 'Edit',
            rus: 'Редактировать',
            ukr: 'Редагувати',
            deu: 'Bearbeiten',
        },
        projects_add_financial_unit_delete: {
            eng: 'Delete',
            rus: 'Удалить',
            ukr: 'Видалити',
            deu: 'Löschen',
        },
        projects_add_financial_units_helper: {
            eng: 'At least 1 token must be added',
            rus: 'Необходимо добавить минимум 1 токен',
            ukr: 'Необхідно додати принаймні 1 токен',
            deu: 'Mindestens 1 Token muss hinzugefügt werden',
        },
        projects_add_images_helper: {
            eng: 'Please upload at least one photo',
            rus: 'Пожалуйста, загрузите хотя бы одну фотографию',
            ukr: 'Будь ласка, завантажте хоча б одне фото',
            deu: 'Bitte laden Sie mindestens ein Foto hoch',
        },
        marketplace_title: {
            eng: 'Marketplace',
            rus: 'Маркетплейс',
            ukr: 'Маркетплейс',
            deu: 'Marktplatz',
        },
        marketplace_total_active: {
            eng: 'Total active lots',
            rus: 'Всего активных лотов',
            ukr: 'Всього активних лотів',
            deu: 'Gesamtzahl der aktiven Angebote',
        },
        marketplace_total_lots: {
            eng: 'Total lots',
            rus: 'Всего лотов',
            ukr: 'Всього лотів',
            deu: 'Gesamtzahl der Angebote',
        },
        marketplace_total_cost: {
            eng: 'Total cost of lots',
            rus: 'Общая стоимость лотов',
            ukr: 'Загальна вартість лотів',
            deu: 'Gesamtkosten der Angebote',
        },
        marketplace_search_title: {
            eng: 'Search by',
            rus: 'Поиск по',
            ukr: 'Пошук за',
            deu: 'Suche nach',
        },
        marketplace_search_name: {
            eng: 'Name',
            rus: 'Название',
            ukr: 'Назва',
            deu: 'Name',
        },
        marketplace_search_type: {
            eng: 'Choose type',
            rus: 'Выберите тип',
            ukr: 'Виберіть тип',
            deu: 'Wählen Sie den Typ',
        },
        marketplace_search_fromPrice: {
            eng: 'Price from',
            rus: 'Цена от',
            ukr: 'Ціна від',
            deu: 'Preis ab',
        },
        marketplace_search_toPrice: {
            eng: 'Price to',
            rus: 'Цена до',
            ukr: 'Ціна до',
            deu: 'Preis bis',
        },
        marketplace_search_find: {
            eng: 'Find',
            rus: 'Найти',
            ukr: 'Знайти',
            deu: 'Suchen',
        },
        marketplace_list_empty: {
            eng: 'List is empty',
            rus: 'Список пуст',
            ukr: 'Список порожній',
            deu: 'Die Liste ist leer',
        },
        marketplace_history_title: {
            eng: 'Market trade history',
            rus: 'История торгов на рынке',
            ukr: 'Історія торгів на ринку',
            deu: 'Handelsgeschichte des Marktes',
        },
        marketplace_history_date: {
            eng: 'Date',
            rus: 'Дата',
            ukr: 'Дата',
            deu: 'Datum',
        },
        marketplace_history_type: {
            eng: 'Type',
            rus: 'Тип',
            ukr: 'Тип',
            deu: 'Typ',
        },
        marketplace_history_price: {
            eng: 'Price',
            rus: 'Цена',
            ukr: 'Ціна',
            deu: 'Preis',
        },
        marketplace_history_quantity: {
            eng: 'Quantity',
            rus: 'Количество',
            ukr: 'Кількість',
            deu: 'Menge',
        },
        projects_list_item_startExpertise: {
            eng: 'Start expertise',
            rus: 'Начать экспертизу',
            ukr: 'Почати експертизу',
            deu: 'Expertise starten',
        },
        projects_add_financial_square_ticker: {
            eng: 'Token ticker',
            rus: 'Тикер токена',
            ukr: 'Тікер токена',
            deu: 'Token-Symbol',
        },
        projects_add_point: {
            eng: 'Add',
            rus: 'Добавить',
            ukr: 'Додати',
            deu: 'Hinzufügen',
        },
        projects_add_point_clear: {
            eng: 'Clear',
            rus: 'Очистить',
            ukr: 'Очистити',
            deu: 'Löschen',
        },
        projects_add_chart_label: {
            eng: 'Price per square meter (EURO)',
            rus: 'Цена за квадратный метр (ЕВРО)',
            ukr: 'Ціна за квадратний метр (ЄВРО)',
            deu: 'Preis pro Quadratmeter (EURO)',
        },
        projects_add_point_time: {
            eng: 'Date of change',
            rus: 'Дата изменения',
            ukr: 'Дата зміни',
            deu: 'Änderungsdatum',
        },

        projects_add_point_cost: {
            eng: 'Price per square meter (EURO)',
            rus: 'Цена за квадратный метр (ЕВРО)',
            ukr: 'Ціна за квадратний метр (ЄВРО)',
            deu: 'Preis pro Quadratmeter (EURO)',
        },
        projects_add_point_title: {
            eng: 'Base price increase',
            rus: 'Увеличение базовой цены',
            ukr: 'Збільшення базової ціни',
            deu: 'Preiserhöhung',
        },
        projects_add_financial_square_ticker_placeholder: {
            eng: 'Think of a unique ticker for your token',
            rus: 'Придумайте уникальный тикер для вашего токена',
            ukr: 'Придумайте унікальний тікер для вашого токена',
            deu: 'Denken Sie sich ein eindeutiges Symbol für Ihren Token aus',
        },
        projects_list_item_start_expertise_success: {
            eng: 'Expertise started successfully',
            rus: 'Экспертиза успешно начата',
            ukr: 'Експертиза успішно розпочата',
            deu: 'Die Expertise wurde erfolgreich gestartet',
        },
        projects_add_financial_loan_total_placeholder: {
            eng: 'Determine the total number of loan tokens for the project',
            rus: 'Определите общее количество заемных токенов для проекта',
            ukr: 'Визначте загальну кількість позичкових токенів для проекту',
            deu: 'Bestimmen Sie die Gesamtzahl der Kredit-Token für das Projekt',
        },
        projects_add_financial_loan_rate_placeholder: {
            eng: 'Specify what rate of return on an investment you want to achieve over a specified time period',
            rus: 'Укажите желаемую ставку доходности инвестиций за определенный период времени',
            ukr: 'Вкажіть бажану ставку доходності інвестицій за визначений період часу',
            deu: 'Geben Sie an, welche Rendite Sie über einen bestimmten Zeitraum erzielen möchten',
        },
        projects_add_financial_loan_amount_placeholder: {
            eng: 'Enter a minimum amount of investments that can be provided for the project',
            rus: 'Введите минимальную сумму инвестиций, которую можно предоставить для проекта',
            ukr: 'Введіть мінімальну суму інвестицій, яку можна надати для проекту',
            deu: 'Geben Sie einen Mindestbetrag an Investitionen ein, der für das Projekt bereitgestellt werden kann',
        },
        projects_add_financial_loan_start_help: {
            eng: 'Set date of start token sale',
            rus: 'Установите дату начала продажи токенов',
            ukr: 'Встановіть дату початку продажу токенів',
            deu: 'Legen Sie das Datum des Beginns des Token-Verkaufs fest',
        },
        projects_add_financial_loan_end_help: {
            eng: 'Set date of end token sale',
            rus: 'Установите дату окончания продажи токенов',
            ukr: 'Встановіть дату завершення продажу токенів',
            deu: 'Legen Sie das Datum des Endes des Token-Verkaufs fest',
        },
        projects_add_financial_loan_period_help: {
            eng: 'Select payment period',
            rus: 'Выберите период выплат',
            ukr: 'Виберіть період виплат',
            deu: 'Wählen Sie den Zahlungszeitraum aus',
        },
        projects_add_financial_loan_quantity_placeholder: {
            eng: 'Enter total number of payments, which will be paid to the investor till the end of payment period',
            rus: 'Введите общее количество платежей, которые будут выплачены инвестору до конца периода выплат',
            ukr: 'Введіть загальну кількість платежів, які будуть виплачені інвестору до кінця періоду виплат',
            deu: 'Geben Sie die Gesamtzahl der Zahlungen ein, die dem Investor bis zum Ende des Zahlungszeitraums gezahlt werden',
        },
        token_coin: {
            eng: 'Token/Coin',
            rus: 'Токен/Монета',
            ukr: 'Токен/Монета',
            deu: 'Token/Münze',
        },
        my_wallet: {
            eng: 'My wallet',
            rus: 'Мой кошелек',
            ukr: 'Мій гаманець',
            deu: 'Mein Portemonnaie',
        },
        crypto: {
            eng: 'Crypto',
            rus: 'Крипто',
            ukr: 'Крипто',
            deu: 'Krypto',
        },
        network: {
            eng: 'Network',
            rus: 'Сеть',
            ukr: 'Мережа',
            deu: 'Netzwerk',
        },
        fiat: {
            eng: 'Fiat',
            rus: 'Фиат',
            ukr: 'Фіат',
            deu: 'Fiat',
        },
        withdraw: {
            eng: 'Withdraw',
            rus: 'Вывод',
            ukr: 'Виведення',
            deu: ' Abhebung',
        },
        bank_account_translation: {
            eng: 'Bank account',
            rus: 'Банковский счет',
            ukr: 'Банківський рахунок',
            deu: 'Bankkonto',
        },
        deposit: {
            eng: 'Deposit',
            rus: 'Депозит',
            ukr: 'Депозит',
            deu: 'die Kaution',
        },
        transaction_details: {
            eng: 'Transaction details',
            rus: 'Детали транзакции',
            ukr: 'Деталі транзакції',
            deu: 'Transaktionsdetails',
        },
        active: {
            eng: 'Active',
            rus: 'Активный',
            ukr: 'Активний',
            deu: 'Aktiv',
        },
        inactive_translation: {
            eng: 'Inactive',
            rus: 'Неактивный',
            ukr: 'Неактивний',
            deu: 'Inaktiv',
        },
        action: {
            eng: 'Action',
            rus: 'Действие',
            ukr: 'Дія',
            deu: 'Aktion',
        },
        new: {
            eng: 'New',
            rus: 'Новый',
            ukr: 'Новий',
            deu: 'Neu',
        },
        wait: {
            eng: 'Wait',
            rus: 'Ожидание',
            ukr: 'Очікування',
            deu: 'Warten',
        },
        fail: {
            eng: 'Fail',
            rus: 'Ошибка',
            ukr: 'Помилка',
            deu: 'Fehler',
        },
        cancel: {
            eng: 'Cancel',
            rus: 'Отмена',
            ukr: 'Скасувати',
            deu: 'Abbrechen',
        },
        done: {
            eng: 'Done',
            rus: 'Готово',
            ukr: 'Виконано',
            deu: 'Erledigt',
        },
        processing: {
            eng: 'Processing',
            rus: 'Обработка',
            ukr: 'Обробка',
            deu: 'Verarbeitung',
        },
        transaction_hash: {
            eng: 'Transaction hash',
            rus: 'Хэш транзакции',
            ukr: 'Хеш транзакції',
            deu: 'Transaktions-Hash',
        },
        view_project: {
            eng: 'View project',
            rus: 'Просмотр проекта',
            ukr: 'Переглянути проект',
            deu: 'Projekt anzeigen',
        },
        total_m2c_pledged: {
            eng: 'Total M2C pledged',
            rus: 'Общее количество M2C взносов',
            ukr: 'Загальна кількість M2C внесків',
            deu: 'Insgesamt zugesagte M2C',
        },
        closed_date_translation: {
            eng: 'Closed date',
            rus: 'Дата закрытия',
            ukr: 'Дата закриття',
            deu: 'Abschlussdatum',
        },
        created_date_translation: {
            eng: 'Created date',
            rus: 'Дата создания',
            ukr: 'Дата створення',
            deu: 'Erstellungsdatum',
        },
        collateral_asset: {
            eng: 'Collateral Asset',
            rus: 'Залоговый актив',
            ukr: 'Актив-забезпечення',
            deu: 'Sicherheitsasset',
        },
        primary_portfolio_price: {
            eng: 'Primary portfolio price',
            rus: 'Цена основного портфеля',
            ukr: 'Ціна основного портфеля',
            deu: 'Preis des Hauptportfolios',
        },
        actual_portfolio_price: {
            eng: 'Actual portfolio price',
            rus: 'Фактическая цена портфеля',
            ukr: 'Фактична ціна портфеля',
            deu: 'Aktueller Portfolio-Preis',
        },
        square_meter_price: {
            eng: 'Square meter price',
            rus: 'Цена за квадратный метр',
            ukr: 'Ціна за квадратний метр',
            deu: 'Quadratmeterpreis',
        },
        total_square_meters: {
            eng: 'Total square meters',
            rus: 'Общая площадь в квадратных метрах',
            ukr: 'Загальна площа у квадратних метрах',
            deu: 'Gesamtfläche in Quadratmetern',
        },
        total_square_meters_price: {
            eng: 'Total square meters price',
            rus: 'Общая стоимость квадратных метров',
            ukr: 'Загальна вартість квадратних метрів',
            deu: 'Gesamtpreis für Quadratmeter',
        },
        sell_contract: {
            eng: 'Sell this contract',
            rus: 'Продать этот контракт',
            ukr: 'Продати цей контракт',
            deu: 'Diesen Vertrag verkaufen',
        },
        add_m2c_to_contract: {
            eng: 'Add M2C to contract',
            rus: 'Добавить M2C в контракт',
            ukr: 'Додати M2C до контракту',
            deu: 'M2C zum Vertrag hinzufügen',
        },
        exchange_contract_for_unit: {
            eng: 'Exchange contract for unit',
            rus: 'Обменять контракт на юнит',
            ukr: 'Обміняти контракт на юніт',
            deu: 'Vertrag gegen Einheit tauschen',
        },
        contract_address: {
            eng: 'Contract address',
            rus: 'Адрес контракта',
            ukr: 'Адреса контракту',
            deu: 'Vertragsadresse',
        },
        show_on_stellar_explorer_translation: {
            eng: 'Show on Stellar explorer',
            rus: 'Показать в Stellar Explorer',
            ukr: 'Показати в Stellar Explorer',
            deu: 'Im Stellar Explorer anzeigen',
        },
        qr_code_value: {
            eng: 'QR code value',
            rus: 'Значение QR-кода',
            ukr: 'Значення QR-коду',
            deu: 'Wert des QR-Codes',
        },
        contract_end_date: {
            eng: 'Contract end date',
            rus: 'Дата окончания контракта',
            ukr: 'Дата закінчення контракту',
            deu: 'Vertragsenddatum',
        },
        nft_name: {
            eng: 'NFT name',
            rus: 'Название NFT',
            ukr: 'Назва NFT',
            deu: 'NFT-Name',
        },
        export_contracts_history: {
            eng: 'Export contracts history',
            rus: 'Экспорт истории контрактов',
            ukr: 'Експорт історії контрактів',
            deu: 'Vertragsverlauf exportieren',
        },
        contact_support: {
            eng: 'If you have any troubles, you can contact our',
            rus: 'Если у вас возникли какие-либо проблемы, вы можете связаться с нашей',
            ukr: "Якщо у вас виникли які-небудь проблеми, ви можете зв'язатися з нашою",
            deu: 'Wenn Sie Probleme haben, können Sie uns kontaktieren',
        },
        customer_support: {
            eng: 'customer support',
            rus: 'службой поддержки',
            ukr: 'службою підтримки',
            deu: 'Kundensupport',
        },
        support_service: {
            eng: 'support service',
            rus: 'службой поддержки',
            ukr: 'службою підтримки',
            deu: 'Support-Service',
        },
        earn_m2c: {
            eng: 'Earn M2C at 10%',
            rus: 'Зарабатывайте M2C на 10%',
            ukr: 'Заробляйте M2C на 10%',
            deu: 'Verdienen Sie M2C mit 10%',
        },

        stake_to_earn_translation: {
            eng: 'Stake to earn M2C at 10% APR',
            rus: 'Стейк для заработка M2C под 10% годовых',
            ukr: 'Стейк для заробітку M2C під 10% річних',
            deu: 'Stake, um M2C mit 10% Jahreszins zu verdienen',
        },
        available_limit_translation: {
            eng: 'Available limit:',
            rus: 'Доступный лимит:',
            ukr: 'Доступний ліміт:',
            deu: 'Verfügbarer Limit:',
        },
        select_asset: {
            eng: 'Select Asset',
            rus: 'Выберите актив',
            ukr: 'Виберіть актив',
            deu: 'Aktiv auswählen',
        },
        deposit_address: {
            eng: 'Deposit Address',
            rus: 'Адрес для депозита',
            ukr: 'Адреса для депозиту',
            deu: 'Einzahlungsadresse',
        },
        network_fee: {
            eng: 'Network Fee',
            rus: 'Комиссия сети',
            ukr: 'Комісія мережі',
            deu: 'Netzwerkgebühr',
        },
        get_address: {
            eng: 'Get address',
            rus: 'Получить адрес',
            ukr: 'Отримати адресу',
            deu: 'Adresse erhalten',
        },
        address_updated: {
            eng: 'Address Updated!',
            rus: 'Адрес обновлен!',
            ukr: 'Адреса оновлена!',
            deu: 'Adresse aktualisiert!',
        },
        deposits_to_old_address: {
            eng: 'Deposits to your old address',
            rus: 'Депозиты на ваш старый адрес',
            ukr: 'Депозити на ваш старий адрес',
            deu: 'Einzahlungen an Ihre alte Adresse',
        },
        new_address_obtained: {
            eng: 'You have successfully obtained a new',
            rus: 'Вы успешно получили новый',
            ukr: 'Ви успішно отримали нову',
            deu: 'Sie haben erfolgreich eine neue',
        },
        no_address_created: {
            eng: 'No',
            rus: 'Нет',
            ukr: 'Немае',
            deu: 'Nein',
        },
        address: {
            eng: 'Address',
            rus: 'Адрес',
            ukr: 'Адреса',
            deu: 'Adresse',
        },
        enter_withdraw_address: {
            eng: 'Please enter a withdraw address',
            rus: 'Пожалуйста, введите адрес для вывода',
            ukr: 'Будь ласка, введіть адресу для виведення',
            deu: 'Bitte geben Sie eine Auszahlungsadresse ein',
        },
        amount_m2c: {
            eng: 'Amount',
            rus: 'Сумма',
            ukr: 'Сума',
            deu: 'Betrag',
        },
        available_balance_translation: {
            eng: 'Available balance:',
            rus: 'Доступный баланс:',
            ukr: 'Доступний баланс:',
            deu: 'Verfügbarer Saldo:',
        },
        minimum_withdrawal_amount_translation: {
            eng: 'Minimum withdrawal amount',
            rus: 'Минимальная сумма для вывода',
            ukr: 'Мінімальна сума для виведення',
            deu: 'Mindestabhebungsbetrag',
        },
        enter_amount_no_higher: {
            eng: 'Please enter an amount no higher than your available balance',
            rus: 'Пожалуйста, введите сумму не превышающую ваш доступный баланс',
            ukr: 'Будь ласка, введіть суму, що не перевищує ваш доступний баланс',
            deu: 'Bitte geben Sie einen Betrag ein, der nicht höher ist als Ihr verfügbarer Kontostand',
        },
        deposit_crypto: {
            eng: 'Deposit crypto',
            rus: 'Пополнить криптовалюту',
            ukr: 'Поповнити криптовалюту',
            deu: 'Krypto einzahlen',
        },
        withdraw_crypto: {
            eng: 'Withdraw crypto',
            rus: 'Вывести криптовалюту',
            ukr: 'Вивести криптовалюту',
            deu: 'Krypto abheben',
        },
        deposit_addressed: {
            eng: 'deposit address',
            rus: 'адреса для депозита',
            ukr: 'адреси для депозиту',
            deu: 'Einzahlungsadresse',
        },
        not_automatic_credit: {
            eng: 'will not be automatically credited',
            rus: 'не будут автоматически зачислены',
            ukr: 'не будуть автоматично зараховані',
            deu: 'werden nicht automatisch gutgeschrieben',
        },
        use_new_address: {
            eng: 'Please be sure to use the new address for all future deposits.',
            rus: 'Пожалуйста, обязательно используйте новый адрес для всех последующих депозитов.',
            ukr: 'Будьте впевнені, що використовуєте нову адресу для всіх майбутніх депозитів.',
            deu: 'Bitte stellen Sie sicher, dass Sie die neue Adresse für alle zukünftigen Einzahlungen verwenden.',
        },
        no_deposit_address_created: {
            eng: 'No deposit address has been previously created. Please first create a deposit address.',
            rus: 'Ранее не был создан адрес для депозита. Пожалуйста, сначала создайте адрес для депозита.',
            ukr: 'Раніше не створювався адресу для депозиту. Будь ласка, спочатку створіть адресу для депозиту.',
            deu: 'Es wurde zuvor keine Einzahlungsadresse erstellt. Bitte erstellen Sie zuerst eine Einzahlungsadresse.',
        },

        copied_to_clipboard: {
            eng: 'Copied to the clipboard',
            rus: 'Скопировано в буфер обмена',
            ukr: 'Скопійовано до буферу обміну',
            deu: 'In die Zwischenablage kopiert',
        },
        aml_verification: {
            eng: 'AML verification is taking place. Based on the results, amounts may be blocked. In this case please contact',
            rus: 'Идет проверка по AML. В зависимости от результатов, суммы могут быть заблокированы. В этом случае, пожалуйста, свяжитесь с',
            ukr: "Йде перевірка згідно AML. Залежно від результатів, суми можуть бути заблоковані. У цьому випадку, будь ласка, зв'яжіться з",
            deu: 'AML-Verifizierung findet statt. Basierend auf den Ergebnissen können Beträge blockiert werden. In diesem Fall kontaktieren Sie bitte',
        },
        send: {
            eng: 'Send',
            rus: 'Отправить',
            ukr: 'Відправити',
            deu: 'Senden',
        },
        projects_add_title: {
            eng: 'Add project',
            rus: 'Добавить проект',
            ukr: 'Додати проект',
            deu: 'Projekt hinzufügen',
        },
        projects_item_expertise_started: {
            eng: 'Expertise started successfully',
            rus: 'Экспертиза успешно начата',
            ukr: 'Експертиза успішно розпочата',
            deu: 'Expertise erfolgreich gestartet',
        },
        exchange_pair: {
            eng: 'Quantity',
            rus: 'Количество',
            ukr: 'Кількість',
            deu: 'Menge',
        },
        exchange_coins_table_pair: {
            eng: 'Pair',
            rus: 'Пара',
            ukr: 'Пара',
            deu: 'Paar',
        },
        exchange_coins_table_price: {
            eng: 'Price',
            rus: 'Цена',
            ukr: 'Ціна',
            deu: 'Preis',
        },
        exchange_coins_table_change: {
            eng: 'Change',
            rus: 'Изменение',
            ukr: 'Зміна',
            deu: 'Änderung',
        },
        exchange_coins_table_amount: {
            eng: 'Amount',
            rus: 'Количество',
            ukr: 'Кількість',
            deu: 'Menge',
        },
        exchange_coins_table_time: {
            eng: 'Time',
            rus: 'Время',
            ukr: 'Час',
            deu: 'Zeit',
        },
        exchange_coins_table_total: {
            eng: 'Total',
            rus: 'Всего',
            ukr: 'Всього',
            deu: 'Gesamt',
        },
        exchange_user_orders_tab_open: {
            eng: 'Open orders',
            rus: 'Открытые ордера',
            ukr: 'Відкриті замовлення',
            deu: 'Offene Bestellungen',
        },
        exchange_user_orders_tab_history: {
            eng: 'History',
            rus: 'История',
            ukr: 'Історія',
            deu: 'Verlauf',
        },
        exchange_user_orders_tab_open_nothing: {
            eng: "Now you haven't open orders",
            rus: 'Сейчас у вас нет открытых ордеров',
            ukr: 'Зараз у вас немає відкритих замовлень',
            deu: 'Sie haben derzeit keine offenen Bestellungen',
        },
        exchange_user_orders_tab_history_nothing: {
            eng: 'History is empty',
            rus: 'История пуста',
            ukr: 'Історія порожня',
            deu: 'Der Verlauf ist leer',
        },
        exchange_spot_price: {
            eng: 'Price',
            rus: 'Цена',
            ukr: 'Ціна',
            deu: 'Preis',
        },
        exchange_spot_count: {
            eng: 'Count',
            rus: 'Количество',
            ukr: 'Кількість',
            deu: 'Anzahl',
        },
        exchange_spot_total: {
            eng: 'Total',
            rus: 'Итого',
            ukr: 'Разом',
            deu: 'Gesamt',
        },
        contract_launch_new: {
            eng: 'Launch new contract',
            rus: 'Запустить новый контракт',
            ukr: 'Запустити новий контракт',
            deu: 'Neuen Vertrag starten',
        },
        contract_accept_asset: {
            eng: 'Accepted asset',
            rus: 'Принимаемый актив',
            ukr: 'Принімаючий актив',
            deu: 'Akzeptiertes Gut',
        },
        contract_pledged: {
            eng: 'Pledged asset',
            rus: 'Заложенный актив',
            ukr: 'Заложений актив',
            deu: 'Verpfändetes Gut',
        },
        contract_payment_asset: {
            eng: 'Payment asset',
            rus: 'Актив для выплат',
            ukr: 'Актив для виплат',
            deu: 'Zahlungsgut',
        },
        contract_limit: {
            eng: 'Collateral limit',
            rus: 'Залоговый лимит',
            ukr: 'Ліміт застави',
            deu: 'Kollateralgrenze',
        },
        contract_term: {
            eng: 'Contract term',
            rus: 'Срок контракта',
            ukr: 'Термін контракту',
            deu: 'Vertragslaufzeit',
        },
        contract_create_btn: {
            eng: 'Create',
            rus: 'Создать',
            ukr: 'Створити',
            deu: 'Erstellen',
        },
        kyc_add_last_name: {
            eng: 'Last name',
            rus: 'Фамилия',
            ukr: 'Прізвище',
            deu: 'Nachname',
        },
        kyc_add_first_name: {
            eng: 'First name',
            rus: 'Имя',
            ukr: "Ім'я",
            deu: 'Vorname',
        },
        kyc_add_additional_name: {
            eng: 'Middle name or other additional name',
            rus: 'Отчество или другое дополнительное имя',
            ukr: "По батькові або інше додаткове ім'я",
            deu: 'Zweiter Vorname oder zusätzlicher Name',
        },
        kyc_add_sex: {
            eng: 'Sex',
            rus: 'Пол',
            ukr: 'Стать',
            deu: 'Geschlecht',
        },
        kyc_add_mobile_number: {
            eng: 'Mobile phone number',
            rus: 'Мобильный телефон',
            ukr: 'Мобільний телефон',
            deu: 'Handynummer',
        },
        kyc_add_email_address: {
            eng: 'Email address',
            rus: 'Email адрес',
            ukr: 'Email адреса',
            deu: 'E-Mail-Adresse',
        },
        kyc_add_state_or_province: {
            eng: 'Name of state/province/region/prefecture',
            rus: 'Название штата/провинции/региона/префектуры',
            ukr: 'Назва штату/області/регіону/префектури',
            deu: 'Name des Bundesstaates/der Provinz/der Region/der Präfektur',
        },
        kyc_add_city: {
            eng: 'Name of city/town',
            rus: 'Название города/населенного пункта',
            ukr: 'Назва міста/населеного пункту',
            deu: 'Name der Stadt/Gemeinde',
        },
        kyc_add_postal_code: {
            eng: 'Postal code',
            rus: 'Почтовый индекс',
            ukr: 'Поштовий індекс',
            deu: 'Postleitzahl',
        },
        kyc_add_address_country_code: {
            eng: 'Country',
            rus: 'Страна',
            ukr: 'Країна',
            deu: 'Land',
        },
        kyc_add_address: {
            eng: 'Address',
            rus: 'Адрес',
            ukr: 'Адреса',
            deu: 'Adresse',
        },
        kyc_add_birth_date: {
            eng: 'Date of birth',
            rus: 'Дата рождения',
            ukr: 'Дата народження',
            deu: 'Geburtsdatum',
        },
        kyc_add_birth_place: {
            eng: 'Place of birth (city, state, country as on passport)',
            rus: 'Место рождения (город, штат, страна в паспорте)',
            ukr: 'Місце народження (місто, область, країна, як у паспорті)',
            deu: 'Geburtsort (Stadt, Bundesland, Land wie im Reisepass)',
        },
        kyc_add_birth_country_code: {
            eng: 'Country of birth',
            rus: 'Страна рождения',
            ukr: 'Країна народження',
            deu: 'Geburtsland',
        },
        kyc_add_bank_account_number: {
            eng: 'Number identifying bank account',
            rus: 'Номер, идентифицирующий банковский счет',
            ukr: 'Номер, що ідентифікує банківський рахунок',
            deu: 'Nummer zur Identifizierung des Bankkontos',
        },
        kyc_add_bank_account_type: {
            eng: 'Account type',
            rus: 'Тип счета',
            ukr: 'Тип рахунку',
            deu: 'Kontotyp',
        },
        kyc_add_bank_number: {
            eng: 'Number identifying bank in national banking system',
            rus: 'Номер, идентифицирующий банк в национальной банковской системе',
            ukr: 'Номер, що ідентифікує банк в національній банківській системі',
            deu: 'Nummer zur Identifizierung der Bank im nationalen Bankensystem',
        },
        kyc_add_bank_phone_number: {
            eng: 'Phone number with country code for bank',
            rus: 'Телефонный номер с кодом страны для банка',
            ukr: 'Телефонний номер з кодом країни для банку',
            deu: 'Telefonnummer mit Ländervorwahl für die Bank',
        },
        kyc_add_bank_branch_number: {
            eng: 'Number identifying bank branch',
            rus: 'Номер, идентифицирующий филиал банка',
            ukr: 'Номер, що ідентифікує відділення банку',
            deu: 'Nummer zur Identifizierung der Bankfiliale',
        },
        kyc_add_tax_id: {
            eng: 'Tax identifier of user in their country',
            rus: 'Идентификатор налогоплательщика пользователя в их стране',
            ukr: 'Ідентифікатор податкового платника користувача у їхній країні',
            deu: 'Steueridentifikationsnummer des Benutzers in ihrem Land',
        },
        kyc_add_tax_id_name: {
            eng: 'Name of the tax ID',
            rus: 'Название налогового идентификатора',
            ukr: 'Назва податкового ідентифікатора',
            deu: 'Name der Steueridentifikationsnummer',
        },
        kyc_add_occupation: {
            eng: 'Occupation ISCO code',
            rus: 'Код профессии по классификации ISCO',
            ukr: 'Код професії за класифікацією ISCO',
            deu: 'Berufs-ISCO-Code',
        },
        kyc_add_employer_name: {
            eng: 'Name of employer',
            rus: 'Название работодателя',
            ukr: 'Назва роботодавця',
            deu: 'Name des Arbeitgebers',
        },
        kyc_add_employer_address: {
            eng: 'Address of employer',
            rus: 'Адрес работодателя',
            ukr: 'Адреса роботодавця',
            deu: 'Adresse des Arbeitgebers',
        },
        kyc_add_id_type: {
            eng: 'ID Type',
            rus: 'Тип удостоверения личности',
            ukr: 'Тип підтвердження особи',
            deu: 'Ausweistyp',
        },
        kyc_add_id_country_code: {
            eng: 'Country',
            rus: 'Страна',
            ukr: 'Країна',
            deu: 'Land',
        },
        kyc_add_id_issue_date: {
            eng: 'ID issue date',
            rus: 'Дата выдачи удостоверения личности',
            ukr: 'Дата видачі підтвердження особи',
            deu: 'Ausstellungsdatum des Ausweises',
        },
        kyc_add_id_expiration_date: {
            eng: 'ID expiration date',
            rus: 'Срок действия удостоверения личности',
            ukr: 'Термін дії підтвердження особи',
            deu: 'Ablaufdatum des Ausweises',
        },
        kyc_add_id_number: {
            eng: 'Passport or ID number',
            rus: 'Номер паспорта или удостоверения личности',
            ukr: 'Номер паспорта або підтвердження особи',
            deu: 'Pass- oder Ausweisnummer',
        },
        kyc_add_photo_id_front: {
            eng: 'Image of front of photo ID or passport',
            rus: 'Изображение передней части фотоудостоверения личности или паспорта',
            ukr: 'Зображення передньої частини фотоідентифікаційного документа або паспорта',
            deu: 'Bild der Vorderseite des Lichtbildausweises oder Reisepasses',
        },
        kyc_add_photo_id_back: {
            eng: 'Image of back of photo ID or passport',
            rus: 'Изображение задней части фотоудостоверения личности или паспорта',
            ukr: 'Зображення задньої частини фотоідентифікаційного документа або паспорта',
            deu: 'Bild der Rückseite des Lichtbildausweises oder Reisepasses',
        },
        kyc_add_notary_approval_of_photo_id: {
            eng: "Image of notary's approval of photo ID or passport",
            rus: 'Изображение подтверждения нотариуса на фотоудостоверении личности или паспорте',
            ukr: 'Зображення підтвердження нотаріуса на фотоідентифікаційному документі або паспорті',
            deu: 'Bild der Notarbestätigung des Lichtbildausweises oder Reisepasses',
        },
        kyc_add_photo_proof_residence: {
            eng: 'Image of a utility bill, bank statement or similar with the name and address',
            rus: 'Изображение коммунального счета, выписки из банка или аналогичного документа с указанием имени и адреса',
            ukr: "Зображення комунального рахунку, виписки з банку або аналогічного документа з ім'ям та адресою",
            deu: 'Bild einer Rechnung für Versorgungsleistungen, Kontoauszug oder ähnlichen Dokuments mit Namen und Adresse',
        },
        kyc_add_proof_of_income: {
            eng: "Image of user's proof of income document",
            rus: 'Изображение документа, подтверждающего доход пользователя',
            ukr: 'Зображення документа, що підтверджує дохід користувача',
            deu: 'Bild des Einkommensnachweisdokuments des Benutzers',
        },
        kyc_add_proof_of_liveness: {
            eng: 'Image file of user as a liveness proof',
            rus: 'Изображение пользователя в качестве доказательства живости',
            ukr: 'Зображення користувача як підтвердження життєздатності',
            deu: 'Bilddatei des Benutzers als Nachweis der Lebendigkeit',
        },
        kyc_add_organization_name: {
            eng: 'Full organization name as on the incorporation',
            rus: 'Полное название организации, как указано в учредительных документах',
            ukr: 'Повна назва організації, як зазначено в установчих документах',
            deu: 'Vollständiger Name der Organisation wie in der Gründungsurkunde',
        },
        kyc_add_organization_VAT_number: {
            eng: 'Organization VAT number',
            rus: 'Номер НДС организации',
            ukr: 'Номер ПДВ організації',
            deu: 'Umsatzsteuer-Identifikationsnummer der Organisation',
        },
        kyc_add_organization_registration_number: {
            eng: 'Organization registration number',
            rus: 'Регистрационный номер организации',
            ukr: 'Реєстраційний номер організації',
            deu: 'Registrierungsnummer der Organisation',
        },
        kyc_add_organization_registration_date: {
            eng: 'Date the organization was registered',
            rus: 'Дата регистрации организации',
            ukr: 'Дата реєстрації організації',
            deu: 'Datum der Organisationseintragung',
        },
        kyc_add_organization_registered_address: {
            eng: 'Organization registered address',
            rus: 'Зарегистрированный адрес организации',
            ukr: 'Зареєстрована адреса організації',
            deu: 'Registrierte Adresse der Organisation',
        },
        kyc_add_organization_number_of_shareholders: {
            eng: 'Organization shareholder number',
            rus: 'Количество акционеров организации',
            ukr: 'Кількість акціонерів організації',
            deu: 'Anzahl der Aktionäre der Organisation',
        },
        kyc_add_organization_shareholder_name: {
            eng: 'Can be an organization or a person',
            rus: 'Может быть организацией или физическим лицом',
            ukr: 'Може бути організацією або фізичною особою',
            deu: 'Kann eine Organisation oder eine Person sein',
        },
        kyc_add_organization_photo_incorporation_doc: {
            eng: 'Image of incorporation documents',
            rus: 'Изображение учредительных документов',
            ukr: 'Зображення установчих документів',
            deu: 'Bild der Gründungsdokumente',
        },
        kyc_add_organization_photo_proof_address: {
            eng: "Image of a utility bill, bank statement with the organization's name and address",
            rus: 'Изображение коммунального счета, выписки из банка с указанием названия и адреса организации',
            ukr: 'Зображення комунального рахунку, виписки з банку з вказівкою назви та адреси організації',
            deu: 'Bild einer Versorgungsrechnung, Kontoauszug mit dem Namen und der Adresse der Organisation',
        },
        kyc_add_organization_address_country_code: {
            eng: 'Country code for current address',
            rus: 'Код страны текущего адреса',
            ukr: 'Код країни поточної адреси',
            deu: 'Ländercode für aktuelle Adresse',
        },
        kyc_add_organization_state_or_province: {
            eng: 'Name of state/province/region/prefecture',
            rus: 'Название штата/провинции/региона/префектуры',
            ukr: 'Назва штату/області/регіону/префектури',
            deu: 'Name des Bundesstaates/der Provinz/Region/Präfektur',
        },
        kyc_add_organization_city: {
            eng: 'Name of city/town',
            rus: 'Название города/населенного пункта',
            ukr: 'Назва міста/населеного пункту',
            deu: 'Name der Stadt/Gemeinde',
        },
        kyc_add_organization_postal_code: {
            eng: "Postal or other code identifying organization's locale",
            rus: 'Почтовый или другой код, идентифицирующий местоположение организации',
            ukr: 'Поштовий або інший код, що ідентифікує розташування організації',
            deu: 'Postleitzahl oder anderer Code zur Identifizierung des Standorts der Organisation',
        },
        kyc_add_organization_director_name: {
            eng: 'Organization registered managing director',
            rus: 'Имя зарегистрированного исполнительного директора организации',
            ukr: "Ім'я зареєстрованого керівника організації",
            deu: 'Name des registrierten Geschäftsführers der Organisation',
        },
        kyc_add_organization_website: {
            eng: 'Organization website',
            rus: 'Веб-сайт организации',
            ukr: 'Веб-сайт організації',
            deu: 'Website der Organisation',
        },
        kyc_add_organization_email: {
            eng: 'Organization contact email',
            rus: 'Контактный email организации',
            ukr: 'Контактний email організації',
            deu: 'Kontakt-E-Mail der Organisation',
        },
        kyc_add_organization_phone: {
            eng: 'Organization contact phone',
            rus: 'Контактный телефон организации',
            ukr: 'Контактний телефон організації',
            deu: 'Kontakttelefon der Organisation',
        },
        kyc_add_title_person: {
            eng: 'Information about significant parties',
            rus: 'Информация о значимых сторонах',
            ukr: 'Інформація про значущі сторони',
            deu: 'Informationen zu bedeutenden Parteien',
        },
        kyc_add_title_entity: {
            eng: 'LEGAL ENTITY INFORMATION',
            rus: 'ИНФОРМАЦИЯ О ПРАВОВОМ ЛИЦЕ',
            ukr: 'ІНФОРМАЦІЯ ПРО ПРАВОВУ ОСОБУ',
            deu: 'INFORMATIONEN ZUR RECHTSPERSON',
        },
        kyc_add_step_general_info: {
            eng: 'General',
            rus: 'Общая информация',
            ukr: 'Загальна інформація',
            deu: 'Allgemeines',
        },
        kyc_add_step_id_info: {
            eng: 'ID/Passport',
            rus: 'ID/Паспорт',
            ukr: 'ID/Паспорт',
            deu: 'Ausweis/Reisepass',
        },
        kyc_add_step_address_info: {
            eng: 'Address',
            rus: 'Адрес',
            ukr: 'Адреса',
            deu: 'Adresse',
        },
        kyc_add_step_documents_info: {
            eng: 'Sources of income',
            rus: 'Источники дохода',
            ukr: 'Джерела доходу',
            deu: 'Einkommensquellen',
        },
        convert_title: {
            eng: 'Convert',
            rus: 'Обмен',
            ukr: 'Обмiн',
            deu: 'Umwandeln',
        },
        convert_alert: {
            eng: 'If you have any questions about the wallet, you can contact our support service',
            rus: 'Если у вас есть вопросы по обменнику, вы можете связаться с нашей службой поддержки',
            ukr: 'Якщо у вас є питання щодо обмінника, ви можете зв’язатися з нашою службою підтримки',
            deu: 'Wenn Sie Fragen zum Austauschdienst haben, können Sie sich an unser Support-Team wenden',
        },
        convert_from: {
            eng: 'From',
            rus: 'От',
            ukr: 'Від',
            deu: 'Von',
        },
        convert_to: {
            eng: 'To',
            rus: 'На',
            ukr: 'До',
            deu: 'Zu',
        },
        fee: {
            eng: 'Fee',
            rus: 'Комиссия',
            ukr: 'Комісія',
            deu: 'Provision',
        },
        no_transactions: {
            eng: 'No transactions',
            rus: 'Нет транзакций',
            ukr: 'Немає транзакцій',
            deu: 'Keine Transaktionen',
        },

        convert_pre_submit: {
            eng: 'Enter an amount',
            rus: 'Введите количество',
            ukr: 'Введіть кількість',
            deu: 'Geben Sie die Anzahl ein',
        },
        convert_submit: {
            eng: 'Convert',
            rus: 'Конвертировать',
            ukr: 'Конвертувати',
            deu: 'Umwandeln',
        },
        convert_balance: {
            eng: 'Balance:',
            rus: 'Баланс:',
            ukr: 'Баланс:',
            deu: 'Guthaben:',
        },
        convert_amount: {
            eng: 'Amount must be in range:',
            rus: 'Сумма должна быть в диапазоне:',
            ukr: 'Сума повинна бути в діапазоні:',
            deu: 'Betrag muss im Bereich liegen:',
        },
        convert_amount_min: {
            eng: 'Amount must be greater than:',
            rus: 'Сумма должна быть больше:',
            ukr: 'Сума повинна бути більше:',
            deu: 'Betrag muss größer sein als:',
        },
        convert_amount_max: {
            eng: 'Amount must be less than:',
            rus: 'Сумма должна быть меньше:',
            ukr: 'Сума повинна бути менше:',
            deu: 'Betrag muss kleiner sein als:',
        },
        convert_modal_title: {
            eng: 'Confirm',
            rus: 'Подтвердить',
            ukr: 'Підтвердити',
            deu: 'Bestätigen',
        },
        convert_modal_rate: {
            eng: 'Rate',
            rus: 'Курс',
            ukr: 'Курс',
            deu: 'Kurs',
        },
        convert_modal_inverse_rate: {
            eng: 'Inverse rate',
            rus: 'Обратный курс',
            ukr: 'Зворотній курс',
            deu: 'Umgekehrter Kurs',
        },
        convert_modal_fees: {
            eng: 'Transaction Fees',
            rus: 'Комиссия за транзакцию',
            ukr: 'Комісія за транзакцію',
            deu: 'Transaktionsgebühren',
        },
        convert_modal_no_fees: {
            eng: 'No Fees',
            rus: 'Без комиссии',
            ukr: 'Без комісії',
            deu: 'Keine Gebühren',
        },
        convert_modal_recive: {
            eng: 'You will receive',
            rus: 'Вы получите',
            ukr: 'Ви отримаєте',
            deu: 'Sie erhalten',
        },
        convert_modal_recive_success: {
            eng: 'You receive',
            rus: 'Вы получили',
            ukr: 'Ви отримали',
            deu: 'Sie haben erhalten',
        },
        convert_modal_confirm_btn: {
            eng: 'Confirm',
            rus: 'Подтвердить',
            ukr: 'Підтвердити',
            deu: 'Bestätigen',
        },
        convert_modal_refresh_btn: {
            eng: 'Refresh Quota',
            rus: 'Обновить квоту',
            ukr: 'Оновити квоту',
            deu: 'Quota aktualisieren',
        },
        convert_modal_balance: {
            eng: 'Your account has insufficient balance. Please fund your account',
            rus: 'На вашем счете недостаточно средств. Пожалуйста, пополните счет',
            ukr: 'На вашому рахунку недостатньо коштів. Будь ласка, поповніть рахунок',
            deu: 'Auf Ihrem Konto ist nicht genügend Guthaben vorhanden. Bitte füllen Sie Ihr Konto auf',
        },
        convert_modal_spent: {
            eng: 'You spent',
            rus: 'Вы потратили',
            ukr: 'Ви витратили',
            deu: 'Sie haben ausgegeben',
        },
        convert_modal_title_success: {
            eng: 'Successfully',
            rus: 'Успешно',
            ukr: 'Успішно',
            deu: 'Erfolgreich',
        },
        my_complex: {
            eng: 'My Complex',
            rus: 'Мои комплексы',
            ukr: 'Мої комплекси',
            deu: 'Mein Komplex',
        },
        all_complex: {
            eng: 'All Complex',
            rus: 'Все комплексы',
            ukr: 'Усі комплекси',
            deu: 'Gesamtkomplex',
        },
        common_complex: {
            eng: 'Common Complex',
            rus: 'Общий комплекс',
            ukr: 'Загальний комплекс',
            deu: 'Gemeinsamer Komplex',
        },
        name_of_the_complex: {
            eng: 'Name of the Complex',
            rus: 'Название комплекса',
            ukr: 'Назва комплексу',
            deu: 'Name des Komplexes',
        },
        enter_name_of_the_complex: {
            eng: 'Enter name of the Complex',
            rus: 'Введите название комплекса',
            ukr: 'Введіть назву комплексу',
            deu: 'Geben Sie den Namen des Komplexes ein',
        },
        type_of_the_complex: {
            eng: 'Type of the Complex',
            rus: 'Тип комплекса',
            ukr: 'Тип комплексу',
            deu: 'Art des Komplexes',
        },
        available: {
            eng: 'Available',
            rus: 'Доступно',
            ukr: 'Доступно',
            deu: 'Verfügbar',
        },
        photo_of_the_complex: {
            eng: 'Photo of the Complex*',
            rus: 'Фото комплекса*',
            ukr: 'Фото комплексу*',
            deu: 'Foto des Komplexes*',
        },
        main_photo_of_the_complex: {
            eng: 'Main photo of the Complex',
            rus: 'Главное фото комплекса',
            ukr: 'Головне фото комплексу',
            deu: 'Hauptfoto des Komplexes',
        },
        characteristics: {
            eng: 'Characteristics',
            rus: 'Характеристики',
            ukr: 'Характеристики',
            deu: 'Merkmale',
        },
        completion_date: {
            eng: 'Completion date',
            rus: 'Дата завершения',
            ukr: 'Дата завершення',
            deu: 'Fertigstellungsdatum',
        },
        number_of_blocks: {
            eng: 'Number of blocks',
            rus: 'Количество блоков',
            ukr: 'Кількість блоків',
            deu: 'Anzahl der Blöcke',
        },
        number_of_storeys: {
            eng: 'Number of storeys',
            rus: 'Количество этажей',
            ukr: 'Кількість поверхів',
            deu: 'Anzahl der Stockwerke',
        },
        number_of_apartments: {
            eng: 'Number of apartments',
            rus: 'Количество квартир',
            ukr: 'Кількість квартир',
            deu: 'Anzahl der Wohnungen',
        },
        construction_technology: {
            eng: 'Construction technology',
            rus: 'Технология строительства',
            ukr: 'Технологія будівництва',
            deu: 'Bau-Technologie',
        },
        wall_material: {
            eng: 'Wall material',
            rus: 'Материал стен',
            ukr: 'Матеріал стін',
            deu: 'Wandmaterial',
        },
        ceiling_height_m: {
            eng: 'Ceiling height (m)',
            rus: 'Высота потолков (м)',
            ukr: 'Висота стель (м)',
            deu: 'Deckenhöhe (m)',
        },
        winter_heating_system: {
            eng: 'Heating system',
            rus: 'Система отопления',
            ukr: 'Система опалення',
            deu: 'Heizsystem',
        },
        number_of_parking_spaces: {
            eng: 'Number of parking spaces',
            rus: 'Количество парковочных мест',
            ukr: 'Кількість паркувальних місць',
            deu: 'Anzahl der Parkplätze',
        },
        plans: {
            eng: 'Plans',
            rus: 'Планы',
            ukr: 'Плани',
            deu: 'Pläne',
        },
        floor_number: {
            eng: 'Floor Number',
            rus: 'Номер этажа',
            ukr: 'Номер поверху',
            deu: 'Stockwerksnummer',
        },
        drop_image_files_to_upload_or_click: {
            eng: 'Drop image files to upload (or click)',
            rus: 'Перетащите изображения для загрузки (или кликните)',
            ukr: 'Перетягніть зображення для завантаження (або клікніть)',
            deu: 'Bilddateien zum Hochladen ablegen (oder klicken)',
        },
        floor_plan: {
            eng: 'Floor plan',
            rus: 'План этажа',
            ukr: 'План поверху',
            deu: 'Grundriss',
        },
        maximum_number_of_files_10: {
            eng: 'Maximum number of files: 10',
            rus: 'Максимальное количество файлов: 10',
            ukr: 'Максимальна кількість файлів: 10',
            deu: 'Maximale Anzahl von Dateien: 10',
        },
        maximum_size_of_each_image_3mb: {
            eng: 'Maximum size of each image: 3Mb',
            rus: 'Максимальный размер каждого изображения: 3 Мб',
            ukr: 'Максимальний розмір кожного зображення: 3 Мб',
            deu: 'Maximale Größe jedes Bildes: 3Mb',
        },
        limit_translation: {
            eng: 'Limit:',
            rus: 'Лимит:',
            ukr: 'Ліміт:',
            deu: 'Limit:',
        },
        total_staked_translation: {
            eng: 'Total staked:',
            rus: 'Застейкано:',
            ukr: 'Застейкано:',
            deu: 'Gesamteinsatz:',
        },
        end_time_translation: {
            eng: 'End time',
            rus: 'Время окончания',
            ukr: 'Час закінчення',
            deu: 'Endzeit',
        },
        start_time_translation: {
            eng: 'Start time',
            rus: 'Время начала',
            ukr: 'Час початку',
            deu: 'Startzeit',
        },
        stake_translation: {
            eng: 'Stake',
            rus: 'Стейки',
            ukr: 'Стейкi',
            deu: 'Einsatz',
        },
        exclusive: {
            eng: 'Exclusive',
            rus: 'Эксклюзивный',
            ukr: 'Ексклюзивний',
            deu: 'Exklusiv',
        },
        unlimited: {
            eng: 'Unlimited',
            rus: 'Неограниченный',
            ukr: 'Необмежений',
            deu: 'Unbegrenzt',
        },
        earn: {
            eng: 'Earn',
            rus: 'Зарабатывать',
            ukr: 'Заробляти',
            deu: 'Verdienen',
        },
        at: {
            eng: 'at',
            rus: 'под',
            ukr: 'под',
            deu: 'bei',
        },
        max: {
            eng: 'MAX',
            rus: 'МАКС',
            ukr: 'МАКС',
            deu: 'MAX',
        },
        staking: {
            eng: 'Staking',
            rus: 'Стейкинг',
            ukr: 'Стейкінг',
            deu: 'Staking',
        },
        alternative_mining_translation: {
            eng: 'Looking for a less resource-intensive alternative to mining? Use your METRUM tokens to earn more tokens, for Free',
            rus: 'Ищете менее ресурсоемкую альтернативу майнингу? Используйте ваши токены METRUM, чтобы заработать больше токенов, бесплатно',
            ukr: 'Шукаєте менш ресурсомістку альтернативу майнінгу? Використовуйте ваші токени METRUM, щоб заробити більше токенів, безкоштовно',
            deu: 'Suchen Sie eine weniger ressourcenintensive Alternative zum Mining? Verwenden Sie Ihre METRUM-Token, um mehr Token kostenlos zu verdienen',
        },
        my_staking_history_translation: {
            eng: 'My Staking History',
            rus: 'История Моего Стейкинга',
            ukr: 'Історія Мого Стейкінгу',
            deu: 'Meine Staking-Geschichte',
        },
        add: {
            eng: 'Add',
            rus: 'Добавить',
            ukr: 'Додати',
            deu: 'Hinzufügen',
        },
        land_parcel_documents: {
            eng: 'Land parcel documents',
            rus: 'Документы на земельный участок',
            ukr: 'Документи на земельну ділянку',
            deu: 'Grundstücksdokumente',
        },
        construction_documents: {
            eng: 'Construction documents',
            rus: 'Документы на строительство',
            ukr: 'Документи на будівництво',
            deu: 'Baudokumente',
        },
        construction_participants: {
            eng: 'Construction participants',
            rus: 'Участники строительства',
            ukr: 'Учасники будівництва',
            deu: 'Bau-Teilnehmer',
        },
        add_complex: {
            eng: 'Add Complex',
            rus: 'Добавить комплекс',
            ukr: 'Додати комплекс',
            deu: 'Komplex hinzufügen',
        },

        convert_modal_wallet_btn: {
            eng: 'Back to wallet',
            rus: 'Вернуться в кошелек',
            ukr: 'Повернутися у гаманець',
            deu: 'Zurück zum Portemonnaie',
        },
        convert_modal_ok_btn: {
            eng: 'OK',
            rus: 'OK',
            ukr: 'OK',
            deu: 'OK',
        },
        information_not_found: {
            eng: 'Information not found',
            rus: 'Информация не найдена',
            ukr: 'Інформація не знайдена',
            deu: 'Information nicht gefunden',
        },
        unit_add_building_number: {
            eng: 'Building number',
            rus: 'Номер здания',
            ukr: 'Номер будівлі',
            deu: 'Gebäudenummer',
        },
        unit_add_entrance: {
            eng: 'Entrance',
            rus: 'Подъезд',
            ukr: "Під'їзд",
            deu: 'Eingang',
        },
        unit_add_conditions: {
            eng: 'Conditions',
            rus: 'Состояние:',
            ukr: 'Стан:',
            deu: 'Bedingungen',
        },
        unit_add_success: {
            eng: 'Unit added successfully',
            rus: 'Unit добавлен успешно',
            ukr: 'Unit додан успішно',
            deu: 'Unit erfolgreich hinzugefügt',
        },
        error_convert_fetch: {
            eng: 'Fetch error, try again later.',
            rus: 'Ошибка получения. Повторите попытку позже.',
            ukr: 'Помилка отримання, спробуйте пізніше.',
            deu: 'Fehler beim Abrufen, versuchen Sie es später noch einmal.',
        },
        error_convert_confirm: {
            eng: 'The exchange request has not been completed.',
            rus: 'Заявка на обмен не завершена.',
            ukr: 'Запит на обмін не виконано.',
            deu: 'Die Umtauschanfrage wurde nicht abgeschlossen.',
        },
        error_convert_wallet: {
            eng: 'Error getting wallet information.',
            rus: 'Ошибка получения информации о кошельке.',
            ukr: 'Помилка отримання інформації про гаманець.',
            deu: 'Fehler beim Abrufen von Wallet-Informationen.',
        },
        error_convert_pairs: {
            eng: 'Error getting pairs list.',
            rus: 'Ошибка получения списка пар.',
            ukr: 'Помилка отримання списку пар.',
            deu: 'Fehler beim Abrufen der Paarliste.',
        },
        error_tickets: {
            eng: 'Tickets not found',
            rus: 'Заявки не найдены',
            ukr: 'Заявки не знайдено',
            deu: 'Tickets nicht gefunden',
        },
        error_convert_rate: {
            eng: 'Error getting exchange rate.',
            rus: 'Ошибка получения курса.',
            ukr: 'Помилка отримання обмінного курсу.',
            deu: 'Fehler beim Abrufen des Wechselkurses.',
        },
        plans_translate: {
            eng: 'Plans',
            rus: 'Планы',
            ukr: 'Плани',
            deu: 'Pläne',
        },
        apartments_translate: {
            eng: 'Apartments',
            rus: 'Квартиры',
            ukr: 'Квартири',
            deu: 'Wohnungen',
        },
        news_translate: {
            eng: 'News',
            rus: 'Новости',
            ukr: 'Новини',
            deu: 'Nachrichten',
        },
        addApartment_translate: {
            eng: 'Add Apartment',
            rus: 'Добавить Квартиру',
            ukr: 'Додати Квартиру',
            deu: 'Wohnung Hinzufügen',
        },

        verification: {
            eng: 'Security Verification',
            rus: 'Верификация',
            ukr: 'Верифікація',
            deu: 'Verifizierung',
        },
        authenticator: {
            eng: 'Authenticator App',
            rus: 'Приложение-аутентификатор',
            ukr: 'Додаток-аутентифікатор',
            deu: 'Authenticator-App',
        },
        paste: {
            eng: 'Paste',
            rus: 'Вставить',
            ukr: 'Вставити',
            deu: 'Einfügen',
        },
        select_method: {
            eng: 'Select Method',
            rus: 'Выберите метод',
            ukr: 'Виберіть метод',
            deu: 'Methode auswählen',
        },
        enter_code: {
            eng: 'Enter the 6-digit code generated by the Authenticator App.',
            rus: 'Введите 6-значный код, сгенерированный приложением Authenticator.',
            ukr: 'Введіть 6-значний код, згенерований додатком Authenticator.',
            deu: 'Geben Sie den 6-stelligen Code ein, der von der Authenticator-App generiert wurde.',
        },
        factoring_next: {
            eng: 'Next',
            rus: 'Далее',
            ukr: 'Далі',
            deu: 'Weiter',
        },
        factoring_qr_text: {
            eng: 'Download Google Authenticator app and scan this QR code',
            rus: 'Загрузите приложение Google Authenticator и отсканируйте этот QR-код.',
            ukr: 'Завантажте програму Google Authenticator і відскануйте цей QR-код.',
            deu: 'Laden Sie die Google Authenticator-App herunter und scannen Sie diesen QR-Code.',
        },
        start_conversation_in_community: {
            eng: 'Start a conversation with someone in the platform community. All chats will appear here',
            rus: 'Начните разговор с кем-нибудь в сообществе платформы. Все чаты будут отображаться здесь',
            ukr: "Розпочніть розмову з кимось у спільноті платформи. Всі чати з'являться тут",
            deu: 'Beginnen Sie ein Gespräch mit jemandem in der Plattform-Community. Alle Chats werden hier angezeigt',
        },
        in_progress: {
            eng: 'In progress',
            rus: 'В процессе',
            ukr: 'В процесі',
            deu: 'In Bearbeitung',
        },
        agreement: {
            eng: 'Agreement',
            rus: 'Согласование',
            ukr: 'Узгодження',
            deu: 'Vereinbarung',
        },
        closed: {
            eng: 'Closed',
            rus: 'Закрыто',
            ukr: 'Закрито',
            deu: 'Geschlossen',
        },
        not_target: {
            eng: 'Not target',
            rus: 'Не целевой',
            ukr: 'Не цільовий',
            deu: 'Nicht Ziel',
        },
        failed: {
            eng: 'Failed',
            rus: 'Неудачно',
            ukr: 'Невдало',
            deu: 'Fehlgeschlagen',
        },

        recipient_translation: {
            eng: 'Recipient',
            rus: 'Получатель',
            ukr: 'Одержувач',
            deu: 'Empfänger',
        },
        bank_details_translation: {
            eng: 'Bank Details',
            rus: 'Банковские Реквизиты',
            ukr: 'Банківські Реквізити',
            deu: 'Bankdaten',
        },
        bank_translation: {
            eng: 'Bank',
            rus: 'Банк',
            ukr: 'Банк',
            deu: 'Bank',
        },
        hash_code_reference_translation: {
            eng: 'When performing an operation using this method, be sure to specify this hash code in the reference of the payment',
            rus: 'При выполнении операции этим методом, убедитесь, что вы указали этот хеш-код в назначении платежа',
            ukr: 'При виконанні операції цим методом, переконайтеся, що ви вказали цей хеш-код у призначенні платежу',
            deu: 'Wenn Sie eine Operation mit dieser Methode durchführen, stellen Sie sicher, dass Sie diesen Hash-Code in der Zahlungsreferenz angeben',
        },
        your_balance_is_translation: {
            eng: 'Your balance is',
            rus: 'Ваш баланс составляет',
            ukr: 'Ваш баланс становить',
            deu: 'Ihr Guthaben beträgt',
        },
        cost: {
            eng: 'Cost',
            rus: 'Стоимость',
            ukr: 'Вартість',
            deu: 'Kosten',
        },
        area: {
            eng: 'Area',
            rus: 'Площадь объекта',
            ukr: "Площа об'єкта",
            deu: 'Fläche',
        },
        brokerage_commission: {
            eng: 'Brokerage Commission',
            rus: 'Комиссия брокера',
            ukr: 'Комісія брокера',
            deu: 'Maklerprovision',
        },
        lead_modal_title: {
            eng: 'Add Lead',
            rus: 'Добавить Лид',
            ukr: 'Додати Лід',
            deu: 'Lead Hinzufügen',
        },
        lead_modal_customer_name: {
            eng: 'Enter full name',
            rus: 'Введите ФИО',
            ukr: 'Введіть ПІБ',
            deu: 'Vollständigen Namen eingeben',
        },
        lead_modal_customer_email: {
            eng: 'Enter email',
            rus: 'Введите email',
            ukr: 'Введіть email',
            deu: 'E-Mail eingeben',
        },
        lead_modal_customer_phone: {
            eng: 'Enter phone number',
            rus: 'Введите номер телефона',
            ukr: 'Введіть номер телефону',
            deu: 'Telefonnummer eingeben',
        },
        lead_modal_customer_comment: {
            eng: 'Comment',
            rus: 'Комментарий',
            ukr: 'Коментар',
            deu: 'Kommentar',
        },
        lead_modal_customer_proposed_terms: {
            eng: 'Proposed terms',
            rus: 'Предлагаемые клиентом условия',
            ukr: 'Запропоновані клієнтом умови',
            deu: 'Vom Kunden vorgeschlagene Bedingungen',
        },
        lead_modal_send: {
            eng: 'Send',
            rus: 'Отправить',
            ukr: 'Надіслати',
            deu: 'Senden',
        },
        lead_in: {
            eng: 'Lead in',
            rus: 'Полученный ЛИД',
            ukr: 'Отриманий лід',
            deu: 'Lead in',
        },
        lead_out: {
            eng: 'Lead out',
            rus: 'Переданный ЛИД',
            ukr: 'Передаваний лід',
            deu: 'Lead out',
        },
        count_of_rooms: {
            eng: 'Count of rooms',
            rus: 'Количество комнат',
            ukr: 'Кількість кімнат',
            deu: 'Anzahl der Zimmer',
        },
        floor_from: {
            eng: 'Floor from',
            rus: 'Этаж от',
            ukr: 'Поверх від',
            deu: 'Etage von',
        },
        floor_to: {
            eng: 'Floor to',
            rus: 'Этаж до',
            ukr: 'Поверх до',
            deu: 'Etage bis',
        },
        year_from: {
            eng: 'Year from',
            rus: 'Год от',
            ukr: 'Рік від',
            deu: 'Jahr von',
        },
        year_to: {
            eng: 'Year to',
            rus: 'Год до',
            ukr: 'Рік до',
            deu: 'Jahr bis',
        },
        split_contract: {
            eng: 'Commission agreement',
            rus: 'Договор о комиссии',
            ukr: 'Договір про комісію',
            deu: 'Provisionsvereinbarung',
        },
        split_commission_contract: {
            eng: 'Split commission contract',
            rus: 'Договор о разделении комиссии',
            ukr: 'Договір щодо розділу комісії',
            deu: 'Vertrag über die Aufteilung der Provision',
        },
        contract_details: {
            eng: 'Contract Details',
            rus: 'Детали договора',
            ukr: 'Деталі договору',
            deu: 'Vertragsdetails',
        },
        issue_date: {
            eng: 'Issue Date:',
            rus: 'Дата выдачи:',
            ukr: 'Дата видачі:',
            deu: 'Ausstellungsdatum',
        },
        starting_sale_price: {
            eng: 'Starting Sale Price:',
            rus: 'Начальная цена продажи:',
            ukr: 'Початкова ціна продажу:',
            deu: 'Startverkaufspreis',
        },
        final_sale_price: {
            eng: 'Final Sale Price:',
            rus: 'Конечная цена продажи:',
            ukr: 'Кінцева ціна продажу:',
            deu: 'Endverkaufspreis',
        },
        payment_due_days: {
            eng: 'Payment Due Days:',
            rus: 'Срок оплаты:',
            ukr: 'Термін оплати:',
            deu: 'Zahlung fällig in Tagen',
        },
        additional_terms: {
            eng: 'Additional Terms:',
            rus: 'Дополнительные условия:',
            ukr: 'Додаткові умови:',
            deu: 'Zusätzliche Bedingungen',
        },
        reward: {
            eng: 'Reward:',
            rus: 'Вознаграждение:',
            ukr: 'Винагорода:',
            deu: 'Belohnung',
        },
        contract_pdf: {
            eng: 'Contract PDF:',
            rus: 'Контракт в формате PDF:',
            ukr: 'Контракт у форматі PDF:',
            deu: 'Vertrags-PDF',
        },
        status_history: {
            eng: 'Status History:',
            rus: 'История статусов:',
            ukr: 'Історія статусів:',
            deu: 'Statushistorie',
        },
        changed_at: {
            eng: 'Changed At',
            rus: 'Изменено',
            ukr: 'Змінено',
            deu: 'Geändert am',
        },
        show: {
            eng: 'Show',
            rus: 'Показать',
            ukr: 'Показати',
            deu: 'Anzeigen',
        },
        copy_landing_link: {
            eng: 'Copy landing link',
            rus: 'Копировать ссылку на лендинг',
            ukr: 'Копіювати посилання на лендінг',
            deu: 'Landing-Link kopieren',
        },
        views: {
            eng: 'Views:',
            rus: 'Просмотры:',
            ukr: 'Перегляди:',
            deu: 'Ansichten:',
        },
        get_object_landing: {
            eng: 'Get object landing',
            rus: 'Получить лендинг объекта',
            ukr: "Отримати лендінг об'єкта",
            deu: 'Objekt-Landing erhalten',
        },
        get_object_landing_broker_agreement_text: {
            eng: 'You can only pass potential clients (leads) after signing',
            rus: 'Передавать потенциальных клиентов (лидов) можно только после подписания',
            ukr: 'Передавати потенційних клієнтів (лідів) можна тільки після підписання',
            deu: 'Sie können potenzielle Kunden (Leads) nur nach der Unterzeichnung weitergeben',
        },
        get_object_landing_broker_agreement_link: {
            eng: ' broker agreement',
            rus: ' договора о комиссии',
            ukr: ' договору про комісію',
            deu: ' Maklervertrag',
        },
        create_new_contract: {
            eng: 'Create new contract',
            rus: 'Создать новый контракт',
            ukr: 'Створити новий контракт',
            deu: 'Neuen Vertrag erstellen',
        },
        property_city: {
            eng: 'City',
            rus: 'Город',
            ukr: 'місто',
            deu: 'Stadt',
        },
        property_city_placeholder: {
            eng: 'Enter city',
            rus: 'Введите город',
            ukr: 'Введіть місто',
            deu: 'Stadt eingeben',
        },
        hide_extra_filters: {
            eng: 'Hide extra filters',
            rus: 'Скрыть дополнительные фильтры',
            ukr: 'Приховати додаткові фільтри',
            deu: 'Zusätzliche Filter ausblenden',
        },
        show_extra_filters: {
            eng: 'Show extra filters',
            rus: 'Показать дополнительные фильтры',
            ukr: 'Показати додаткові фільтри',
            deu: 'Zusätzliche Filter anzeigen',
        },
        property_district: {
            eng: 'District',
            rus: 'Район',
            ukr: 'Район',
            deu: 'Bezirk',
        },
        property_district_placeholder: {
            eng: 'Enter district',
            rus: 'Введите район',
            ukr: 'Введіть район',
            deu: 'Bezirk eingeben',
        },
        property_sub_type: {
            eng: 'Type',
            rus: 'Тип',
            ukr: 'Тип',
            deu: 'Typ',
        },
        keywords: {
            eng: 'Keywords',
            rus: 'Ключевые слова',
            ukr: 'Ключові слова',
            deu: 'Schlüsselwörter',
        },
        manual_withdraw: {
            eng: 'Manual withdraw',
            rus: 'Ручной вывод',
            ukr: 'Ручний вивід',
            deu: 'Manuelle Auszahlung',
        },
        manual_withdraw_desc: {
            eng: "Didn't find the right way? Submit a withdrawal request",
            rus: 'Не нашли подходящий способ? Подайте запрос на вывод',
            ukr: 'Не знайшли відповідний спосіб? Подайте запит на вивід',
            deu: 'Den richtigen Weg nicht gefunden? Stellen Sie einen Auszahlungsantrag',
        },
        no_data: {
            eng: 'No data',
            rus: 'Нет данных',
            ukr: 'Немає даних',
            deu: 'Keine Daten',
        },
        leads: {
            eng: 'Leads',
            rus: 'Лиды',
            ukr: 'Ліди',
            deu: 'Leads',
        },
        crm_finalize_info: {
            eng: 'Lead information',
            rus: 'Информация о лиде',
            ukr: 'Інформація про лід',
            deu: 'Lead-Informationen',
        },
        crm_finalize_object_info: {
            eng: 'Object information',
            rus: 'Информация об объекте',
            ukr: 'Інформація про об\'єкт',
            deu: 'Objektinformationen',
        },
        crm_finalize_sub_title: {
            eng: 'Please specify the final sale amount of the object',
            rus: 'Укажите финальную сумму продажи объекта',
            ukr: 'Вкажіть кінцеву суму продажу об\'єкта',
            deu: 'Bitte geben Sie den endgültigen Verkaufspreis des Objekts an',
        },
        crm_finalize_final_summ: {
            eng: 'Final sale amount',
            rus: 'Финальная сумма продажи',
            ukr: 'Кінцева сума продажу',
            deu: 'Endgültiger Verkaufspreis',
        },
        crm_finalize_text_1: {
            eng: 'According to',
            rus: 'Согласно',
            ukr: 'Згідно з',
            deu: 'Gemäß',
        },
        crm_finalize_text_2: {
            eng: 'commission sharing agreement',
            rus: 'договору о разделении комиссии',
            ukr: 'договором про розділ комісії',
            deu: 'Provisionsvereinbarung',
        },
        crm_finalize_text_3: {
            eng: 'you agree to pay GREM platform a commission for the received lead',
            rus: 'за полученного лида вы обязуетесь выплатить платформе GREM комиссию',
            ukr: 'за отриманого ліда ви зобов\'язуєтеся виплатити платформі GREM комісію',
            deu: 'für den erhaltenen Lead verpflichten Sie sich, der GREM-Plattform eine Provision zu zahlen.',
        },
        crm_finalize_text_4: {
            eng: 'in the amount of',
            rus: 'в размере',
            ukr: 'в розмірі',
            deu: 'in Höhe von',
        },
        crm_finalize_total_title: {
            eng: 'Commission payable to GREM',
            rus: 'Комиссия к выплате GREM',
            ukr: 'Комісія до виплати GREM',
            deu: 'An GREM zu zahlende Provision'
        },
        crm_finalize_button_cancel: {
            eng: 'Cancel',
            rus: 'Отмена',
            ukr: 'Скасувати',
            deu: 'Stornieren',
        },
        crm_finalize_button_submit: {
            eng: 'Finalize deal',
            rus: 'Подтверждаю сделку',
            ukr: 'Підтверджую угоду',
            deu: 'Geschäft abschließen',
        },
        crm_finalize_problem: {
            eng: 'Report a problem',
            rus: 'Сообщить о проблеме',
            ukr: 'Повідомити про проблему',
            deu: 'Сообщить о проблеме'
        },
        crm_finalize_title: {
            eng: 'Are you confirming that the transaction closed successfully?',
            rus: 'Вы подтверждаете, что сделка успешно закрыта?',
            ukr: 'Ви підтверджуєте, що угоду успішно закрито?',
            deu: 'Bestätigen Sie, dass die Transaktion erfolgreich abgeschlossen wurde?'
        },
        crm_email: {
            eng: 'Email',
            rus: 'Email',
            ukr: 'Email',
            deu: 'E-Mail',
        },
        crm_phone: {
            eng: 'Phone number',
            rus: 'Номер телефона',
            ukr: 'Номер телефону',
            deu: 'Telefonnummer',
        },
        crm_comments: {
            eng: 'Comments',
            rus: 'Комментарии',
            ukr: 'Коментарі',
            deu: 'Kommentare',
        },
        crm_other: {
            eng: 'Other',
            rus: 'Другое',
            ukr: 'Інше',
            deu: 'Andere',
        },
        crm_broker_comments: {
            eng: 'Broker comments',
            rus: 'Комментарии брокера',
            ukr: 'Коментарі брокера',
            deu: 'Brokerkommentare',
        },
        crm_proposed_terms: {
            eng: 'Proposed terms',
            rus: 'Предложенные условия',
            ukr: 'Запропоновані умови',
            deu: 'Vorgeschlagene Bedingungen',
        },
        lead_form_type_complex: {
            eng: 'New building supermarket',
            rus: 'Супермаркет новостроек',
            ukr: 'Супермаркет новобудов',
            deu: 'Supermarkt für Neubauten',
        },
        lead_form_type_property: {
            eng: 'Real estate',
            rus: 'Недвижимость',
            ukr: 'Нерухомість',
            deu: 'Immobilien',
        },
        lead_form_type_transfer: {
            eng: 'Transfer lead to a community member',
            rus: 'Передать лида участнику сообщества',
            ukr: 'Передати ліда учаснику спільноти',
            deu: 'Lead an ein Gemeinschaftsmitglied übergeben',
        },
        lead_form_input_object: {
            eng: 'Real estate object',
            rus: 'Объект недвижимости',
            ukr: 'Об\'єкт нерухомості',
            deu: 'Immobilienobjekt',
        },
        lead_form_input_user: {
            eng: 'Customer name',
            rus: 'Имя и фамилия клиента',
            ukr: 'Ім\'я та прізвище клієнта',
            deu: 'Kundenname',
        },
        lead_form_input_phone: {
            eng: 'Customer phone number',
            rus: 'Номер телефона клиента',
            ukr: 'Номер телефону клієнта',
            deu: 'Kunden-Telefonnummer',
        },
        lead_form_input_email: {
            eng: 'Customer email',
            rus: 'Электронная почта',
            ukr: 'Електронна пошта',
            deu: 'Kunden-E-Mail',
        },
        lead_form_input_budget: {
            eng: 'Client\'s budget',
            rus: 'Бюджет клиента',
            ukr: 'Бюджет клієнта',
            deu: 'Kundenbudget',
        },
        lead_form_input_country: {
            eng: 'Country where the client is located',
            rus: 'Страна где находится клиент',
            ukr: 'Країна, де знаходиться клієнт',
            deu: 'Land, in dem sich der Kunde befindet',
        },
        lead_form_input_messenger: {
            eng: 'Client\'s messenger',
            rus: 'Мессенджер клиента',
            ukr: 'Месенджер клієнта',
            deu: 'Kundenmessenger',
        },
        lead_form_input_notes: {
            eng: 'Note (specify conditions and wishes of the client)',
            rus: 'Примечание (укажите условия и пожелания клиента)',
            ukr: 'Примітка (вкажіть умови та побажання клієнта)',
            deu: 'Hinweis (geben Sie die Bedingungen und Wünsche des Kunden an)',
        },
        lead_form_send_lead: {
            eng: 'Send lead',
            rus: 'Передать лида',
            ukr: 'Передати ліда',
            deu: 'Lead senden',
        },
        lead_form_send_lead_info_1: {
            eng: 'Please note that the transmissions you make',
            rus: 'Обратите внимание, что передаваемые вами',
            ukr: 'Зверніть увагу, що передавані вами',
            deu: 'Bitte beachten Sie, dass die Übertragungen, die Sie machen',
        },
        lead_form_send_lead_info_2: {
            eng: ' potential customers (leads) need to be processed:',
            rus: ' потенциальные клиенты (лиды) требуют обработки:',
            ukr: ' потенційні клієнти (ліди) потребують обробки:',
            deu: ' potenzielle Kunden (Leads) müssen verarbeitet werden:',
        },
        lead_form_send_lead_info_3: {
            eng: ' identification of needs, budget assessment and verification of contact details. Commission is paid for a successfully completed deal with a well-processed contact',
            rus: ' выявление потребностей, оценка бюджета и проверка корректности контактных данных. Комиссионное вознаграждение выплачивается за успешно завершенную сделку с хорошо обработанным вами контактом',
            ukr: ' виявлення потреб, оцінка бюджету та перевірка коректності контактних даних. Комісійне винагородження виплачується за успішно завершену угоду з добре обробленим вами контактом',
            deu: ' Bedarfsidentifikation, Budgetbewertung und Überprüfung der Kontaktdaten. Die Provision wird für einen erfolgreich abgeschlossenen Deal mit einem gut verarbeiteten Kontakt gezahlt',
        },
        crm_title: {
            eng: 'CRM system',
            rus: 'CRM-система',
            ukr: 'CRM-система',
            deu: 'CRM-System',
        },
        crm_lead_income: {
            eng: 'Income lead',
            rus: 'Полученный лид',
            ukr: 'Отриманий лід',
            deu: 'Lead-Einkommen',
        },
        crm_lead_outcome: {
            eng: 'Outcome lead',
            rus: 'Переданный Лид',
            ukr: 'Переданий лід',
            deu: 'Lead-Ergebnis',
        },
        crm_column_lead_counter: {
            eng: 'leads',
            rus: 'лидов',
            ukr: 'лідів',
            deu: 'Leads',
        },
        crm_lead_details_created_at: {
            eng: 'Lead created at:',
            rus: 'Лид создан:',
            ukr: 'Лід створено:',
            deu: 'Lead erstellt am:',
        },
        crm_lead_details_updated_at: {
            eng: 'Lead updated at:',
            rus: 'Лид обновлен:',
            ukr: 'Лід оновлено:',
            deu: 'Lead aktualisiert am:',
        },
        crm_lead_details_title: {
            eng: 'Lead information',
            rus: 'Информация о лиде',
            ukr: 'Інформація про лід',
            deu: 'Lead-Informationen',
        },
        crm_lead_details_budget: {
            eng: 'Budget',
            rus: 'Бюджет',
            ukr: 'Бюджет',
            deu: 'Budget',
        },
        crm_lead_details_country: {
            eng: 'Country of residence',
            rus: 'Страна нахождения',
            ukr: 'Країна проживання',
            deu: 'Land des Wohnsitzes',
        },
        crm_lead_details_client_messenger: {
            eng: 'Client\'s messenger',
            rus: 'Мессенджер клиента',
            ukr: 'Месенджер клієнта',
            deu: 'Kundenmessenger',
        },
        crm_lead_details_client_message: {
            eng: 'Client comments',
            rus: 'Комментарии клиента',
            ukr: 'Коментарі клієнта',
            deu: 'Kundenkommentare',
        },
        crm_lead_details_broker_message: {
            eng: 'Broker comments',
            rus: 'Комментарии брокера:',
            ukr: 'Коментарі брокера:',
            deu: 'Brokerkommentare:',
        },
        crm_lead_details_conditions: {
            eng: 'Suggested conditions:',
            rus: 'Предложенные условия:',
            ukr: 'Запропоновані умови:',
            deu: 'Vom Kunden vorgeschlagene Bedingungen:',
        },
        crm_lead_details_comments_title: {
            eng: 'Comments:',
            rus: 'Комментарии:',
            ukr: 'Коментарі:',
            deu: 'Kommentare:',
        },
        crm_lead_details_comment: {
            eng: 'Comment',
            rus: 'Комментарий',
            ukr: 'Коментар',
            deu: 'Kommentar',
        },
        crm_lead_details_comment_send: {
            eng: 'Send',
            rus: 'Отправить',
            ukr: 'Надіслати',
            deu: 'Senden',
        },
        community_send_message: {
            eng: 'Send Message',
            rus: 'Отправить сообщение',
            ukr: 'Надіслати повідомлення',
            deu: 'Nachricht senden',
        },

        contract_alert_fields: {
            eng: 'All fields must be completed in English!',
            rus: 'Все поля должны быть заполнены на английском языке!',
            ukr: 'Всі поля повинні бути заповнені англійською мовою!',
            deu: 'Alle Felder müssen in Englisch ausgefüllt werden!',
        },
        contract_alert_read: {
            eng: 'Read the text of the agreement, define the values and click "Create Contract" button',
            rus: 'Прочтите текст соглашения, укажите значения и нажмите кнопку "Создать контракт"',
            ukr: 'Прочитайте текст угоди, визначте значення і натисніть кнопку "Створити контракт"',
            deu: 'Lesen Sie den Text des Vertrags, definieren Sie die Werte und klicken Sie auf die Schaltfläche "Vertrag erstellen"',
        },
        contract_add_title: {
            eng: 'Agreement on the division of commission',
            rus: 'Соглашение о разделении комиссии',
            ukr: 'Угода про розподіл комісії',
            deu: 'Vereinbarung über die Aufteilung der Provision',
        },
        contract_add_object: {
            eng: 'The object of the agreement:',
            rus: 'Объект соглашения:',
            ukr: "Об'єкт угоди:",
            deu: 'Der Gegenstand der Vereinbarung:',
        },
        contract_add_object_name: {
            eng: 'Object name:',
            rus: 'Название объекта:',
            ukr: "Назва об'єкта:",
            deu: 'Objektname:',
        },
        contract_add_object_start_price_label: {
            eng: 'The starting cost of the facility is equivalent:',
            rus: 'Начальная стоимость объекта в эквиваленте:',
            ukr: "Початкова вартість об'єкта в еквіваленті:",
            deu: 'Der Startpreis des Objekts entspricht:',
        },
        contract_add_object_start_price: {
            eng: 'Start price per unit',
            rus: 'Стартовая стоимость юнита',
            ukr: 'Початкова вартість за одиницю',
            deu: 'Startpreis pro Einheit',
        },
        contract_add_object_address: {
            eng: 'Full address:',
            rus: 'Полный адрес:',
            ukr: 'Повна адреса:',
            deu: 'Vollständige Adresse:',
        },
        contract_add_object_floors: {
            eng: 'Floors:',
            rus: 'Этажность:',
            ukr: 'Кількість поверхів:',
            deu: 'Etagen:',
        },
        contract_add_object__count_floors: {
            eng: 'Count of floors',
            rus: 'Количество этажей',
            ukr: 'Кількість поверхів',
            deu: 'Anzahl der Etagen',
        },
        contract_add_object_total_area: {
            eng: 'Total area in sq.m.:',
            rus: 'Общая площадь в кв.м.:',
            ukr: 'Загальна площа в кв.м.:',
            deu: 'Gesamtfläche in qm:',
        },
        contract_add_object_status: {
            eng: 'Status of object: *',
            rus: 'Статус объекта: *',
            ukr: "Статус об'єкта: *",
            deu: 'Status des Objekts: *',
        },
        contract_add_object_status_under: {
            eng: 'Under construction',
            rus: 'В процессе строительства',
            ukr: 'У процесі будівництва',
            deu: 'Im Bau',
        },
        contract_add_object_status_entered: {
            eng: 'Entered into operation',
            rus: 'Введено в эксплуатацию',
            ukr: 'Введено в експлуатацію',
            deu: 'In Betrieb genommen',
        },
        contract_add_service: {
            eng: 'General info',
            rus: 'Общая информация',
            ukr: 'Загальна інформація',
            deu: 'Allgemeine Informationen',
        },
        contract_add_service_number: {
            eng: 'Contract Number',
            rus: 'Номер договора',
            ukr: 'Номер угоди',
            deu: 'Vertragsnummer',
        },
        contract_add_service_issue: {
            eng: 'Issue Date',
            rus: 'Дата выдачи',
            ukr: 'Дата видачі',
            deu: 'Ausstellungsdatum',
        },
        contract_add_service_payment: {
            eng: 'Payment Due Days',
            rus: 'Дни оплаты',
            ukr: 'Дні оплати',
            deu: 'Zahlung fällig in Tagen',
        },
        contract_add_service_payment_helper: {
            eng: "Working days after receiving payment from the Buyer to your account",
            rus: 'Рабочих дней после поступления денежных средств от Покупателя на ваш счет',
            ukr: 'Робочих днів після надходження коштів від Покупця на ваш рахунок',
            deu: 'Arbeitstage nach Erhalt der Zahlung vom Käufer auf Ihr Konto',
        },
        contract_add_service_penalty: {
            eng: 'Penalty Amount (%)',
            rus: 'Размер пени (%)',
            ukr: 'Розмір пені (%)',
            deu: 'Strafgebühr Betrag (%)',
        },
        contract_add_member: {
            eng: 'Parties to the agreement',
            rus: 'Стороны соглашения',
            ukr: 'Сторони угоди',
            deu: 'Vertragsparteien',
        },
        contract_add_member_side_1: {
            eng: 'Side 1',
            rus: 'Сторона 1',
            ukr: 'Сторона 1',
            deu: 'Seite 1',
        },
        contract_add_member_side_2: {
            eng: 'Side 2 - Platform',
            rus: 'Сторона 2 - Площадка',
            ukr: 'Сторона 2 - Платформа',
            deu: 'Seite 2 - Plattform',
        },
        contract_add_member_company_name: {
            eng: 'Company name',
            rus: 'Название компании',
            ukr: 'Назва компанії',
            deu: 'Firmenname',
        },
        contract_add_member_person_name: {
            eng: 'Company person',
            rus: 'Лицо компании',
            ukr: 'Особа компанії',
            deu: 'Person des Unternehmens',
        },
        contract_add_member_company_address: {
            eng: 'Company address',
            rus: 'Адрес компании',
            ukr: 'Адреса компанії',
            deu: 'Firmenadresse',
        },
        contract_add_member_registration_number: {
            eng: 'Registration Number',
            rus: 'Регистрационный номер',
            ukr: 'Реєстраційний номер',
            deu: 'Registriernummer',
        },
        contract_add_member_contact_full_name: {
            eng: 'Contact Full Name',
            rus: 'ФИО контактного лица',
            ukr: "Повне ім'я контактної особи",
            deu: 'Kontakt Vollständiger Name',
        },
        contract_add_member_acting_based: {
            eng: 'Acting based on',
            rus: 'Действующий на основании',
            ukr: 'Діючий на підставі',
            deu: 'Handeln auf der Grundlage von',
        },
        contract_add_member_company: {
            eng: 'Company',
            rus: 'Компания',
            ukr: 'Компанія',
            deu: 'Unternehmen',
        },
        contract_add_member_person: {
            eng: 'Private individual',
            rus: 'Частное лицо',
            ukr: 'Приватна особа',
            deu: 'Privatperson',
        },
        contract_add_member_person_fname: {
            eng: 'Name',
            rus: 'Имя',
            ukr: "Ім'я",
            deu: 'Name',
        },
        contract_add_member_person_lname: {
            eng: 'Last name',
            rus: 'Фамилия',
            ukr: 'Прізвище',
            deu: 'Nachname',
        },
        contract_add_member_person_passport: {
            eng: 'Passport Number',
            rus: '№ паспорта',
            ukr: '№ паспорту',
            deu: 'Reisepassnummer',
        },
        contract_add_member_person_passport_issued: {
            eng: 'Issued By',
            rus: 'Кем выдан',
            ukr: 'Ким виданий',
            deu: 'Ausgestellt von',
        },

        contract_add_member_person_passport_date: {
            eng: 'Issue Date',
            rus: 'Дата выдачи',
            ukr: 'Дата видачі',
            deu: 'Ausstellungsdatum',
        },
        contract_add_member_person_passport_address: {
            eng: 'Passport Address',
            rus: 'Адрес по паспорту',
            ukr: 'Адреса за паспортом',
            deu: 'Adresse im Reisepass',
        },
        contract_add_member_person_address: {
            eng: 'Living Address',
            rus: 'Адрес проживания',
            ukr: 'Адреса проживання',
            deu: 'Wohnadresse',
        },
        contract_add_member_person_acting_base: {
            eng: 'Acting Based',
            rus: 'На основе действия',
            ukr: 'На підставі дії',
            deu: 'Aufgrund von Handeln',
        },
        contract_add_member_person_email: {
            eng: 'Email',
            rus: 'Электронная почта',
            ukr: 'Електронна пошта',
            deu: 'E-Mail',
        },
        contract_add_reward: {
            eng: 'Reward',
            rus: 'Вознаграждение',
            ukr: 'Винагорода',
            deu: 'Belohnung',
        },
        contract_add_reward_broker: {
            eng: 'Broker commission:',
            rus: 'Комиссия брокера:',
            ukr: 'Комісія брокера:',
            deu: 'Maklerprovision:',
        },
        contract_add_reward_currency: {
            eng: 'Currency',
            rus: 'Валюта',
            ukr: 'Валюта',
            deu: 'Währung',
        },
        contract_add_reward_type: {
            eng: 'Reward Type',
            rus: 'Тип вознаграждения',
            ukr: 'Тип винагороди',
            deu: 'Belohnungstyp',
        },
        contract_add_reward_type_percent: {
            eng: 'Percentage',
            rus: 'Процент',
            ukr: 'Відсоток',
            deu: 'Prozentsatz',
        },
        contract_add_reward_value: {
            eng: 'Reward percentage (%)',
            rus: 'Процент вознаграждения (%)',
            ukr: 'Відсоток винагороди (%)',
            deu: 'Belohnungsprozentsatz (%)',
        },
        contract_add_reward_terms: {
            eng: 'Additional Terms',
            rus: 'Дополнительные условия',
            ukr: 'Додаткові умови',
            deu: 'Zusätzliche Bedingungen',
        },
        contract_add_create: {
            eng: 'Create Contract',
            rus: 'Создать контракт',
            ukr: 'Створити контракт',
            deu: 'Vertrag erstellen',
        },
        property_add_common_apartment_desc_ind: {
            eng: 'Individual apartment',
            rus: 'Индивидуальная квартира',
            ukr: 'Індивідуальна квартира',
            deu: 'Individuelle Wohnung',
        },
        property_add_common_apartment_desc_comm: {
            eng: 'Communal flat',
            rus: 'Общежитие',
            ukr: 'Гуртожиток',
            deu: 'Gemeinschaftswohnung',
        },
        validate_empty: {
            eng: 'Field is empty',
            rus: 'Поле пусто',
            ukr: 'Поле порожнє',
            deu: 'Feld ist leer',
        },
        validate_email: {
            eng: 'Invalid email',
            rus: 'Не валидный E-mail',
            ukr: 'Неправильний електронний адрес',
            deu: 'Ungültige E-Mail',
        },
        validate_phone: {
            eng: 'Invalid phone number',
            rus: 'Неверный номер телефона',
            ukr: 'Неправильний номер телефону',
            deu: 'Ungültige Telefonnummer',
        },
        validate_wrong_language: {
            eng: 'Enter data in English',
            rus: 'Введите данные на Английском',
            ukr: 'Введіть дані Англійською',
            deu: 'Geben Sie Daten in Englisch ein',
        },
        validate_form: {
            eng: 'Form validation error',
            rus: 'Ошибка валидации формы',
            ukr: 'Помилка валідації форми',
            deu: 'Formularvalidierungsfehler',
        },
        complex: {
            eng: 'Complex',
            rus: 'Комплекс',
            ukr: 'Комплекс',
            deu: 'Komplex',
        },
        loaded: {
            eng: 'Loaded',
            rus: 'Загружено',
            ukr: 'Завантажено',
            deu: 'Geladen',
        },
        complexes: {
            eng: 'Complexes',
            rus: 'Комплексов',
            ukr: 'Комплексів',
            deu: 'Komplexe',
        },
        complex_expluatation_date: {
            eng: 'Commissioning',
            rus: 'Ввод в эксплуатацию',
            ukr: 'Введення в експлуатацію',
            deu: 'Inbetriebnahme',
        },
        complexes_photo_title: {
            eng: 'Property Photos',
            rus: 'Фотографии объекта',
            ukr: 'Фотографії об\'єкта',
            deu: 'Immobilienfotos'
        },
        complexes_logo_title: {
            eng: 'Residential Complex Logo',
            rus: 'Логотип жилого комплекса',
            ukr: 'Логотип житлового комплексу',
            deu: 'Logo des Wohnkomplexes'
        },
        complexes_documents_land_title: {
            eng: 'Land Documents',
            rus: 'Документы на земельный участок',
            ukr: 'Документи на земельну ділянку',
            deu: 'Grundstücksunterlagen'
        },
        complexes_documents_participants_title: {
            eng: 'Construction Documents',
            rus: 'Документы на строительство',
            ukr: 'Документи на будівництво',
            deu: 'Bauunterlagen'
        },
        complexes_documents_construction_title: {
            eng: 'Construction Participants',
            rus: 'Участники строительства',
            ukr: 'Учасники будівництва',
            deu: 'Bauherren'
        },
        complexes_photo_sub_title: {
            eng: 'The more photos and other materials you provide, the higher the likelihood of completing a deal and, as a result, your income',
            rus: 'Чем больше фото и других материалов вы предоставите, тем выше вероятность совершения сделки и, как следствие, ваш доход',
            ukr: 'Чим більше фото та інших матеріалів ви надаєте, тим вища ймовірність укладення угоди і, відповідно, ваш дохід',
            deu: 'Je mehr Fotos und andere Materialien Sie bereitstellen, desto höher ist die Wahrscheinlichkeit, dass ein Geschäft abgeschlossen wird, und damit Ihr Einkommen'
        },
        complexes_logo_sub_title: {
            eng: 'We recommend uploading the logo in PNG format. You can also add several versions of the logo, for example vertical, horizontal, or in different color schemes',
            rus: 'Рекомендуем загрузить логотип в формате PNG. Также вы можете добавить несколько вариантов начертания логотипа, например вертикальный, горизонтальный или в разных цветовых гаммах',
            ukr: 'Рекомендуємо завантажити логотип у форматі PNG. Ви також можете додати кілька варіантів оформлення логотипу, наприклад вертикальний, горизонтальний або в різних кольорових гаммах',
            deu: 'Wir empfehlen, das Logo im PNG-Format hochzuladen. Sie können auch mehrere Versionen des Logos hinzufügen, z.B. vertikal, horizontal oder in verschiedenen Farbschemata'
        },
        complexes_min_key: {
            eng: 'property photos',
            rus: 'фотографий объектов',
            ukr: 'фотографій об\'єктів',
            deu: 'Immobilienfotos'
        },
        complexes_documents_min_key: {
            eng: 'photos',
            rus: 'фото',
            ukr: 'фото',
            deu: 'Fotos'
        }, photos_loader_set_general: {
            eng: 'Set as main',
            rus: 'сделать основным',
            ukr: 'зробити основним',
            deu: 'Als Hauptfoto festlegen',
        },
        photos_loader_general: {
            eng: 'Main photo',
            rus: 'основное фото',
            ukr: 'основне фото',
            deu: 'Hauptfoto',
        },
        photos_loader_upload: {
            eng: 'Upload photo',
            rus: 'Загрузите фото',
            ukr: 'Завантажте фото',
            deu: 'Foto hochladen',
        },
        photos_loader_upload_document: {
            eng: 'Upload document',
            rus: 'Загрузить документ',
            ukr: 'Завантажити документ',
            deu: 'Dokument hochladen',
        },
        photo_loader_drop: {
            eng: 'or drag photo here',
            rus: 'или перетащите фото сюда',
            ukr: 'або перетягніть фото сюди',
            deu: 'oder ziehen Sie das Foto hierher',
        },
        photo_loader_drop_document: {
            eng: 'Drop photo here or',
            rus: 'Перетащите фото сюда или',
            ukr: 'Перетягніть фото сюди або',
            deu: 'Foto hier ablegen oder',
        },
        photo_loader_drop_file: {
            eng: 'Drop file here or',
            rus: 'Перетащите файл сюда или',
            ukr: 'Перетягніть файл сюди або',
            deu: 'Datei hier ablegen oder',
        },
        photo_loader_formats: {
            eng: 'Recommended format: jpeg/jpg',
            rus: 'Рекомендуемый формат: jpeg/jpg',
            ukr: 'Рекомендований формат: jpeg/jpg',
            deu: 'Empfohlenes Format: jpeg/jpg',
        },
        photo_loader_file_count: {
            eng: 'Add no more than',
            rus: 'Добавьте не более',
            ukr: 'Додайте не більше',
            deu: 'Fügen Sie nicht mehr als',
        },
        photo_loader_file_count_min: {
            eng: 'Add at least',
            rus: 'Добавьте не менее',
            ukr: 'Додайте щонайменше',
            deu: 'Fügen Sie mindestens',
        },
        photo_loader_file_size: {
            eng: 'Maximum size of one photo',
            rus: 'Максимальный размер одного фото',
            ukr: 'Максимальний розмір одного фото',
            deu: 'Maximale Größe eines Fotos',
        },
        stepper_send: {
            eng: 'Send',
            rus: 'Отправить',
            ukr: 'Надіслати',
            deu: 'Senden',
        },
        stepper_next: {
            eng: 'Next',
            rus: 'Далее',
            ukr: 'Далі',
            deu: 'Weiter',
        },
        stepper_back: {
            eng: 'Previous step',
            rus: 'Предыдущий шаг',
            ukr: 'Попередній крок',
            deu: 'Vorheriger Schritt',
        },
        complex_common_title: {
            eng: 'Main parameters',
            rus: 'Основные параметры',
            ukr: 'Основні параметри',
            deu: 'Hauptparameter',
        },
        complex_common_available: {
            eng: 'The object is available for viewing',
            rus: 'Объект доступен для просмотра',
            ukr: 'Об`єкт доступний для перегляду',
            deu: 'Das Objekt ist zur Ansicht verfügbar',
        },
        complex_common_available_true: {
            eng: 'Available',
            rus: 'Доступен',
            ukr: 'Доступний',
            deu: 'Verfügbar',
        },
        complex_common_available_false: {
            eng: 'Not available',
            rus: 'Не доступен',
            ukr: 'Не доступний',
            deu: 'Nicht verfügbar',
        },
        complex_common_sold: {
            eng: 'The object is sold',
            rus: 'Объект продан',
            ukr: 'Об`єкт проданий',
            deu: 'Das Objekt ist verkauft',
        },
        complex_common_sold_true: {
            eng: 'Sold',
            rus: 'Продан',
            ukr: 'Продано',
            deu: 'Verkauft',
        },
        complex_common_sold_false: {
            eng: 'Not sold',
            rus: 'Не продан',
            ukr: 'Не продано',
            deu: 'Nicht verkauft',
        },
        complex_common_field_name: {
            eng: 'Complex name',
            rus: 'Название комплекса',
            ukr: 'Назва комплексу',
            deu: 'Komplexname',
        },
        complex_common_field_price: {
            eng: 'Price per square meter',
            rus: 'Стоимость кв.м',
            ukr: 'Вартість кв.м',
            deu: 'Preis pro Quadratmeter',
        },
        from: {
            eng: 'from',
            rus: 'от',
            ukr: 'від',
            deu: 'ab',
        },
        to: {
            eng: 'to',
            rus: 'до',
            ukr: 'до',
            deu: 'bis',
        },
        complex_common_field_description: {
            eng: 'Complex description',
            rus: 'Описание комплекса',
            ukr: 'Опис комплексу',
            deu: 'Komplexbeschreibung',
        },
        complex_apart_field_description: {
            eng: 'Description',
            rus: 'Описание',
            ukr: 'Опис',
            deu: 'Beschreibung',
        },
        complex_plans_field_description: {
            eng: 'View description',
            rus: 'Просмотр описания',
            ukr: 'Перегляд опису',
            deu: 'Beschreibung anzeigen',
        },
        complex_common_field_installment_terms: {
            eng: 'Installment terms',
            rus: 'Условия рассрочки',
            ukr: 'Умови розстрочки',
            deu: 'Ratenzahlungsbedingungen',
        },
        complex_technical_title: {
            eng: 'Specifications',
            rus: 'Характеристики',
            ukr: 'Характеристики',
            deu: 'Technische Spezifikationen',
        },
        complex_technical_field_date: {
            eng: 'Completion date',
            rus: 'Сдача в єксплуатацию',
            ukr: 'Здача в експлуатацію',
            deu: 'Fertigstellungsdatum',
        },
        complex_technical_field_house: {
            eng: 'Building class',
            rus: 'Класс дома',
            ukr: 'Клас будинку',
            deu: 'Gebäudeklasse',
        }, complex_technical_field_sections: {
            eng: 'Number of sections',
            rus: 'Количество секций',
            ukr: 'Кількість секцій',
            deu: 'Anzahl der Abschnitte',
        },
        complex_technical_field_floor: {
            eng: 'Number of floors',
            rus: 'Этажность',
            ukr: 'Поверховість',
            deu: 'Anzahl der Stockwerke',
        },
        property_technical_field_floor: {
            eng: 'Floor',
            rus: 'Этаж',
            ukr: 'Поверх',
            deu: 'Boden',
        },
        complex_technical_field_apartments: {
            eng: 'Number of apartments',
            rus: 'Количество квартир',
            ukr: 'Кількість квартир',
            deu: 'Anzahl der Wohnungen',
        },
        complex_technical_field_parking: {
            eng: 'Number of parking spaces',
            rus: 'Количество парковочних мест',
            ukr: 'Кількість паркомісць',
            deu: 'Anzahl der Parkplätze',
        },
        complex_technical_field_apartment_status: {
            eng: 'Apartment status',
            rus: 'Состояние квартир',
            ukr: 'Стан квартир',
            deu: 'Wohnungsstatus',
        },
        complex_technical_field_space: {
            eng: 'Apartment area',
            rus: 'Площадь квартир',
            ukr: 'Площа квартир',
            deu: 'Wohnfläche',
        },
        complex_technical_field_apart_type: {
            eng: 'Apartment types',
            rus: 'Типы квартир',
            ukr: 'Типи квартир',
            deu: 'Wohnungstypen',
        },
        complex_technical_field_furniture: {
            eng: 'Furniture',
            rus: 'Мебель',
            ukr: 'Меблі',
            deu: 'Möbel',
        },
        complex_technical_field_furniture_true: {
            eng: 'Yes',
            rus: 'Есть',
            ukr: 'Є',
            deu: 'Ja',
        },
        complex_technical_field_furniture_false: {
            eng: 'Without furniture',
            rus: 'Без мебели',
            ukr: 'Без меблів',
            deu: 'Ohne Möbel',
        },
        complex_plans_title: {
            eng: 'Floor plans',
            rus: 'Планировка этажей',
            ukr: 'Планування поверхів',
            deu: 'Grundrisse',
        },
        complex_plans_button_photo: {
            eng: 'Save Floor Plan',
            rus: 'Сохранить план этажа',
            ukr: 'Зберегти план поверху',
            deu: 'Grundriss speichern',
        },
        complex_plans_min_key_photo: {
            eng: 'Floor plan photo',
            rus: 'фото планировки',
            ukr: 'фото планування',
            deu: 'Grundrissfoto',
        },
        complex_plans_field_number: {
            eng: 'Floor',
            rus: 'Этаж',
            ukr: 'Поверх',
            deu: 'Boden',
        },
        complex_plans_aparts_title: {
            eng: 'Apartment layouts',
            rus: 'Планировка квартир',
            ukr: 'Планування квартир',
            deu: 'Wohnungsgrundrisse',
        },
        complex_plans_aparts_button_photo: {
            eng: 'Upload apartment plan',
            rus: 'Загрузить план квартиры',
            ukr: 'Завантажити план квартири',
            deu: 'Wohnungsgrundriss hochladen',
        },
        complex_plans_field_apart_type: {
            eng: 'Apartment type',
            rus: 'Тип квартиры',
            ukr: 'Тип квартири',
            deu: 'Wohnungstyp',
        },
        complex_plans_field_area: {
            eng: 'Area',
            rus: 'Площадь',
            ukr: 'Площа',
            deu: 'Fläche',
        },
        complex_plans_field_price: {
            eng: 'Price',
            rus: 'Стоимость',
            ukr: 'Ціна',
            deu: 'Preis',
        },
        complex_plans_field_type: {
            eng: 'Type',
            rus: 'Тип',
            ukr: 'Тип',
            deu: 'Typ',
        },
        complex_plans_button_more: {
            eng: 'More details',
            rus: 'Детальнее',
            ukr: 'Детальніше',
            deu: 'Mehr Details',
        },
        complex_plans_field_apart_description: {
            eng: 'Apartment description',
            rus: 'Описание квартиры',
            ukr: 'Опис квартири',
            deu: 'Wohnungsbeschreibung',
        },
        complex_progress_title: {
            eng: 'Construction progress news',
            rus: 'Новости о ходе строительства объекта',
            ukr: 'Новини про хід будівництва об\'єкта',
            deu: 'Baufortschrittsnachrichten',
        }, complex_progress_title_sub: {
            eng: 'In this section, you can update information about the current stage of construction of the residential complex',
            rus: 'В этом разделе вы можете обновлять информацию о текущей стадии строительства жилого комплекса',
            ukr: 'У цьому розділі ви можете оновлювати інформацію про поточний етап будівництва житлового комплексу',
            deu: 'In diesem Abschnitt können Sie Informationen über den aktuellen Stand des Baus des Wohnkomplexes aktualisieren'
        },
        complex_progress_field_name: {
            eng: 'News Title',
            rus: 'Название новости',
            ukr: 'Назва новини',
            deu: 'Nachrichtentitel'
        },
        complex_progress_field_date: {
            eng: 'News Date',
            rus: 'Дата новости',
            ukr: 'Дата новини',
            deu: 'Nachrichtendatum'
        },
        complex_progress_field_description: {
            eng: 'Short Description',
            rus: 'Краткое описание',
            ukr: 'Короткий опис',
            deu: 'Kurzbeschreibung'
        },
        complex_progress_photo_min: {
            eng: 'Photo',
            rus: 'фото',
            ukr: 'фото',
            deu: 'Foto'
        },
        complex_progress_field_button: {
            eng: 'Add Information',
            rus: 'Добавить информацию',
            ukr: 'Додати інформацію',
            deu: 'Information hinzufügen'
        },
        complex_news_title: {
            eng: 'Company stocks and news',
            rus: 'Акции и новости компании',
            ukr: 'Акції та новини компанії',
            deu: 'Aktien und Nachrichten des Unternehmens',
        },
        complex_news_title_sub: {
            eng: 'In this section, you can update information about interesting stocks and profitable discounts/offers for buyers',
            rus: 'В этом разделе вы можете обновлять информацию об интересных акциях и выгодных скидках/предложениями для покупателей',
            ukr: 'У цьому розділі ви можете оновлювати інформацію про цікаві акції та вигідні знижки/пропозиції для покупців',
            deu: 'In diesem Abschnitt können Sie Informationen zu interessanten Aktien und profitablen Rabatten/Angeboten für Käufer aktualisieren',
        },
        complex_news_field_name: {
            eng: 'News Title',
            rus: 'Название новости',
            ukr: 'Назва новини',
            deu: 'Nachrichtentitel'
        },
        complex_news_field_date: {
            eng: 'News Date',
            rus: 'Дата новости',
            ukr: 'Дата новини',
            deu: 'Nachrichtendatum'
        },
        complex_news_field_description: {
            eng: 'Short Description',
            rus: 'Краткое описание',
            ukr: 'Короткий опис',
            deu: 'Kurzbeschreibung'
        },
        complex_news_photo_min: {
            eng: 'Photo',
            rus: 'фото',
            ukr: 'фото',
            deu: 'Foto'
        },
        complex_news_field_button: {
            eng: 'Add Information',
            rus: 'Добавить информацию',
            ukr: 'Додати інформацію',
            deu: 'Information hinzufügen'
        },
        location_country: {
            eng: 'Country',
            rus: 'Страна',
            ukr: 'Країна',
            deu: 'Land'
        },
        location_city: {
            eng: 'City',
            rus: 'Город',
            ukr: 'Місто',
            deu: 'Stadt'
        },
        location_street: {
            eng: 'Street',
            rus: 'Улица',
            ukr: 'Вулиця',
            deu: 'Straße'
        },
        location_street_num: {
            eng: 'House number',
            rus: 'Номер дома',
            ukr: 'Номер будинку',
            deu: 'Hausnummer'
        },

        object_list_my_not_found: {
            "eng": "You currently do not have any personal complexes. You can add a complex at any time.",
            "rus": "На данный момент у вас нет собственных комплексов. Вы можете добавить комплекс в любое время",
            "ukr": "Наразі у вас немає власних комплексів. Ви можете додати комплекс у будь-який час.",
            "deu": "Sie haben derzeit keine eigenen Komplexe. Sie können jederzeit einen Komplex hinzufügen."
        },
        property_list_my_title: {
            eng: 'My Property',
            rus: 'Моя недвижимость',
            ukr: 'Моя нерухомість',
            deu: 'Meine Immobilie'
        },
        property_list_title: {
            eng: 'Overview of All Property',
            rus: 'Обзор всех объектов недвижимости',
            ukr: 'Огляд усіх об\'єктів нерухомості',
            deu: 'Übersicht aller Immobilien'
        },
        property_list_my_add: {
            eng: 'Add New Property',
            rus: 'Добавить объект недвижимости',
            ukr: 'Додати новий об\'єкт нерухомості',
            deu: 'Neue Immobilie hinzufügen'
        },
        complex_list_my_title: {
            "eng": "My Complexes",
            "rus": "Мои комплексы",
            "ukr": "Мої комплекси",
            "deu": "Meine Komplexe"
        },
        complex_list_my_add: {
            "eng": "Add New Complex",
            "rus": "Добавить новый комплекс",
            "ukr": "Додати новий комплекс",
            "deu": "Neuen Komplex hinzufügen"
        },
        complex_list_filter_search: {
            eng: "Search for Objects",
            rus: "Поиск объектов",
            ukr: "Пошук об'єктів",
            deu: "Objektsuche"
        },
        complex_list_title: {
            eng: "Overview of All Complexes",
            rus: "Обзор всех комплексов",
            ukr: "Огляд усіх комплексів",
            deu: "Übersicht aller Komplexe"
        },
        object_status_not_available: {
            eng: "Object Not Published",
            rus: "Объект не опубликован",
            ukr: "Об'єкт не опублікований",
            deu: "Objekt nicht veröffentlicht"
        },
        object_status_available: {
            eng: "Object Published",
            rus: "Объект опубликован",
            ukr: "Об'єкт опублікований",
            deu: "Objekt veröffentlicht"
        },
        object_status_sold: {
            eng: "Sold",
            rus: "Продан",
            ukr: "Продано",
            deu: "Verkauft"
        },
        object_status_draft: {
            eng: "Draft",
            rus: "Черновик",
            ukr: "Чернетка",
            deu: "Entwurf"
        },
        object_status_update_error: {
            eng: "Update Error",
            rus: "Ошибка при обновлении",
            ukr: "Помилка при оновленні",
            deu: "Fehler beim Aktualisieren"
        },
        object_list_item_delete_draft: {
            eng: "Delete Draft",
            rus: "Удалить драфт",
            ukr: "Видалити чернетку",
            deu: "Entwurf löschen"
        },
        object_list_item_sold: {
            eng: "Object Sold",
            rus: "Объект продан",
            ukr: "Об'єкт продано",
            deu: "Objekt verkauft"
        },
        object_list_item_available: {
            eng: "Object Available",
            rus: "Объект доступен",
            ukr: "Об'єкт доступний",
            deu: "Objekt verfügbar"
        },
        complex_item_title: {
            eng: "Overview",
            rus: "Обзор",
            ukr: "Огляд",
            deu: "Übersicht"
        },
        complex_item_tab_overview: {
            eng: "Complex Overview",
            rus: "Обзор комплекса",
            ukr: "Огляд комплексу",
            deu: "Überblick über den Komplex"
        },
        complex_item_tab_technical: {
            eng: "Technical Specifications",
            rus: "Технические показатели",
            ukr: "Технічні показники",
            deu: "Technische Daten"
        },
        complex_item_tab_plans: {
            eng: "Layouts",
            rus: "Планировки",
            ukr: "Планування",
            deu: "Grundrisse"
        },
        complex_item_tab_documents: {
            eng: "Documents",
            rus: "Документы",
            ukr: "Документи",
            deu: "Dokumente"
        },
        complex_item_tab_progress: {
            eng: "Construction Progress",
            rus: "Ход строительства",
            ukr: "Хід будівництва",
            deu: "Baufortschritt"
        },
        complex_item_overview_location_title: {
            eng: "Object Location",
            rus: "Расположение объекта",
            ukr: "Розташування об'єкта",
            deu: "Objektstandort"
        },
        complex_item_finance_price: {
            eng: "Object Price",
            rus: "Стоимость объекта",
            ukr: "Вартість об'єкта",
            deu: "Objektpreis"
        },
        complex_item_finance_installment_terms: {
            eng: "Installment Terms",
            rus: "Условия рассрочки",
            ukr: "Умови розстрочки",
            deu: "Ratenzahlungsbedingungen"
        },
        complex_item_news_title: {
            eng: "Object News",
            rus: "Новости объекта",
            ukr: "Новини об'єкта",
            deu: "Objektnachrichten"
        },
        complex_item_docs_land: {
            eng: "Land Documents",
            rus: "Документы на земельный участок",
            ukr: "Документи на земельну ділянку",
            deu: "Grundstücksdokumente"
        },
        complex_item_docs_participants: {
            eng: "Construction Participants",
            rus: "Участники строительства",
            ukr: "Учасники будівництва",
            deu: "Beteiligte am Bau"
        },
        complex_item_docs_construction: {
            eng: "Construction Documents",
            rus: "Документы на строительство",
            ukr: "Документи на будівництво",
            deu: "Baudokumente"
        },
        complex_item_docs_download: {
            eng: "Download",
            rus: "Скачать",
            ukr: "Завантажити",
            deu: "Herunterladen"
        },
        complex_item_docs_title: {
            eng: "Public Documents",
            rus: "Общедоступные документы",
            ukr: "Загальнодоступні документи",
            deu: "Öffentliche Dokumente"
        },
        complex_item_docs_empty: {
            eng: "No Documents",
            rus: "Документы отсутствуют",
            ukr: "Документи відсутні",
            deu: "Keine Dokumente"
        },
        complex_item_news_item_more: {
            eng: "Learn More",
            rus: "Подробнее",
            ukr: "Детальніше",
            deu: "Mehr erfahren"
        },
        complex_item_owner_contact: {
            eng: "Contact",
            rus: "Связаться",
            ukr: "Зв'язатися",
            deu: "Kontakt"
        },
        lead_form_title: {
            eng: 'Referral of a potential client',
            rus: 'Передача потенциального клиента',
            ukr: 'Передача потенційного клієнта',
            deu: 'Empfehlung eines potenziellen Kunden'
        },
        grem_services_form_title: {
            eng: 'Your application has been successfully submitted',
            rus: 'Ваша заявка успешно отправлена',
            ukr: 'Ваша заявка успішно надіслана',
            deu: 'Ihre Bewerbung wurde erfolgreich eingereicht'
        },
        complex_item_plans_type_studio: {
            eng: "Studio Apartment",
            rus: "Квартира-студия",
            ukr: "Квартира-студія",
            deu: "Studio-Wohnung"
        },
        complex_item_plans_type_penthouse: {
            eng: "Penthouse",
            rus: "Пентхаус",
            ukr: "Пентхаус",
            deu: "Penthouse"
        },
        complex_item_plans_type_1: {
            eng: "1 Bedroom",
            rus: "1 спальня",
            ukr: "1 спальня",
            deu: "1 Schlafzimmer"
        },
        complex_item_plans_type_2: {
            eng: "2 Bedrooms",
            rus: "2 спальни",
            ukr: "2 спальні",
            deu: "2 Schlafzimmer"
        },
        complex_item_plans_type_3: {
            eng: "3 Bedrooms",
            rus: "3 спальни",
            ukr: "3 спальні",
            deu: "3 Schlafzimmer"
        },
        complex_item_plans_type_4: {
            eng: "4 Bedrooms",
            rus: "4 спальни",
            ukr: "4 спальні",
            deu: "4 Schlafzimmer"
        },
        complex_item_plans_type_5: {
            eng: "5 Bedrooms",
            rus: "5 спален",
            ukr: "5 спалень",
            deu: "5 Schlafzimmer"
        },
        complex_item_plans_price: {
            eng: "price",
            rus: "стоимость",
            ukr: "вартість",
            deu: "Preis"
        },
        complex_item_plans_area: {
            eng: "area",
            rus: "площадь",
            ukr: "площа",
            deu: "Fläche"
        },
        complex_item_plans_area_sub: {
            eng: "sq.m",
            rus: "кв.м",
            ukr: "кв.м",
            deu: "qm"
        },
        complex_item_plans_empty: {
            eng: "No results found for your query",
            rus: "По вашему запросу ничего не найдено",
            ukr: "За вашим запитом нічого не знайдено",
            deu: "Zu Ihrer Anfrage wurde nichts gefunden"
        },
        complex_item_floor_plans_empty: {
            eng: "No results found for your query",
            rus: "По вашему запросу ничего не найдено",
            ukr: "За вашим запитом нічого не знайдено",
            deu: "Zu Ihrer Anfrage wurde nichts gefunden"
        },
        complex_item_plans_title: {
            eng: "Apartment Layouts",
            rus: "Планировки квартир",
            ukr: "Планування квартир",
            deu: "Wohnungsgrundrisse"
        },
        complex_item_floor_plans_title: {
            eng: "Floor Plans",
            rus: "Планировки этажей",
            ukr: "Планування поверхів",
            deu: "Grundrisse der Etagen"
        },
        complex_item_progress_empty: {
            eng: "No results found for your query",
            rus: "По вашему запросу ничего не найдено",
            ukr: "За вашим запитом нічого не знайдено",
            deu: "Zu Ihrer Anfrage wurde nichts gefunden"
        },
        complex_item_progress_title: {
            eng: "Construction Progress Updates",
            rus: "Новости о ходе строительства",
            ukr: "Новини про хід будівництва",
            deu: "Neuigkeiten zum Baufortschritt"
        },
        complex_item_technical_title: {
            eng: "Technical Description",
            rus: "Техническое описание",
            ukr: "Технічний опис",
            deu: "Technische Beschreibung"
        },
        complex_item_technical_house_class: {
            eng: "House Class",
            rus: "Класс дома",
            ukr: "Клас будинку",
            deu: "Hausklasse"
        },
        complex_item_technical_floor: {
            eng: "Floors",
            rus: "Этажность",
            ukr: "Поверховість",
            deu: "Stockwerke"
        },
        complex_item_technical_apartments_number: {
            eng: "Number of Apartments",
            rus: "Количество квартир",
            ukr: "Кількість квартир",
            deu: "Anzahl der Wohnungen"
        },
        complex_item_technical_parking_spaces: {
            eng: 'Number of parking spaces',
            rus: 'Количество парковочных мест',
            ukr: 'Кількість парковочних місць',
            deu: 'Anzahl der Parkplätze',
        },
        complex_item_technical_sections_number: {
            eng: "Number of Sections",
            rus: "Количество секций",
            ukr: "Кількість секцій",
            deu: "Anzahl der Abschnitte"
        },
        complex_item_technical_furniture_title: {
            eng: "Furniture",
            rus: "Мебель",
            ukr: "Меблі",
            deu: "Möbel"
        },
        complex_item_technical_furniture: {
            eng: "With Furniture",
            rus: "С мебелью",
            ukr: "З меблями",
            deu: "Mit Möbeln"
        },
        complex_item_technical_no_furniture: {
            eng: "Without Furniture",
            rus: "Без мебели",
            ukr: "Без меблів",
            deu: "Ohne Möbel"
        },
        complex_item_technical_date_construction: {
            eng: "Handover Date",
            rus: "Сдача в эксплуатацию",
            ukr: "Здача в експлуатацію",
            deu: "Übergabedatum"
        },
        complex_item_technical_space: {
            eng: "Apartment Area (sq.m)",
            rus: "Площадь квартир (кв.м)",
            ukr: "Площа квартир (кв.м)",
            deu: "Wohnfläche (qm)"
        },
        complex_item_technical_apart_type: {
            eng: "Types of Apartments",
            rus: "Типы квартир",
            ukr: "Типи квартир",
            deu: "Wohnungstypen"
        },

        draft_stepper_saved: {
            eng: 'Draft form saved successfully',
            rus: 'Черновик формы успешно сохранен',
            ukr: 'Чернетку форми успішно збережено',
            deu: 'Formularentwurf erfolgreich gespeichert'
        },
        draft_stepper_sent: {
            eng: 'Form sent successfully',
            rus: 'Форма успешно отправлена',
            ukr: 'Форма успішно надіслана',
            deu: 'Formular erfolgreich gesendet'
        },
        request_failed: {
            eng: 'The request failed',
            rus: 'Запрос завершился неудачей',
            ukr: 'Запит завершився невдачею',
            deu: 'Die Anfrage ist fehlgeschlagen'
        },
        complex_steps_plans: {
            eng: 'Layouts',
            rus: 'Планировки',
            ukr: 'Планування',
            deu: 'Layouts'
        },
        complex_steps_documents: {
            eng: 'Documents',
            rus: 'Документы',
            ukr: 'Документи',
            deu: 'Unterlagen'
        },
        complex_steps_progress: {
            eng: 'Construction progress',
            rus: 'Ход строительства',
            ukr: 'Хід будівництва',
            deu: 'Baufortschritt'
        },
        complex_add_title: {
            eng: 'Adding a new complex',
            rus: 'Добавление нового комплекса',
            ukr: 'Додавання нового комплексу',
            deu: 'Hinzufügen eines neuen Komplexes'
        },
        complex_add_title_button_text: {
            eng: 'Save Changes',
            rus: 'Сохранить изменения',
            ukr: 'Зберегти зміни',
            deu: 'Änderungen speichern'
        },
        add_send_button_text: {
            eng: 'Publish Property',
            rus: 'Опубликовать объект',
            ukr: 'Опублікувати об\'єкт',
            deu: 'Immobilie veröffentlichen',
        },
        complex_location_block_title: {
            eng: 'Object location',
            rus: 'Расположение объекта',
            ukr: 'Місце розташування об\'єкта',
            deu: 'Objektstandort'
        },
        complex_add_moderation_title: {
            eng: 'Your property is sent for moderation',
            rus: 'Ваш объект недвижимости отправлен на модерацию',
            ukr: 'Ваш об\'єкт нерухомості відправлено на модерацію',
            deu: 'Ihre Immobilie wird zur Moderation gesendet'
        },
        complex_add_moderation_text: {
            eng: 'Thank you for trusting the GREM platform. Your property will be displayed in the new buildings supermarket section and other users can familiarize themselves with it, after signing',
            rus: 'Благодарим вас за доверие платформе GREM. Ваш объект недвижимости будет отображен в разделе супермаркет новостроек и другие пользователи могут с ним ознакомиться, после заключения',
            ukr: 'Дякуємо вам за довіру платформі GREM. Ваш об\'єкт нерухомості буде відображено в розділі супермаркету новобудов, і інші користувачі можуть з ним ознайомитися після підписання',
            deu: 'Danke für Ihr Vertrauen in die GREM-Plattform. Ihre Immobilie wird im Abschnitt "Supermarkt für Neubauten" angezeigt und andere Benutzer können sie nach der Unterzeichnung einsehen'
        },
        complex_add_moderation_link_text: {
            eng: ' commission agreement. ',
            rus: ' договора о комиссии. ',
            ukr: ' договору про комісію. ',
            deu: ' Provisionsvereinbarung. '
        },
        complex_add_moderation_editing: {
            eng: 'You can edit information about the property at any time',
            rus: 'Редактировать информацию об объекте вы можете в любое время',
            ukr: 'Ви можете редагувати інформацію про об\'єкт в будь-який час',
            deu: 'Sie können die Informationen über das Objekt jederzeit bearbeiten'
        },
        complex_add_moderation_objects: {
            eng: 'My properties',
            rus: 'Мои объекты',
            ukr: 'Мої об\'єкти',
            deu: 'Meine Immobilien'
        },
        complex_technical_field_configs_facade: {
            eng: 'Is the "Chessboard" widget enabled for you? Do you want to display it on the landing page?',
            rus: 'У вас подключен виджет “Шахматка”, хотите отображать его на лендинге?',
            ukr: 'У вас підключено віджет "Шахматка", хочете відображати його на лендінгу?',
            deu: 'Ist das "Schachbrett" -Widget für Sie aktiviert? Möchten Sie es auf der Landingpage anzeigen?'
        },
        complex_technical_field_configs_facade_true: {
            eng: 'Display',
            rus: 'Отображать',
            ukr: 'Показати',
            deu: 'Anzeigen'
        },
        complex_technical_field_configs_facade_false: {
            eng: 'Do not display',
            rus: 'Не отображать',
            ukr: 'Не показувати',
            deu: 'Nicht anzeigen'
        },
        complex_dashboard_links_new_modal_title: {
            eng: 'Creating a new URL link',
            rus: 'Создание новой URL-ссылки',
            ukr: 'Створення нового URL-посилання',
            deu: 'Erstellen eines neuen URL-Links'
        },
        complex_list_tab_analitics: {
            eng: 'Analytics',
            rus: 'Аналитика',
            ukr: 'Аналітика',
            deu: 'Analytik'
        },
        complex_list_tab_contract: {
            eng: 'Commission Contract',
            rus: 'Договор о комиссии',
            ukr: 'Договір про комісію',
            deu: 'Provisionsvertrag'
        },
        complex_list_tab_landing: {
            eng: 'Object Landing',
            rus: 'Лендинг объекта',
            ukr: 'Лендінг об\'єкта',
            deu: 'Objekt-Landing'
        },
        complex_list_tab_news: {
            eng: 'News',
            rus: 'Новости',
            ukr: 'Новини',
            deu: 'Nachrichten'
        },
        complex_list_tab_analitics_additional: {
            eng: 'Additional Features',
            rus: 'Дополнительные возможности',
            ukr: 'Додаткові можливості',
            deu: 'Zusätzliche Funktionen'
        },
        complex_list_tab_analitics_additional_services: {
            eng: 'For effective promotion of your real estate, the GREM platform offers additional services including:',
            rus: 'Для эффективного продвижения вашей недвижимости, платформа GREM предлагает дополнительные сервисы, включая:',
            ukr: 'Для ефективного просування вашої нерухомості, платформа GREM пропонує додаткові сервіси, включаючи:',
            deu: 'Für eine effektive Förderung Ihrer Immobilie bietet die GREM-Plattform zusätzliche Dienstleistungen, darunter:'
        },
        complex_list_tab_analitics_additional_serv_3d: {
            eng: 'Development of 3D visualization of the object facade (see ',
            rus: 'Разработка 3D-визуализации фасада объекта (ознакомиться с ',
            ukr: 'Розробка 3D-візуалізації фасаду об\'єкта (ознайомитися з ',
            deu: 'Entwicklung einer 3D-Visualisierung der Objektfassade (siehe '
        },
        complex_list_tab_analitics_additional_serv_3d_example: {
            eng: ' example ',
            rus: ' примером ',
            ukr: ' прикладом ',
            deu: ' Beispiel '
        },
        complex_list_tab_analitics_additional_serv_3d_p2: {
            eng: ' interactive facade)',
            rus: ' интерактивного фасада)',
            ukr: ' інтерактивного фасаду)',
            deu: ' interaktive Fassade)'
        },
        complex_list_tab_analitics_additional_serv_ad: {
            eng: 'Promoting your property using advertising or marketing',
            rus: 'Продвижение вашего объекта с использованием рекламы или маркетинга',
            ukr: 'Продвиження вашого об\'єкту за допомогою реклами або маркетингу',
            deu: 'Ihre Immobilie mit Werbung oder Marketing bewerben'
        },
        complex_list_tab_analitics_additional_serv_landing: {
            eng: 'Creating a unique and personalized website or landing page for your property',
            rus: 'Создание уникального и персонализированного веб-сайта или лендинга под ваш объект',
            ukr: 'Створення унікального та персоналізованого веб-сайту або лендінгу для вашого об\'єкту',
            deu: 'Erstellung einer einzigartigen und personalisierten Website oder Landingpage für Ihre Immobilie'
        },
        complex_list_tab_analitics_additional_serv_request: {
            eng: 'Submit Request',
            rus: 'Оставить заявку',
            ukr: 'Залишити заявку',
            deu: 'Anfrage senden'
        },
        complex_list_tab_analitics_statistic_leads: {
            eng: 'Leads Transferred',
            rus: 'Переданых лидов',
            ukr: 'Переданих лідів',
            deu: 'Übertragene Leads'
        },
        complex_list_tab_analitics_statistic_closed: {
            eng: 'Closed Deals',
            rus: 'Закрытые сделки',
            ukr: 'Закриті угоди',
            deu: 'Abgeschlossene Deals'
        },
        complex_list_tab_analitics_statistic_summ: {
            eng: 'Total Deal Amount',
            rus: 'Общая сумма сделок',
            ukr: 'Загальна сума угод',
            deu: 'Gesamtvertragsbetrag'
        },
        complex_list_tab_analitics_statistic_history: {
            eng: 'Closed Deal History',
            rus: 'История закрытых сделок',
            ukr: 'Історія закритих угод',
            deu: 'Geschichte der abgeschlossenen Deals'
        },
        complex_list_tab_analitics_statistic_day: {
            eng: 'By Day',
            rus: 'За день',
            ukr: 'За день',
            deu: 'Nach Tag'
        },
        complex_list_tab_analitics_statistic_week: {
            eng: 'By Week',
            rus: 'За неделю',
            ukr: 'За тиждень',
            deu: 'Nach Woche'
        },
        complex_list_tab_analitics_statistic_month: {
            eng: 'By Month',
            rus: 'За месяц',
            ukr: 'За місяць',
            deu: 'Nach Monat'
        },
        complex_list_tab_analitics_statistic_history_date: {
            eng: 'Date',
            rus: 'Дата',
            ukr: 'Дата',
            deu: 'Datum'
        },
        complex_list_tab_analitics_statistic_history_summ: {
            eng: 'Deal Amount (€)',
            rus: 'Сумма сделки (€)',
            ukr: 'Сума угоди (€)',
            deu: 'Vertragsbetrag (€)'
        },
        complex_list_tab_analitics_statistic_history_commision: {
            eng: 'GREM Commission Amount (€)',
            rus: 'Сумма комиссии GREM (€)',
            ukr: 'Сума комісії GREM (€)',
            deu: 'GREM-Kommissionsbetrag (€)'
        },
        complex_list_tab_contract_info_p1: {
            eng: 'In this section, you can create commission contracts. We recommend familiarizing yourself with the contract template initially.',
            rus: 'В этом разделе вы можете создавать договоры о комиссии. Рекомендуем изначально ознакомиться с шаблоном договора.',
            ukr: 'У цьому розділі ви можете створювати договори про комісію. Рекомендуємо спочатку ознайомитися з шаблоном договору.',
            deu: 'In diesem Abschnitt können Sie Provisionsverträge erstellen. Wir empfehlen, sich zunächst mit der Vertragvorlage vertraut zu machen.'
        },
        complex_list_tab_contract_info_p2: {
            eng: ' All contracts are issued in English.',
            rus: ' Все договора оформляются на английском языке.',
            ukr: ' Усі договори видаються англійською мовою.',
            deu: ' Alle Verträge werden in Englisch ausgestellt.'
        },
        complex_list_tab_contract_info_p3: {
            eng: ' Please pay attention, all fields must be filled in Latin characters.',
            rus: ' Будьте внимательны, все поля должны быть заполнены латиницей.',
            ukr: ' Будьте уважні, всі поля повинні бути заповнені латиницею.',
            deu: ' Bitte beachten Sie, alle Felder müssen in lateinischen Zeichen ausgefüllt werden.'
        },
        complex_list_tab_contract_info_sample: {
            eng: 'Contract Template',
            rus: 'Шаблон договора',
            ukr: 'Шаблон договору',
            deu: 'Vertragvorlage'
        },
        complex_list_tab_contract_object: {
            eng: 'Agreement Object',
            rus: 'Объект соглашения',
            ukr: 'Об`єкт угоди',
            deu: 'Vereinbarungsobjekt'
        },
        complex_list_tab_contract_members: {
            eng: 'Parties to the Agreement',
            rus: 'Стороны соглашения',
            ukr: 'Сторони угоди',
            deu: 'Vertragsparteien'
        },
        complex_list_tab_contract_reward: {
            eng: 'Subject of Agreement',
            rus: 'Предмет соглашения',
            ukr: 'Предмет угоди',
            deu: 'Vertragsgegenstand'
        },
        complex_list_tab_contract_create: {
            eng: 'Create Contract',
            rus: 'Создать договор',
            ukr: 'Створити договір',
            deu: 'Vertrag erstellen'
        },
        complex_list_tab_contract_pending: {
            eng: 'Your contract is sent for review',
            rus: 'Ваш договор отправлен на проверку',
            ukr: 'Ваш договір відправлено на перевірку',
            deu: 'Ihr Vertrag wird überprüft'
        },
        complex_list_tab_contract_pending_info: {
            eng: 'The contract will be created based on the information provided by you, and upon completion, you will receive a notification by email. The verification process takes up to 72 hours.',
            rus: 'Договор будет создан на основе предоставленной вами информации, и по его завершении вы получите уведомление на электронную почту. Процесс проверки занимает до 72 часов.',
            ukr: 'Договір буде створено на основі наданої вами інформації, і по його завершенню ви отримаєте повідомлення електронною поштою. Процес перевірки займає до 72 годин.',
            deu: 'Der Vertrag wird basierend auf den von Ihnen bereitgestellten Informationen erstellt, und nach Abschluss erhalten Sie eine Benachrichtigung per E-Mail. Der Überprüfungsprozess dauert bis zu 72 Stunden.'
        },
        complex_list_tab_contract_platform: {
            eng: 'Your contract has been successfully verified',
            rus: 'Ваш контракт успешно прошел проверку',
            ukr: 'Ваш контракт успішно пройшов перевірку',
            deu: 'Ihr Vertrag wurde erfolgreich überprüft'
        },
        complex_list_tab_contract_platform_info: {
            eng: 'Thank you for trusting the GREM platform. To complete the process, please sign the contract and send its scanned copy.',
            rus: 'Благодарим за доверие к платформе GREM. Для завершения процесса, пожалуйста, подпишите договор и отправьте его скан-копию',
            ukr: 'Дякуємо за довіру до платформи GREM. Для завершення процесу, будь ласка, підпишіть договір і надішліть його скановану копію.',
            deu: 'Vielen Dank für Ihr Vertrauen in die GREM-Plattform. Um den Prozess abzuschließen, unterschreiben Sie bitte den Vertrag und senden Sie dessen gescannte Kopie.'
        },
        contract_download: {
            eng: 'Download verified contract',
            rus: 'Скачать проверенный договор',
            ukr: 'Завантажити перевірений договір',
            deu: 'Überprüften Vertrag herunterladen'
        },
        complex_list_tab_contract_platform_atemp: {
            eng: 'To complete the process, the contract must be signed:',
            rus: 'Для завершения оформления, контракт должен быть подписан:',
            ukr: 'Для завершення оформлення, контракт повинен бути підписаний:',
            deu: 'Um den Prozess abzuschließen, muss der Vertrag unterzeichnet werden:',
        },
        complex_list_tab_contract_platform_file: {
            eng: 'Signed copy of contract',
            rus: 'Подписанная скан-копия договора',
            ukr: 'Підписана копія договору',
            deu: 'Unterzeichnete Kopie des Vertrags',
        },
        contract_send_scan: {
            eng: 'Send scanned copy',
            rus: 'Отправить скан-копию',
            ukr: 'Надіслати скановану копію',
            deu: 'Gescannte Kopie senden'
        },
        complex_list_tab_contract_pending_user: {
            eng: 'Your scanned copy has been sent for review',
            rus: 'Ваша скан-копия отправлена на проверку',
            ukr: 'Ваша сканована копія надіслана на перевірку',
            deu: 'Ihre gescannte Kopie wurde zur Überprüfung gesendet'
        },
        complex_list_tab_contract_pending_user_info: {
            eng: 'Upon completion of the review, you will receive a notification by email. The verification process takes up to 72 hours.',
            rus: 'По завершении проверки вы получите уведомление на электронную почту. Процесс проверки занимает до 72 часов.',
            ukr: 'Після завершення перевірки ви отримаєте повідомлення електронною поштою. Процес перевірки займає до 72 годин.',
            deu: 'Nach Abschluss der Überprüfung erhalten Sie eine Benachrichtigung per E-Mail. Der Überprüfungsprozess dauert bis zu 72 Stunden.'
        },
        complex_list_tab_contract_pending_approved: {
            eng: 'Congratulations! Your contract has been verified and signed',
            rus: 'Поздравляем! Ваш договор проверен и подписан',
            ukr: 'Вітаємо! Ваш договір перевірений і підписаний',
            deu: 'Herzlichen Glückwunsch! Ihr Vertrag wurde überprüft und unterzeichnet'
        },
        complex_list_tab_contract_pending_approved_thx: {
            eng: 'Thank you for trusting the GREM platform',
            rus: 'Благодарим вас за доверие к платформе GREM',
            ukr: 'Дякуємо вам за довіру до платформи GREM',
            deu: 'Vielen Dank für Ihr Vertrauen in die GREM-Plattform'
        },
        complex_list_tab_contract_pending_rejected: {
            eng: 'Verification failed',
            rus: 'Проверка не пройдена',
            ukr: 'Перевірка не пройшла',
            deu: 'Überprüfung fehlgeschlagen'
        },
        complex_list_tab_contract_pending_rejected_info: {
            eng: 'During the verification process, some discrepancies were found in the information provided by you. The GREM platform strives to maintain a high standard of quality. For any questions, you can contact the ',
            rus: 'В процессе проверки выявлены некоторые несоответствия в предоставленных вами данных. Платформа GREM старается обеспечить высокий стандарт качества. По любым вопросам вы можете обратиться в ',
            ukr: 'Під час перевірки виявлено деякі розбіжності в наданих вами даних. Платформа GREM прагне забезпечити високий стандарт якості. З будь-якими питаннями ви можете звернутися до ',
            deu: 'Während des Überprüfungsprozesses wurden einige Unstimmigkeiten in den von Ihnen bereitgestellten Informationen festgestellt. Die GREM-Plattform bemüht sich um einen hohen Qualitätsstandard. Bei Fragen können Sie sich an den '
        },
        complex_list_tab_contract_pending_rejected_support: {
            eng: ' customer support service',
            rus: ' службу поддержки клиентов',
            ukr: ' службу підтримки клієнтів',
            deu: ' Kundensupport'
        },
        complex_list_tab_contract_pending_rejected_correct: {
            eng: 'Necessary corrections',
            rus: 'Необходимые корректировки',
            ukr: 'Необхідні корекції',
            deu: 'Notwendige Korrekturen'
        },
        contract_edit: {
            eng: 'Edit contract',
            rus: 'Редактировать договор',
            ukr: 'Редагувати договір',
            deu: 'Vertrag bearbeiten'
        },
        complex_list_tab_landing_title: {
            eng: 'Your unique links',
            rus: 'Ваши уникальные ссылки',
            ukr: 'Ваші унікальні посилання',
            deu: 'Ihre einzigartigen Links'
        },
        complex_list_tab_landing_info: {
            eng: 'You can share unique links to landing pages and/or social networks with your contacts or clients. All URL links are automatically generated based on the provided data',
            rus: 'Вы можете делиться с вашими контактами или клиентами уникальными ссылками на лендинги и/или социальные сети. Все URL-ссылки создаются автоматически на основе предоставленных данных',
            ukr: 'Ви можете поділитися з вашими контактами або клієнтами унікальними посиланнями на лендінги та/або соціальні мережі. Усі URL-посилання автоматично генеруються на основі наданих даних',
            deu: 'Sie können einzigartige Links zu Landingpages und/oder sozialen Netzwerken mit Ihren Kontakten oder Kunden teilen. Alle URL-Links werden automatisch auf Basis der bereitgestellten Daten generiert'
        },
        complex_list_tab_landing_create: {
            eng: 'Create new link',
            rus: 'Создать новую ссылку',
            ukr: 'Створити нове посилання',
            deu: 'Neuen Link erstellen'
        },
        complex_list_tab_landing_modal_request: {
            eng: 'Please fill in all required fields',
            rus: 'Заполните все обязательные поля',
            ukr: 'Заповніть всі обов\'язкові поля',
            deu: 'Bitte füllen Sie alle erforderlichen Felder aus'
        },
        complex_list_tab_landing_modal_success: {
            eng: 'Link successfully added',
            rus: 'Ссылка успешно добавлена',
            ukr: 'Посилання успішно додане',
            deu: 'Link erfolgreich hinzugefügt'
        },
        complex_list_tab_landing_modal_error: {
            eng: 'Error adding link',
            rus: 'Ошибка при добавлении ссылки',
            ukr: 'Помилка при додаванні посилання',
            deu: 'Fehler beim Hinzufügen des Links'
        },
        complex_list_tab_landing_modal_info: {
            eng: 'Enter the type of link you need. An automatically generated link will be added to the table',
            rus: 'Введите тип ссылки, который вам необходим. Автоматически созданная ссылка будет добавлена в таблицу',
            ukr: 'Введіть тип посилання, яке вам потрібно. Автоматично створене посилання буде додане до таблиці',
            deu: 'Geben Sie den gewünschten Linktyp ein. Ein automatisch generierter Link wird der Tabelle hinzugefügt'
        },
        complex_list_tab_landing_modal_name: {
            eng: 'Link name',
            rus: 'Название ссылки',
            ukr: 'Назва посилання',
            deu: 'Linkname'
        },
        complex_list_tab_landing_list_name: {
            eng: 'Name',
            rus: 'Название',
            ukr: 'Назва',
            deu: 'Name'
        },
        complex_list_tab_landing_list_clicks: {
            eng: 'Clicks',
            rus: 'Переходы',
            ukr: 'Переходи',
            deu: 'Klicks'
        },
        complex_list_tab_landing_list_leads: {
            eng: 'Leads',
            rus: 'Регистрации',
            ukr: 'Реєстрації',
            deu: 'Leads'
        },
        complex_list_tab_landing_list_url: {
            eng: 'Link',
            rus: 'Ссылка',
            ukr: 'Посилання',
            deu: 'Link'
        },
        copy: {
            eng: 'Copy',
            rus: 'Копировать',
            ukr: 'Копіювати',
            deu: 'Kopieren'
        },
        reset: {
            eng: 'Reset',
            rus: 'Сбросить',
            ukr: 'Скинути',
            deu: 'Zurücksetzen'
        },
        complex_list_tab_landing_modal_reset: {
            eng: 'Reset statistics',
            rus: 'Сбросить статистику',
            ukr: 'Скинути статистику',
            deu: 'Statistik zurücksetzen'
        },
        complex_list_tab_landing_modal_delete: {
            eng: 'Delete link',
            rus: 'Удалить ссылку',
            ukr: 'Видалити посилання',
            deu: 'Link löschen'
        },
        complex_list_tab_landing_modal_reset_error: {
            eng: 'Error while resetting link stats',
            rus: 'Ошибка при сбросе статистики ссылки',
            ukr: 'Помилка при скиданні статистики посилання',
            deu: 'Fehler beim Zurücksetzen der Linkstatistik'
        },
        complex_list_tab_landing_modal_reset_success: {
            eng: 'Link stats reset',
            rus: 'Статистика ссылки сброшена',
            ukr: 'Статистика посилання скинута',
            deu: 'Linkstatistik zurückgesetzt'
        },
        complex_list_tab_landing_modal_delete_success: {
            eng: 'Link removed',
            rus: 'Ссылка удалена',
            ukr: 'Посилання видалено',
            deu: 'Link entfernt'
        },
        complex_list_tab_landing_modal_delete_error: {
            eng: 'Error while removing link',
            rus: 'Ошибка при удалении ссылки',
            ukr: 'Помилка при видаленні посилання',
            deu: 'Fehler beim Entfernen des Links'
        },
        complex_list_tab_landing_modal_reset_delete: {
            eng: "This action will result in the loss of data for this link. Confirm your choice",
            rus: "Это действие приведет к утере данных по данной ссылке. Подтвердите ваш выбор",
            ukr: "Ця дія призведе до втрати даних за цим посиланням. Підтвердіть ваш вибір",
            deu: "Diese Aktion führt zum Verlust von Daten für diesen Link. Bestätigen Sie Ihre Wahl"
        },
        complex_list_tab_landing_modal_reset_button: {
            eng: "Reset analytics",
            rus: "Сбросить аналитику",
            ukr: "Скинути аналітику",
            deu: "Analyse zurücksetzen"
        },
        complex_list_tab_landing_modal_delete_button: {
            eng: "Delete link",
            rus: "Удалить ссылку",
            ukr: "Видалити посилання",
            deu: "Link löschen"
        },
        cancel_action: {
            eng: "Cancel action",
            rus: "Отменить действие",
            ukr: "Скасувати дію",
            deu: "Aktion abbrechen"
        },
        show_all_in_crm: {
            eng: 'Show all deals in CRM',
            rus: 'Просмотр всех сделок в CRM',
            ukr: 'Перегляд усіх угод в CRM',
            deu: 'Alle Deals in CRM anzeigen'
        },
        contract_agency_info: {
            eng: "To place an object for sale within the GREM platform, you need to upload an agency agreement confirming your right to sell this real estate object",
            rus: "Для размещения объекта на продажу внутри платформы GREM вам необходимо загрузить агентский договор, подтверждающий ваше право на продажу данного объекта недвижимости",
            ukr: "Щоб розмістити об'єкт на продаж у межах платформи GREM, вам потрібно завантажити агентський договір, який підтверджує ваше право на продаж цього об'єкта нерухомості",
            deu: "Um ein Objekt zum Verkauf innerhalb der GREM-Plattform zu platzieren, müssen Sie einen Agenturvertrag hochladen, der Ihr Recht zum Verkauf dieses Immobilienobjekts bestätigt"
        },
        contract_agency_upload: {
            eng: "Upload agency agreement",
            rus: "Загрузить агентский договор",
            ukr: "Завантажити агентський договір",
            deu: "Agenturvertrag hochladen"
        },
        contract_agency_file: {
            eng: "Aagency agreement",
            rus: "Агентский договор",
            ukr: "Агентський договір",
            deu: "Agenturvertrag"
        },
        complex_overview_created: {
            eng: 'Complex placed: ',
            rus: 'Объект размещен: ',
            ukr: 'Об`єкт розміщено: ',
            deu: 'Komplex platziert: '
        },
        complex_overview_updated: {
            eng: 'Complex updated: ',
            rus: 'Объект обновлен: ',
            ukr: 'Об`єкт оновлено: ',
            deu: 'Komplex aktualisiert: '
        },
        property_overview_created: {
            eng: 'Object placed:',
            rus: 'Объект создан:',
            ukr: 'Об\'єкт створено:',
            deu: 'Objekt erstellt:',
        },
        property_overview_updated: {
            eng: 'Object updated:',
            rus: 'Объект обновлен:',
            ukr: 'Об\'єкт оновлено:',
            deu: 'Objekt aktualisiert:',
        },
        complex_overview_edit: {
            eng: 'Edit Information',
            rus: 'Редактировать информацию',
            ukr: 'Редагувати інформацію',
            deu: 'Informationen bearbeiten',
        },
        complex_overview_commission: {
            eng: 'Broker commission:',
            rus: 'Комиссия брокера:',
            ukr: 'Комісія брокера:',
            deu: 'Broker-Provision:'
        },
        complex_overview_send_lead: {
            eng: 'Send lead',
            rus: 'Передать лида',
            ukr: 'Передати лід',
            deu: 'Lead senden'
        },
        complex_overview_get_landing: {
            eng: 'Get link',
            rus: 'Получить ссылку',
            ukr: 'Отримати посилання',
            deu: 'Link erhalten'
        },
        complex_overview_get_landing_info: {
            eng: 'To effectively advertise and sell this property, you can receive a unique URL link (landing page) containing your contact information. Applications and potential clients will be automatically transferred to',
            rus: 'Для эффективной рекламы и продажи данного объекта вы можете получить уникальную URL-ссылку (landingpage), содержащую ваши контактные данные. Заявки и потенциальные клиенты будут автоматически передаваться в ',
            ukr: 'Для ефективної реклами та продажу даного об\'єкта ви можете отримати унікальне URL-посилання (landing page), яке містить ваші контактні дані. Заявки та потенційні клієнти будуть автоматично передаватися до',
            deu: 'm diese Immobilie effektiv zu bewerben und zu verkaufen, können Sie einen eindeutigen URL-Link (Landingpage) mit Ihren Kontaktinformationen erhalten. Bewerbungen und potenzielle Kunden werden automatisch weitergeleitet'
        },
        complex_overview_get_landing_info_crm: {
            eng: ' CRM system ',
            rus: ' CRM-систему ',
            ukr: ' CRM-систему ',
            deu: ' CRM-System '
        },
        complex_overview_get_landing_info_p1: {
            eng: 'where it is convenient to work with potential clients (leads).',
            rus: 'где удобно работать с потенциальными клиентами (лидами).',
            ukr: 'де зручно працювати з потенційними клієнтами (лідами).',
            deu: 'wo es bequem ist, mit potenziellen Kunden (Leads) zu arbeiten.'
        },
        avatar_loader_file_drag: {
            eng: 'Drag photo here',
            rus: 'Перетащите фото сюда',
            ukr: 'Перетягніть фото сюди',
            deu: 'Foto hierher ziehen'
        },
        avatar_loader_file_count_min: {
            eng: 'Add at least 1 photo.',
            rus: 'Добавьте минимум 1 фото.',
            ukr: 'Додайте мінімум 1 фото.',
            deu: 'Fügen Sie mindestens 1 Foto hinzu.'
        },
        profile_avatar: {
            eng: 'avatar',
            rus: 'аватар',
            ukr: 'аватар',
            deu: 'Avatar'
        },
        profile_portfolio: {
            eng: 'Portfolio of realized objects',
            rus: 'Портфолио реализованных объектов',
            ukr: 'Портфоліо реалізованих об\'єктів',
            deu: 'Portfolio realisierter Objekte'
        },
        profile_verification_user_title: {
            eng: 'Personal Information',
            rus: 'Личная информация',
            ukr: 'Особиста інформація',
            deu: 'Persönliche Informationen'
        },
        email: {
            eng: 'Email',
            rus: 'Электронная почта',
            ukr: 'Електронна пошта',
            deu: 'E-Mail'
        },
        profile_verification_user_fname: {
            eng: 'Your Name*',
            rus: 'Ваше имя*',
            ukr: 'Ваше ім\'я*',
            deu: 'Ihr Name*'
        },
        profile_verification_user_phone: {
            eng: 'Contact Phone',
            rus: 'Контактный телефон',
            ukr: 'Контактний телефон',
            deu: 'Kontakttelefon'
        },
        profile_verification_user_lname: {
            eng: 'Your Surname*',
            rus: 'Ваша фамилия*',
            ukr: 'Ваша прізвище*',
            deu: 'Ihr Nachname*'
        },
        profile_verification_user_load_photo: {
            eng: 'Upload Photo',
            rus: 'Загрузить фото',
            ukr: 'Завантажити фото',
            deu: 'Foto hochladen'
        },
        profile_verification_user_modal_secondary_success: {
            eng: 'Congratulations, your primary verification is successfully completed',
            rus: 'Поздравляем, ваша первичная верификация успешно завершена',
            ukr: 'Вітаємо, ваша первинна верифікація успішно завершена',
            deu: 'Herzlichen Glückwunsch, Ihre primäre Verifizierung wurde erfolgreich abgeschlossen'
        },
        profile_verification_user_modal_secondary_available: {
            eng: 'You have access to platform features:',
            rus: 'Вам открыт доступ к функциям платформы:',
            ukr: 'Вам відкритий доступ до функцій платформи:',
            deu: 'Sie haben Zugriff auf Plattformfunktionen:'
        },
        profile_verification_user_modal_secondary_buy: {
            eng: 'Buying and selling M2C',
            rus: 'Покупка и продажа M2C',
            ukr: 'Купівля і продаж M2C',
            deu: 'Kauf und Verkauf von M2C'
        },
        profile_verification_user_modal_secondary_watch: {
            eng: 'Viewing real estate catalog',
            rus: 'Просмотр каталога объектов',
            ukr: 'Перегляд каталогу об\'єктів',
            deu: 'Anzeigen des Objektkatalogs'
        },
        profile_verification_user_modal_secondary_request: {
            eng: 'Request for square meters purchase',
            rus: 'Заявка на покупку квадратных метров',
            ukr: 'Заявка на купівлю квадратних метрів',
            deu: 'Anfrage zum Kauf von Quadratmetern'
        },
        profile_verification_user_modal_secondary_profile: {
            eng: 'My Profile',
            rus: 'Мой профиль',
            ukr: 'Мій профіль',
            deu: 'Mein Profil'
        },
        profile_verification_user_modal_secondary_community_ask: {
            eng: 'Are you a real estate market participant, representative of a company or organization',
            rus: 'Вы участник рынка недвижимости, представитель компании или организации',
            ukr: 'Ви учасник ринку нерухомості, представник компанії чи організації',
            deu: 'Sind Sie ein Teilnehmer am Immobilienmarkt, Vertreter eines Unternehmens oder einer Organisation'
        },
        profile_verification_user_modal_secondary_community_need: {
            eng: 'you need to pass professional verification',
            rus: 'вам необходимо пройти профессиональную верификацию',
            ukr: 'вам необхідно пройти професійну верифікацію',
            deu: 'Sie müssen eine professionelle Verifizierung durchführen'
        },
        profile_verification_user_modal_secondary_community_start: {
            eng: 'Start verification',
            rus: 'Пройти верификацию',
            ukr: 'Пройти верифікацію',
            deu: 'Starte Verifizierung'
        },
        profile_verification_community_specialist: {
            eng: 'Real estate market participant',
            rus: 'Участник рынка недвижимости',
            ukr: 'Учасник ринку нерухомості',
            deu: 'Teilnehmer am Immobilienmarkt'
        },
        profile_verification_community_specialist_description: {
            eng: 'Suitable if you are a buyer, seller, or a real estate specialist',
            rus: 'Подходит в том случае, если вы покупатель, продавец или являетесь специалистом в сфере недвижимости',
            ukr: 'Підходить у випадку, якщо ви покупець, продавець або спеціаліст з нерухомості',
            deu: 'Geeignet, wenn Sie Käufer, Verkäufer oder ein Immobilienexperte sind'
        },
        profile_verification_role_tab_info: {
            eng: 'Depending on the nature of your activity, choose one of the options:',
            rus: 'В зависимости от направления вашей деятельности, выберите один из предложенных вариантов:',
            ukr: 'Залежно від напрямку вашої діяльності, оберіть один із запропонованих варіантів:',
            deu: 'Abhängig von der Art Ihrer Tätigkeit wählen Sie eine der Optionen:'
        },
        profile_verification_role_select: {
            eng: 'Select role',
            rus: 'Выбрать роль',
            ukr: 'Вибрати роль',
            deu: 'Rolle auswählen'
        },
        profile_verification_role_selected: {
            eng: 'Selected role',
            rus: 'Выбранная роль',
            ukr: 'Вибрана роль',
            deu: 'Ausgewählte Rolle'
        },
        profile_verification_community_company: {
            eng: 'Company',
            rus: 'Компания',
            ukr: 'Компанія',
            deu: 'Unternehmen'
        },
        profile_verification_community_company_description: {
            eng: 'Suitable if you are the owner or legal representative of a company of any legal form',
            rus: 'Подходит, если вы владелец или законный представитель компании любой организационно-правовой формы.',
            ukr: 'Підійде, якщо ви є власником або законним представником компанії будь-якої організаційно-правової форми',
            deu: 'Geeignet, wenn Sie Eigentümer oder gesetzlicher Vertreter eines Unternehmens jeder Rechtsform sind'
        },
        profile_verification_community_organization: {
            eng: 'Organization',
            rus: 'Организация',
            ukr: 'Організація',
            deu: 'Organisation'
        },
        profile_verification_community_organization_description: {
            eng: 'Suitable if you represent a public organization',
            rus: 'Подходит в том случае, если вы представляете общественную организацию',
            ukr: 'Підходить у випадку, якщо ви представляєте громадську організацію',
            deu: 'Geeignet, wenn Sie eine öffentliche Organisation vertreten'
        },
        profile_verification_community_specialist_continue_1: {
            eng: 'To continue registration',
            rus: 'Для продолжения регистрации',
            ukr: 'Для продовження реєстрації',
            deu: 'Um die Registrierung fortzusetzen'
        },
        profile_verification_community_specialist_continue_2: {
            eng: 'select one or more roles on the platform',
            rus: 'выберите одну или несколько ролей на платформе',
            ukr: 'виберіть одну або декілька ролей на платформі',
            deu: 'wählen Sie eine oder mehrere Rollen auf der Plattform'
        },
        profile_verification_community_specialist_continue_3: {
            eng: ', that maximize the fit with your professional activities. Each role has its own unique capabilities, meeting the current requirements and criteria of the global real estate market.',
            rus: ', которые максимально соответствуют вашей профессиональной деятельности. Каждая роль имеет свои уникальные возможности, отвечая современным требованиям и критериям мирового рынка недвижимости.',
            ukr: ", які максимально відповідають вашій професійній діяльності. Кожна роль має свої унікальні можливості, що відповідають сучасним вимогам та критеріям світового ринку нерухомості.",
            deu: ", die am besten zu Ihren beruflichen Aktivitäten passen. Jede Rolle hat ihre eigenen einzigartigen Fähigkeiten, die den aktuellen Anforderungen und Kriterien des globalen Immobilienmarktes entsprechen."
        },
        profile_verification_community_specialist_field_description: {
            eng: 'Description',
            rus: 'Описание',
            ukr: 'Опис',
            deu: 'Beschreibung',
        },
        profile_verification_community_specialist_field_description_sub: {
            eng: 'Tell us briefly about yourself in the context of your participation in the real estate market or share information about your professional activity',
            rus: 'Расскажите нам кратко о себе в контексте вашего участия в рынке недвижимости или поделитесь информацией о вашей профессиональной деятельности',
            ukr: 'Розкажіть нам коротко про себе в контексті вашої участі в ринку нерухомості або поділіться інформацією про вашу професійну діяльність',
            deu: 'Erzählen Sie uns kurz über sich im Kontext Ihrer Teilnahme am Immobilienmarkt oder teilen Sie Informationen über Ihre berufliche Tätigkeit mit',
        },
        profile_verification_community_company_sub: {
            eng: 'Select the type of company that most closely matches your field of activity and business format:',
            rus: 'Выберите тип компании, который максимально близко отвечает вашему роду деятельности и формату ведения бизнеса:',
            ukr: 'Виберіть тип компанії, який максимально відповідає вашому виду діяльності та формату ведення бізнесу:',
            deu: 'Wählen Sie die Art des Unternehmens aus, die Ihrem Tätigkeitsbereich und Geschäftsformat am besten entspricht:',
        },
        yes: {
            eng: 'Yes',
            rus: 'Да',
            ukr: 'Так',
            deu: 'Ja',
        },
        no: {
            eng: 'No',
            rus: 'Нет',
            ukr: 'Ні',
            deu: 'Nein',
        },
        profile_verification_community_specialist_field_specialization: {
            eng: 'Real Estate Specialization',
            rus: 'Специализация по недвижимости',
            ukr: 'Спеціалізація у сфері нерухомості',
            deu: 'Immobilien Spezialisierung'
        },
        profile_verification_community_specialist_field_businessCountry: {
            eng: 'Business Country',
            rus: 'Бизнес страны',
            ukr: 'Країна бізнесу',
            deu: 'Geschäftsland'
        },
        profile_verification_community_specialist_field_investmentCountries: {
            eng: 'Investment Countries',
            rus: 'Страны для инвестиций',
            ukr: 'Країни для інвестицій',
            deu: 'Investitionsländer'
        },
        profile_verification_community_specialist_field_experience: {
            eng: 'Years of Experience',
            rus: 'Годы опыта',
            ukr: 'Роки досвіду',
            deu: 'Jahre der Erfahrung'
        },
        profile_verification_community_specialist_field_maxDeal: {
            eng: 'Maximum Deal Amount (€)',
            rus: 'Максимальная сумма сделки (€)',
            ukr: 'Максимальна сума угоди (€)',
            deu: 'Maximaler Transaktionsbetrag (€)'
        },
        profile_verification_community_specialist_field_deals: {
            eng: 'Number of Deals',
            rus: 'Количество сделок',
            ukr: 'Кількість угод',
            deu: 'Anzahl der Geschäfte'
        },
        profile_verification_community_specialist_field_desiredInvestmentPeriod: {
            eng: 'Desired Investment Period',
            rus: 'Желаемый период инвестиций',
            ukr: 'Бажаний період інвестицій',
            deu: 'Gewünschter Investitionszeitraum'
        },
        profile_verification_community_specialist_field_desiredProfitability: {
            eng: 'Desired Profitability (%)',
            rus: 'Желаемая доходность (%)',
            ukr: 'Бажана дохідність (%)',
            deu: 'Gewünschte Rentabilität (%)'
        },
        profile_verification_community_specialist_field_qualifiedSpecialist: {
            eng: 'Qualified Specialist',
            rus: 'Квалифицированный специалист',
            ukr: 'Кваліфікований спеціаліст',
            deu: 'Qualifizierter Spezialist'
        },
        profile_verification_community_specialist_field_taxResidentUSA: {
            eng: 'Tax Resident USA?',
            rus: 'Налоговый резидент США?',
            ukr: 'Податковий резидент США?',
            deu: 'Steuereinwohner USA?'
        },
        profile_verification_community_specialist_professional_title: {
            eng: 'Professional Details',
            rus: 'Профессиональные данные',
            ukr: 'Професійні дані',
            deu: 'Berufliche Details'
        },
        profile_verification_community_specialist_license_title: {
            eng: 'Licenses',
            rus: 'Лицензии',
            ukr: 'Ліцензії',
            deu: 'Lizenzen'
        },
        profile_verification_community_specialist_license_add: {
            eng: 'Add License',
            rus: 'Добавить лицензию',
            ukr: 'Додати ліцензію',
            deu: 'Lizenz hinzufügen'
        },
        profile_verification_community_specialist_license_update: {
            eng: 'Update License',
            rus: 'Обновить лицензию',
            ukr: 'Оновити ліцензію',
            deu: 'Lizenz aktualisieren',
        },
        profile_verification_community_specialist_license_description: {
            eng: 'In this section, you can add up to 5 different licenses.',
            rus: 'В этом разделе вы можете добавить до 5 различных лицензий',
            ukr: 'У цьому розділі ви можете додати до 5 різних ліцензій',
            deu: 'In diesem Abschnitt können Sie bis zu 5 verschiedene Lizenzen hinzufügen.'
        },
        profile_verification_community_specialist_education_title: {
            eng: 'Education',
            rus: 'Образование',
            ukr: 'Освіта',
            deu: 'Bildung'
        },
        profile_verification_community_specialist_education_add: {
            eng: 'Add Education',
            rus: 'Добавить образование',
            ukr: 'Додати освіту',
            deu: 'Bildung hinzufügen'
        },
        profile_verification_community_specialist_education_update: {
            eng: 'Update Education',
            rus: 'Обновить образование',
            ukr: 'Оновити освіту',
            deu: 'Bildung aktualisieren',
        },
        profile_verification_community_specialist_education_description: {
            eng: 'In this section, you can add up to 5 different areas of additional education.',
            rus: 'В этом разделе вы можете добавить до 5 различных сфер дополнительного образования',
            ukr: 'У цьому розділі ви можете додати до 5 різних сфер додаткової освіти.',
            deu: 'In diesem Abschnitt können Sie bis zu 5 verschiedene Bereiche zusätzlicher Bildung hinzufügen.'
        },
        profile_verification_community_specialist_license_field_object: {
            eng: 'License number',
            rus: 'Номер лицензии',
            ukr: 'Номер ліцензії',
            deu: 'Lizenznummer'
        },
        profile_verification_community_specialist_portfolio_title: {
            eng: 'Portfolio of your objects',
            rus: 'Портфолио ваших объектов',
            ukr: 'Портфоліо ваших об\'єктів',
            deu: 'Portfolio Ihrer Objekte'
        },
        profile_verification_community_specialist_portfolio_subTitle: {
            eng: 'You can upload photos or plans of your real estate objects',
            rus: 'Вы можете загрузить фото или планы ваших объектов недвижимости',
            ukr: 'Ви можете завантажити фото або плани ваших об\'єктів нерухомості',
            deu: 'Sie können Fotos oder Pläne Ihrer Immobilienobjekte hochladen'
        },
        contract_add_object_country: {
            eng: 'Country',
            rus: 'Страна',
            ukr: 'Країна',
            deu: 'Land'
        },
        contract_add_company_country: {
            eng: 'Company Country',
            rus: 'Страна компании',
            ukr: 'Країна компанії',
            deu: 'Firmenland'
        },
        contract_add_company_contact: {
            eng: 'Company Director',
            rus: 'Директор компании',
            ukr: 'Директор компанії',
            deu: 'Unternehmensleiter'
        },
        contract_add_company_contactFullName: {
            eng: 'Company Director Full Name',
            rus: 'Полное имя директора компании',
            ukr: 'Повне ім\'я директора компанії',
            deu: 'Vollständiger Name des Firmendirektors'
        },
        profile_verification_start_button: {
            eng: 'Start Verification',
            rus: 'Пройти верификацию',
            ukr: 'Розпочати верифікацію',
            deu: 'Verifizierung starten',
        },
        profile_verification_start_alert: {
            eng: 'To use all the features of the platform, you need to go through the verification process',
            rus: 'Для использования всех возможностей платформы необходимо пройти процедуру верификации',
            ukr: 'Щоб використовувати всі можливості платформи, вам потрібно пройти процедуру верифікації',
            deu: 'Um alle Funktionen der Plattform nutzen zu können, müssen Sie den Verifizierungsprozess durchlaufen',
        },
        profile_tabs_verification: {
            eng: 'Verification',
            rus: 'Верификация',
            ukr: 'Верифікація',
            deu: 'Verifizierung',
        },
        profile_tabs_verification_commission_agreement: {
            eng: 'Commission Agreement',
            rus: 'Договор о комиссии',
            ukr: 'Договір про комісію',
            deu: 'Kommissionsvereinbarung',
        },
        profile_verification_email: {
            eng: 'Resend Link',
            rus: 'Отправить ссылку повторно',
            ukr: 'Надіслати посилання повторно',
            deu: 'Link erneut senden',
        },
        profile_verification_email_alert: {
            eng: 'Your email address is not verified. Please follow the link in the email to complete the verification process.',
            rus: 'Ваш адрес электронной почты не подтвержден. Пожалуйста, перейдите по ссылке в письме для завершения процесса верификации.',
            ukr: 'Ваша адреса електронної пошти не підтверджена. Будь ласка, перейдіть за посиланням у листі, щоб завершити процес верифікації.',
            deu: 'Ihre E-Mail-Adresse ist nicht verifiziert. Bitte folgen Sie dem Link in der E-Mail, um den Verifizierungsprozess abzuschließen.',
        },
        profile_email: {
            eng: 'Email',
            rus: 'Электронная почта',
            ukr: 'Електронна пошта',
            deu: 'E-Mail',
        },
        profile_lastActivity: {
            eng: 'Last Activity:',
            rus: 'Недавняя активность:',
            ukr: 'Недавня активність:',
            deu: 'Letzte Aktivität:',
        },
        profile_contactPerson: {
            eng: 'Contact Person',
            rus: 'Контактное лицо',
            ukr: 'Контактна особа',
            deu: 'Kontaktperson',
        },
        profile_experience: {
            eng: 'Experience: ',
            rus: 'Опыт: ',
            ukr: 'Досвід: ',
            deu: 'Erfahrung: ',
        },
        profile_experience_years: {
            eng: ' years',
            rus: ' лет',
            ukr: ' років',
            deu: ' Jahre',
        },
        profile_fundationYear: {
            eng: 'Year of foundation: ',
            rus: 'Год основания: ',
            ukr: 'Рік заснування: ',
            deu: 'Gründungsjahr: ',
        },
        profile_add_role: {
            eng: 'Add role',
            rus: 'Добавить роль',
            ukr: 'Додати роль',
            deu: 'Rolle hinzufügen',
        },
        profile_phone: {
            eng: 'Phone number',
            rus: 'Контактный телефон',
            ukr: 'Контактний телефон',
            deu: 'Telefonnummer',
        },
        profile_associations: {
            eng: 'Associations',
            rus: 'Ассоциации',
            ukr: 'Асоціації',
            deu: 'Verbände',
        },
        profile_companyNumber: {
            eng: 'Registration number',
            rus: 'Регистрационный номер',
            ukr: 'Реєстраційний номер',
            deu: 'Registrierungsnummer',
        },
        profile_specializations: {
            eng: 'Specialization',
            rus: 'Специализация',
            ukr: 'Спеціалізація',
            deu: 'Spezialisierung',
        },
        profile_businessCountries: {
            eng: 'Business countries',
            rus: 'Страны ведения бизнеса',
            ukr: 'Країни ведення бізнесу',
            deu: 'Geschäftsländer',
        },
        profile_services: {
            eng: 'Offered services',
            rus: 'Предлагаемые услуги',
            ukr: 'Пропоновані послуги',
            deu: 'Angebotene Dienstleistungen',
        },
        profile_clients: {
            eng: 'Clients',
            rus: 'Клиенты',
            ukr: 'Клієнти',
            deu: 'Kunden',
        },
        profile_realisedProjects: {
            eng: 'Completed projects',
            rus: 'Реализованные проекты',
            ukr: 'Реалізовані проекти',
            deu: 'Abgeschlossene Projekte',
        },
        profile_license: {
            eng: 'License - ',
            rus: 'Лицензия - ',
            ukr: 'Ліцензія - ',
            deu: 'Lizenz - ',
        },
        profile_maxTransactionAmount: {
            eng: 'Maximum transaction amount',
            rus: 'Максимальная сумма сделки',
            ukr: 'Максимальна сума угоди',
            deu: 'Maximaler Transaktionsbetrag',
        },
        profile_totalConstructionArea: {
            eng: 'Total built area (sq.m)',
            rus: 'Общая площадь построенного (кв.м)',
            ukr: 'Загальна площа збудованого (кв.м)',
            deu: 'Gesamtbaufläche (m²)',
        },
        profile_totalCompletedProjects: {
            eng: 'Completed projects',
            rus: 'Завершенные проекты',
            ukr: 'Завершені проекти',
            deu: 'Abgeschlossene Projekte',
        },
        profile_totalProjectsCost: {
            eng: 'Total project cost under management',
            rus: 'Стоимость объектов в управлении',
            ukr: 'Загальна вартість проектів в управлінні',
            deu: 'Gesamtkosten der verwalteten Objekte',
        },
        profile_totalWorkers: {
            eng: 'Number of employees',
            rus: 'Количество сотрудников',
            ukr: 'Кількість співробітників',
            deu: 'Anzahl der Mitarbeiter',
        },
        profile_totalFiliales: {
            eng: 'Number of branches',
            rus: 'Количество филиалов',
            ukr: 'Кількість філій',
            deu: 'Anzahl der Filialen',
        },
        profile_description: {
            eng: 'Description',
            rus: 'Описание',
            ukr: 'Опис',
            deu: 'Beschreibung',
        },
        profile_verification_community_company_name: {
            eng: 'Company name',
            rus: 'Название компании',
            ukr: 'Назва компанії',
            deu: 'Name der Firma',
        },
        profile_verification_community_company_foundationYear: {
            eng: 'Year of foundation',
            rus: 'Год основания',
            ukr: 'Рік заснування',
            deu: 'Gründungsjahr',
        },
        profile_verification_community_company_registrationNumber: {
            eng: 'Registration number',
            rus: 'Регистрационный номер',
            ukr: 'Реєстраційний номер',
            deu: 'Registrierungsnummer'
        },
        profile_verification_community_company_businessCountry: {
            eng: 'Business country',
            rus: 'Бизнес страны',
            ukr: 'Країна бізнесу',
            deu: 'Geschäftsland'
        },
        profile_verification_community_company_field_description: {
            eng: 'Company description',
            rus: 'Описание компании',
            ukr: 'Опис компанії',
            deu: 'Unternehmensbeschreibung'
        },
        profile_verification_community_company_profecional_title: {
            eng: 'Company professional data',
            rus: 'Профессиональные данные о компании',
            ukr: 'Професійні дані про компанію',
            deu: 'Unternehmensberufsdaten'
        },
        profile_verification_community_company_specialization: {
            eng: 'Specialization',
            rus: 'Специализация',
            ukr: 'Спеціалізація',
            deu: 'Spezialisierung'
        },
        profile_verification_community_company_numberOfCompletedProjects: {
            eng: 'Number of completed projects',
            rus: 'Количество реализованных проектов',
            ukr: 'Кількість завершених проектів',
            deu: 'Anzahl der abgeschlossenen Projekte'
        },
        profile_verification_community_company_totalNumberOfConstructedSquareMeters: {
            eng: 'Total constructed square meters',
            rus: 'Общая площадь построенного (кв.м)',
            ukr: 'Загальна площа побудованого (кв. м)',
            deu: 'Gesamtbaufläche (Quadratmeter)'
        }, profile_verification_community_company_biggestProjects: {
            eng: 'Biggest Projects',
            rus: 'Наиболее крупные проекты',
            ukr: 'Найбільші проекти',
            deu: 'Größte Projekte'
        },
        profile_verification_community_company_managementObjectsValue: {
            eng: 'Management Objects Value',
            rus: 'Стоимость объектов в управлении',
            ukr: 'Вартість об\'єктів управління',
            deu: 'Wert der Verwaltungsobjekte'
        },
        profile_verification_community_company_managementObjectsQuantity: {
            eng: 'Management Objects Quantity',
            rus: 'Количество объектов в управлении',
            ukr: 'Кількість об\'єктів управління',
            deu: 'Anzahl der Verwaltungsobjekte'
        },
        profile_verification_community_company_employees: {
            eng: 'Number of Employees',
            rus: 'Количество сотрудников',
            ukr: 'Кількість співробітників',
            deu: 'Anzahl der Mitarbeiter'
        },
        profile_verification_community_company_implementedProjects: {
            eng: 'Implemented Projects',
            rus: 'Реализованные проекты',
            ukr: 'Реалізовані проекти',
            deu: 'Umgesetzte Projekte'
        },
        profile_verification_community_company_investmentAreas: {
            eng: 'Investment Areas',
            rus: 'Сферы для инвестиций',
            ukr: 'Сфери для інвестицій',
            deu: 'Investitionsbereiche'
        },
        profile_verification_community_company_ownTenantBase: {
            eng: 'Own Tenant Base',
            rus: 'Наличие собственной базы арендаторов',
            ukr: 'Наявність власної бази орендарів',
            deu: 'Eigener Mieterstamm'
        },
        profile_verification_community_company_services: {
            eng: 'Offered Services',
            rus: 'Предлагаемые услуги',
            ukr: 'Пропоновані послуги',
            deu: 'Angebotene Dienstleistungen'
        },
        profile_verification_community_company_clients: {
            eng: 'Your Clients',
            rus: 'Ваши клиенты',
            ukr: 'Ваші клієнти',
            deu: 'Ihre Kunden'
        },
        profile_verification_community_company_membersNumber: {
            eng: 'Number of Members',
            rus: 'Количество членов',
            ukr: 'Кількість членів',
            deu: 'Anzahl der Mitglieder'
        },
        profile_verification_community_company_branchesNumber: {
            eng: 'Number of Branches',
            rus: 'Количество филиалов',
            ukr: 'Кількість філіалів',
            deu: 'Anzahl der Zweigstellen'
        },
        profile_verification_community_organization_logo_title: {
            eng: 'Organization Logo',
            rus: 'Логотип организации',
            ukr: 'Логотип організації',
            deu: 'Organisationslogo'
        }, profile_verification_community_organization_logo_title_sub: {
            eng: 'Upload your organization\'s logo for additional personalization. This will also help potential clients find your company easier on the GREM platform.',
            rus: 'Загрузите логотип вашей организации, чтобы обеспечить дополнительную персонализацию. Это также поможет потенциальным клиентам легче находить вашу компанию на платформе GREM',
            ukr: 'Завантажте логотип вашої організації, щоб забезпечити додаткову персоналізацію. Це також допоможе потенційним клієнтам легше знаходити вашу компанію на платформі GREM',
            deu: 'Laden Sie das Logo Ihrer Organisation hoch, um eine zusätzliche Personalisierung zu erhalten. Dies hilft auch potenziellen Kunden, Ihr Unternehmen auf der GREM-Plattform leichter zu finden.'
        },
        profile_verification_community_company_certificateScan_title: {
            eng: 'Company Establishment Certificate',
            rus: 'Сертификат об учреждении компании',
            ukr: 'Сертифікат про утворення компанії',
            deu: 'Unternehmensgründungszertifikat'
        },
        profile_verification_community_company_certificateScan_title_sub: {
            eng: 'To confirm the existence of your company, you can add a company establishment certificate.',
            rus: 'Для подтверждения факта существования компании, вы можете добавить сертификат об учреждении.',
            ukr: 'Для підтвердження факту існування вашої компанії, ви можете додати сертифікат про утворення компанії.',
            deu: 'Um die Existenz Ihres Unternehmens zu bestätigen, können Sie ein Unternehmensgründungszertifikat hinzufügen.'
        },
        profile_verification_community_company_contact_title: {
            eng: 'Contact Information',
            rus: 'Информация о контактном лице',
            ukr: 'Контактна інформація',
            deu: 'Kontaktinformationen'
        },
        profile_verification_community_company_contact_fname: {
            eng: 'First Name',
            rus: 'Имя контактного лица',
            ukr: 'Ім\'я контактної особи',
            deu: 'Vorname des Kontakts'
        },
        profile_verification_community_company_contact_lname: {
            eng: 'Last Name',
            rus: 'Фамилия контактного лица',
            ukr: 'Прізвище контактної особи',
            deu: 'Nachname des Kontakts'
        },
        profile_verification_community_company_contact_position: {
            eng: 'Position',
            rus: 'Должность',
            ukr: 'Посада',
            deu: 'Position'
        },
        profile_verification_community_company_contact_phone: {
            eng: 'Contact Phone',
            rus: 'Контактный телефон',
            ukr: 'Контактний телефон',
            deu: 'Kontakttelefon'
        },
        profile_verification_community_company_contact_email: {
            eng: 'Email',
            rus: 'Электронная почта',
            ukr: 'Електронна пошта',
            deu: 'E-Mail'
        },
        profile_verification_community_specialist_license_title_sub: {
            eng: 'In this section, you can add up to 5 different licenses confirming your professional activity.',
            rus: 'В этом разделе вы можете добавить до 5 различных лицензий, подтверждающих вашу профессиональную деятельность',
            ukr: 'У цьому розділі ви можете додати до 5 різних ліцензій, що підтверджують вашу професійну діяльність.',
            deu: 'In diesem Abschnitt können Sie bis zu 5 verschiedene Lizenzen hinzufügen, die Ihre berufliche Tätigkeit bestätigen.'
        },
        profile_verification_community_specialist_association_title: {
            eng: 'Associations and Memberships',
            rus: 'Ассоциации и членства',
            ukr: 'Асоціації та членство',
            deu: 'Verbände und Mitgliedschaften'
        },
        profile_verification_community_specialist_association_title_sub: {
            eng: 'If you are a member of public organizations or associations, please specify them below.',
            rus: 'Если вы являетесь членом общественных организаций или ассоциаций, пожалуйста, укажите их ниже',
            ukr: 'Якщо ви є членом громадських організацій або асоціацій, будь ласка, вкажіть їх нижче.',
            deu: 'Wenn Sie Mitglied von öffentlichen Organisationen oder Verbänden sind, geben Sie diese bitte unten an.'
        },
        profile_verification_community_specialist_association_add: {
            eng: 'Add Association',
            rus: 'Добавить ассоциацию',
            ukr: 'Додати асоціацію',
            deu: 'Verband hinzufügen'
        },
        profile_verification_user_country: {
            eng: 'Country*',
            rus: 'Страна*',
            ukr: 'Країна*',
            deu: 'Land*'
        },
        complex_platform_services_modal_request_fields: {
            eng: 'Please fill in all required fields',
            rus: 'Заполните все обязательные поля',
            ukr: 'Заповніть всі обов\'язкові поля',
            deu: 'Bitte füllen Sie alle erforderlichen Felder aus'
        },
        close: {
            eng: 'Close',
            rus: 'Закрыть',
            ukr: 'Закрити',
            deu: 'Schließen',
        },
        complex_platform_services_modal_request_error: {
            eng: 'Error sending request',
            rus: 'Ошибка при отправке заявки',
            ukr: 'Помилка при відправленні запиту',
            deu: 'Fehler beim Senden der Anfrage'
        },
        complex_platform_services_modal_request_success: {
            eng: 'Thank you for your inquiry! Our team will contact you shortly.',
            rus: 'Мы благодарим вас за обращение! Наша команда свяжется с вами в ближайшее время.',
            ukr: 'Дякуємо за ваш запит! Наша команда зв\'яжеться з вами найближчим часом.',
            deu: 'Vielen Dank für Ihre Anfrage! Unser Team wird sich in Kürze bei Ihnen melden.'
        },
        complex_platform_services_modal_request_field_name: {
            eng: 'Enter your full name',
            rus: 'Укажите ФИО',
            ukr: 'Введіть ваше повне ім\'я',
            deu: 'Geben Sie Ihren vollständigen Namen ein'
        },
        complex_platform_services_modal_request_field_phone: {
            eng: 'Your phone number',
            rus: 'Ваш телефон',
            ukr: 'Ваш номер телефону',
            deu: 'Ihre Telefonnummer'
        },
        complex_platform_services_modal_request_field_description: {
            eng: 'Comment',
            rus: 'Комментарий',
            ukr: 'Коментар',
            deu: 'Kommentar'
        },
        complex_platform_services_modal_request_serviced_3d: {
            eng: '3D visualization of the facade of the object',
            rus: '3D-визуализации фасада объекта',
            ukr: '3D-візуалізація фасаду об\'єкту',
            deu: '3D-Visualisierung der Fassade des Objekts'
        },
        complex_platform_services_modal_request_serviced_promotion: {
            eng: 'Promotion of the object',
            rus: 'Продвижение объекта',
            ukr: 'Продвиження об\'єкту',
            deu: 'Förderung des Objekts'
        },
        complex_platform_services_modal_request_serviced_landing: {
            eng: 'Creating a website or landing page',
            rus: 'Создание веб-сайта или лендинга',
            ukr: 'Створення веб-сайту або лендінгу',
            deu: 'Erstellung einer Website oder Landingpage'
        },
        profile_resend_confirm_email: {
            eng: 'Email successfully sent to your mailbox',
            rus: 'Письмо успешно отправлено на ваш почтовый ящик',
            ukr: 'Лист успішно відправлено на вашу поштову скриньку',
            deu: 'E-Mail erfolgreich an Ihren Posteingang gesendet'
        },
        update_success: {
            eng: 'Data successfully updated',
            rus: 'Данные успешно обновлены',
            ukr: 'Дані успішно оновлені',
            deu: 'Daten erfolgreich aktualisiert'
        },
        profile_verification_community_company_title: {
            eng: 'Company Overview',
            rus: 'Общие данные о компании',
            ukr: 'Огляд компанії',
            deu: 'Unternehmensübersicht'
        },
        profile_verification_community_specialist_education_button: {
            eng: 'Upload certificate scan',
            rus: 'Загрузите скан сертификата',
            ukr: 'Завантажте скан сертифіката',
            deu: 'Laden Sie den Scan des Zertifikats hoch'
        },
        profile_verification_community_specialist_license_button: {
            eng: 'Upload License Scan',
            rus: 'Загрузите скан лицензии',
            ukr: 'Завантажити скан ліцензії',
            deu: 'Lizenzscan hochladen'
        },
        profile_verification_community_company_certificateScan_button: {
            eng: 'Add certificate',
            rus: 'Добавить сертификат',
            ukr: 'Додати сертифікат',
            deu: 'Zertifikat hinzufügen'
        },
        profile_verification_community_organization_logo_button: {
            eng: 'Add logo',
            rus: 'Добавить логотип',
            ukr: 'Додати логотип',
            deu: 'Logo hinzufügen'
        },
        number: {
            eng: 'Number',
            rus: 'Номер',
            ukr: 'Номер',
            deu: 'Nummer'
        },
        save_changes: {
            eng: 'Save Changes',
            rus: 'Сохранить изменения',
            ukr: 'Зберегти зміни',
            deu: 'Änderungen speichern'
        },
        property_add_common_title_sub: {
            eng: 'Here you can enter a code for the property. For example, a code in your database or elsewhere.',
            rus: 'Здесь вы можете ввести код для объекта недвижимости. Например код в вашей базе данных или в другом месте.',
            ukr: 'Тут ви можете ввести код для об\'єкта нерухомості. Наприклад, код у вашій базі даних або в іншому місці.',
            deu: 'Hier können Sie einen Code für die Immobilie eingeben. Zum Beispiel einen Code in Ihrer Datenbank oder anderswo.'
        },
        property_add_common_landmark_info: {
            eng: 'You can add landmarks, for example, a well-known or prominent object nearby.',
            rus: 'Вы можете добавить ориентиры, например, хорошо известный или заметный объект, находящийся поблизости',
            ukr: 'Ви можете додати орієнтири, наприклад, добре відомий або визначний об\'єкт поблизу.',
            deu: 'Sie können markante Punkte hinzufügen, zum Beispiel ein bekanntes oder auffälliges Objekt in der Nähe.'
        },
        property_add_technical_sub: {
            eng: 'Additional Parameters',
            rus: 'Дополнительные параметры',
            ukr: 'Додаткові параметри',
            deu: 'Zusätzliche Parameter'
        },
        property_add_technical_plans_title: {
            eng: 'Property/Floor Layout',
            rus: 'Планировка объекта/этажа',
            ukr: 'План об\'єкта/поверху',
            deu: 'Gebäude-/Stockwerkslayout'
        },
        property_technical_plans_title: {
            eng: 'Property layout',
            rus: 'Планировка объекта',
            ukr: 'План об\'єкта',
            deu: 'Anlagenlayout'
        },
        property_add_technical_plans_title_sub: {
            eng: 'You can add a layout of the property or floor. This will allow for a more detailed understanding of the space and layout.',
            rus: 'Вы можете добавить план объекта или этажа. Это позволит более детально ознакомится с пространством и планировкой ',
            ukr: 'Ви можете додати план об\'єкта або поверху. Це дозволить більш детально ознайомитися з простором та плануванням.',
            deu: 'Sie können einen Grundriss des Gebäudes oder des Stockwerks hinzufügen. Dies ermöglicht ein genaueres Verständnis des Raums und des Layouts.'
        },
        property_add_technical_plan_button: {
            eng: 'Upload the plan',
            rus: 'Загрузите план',
            ukr: 'Завантажте план',
            deu: 'Den Plan herunterladen'
        },
        property_add_photos_title_sub: {
            eng: 'The more photos and other materials you provide, the higher the chances of making a deal.',
            rus: 'Чем больше фото и других материалов вы предоставите, тем выше вероятность совершения сделки',
            ukr: 'Чим більше фотографій та інших матеріалів ви надаєте, тим вища ймовірність укладення угоди.',
            deu: 'Je mehr Fotos und andere Materialien Sie zur Verfügung stellen, desto höher sind die Chancen auf einen Abschluss.'
        },
        property_add_documents_owner_title: {
            eng: 'Ownership documents',
            rus: 'Документы, подтверждающие право собственности',
            ukr: 'Документи, що підтверджують право власності',
            deu: 'Eigentumsdokumente'
        },
        property_add_documents_agency_title: {
            eng: 'Agency agreement (or exclusive agreement)',
            rus: 'Агентский договор (или договор эксклюзива)',
            ukr: 'Агентський договір (або ексклюзивний договір)',
            deu: 'Agenturvertrag (oder Exklusivvertrag)'
        },
        property_add_documents_agency_title_sub: {
            eng: 'To list a property for sale within the GREM platform, you need to upload an agency agreement (or exclusive agreement) confirming your right to sell this real estate property.',
            rus: 'Для размещения объекта на продажу внутри платформы GREM вам необходимо загрузить агентский договор (или договор эксклюзива), подтверждающий ваше право на продажу данного объекта недвижимости',
            ukr: 'Для розміщення об\'єкта на продаж в середині платформи GREM вам потрібно завантажити агентський договір (або ексклюзивний договір), що підтверджує ваше право на продаж даного об\'єкта нерухомості.',
            deu: 'Um eine Immobilie innerhalb der GREM-Plattform zum Verkauf anzubieten, müssen Sie einen Agenturvertrag (oder Exklusivvertrag) hochladen, der Ihr Recht zum Verkauf dieser Immobilie bestätigt.'
        },
        have: {
            eng: 'Yes',
            rus: 'Есть',
            ukr: 'Є',
            deu: 'Ja',
        },
        property_add_technical_balcony_havent: {
            eng: 'No balcony',
            rus: 'Нет балкона',
            ukr: 'Немає балкона',
            deu: 'Kein Balkon',
        },
        property_add_technical_parking_area_havent: {
            eng: 'No parking area',
            rus: 'Нет парковки',
            ukr: 'Немає парковки',
            deu: 'Kein Parkplatz vorhanden',
        },
        property_item_docs_title: {
            eng: "Documents Added by You",
            rus: "Добавленные вами документы",
            ukr: "Документи, додані вами",
            deu: "Von Ihnen hinzugefügte Dokumente"
        },
        tab_overview: {
            eng: "Project Overview",
            rus: "Обзор проекта",
            ukr: "Огляд проекту",
            deu: "Projektübersicht"
        },
        owner_tab_overview: {
            eng: "Property Information",
            rus: "Информация об объекте",
            ukr: "Інформація про об'єкт",
            deu: "Objektinformation"
        },

        tab_documents: {
            eng: "Documents",
            rus: "Документы",
            ukr: "Документи",
            deu: "Dokumente"
        },
        tab_analytics: {
            eng: 'Analytics',
            rus: 'Аналитика',
            ukr: 'Аналітика',
            deu: 'Analytik'
        },
        property_technical_balcony: {
            eng: 'Balcony',
            rus: 'Балкон',
            ukr: 'Балкон',
            deu: 'Balkon',
        },
        property_technical_price: {
            eng: "Object Price:",
            rus: "Стоимость объекта:",
            ukr: "Вартість об'єкта:",
            deu: "Objektpreis:"
        },
        tab_contract: {
            eng: 'Commission Contract',
            rus: 'Договор о комиссии',
            ukr: 'Договір про комісію',
            deu: 'Provisionsvertrag'
        },
        tab_landing: {
            eng: 'Object Landing',
            rus: 'Лендинг объекта',
            ukr: 'Лендінг об\'єкта',
            deu: 'Objekt-Landing'
        },
        property_technical_parking: {
            eng: 'Parking',
            rus: 'Парковка',
            ukr: 'Паркування',
            deu: 'Parkplatz'
        },
        email_confirm: {
            eng: 'Email confirmed',
            rus: 'Почта подтверждена',
            ukr: 'Електронна пошта підтверджена',
            deu: 'E-Mail bestätigt'
        },
        profile_update_success: {
            eng: 'Profile successfully updated',
            rus: 'Профиль успешно обновлен',
            ukr: 'Профіль успішно оновлено',
            deu: 'Profil erfolgreich aktualisiert'
        },
        layout_cabinet_footer_sub_terms: {
            eng: 'Terms & Conditions',
            rus: 'Положения и Условия',
            ukr: 'Умови та Положення',
            deu: 'Geschäftsbedingungen'
        },
        layout_cabinet_footer_sub_privacy: {
            eng: 'Privacy Policy',
            rus: 'Политика Конфиденциальности',
            ukr: 'Політика Конфіденційності',
            deu: 'Datenschutz-Bestimmungen'
        },
        layout_cabinet_footer_sub_anti_money: {
            eng: 'Anti-Money Laundering\n',
            rus: 'Противодействие отмыванию денег',
            ukr: 'Протидія відмиванню грошей',
            deu: 'Geldwäschebekämpfung'
        },
        layout_cabinet_footer_copyright: {
            eng: 'Company number 14177574, 2017 - ',
            rus: 'Номер компании 14177574, 2017 - ',
            ukr: 'Номер компанії 14177574, 2017 - ',
            deu: 'Firmennummer 14177574, 2017 - '
        },
        profile_avatar_error: {
            eng: 'Add your avatar',
            rus: 'Добавьте ваш аватар',
            ukr: 'Додайте свій аватар',
            deu: 'Fügen Sie Ihren Avatar hinzu'
        },
        community_form_sent: {
            eng: 'Community form sent',
            rus: 'Форма отправлена',
            ukr: 'Форма надіслана',
            deu: 'Gemeinschaftsformular gesendet'
        },
        contract_object_info: {
            eng: 'Object Information',
            rus: 'Информация об объекте',
            ukr: 'Інформація про об\'єкт',
            deu: 'Objektinformation'
        },
        contract_object_location: {
            eng: 'Object Location',
            rus: 'Расположение объекта',
            ukr: 'Місцезнаходження об\'єкта',
            deu: 'Objektstandort'
        },
        contract_object_seller: {
            eng: 'To list an object for sale within the GREM platform, you need to provide documents confirming your ownership rights and your identity (passport). It is important that the information provided in the ownership document matches your personal information.',
            rus: 'Для размещения объекта на продажу внутри платформы GREM, требуется предоставить документы, подтверждающие ваше право собственности и вашу личность (паспорт). Важно, чтобы информация, указанная в документе на праве собственности совпадала с личной информацией о вас.',
            ukr: 'Для розміщення об\'єкта на продаж у межах платформи GREM, потрібно надати документи, що підтверджують ваші права власності та вашу особу (паспорт). Важливо, щоб інформація, зазначена в документі про право власності, відповідала вашим особистим даним.',
            deu: 'Um ein Objekt innerhalb der GREM-Plattform zum Verkauf anzubieten, müssen Sie Dokumente vorlegen, die Ihre Eigentumsrechte und Ihre Identität (Pass) bestätigen. Es ist wichtig, dass die im Eigentumsdokument angegebene Information mit Ihren persönlichen Daten übereinstimmt.'
        },
        contract_object_seller_passport: {
            eng: 'Upload passport scan',
            rus: 'Загрузить скан паспорта',
            ukr: 'Завантажити скан паспорта',
            deu: 'Reisepass-Scan hochladen'
        },
        contract_object_seller_passport_scan: {
            eng: 'Passport scan',
            rus: 'Скан паспорта',
            ukr: 'Скан паспорта',
            deu: 'Scan hochladen'
        },
        contract_object_seller_owner: {
            eng: 'Upload ownership document',
            rus: 'Загрузить право собственности',
            ukr: 'Завантажити документ власності',
            deu: 'Eigentumsdokument hochladen'
        },
        contract_object_seller_owner_scan: {
            eng: 'Ownership document',
            rus: 'Право собственности',
            ukr: 'Документ власності',
            deu: 'Eigentumsurkunde'
        },

        file_loader_drop_loaded: {
            eng: 'File loaded',
            rus: 'Файл загружен',
            ukr: 'Файл завантажено',
            deu: 'Datei geladen'
        },
        file_loader_drop_open_file: {
            eng: 'Open file',
            rus: 'Открыть файл',
            ukr: 'Відкрити файл',
            deu: 'Datei öffnen'
        },
        community_license: {
            eng: 'License',
            rus: 'Лицензия',
            ukr: 'Ліцензія',
            deu: 'Lizenz'
        },
        community_education: {
            eng: 'Education Document',
            rus: 'Документ об образовании',
            ukr: 'Документ про освіту',
            deu: 'Bildungsdokument'
        },
        community_license_name: {
            eng: 'License Name',
            rus: 'Название лицензии',
            ukr: 'Назва ліцензії',
            deu: 'Lizenzname'
        },
        community_education_name: {
            eng: 'Education Name',
            rus: 'Название',
            ukr: 'Назва',
            deu: 'Bildungsname'
        },
        faq_title: {
            eng: 'Frequently Asked Questions',
            rus: 'Часто задаваемые вопросы',
            ukr: 'Часто задавані питання',
            deu: 'Häufig gestellte Fragen'
        },
        faq_alarm_1: {
            eng: 'Our knowledge base and FAQ section are updated daily. If you do not find an answer to your question, do not hesitate to contact our',
            rus: 'Наша база знаний и раздел с ответами на часто задаваемые вопросы ежедневно обновляются. Если вы не найдёте ответ на свой вопрос, не стесняйтесь обратиться в нашу',
            ukr: 'Наша база знань та розділ з відповідями на часто задавані питання щодня оновлюються. Якщо ви не знайдете відповідь на своє питання, не соромтеся звернутися до нашої',
            deu: 'Unsere Wissensdatenbank und FAQ-Bereich werden täglich aktualisiert. Wenn Sie keine Antwort auf Ihre Frage finden, zögern Sie nicht, sich an unser'
        },
        faq_alarm_2: {
            eng: 'support service',
            rus: 'службу поддержки',
            ukr: 'службу підтримки',
            deu: 'Support-Service'
        },
        faq_item_title_1: {
            "eng": "What is the GREM platform and what does it offer?",
            "rus": "Что такое платформа GREM и что она предлагает?",
            "ukr": "Що таке платформа GREM та що вона пропонує?",
            "deu": "Was ist die GREM-Plattform und was bietet sie?"
        },
        faq_item_text_1: {
            "eng": "GREM is a convenient, simple, and secure platform for working on the international real estate market online. GREM not only unites various real estate professionals, but also provides modern technological tools for buying, selling, searching for financing, and marketing promotion of all types of real estate or development projects.",
            "rus": "GREM – это удобная, простая и безопасная платформа для работы на международном рынке недвижимости онлайн. GREM не только объединяет различных специалистов в сфере недвижимости, а также предоставляет современные технологические инструменты для покупки, продажи, поиска финансирования и маркетингового продвижения всех типов объектов недвижимости или девелоперских проектов.",
            "ukr": "GREM – це зручна, проста та безпечна платформа для роботи на міжнародному ринку нерухомості онлайн. GREM не тільки об'єднує різних спеціалістів у сфері нерухомості, а також надає сучасні технологічні інструменти для купівлі, продажу, пошуку фінансування та маркетингового просування всіх типів об'єктів нерухомості чи девелоперських проєктів.",
            "deu": "The GREM platform - is a streamlined, user-friendly, and secure platform for working on the international real estate market online. It serves as a hub for various real estate professionals, offering cutting-edge technological solutions for property transactions, financing, and marketing promotion of all types of real estate or development projects."
        },
        faq_item_title_2: {
            "eng": "How to complete full registration on the platform?",
            "rus": "Как пройти полную регистрацию на платформе?",
            "ukr": "Як пройти повну реєстрацію на платформі?",
            "deu": 'Wie kann ich die vollständige Registrierung auf der Plattform abschließen?'
        },
        faq_item_text_2: {
            "eng": `The registration process on the GREM platform entails primary and secondary steps. Upon initial registration, users create their accounts by providing a valid email address, setting up a password, and verifying the email. Alternatively, users can opt for registration through their Google, Facebook, or LinkedIn accounts. Secondary registration involves selecting a role. This is a quick and easy step that will give you access to the full functionality of the GREM platform, including asset tokenization, tracking applications in the CRM system, communication with any community expert, and much more.
            A.\tReal Estate Market Participant: Suitable for independent professionals.
            B.\tCompanies: For business owners or legal representatives.
            C.\tPublic Organization: Intended for official representatives of public real estate entities.
            Further details on secondary registration and its stages are available here.`,
            "rus": `На платформе предусмотрена первичная и вторичная регистрация. При первичной регистрации вы создаете свой аккаунт. Для этого необходимо ввести ваш действующий e-mail, придумать и ввести пароль для входа в GREM, а затем подтвердить e-mail. Создать учетную запись также можно с помощью аккаунтов Google, Facebook или LinkedIn. Вторичная регистрация предполагает выбор роли. Это быстрый и простой этап, который даст вам доступ к полному функционалу платформы GREM, в том числе к токенизации активов, отслеживанию заявок в CRM-системе, коммуникации с любым экспертом сообщества и многому другому.
            a) Участник рынка недвижимости – если вы  работаете самостоятельно
            b) Компании – если владеете компанией или являетесь ее законным представителем
            c) Общественной  организации – если вы являетесь официальным представителем одной из общественных организаций по недвижимости.`,
            "ukr": `На платформі передбачена первинна та вторинна реєстрація. При первинній реєстрації ви створюєте свій обліковий запис. Для цього необхідно ввести ваш діючий e-mail, вигадати та ввести пароль для входу в GREM, а потім підтвердити e-mail. Створити обліковий запис можна також за допомогою облікових записів Google, Facebook або LinkedIn. Вторинна реєстрація передбачає вибір ролі. Це швидкий та простий етап, який дасть вам доступ до повного функціоналу платформи GREM, у тому числі до токенізації активів, відстеження заявок у CRM-системі, комунікації з будь-яким експертом спільноти та багато іншого.
            a)\tУчасник ринку нерухомості – якщо ви працюєте самостійно
            b)\tКомпанії – якщо володієте компанією чи є її законним представником
            c)\tГромадська організація – якщо ви є офіційним представником однієї з громадських організацій з нерухомості`,
            "deu": `Die Plattform bietet eine Erst- und eine Zweitregistrierung. Bei der Erstregistrierung erstellen Sie Ihr Konto. Dazu müssen Sie Ihre gültige E-Mail-Adresse eingeben, ein Passwort erstellen und eingeben, um sich bei GREM anzumelden, und dann Ihre E-Mail bestätigen. Sie können auch ein Konto über Ihr Google-, Facebook- oder LinkedIn-Konto einrichten. Bei der Sekundärregistrierung müssen Sie eine Rolle auswählen. Dies ist ein schneller und einfacher Schritt, der Ihnen Zugang zu allen Funktionen der GREM-Plattform verschafft, einschließlich der Tokenisierung von Vermögenswerten, der Verfolgung von Anträgen im CRM-System, der Kommunikation mit allen Community-Experten und vielem mehr.
            a) Immobilienmarktteilnehmer - wenn Sie selbständig arbeiten
            b) Unternehmen - wenn Sie ein Unternehmen besitzen oder dessen gesetzlicher Vertreter sind
            c) Öffentliche Organisation - wenn Sie ein offizieller Vertreter einer der öffentlichen Immobilienorganisationen sind`
        },
        faq_item_title_3: {
            "eng": "Who can use all the functionality of the platform?",
            "rus": "Кто может использовать весь функционал платформы?",
            "ukr": "Хто може використовувати весь функціонал платформи?",
            "deu": "Wer kann alle Funktionen der Plattform nutzen?"
        },
        faq_item_text_3: {
            "eng": "The comprehensive features of the platform are accessible to users who have completed both primary and secondary registration stages and have chosen a specific role within the platform.",
            "rus": "Полный функционал платформы могут использовать пользователи, прошедшие первичный и вторичный этапы регистрации и выбравшие определенную роль на платформе.",
            "ukr": "Повний функціонал платформи можуть використовувати користувачі, які пройшли первинний та вторинний етапи реєстрації та вибрали певну роль на платформі.",
            "deu": "Die umfassenden Funktionen der Plattform stehen Benutzern zur Verfügung, die sowohl den primären als auch den sekundären Registrierungsprozess abgeschlossen und eine spezifische Rolle innerhalb der Plattform gewählt haben."
        },
        faq_item_title_4: {
            "eng": "What is a personal account and its functionality?",
            "rus": "Что такое личный кабинет и его функционал?",
            "ukr": "Що таке особистий кабінет та його функціонал?",
            "deu": "Was ist ein persönliches Konto und seine Funktionalität?"
        },
        faq_item_text_4: {
            "eng": "The GREM personal account serves as an individual workspace where users interact with other participants, upload property objects or projects, engage in contractual agreements, and execute property transactions. The personal account provides access to the platform's complete array of functionalities.",
            "rus": "Личный кабинет GREM – это ваше рабочее место. Это то пространство где вы связываетесь с другими участниками, загружаете ваши объекты или проекты, заключаете договора, проводите сделки купли-продажи и т.д. В личном кабинете доступен самый полный функционал на платформе.",
            "ukr": "Особистий кабінет GREM – це ваше робоче місце. Це той простір, де ви зв'язуєтеся з іншими учасниками, завантажуєте ваші об'єкти або проекти, укладаєте договори, здійснюєте операції з купівлі-продажу тощо. В особистому кабінеті доступний найповніший функціонал на платформі.",
            "deu": "Das GREM-Personalkonto dient als individueller Arbeitsbereich, in dem Benutzer mit anderen Teilnehmern interagieren, Immobilienobjekte oder Projekte hochladen, Verträge abschließen und Immobilientransaktionen durchführen. Das persönliche Konto bietet Zugriff auf das gesamte Funktionsangebot der Plattform."
        },
        faq_item_title_5: {
            "eng": "How can I sell objects through the platform?",
            "rus": "Как я могу продавать объекты через платформу?",
            "ukr": "Як я можу продавати об'єкти через платформу?",
            "deu": "Wie kann ich Objekte über die Plattform verkaufen?"
        },
        faq_item_text_5: {
            "eng": "To list real estate properties for sale on the platform, users must undergo full registration, selecting the role of \"Broker\" for those, who are professionally engaged in selling real estate or \"Seller\" for property owners. Subsequently, users gain access to their personal accounts, where they can input property details. To have a property listed in the platform's general list of all objects and create a personalized landing page, users must initiate the signing of a commission split agreement within their accounts.",
            "rus": "Для продажи недвижимости через платформу вам необходимо пройти полную регистрацию на платформе, выбрав роль «Брокер» , если вы профессионально занимаетесь продажей недвижимости, или «Продавец», если вы собираетесь продать принадлежащий вам объект недвижимости. После этого вы получите доступ в личный кабинет, где сможете внести информацию о вашем объекте. Для того, чтобы объект стал доступен в общем списке всех объектов на платформе и для него был сформирован персональный лендинг, Вам необходимо в своем кабинете инициировать подписание договора о разделении комиссии.",
            "ukr": "Для продажу нерухомості через платформу вам необхідно пройти повну реєстрацію на платформі, вибравши роль «Брокер», якщо ви професійно займаєтеся продажем нерухомості, або «Продавець», якщо ви збираєтеся продати об'єкт нерухомості, що належить вам. Після цього ви отримаєте доступ до особистого кабінету, де зможете внести інформацію про ваш об'єкт. Для того, щоб об'єкт став доступним у загальному списку всіх об'єктів на платформі та для нього був сформований персональний лендинг, Вам необхідно у своєму кабінеті ініціювати підписання договору про поділ комісії.",
            "deu": "Um Immobilien über die Plattform zu verkaufen, müssen Benutzer eine vollständige Registrierung durchlaufen und die Rolle des „Maklers“ auswählen, wenn sie beruflich im Immobilienverkauf tätig sind, oder „Verkäufer“, wenn sie eine Immobilie verkaufen möchten. Anschließend erhalten Benutzer Zugriff auf ihre persönlichen Konten, in denen sie Details zur Immobilie eingeben können. Um eine Immobilie im allgemeinen Listen aller Objekte der Plattform aufzulisten und eine personalisierte Landingpage zu erstellen, müssen Benutzer innerhalb ihrer Konten die Unterzeichnung eines Provisionsaufteilungsvertrags initiieren."
        },
        faq_item_title_6: {
            "eng": "How can I attract funding for Projects through the platform?",
            "rus": "Как я могу привлекать финансирование для Проектов через платформу?",
            "ukr": "Як я можу залучити фінансування для проектів через платформу?",
            "deu": 'Wie kann ich über die Plattform Finanzierung für Projekte anziehen?'
        },
        faq_item_text_6: {
            "eng": "After entering information about your project, it undergoes an expert assessment to ensure it meets the parameters necessary for its correct implementation on the GREM platform. For this project, a certain number of tokens are emitted (the number of tokens is equal to the number of square meters), and then a smart contract is created. The number of tokens corresponds to the project's square meter and is collateralized by the project itself. These tokens are available for purchase to the platform's 10,000+ users, thereby facilitating project funding.",
            "rus": "После внесения информации о вашем проекте, он проходит экспертную оценку на соответствие параметрам, необходимым для его правильной реализации на платформе GREM. Для этого проекта эмитируется определенное количество токенов (количество токенов равно количеству квадратных метров), а затем выпускается смарт-контракт. Количество токенов зависит от количества квадратных метров самого проекта и обеспечено ими. Токены доступны для покупки всем 10000+ пользователям платформы, обеспечивая финансирование проекта.",
            "ukr": "Після внесення інформації про ваш проект він проходить експертну оцінку на відповідність параметрам, необхідним для його правильної реалізації на платформі GREM. Для цього проекту емітується певна кількість токенів (кількість токенів дорівнює кількості квадратних метрів), а потім випускається смарт-контракт. Кількість токенів залежить від кількості квадратних метрів самого проекту та забезпечено ними. Токени доступні для покупки всім 10000+ користувачам платформи, забезпечуючи фінансування проекту.",
            "deu": 'Nach Eingabe von Informationen zu Ihrem Projekt wird es einer Expertenbewertung unterzogen, um sicherzustellen, dass es die für die korrekte Umsetzung auf der GREM-Plattform erforderlichen Parameter erfüllt. Für dieses Projekt werden eine bestimmte Anzahl von Token emittiert (die Anzahl der Token entspricht der Anzahl der Quadratmeter), und dann wird ein Smart-Vertrag erstellt. Die Anzahl der Token entspricht der Quadratmeterzahl des Projekts und ist durch das Projekt selbst besichert. Diese Token sind für den Kauf durch die 10.000+ Benutzer der Plattform verfügbar und erleichtern so die Projektfinanzierung.'
        },
        faq_item_title_7: {
            "eng": "What is a landing page and personal link of an object/project?",
            "rus": "Что такое лендинг и персональная ссылка объекта/ проекта?",
            "ukr": "Що таке лендинг та персональне посилання об'єкта/проекту?",
            "deu": 'Was ist eine Landingpage und ein persönlicher Link eines Objekts/Projekts?'
        },
        faq_item_text_7: {
            "eng": "A landing page serves as a personalized presentation page for your property, akin to a concise, one-page website designed to attract potential clients (leads). Applications originating from the landing page seamlessly integrate into the CRM system. The personal link functions as the unique URL for your object/project on the internet. You can create a landing page by providing all necessary information about the object or project within your personal account on the platform.",
            "rus": "Лэндинг – это персональная страница вашего объекта – аналог одностраничного презентационного сайта, который должен привлекать потенциальных клиентов (лидов). Заявки с лендинга автоматически попадают в CRM систему. Персональная ссылка – это URL-адрес вашего объекта/ проекта в интернете. Лэндинг вы можете создать после заполнения всей необходимой информации об объекте или проекте в вашем личном кабинете на платформе.",
            "ukr": "Лендінг – це персональна сторінка вашого об'єкта – аналог односторінкового презентаційного сайту, який має залучати потенційних клієнтів (ліди). Заявки з лендінгу автоматично потрапляють до CRM системи. Персональне посилання – це URL-адреса вашого об'єкта/проекту в Інтернеті. Лендінг можна створити після заповнення всієї необхідної інформації про об'єкт або проект у вашому особистому кабінеті на платформі.\n",
            "deu": 'Eine Landingpage dient als personalisierte Präsentationsseite für Ihr Objekt, ähnlich einer prägnanten, einseitigen Website, die darauf abzielt, potenzielle Kunden (Leads) anzulocken. Anwendungen, die von der Landingpage stammen, integrieren sich nahtlos in das CRM-System. Der persönliche Link fungiert als eindeutige URL für Ihr Objekt/Projekt im Internet. Sie können eine Landingpage erstellen, indem Sie alle erforderlichen Informationen zum Objekt oder Projekt in Ihrem persönlichen Konto auf der Plattform bereitstellen.'
        },
        faq_item_title_8: {
            "eng": "How can I obtain leads?",
            "rus": "Как получать лиды?",
            "ukr": "Як отримувати ліди?",
            "deu": 'Wie kann ich Leads erhalten?'
        },
        faq_item_text_8: {
            "eng": "Any prospective client who provides their information through the landing page of an object transforms into a lead. These leads are then integrated into your CRM system, where comprehensive details about each lead are accessible. Subsequently, leads are distributed among managers and undergo further development.",
            "rus": "Всякий заинтересовавшийся потенциальный клиент, который оставил данные о себе на лэндинге объекта превращается в лид. Лиды, в свою очередь, поступают в вашу CRM-систему, в которой доступна вся собранная информация о лиде. Далее в вашей CRM-системе лиды распределяются между менеджерами и поступают в разработку.",
            "ukr": "Кожен потенційний клієнт, що зацікавився, який залишив дані про себе на лендингу об'єкта перетворюється на лід. Ліди, у свою чергу, вступають до вашої CRM-системи, в якій доступна вся зібрана інформація про лід. Далі у вашій CRM-системі ліди розподіляються між менеджерами та надходять у розробку.",
            "deu": 'Jeder potenzielle Kunde, der seine Informationen über die Landingpage eines Objekts bereitstellt, wird zu einem Lead. Diese Leads werden dann in Ihr CRM-System integriert, in dem umfassende Details zu jedem Lead verfügbar sind. Anschließend werden die Leads unter den Managern verteilt und weiterentwickelt.'
        },
        faq_item_title_9: {
            "eng": "What is a smart application?",
            "rus": "Что такое смарт-заявка?",
            "ukr": "Що таке смарт-заявка?",
            "deu": 'Was ist eine Smart-Anwendung?'
        },
        faq_item_text_9: {
            "eng": `A smart application enhances users' ability to efficiently search for specialists or real estate by leveraging internal functionality or engaging other users on the platform. There are two types of smart applications:
            
            A. Application to find a specialist.
            B. Application for real estate search.
            
            An application to find a specialist allows users to customize search parameters tailored to their specific requirements within their respective fields. The accuracy of the description directly influences the quality of results. 
            
            An application for real estate search summons all platform brokers to explore properties within their databases and through their search channels, significantly streamlining the search process and saving time.`,
            "rus": `Смарт-заявка помогает пользователям искать нужных специалистов или недвижимость более эффективно, привлекая для этого внутренний функционал, либо других пользователей платформы. \nСуществует 2 типа смарт-заявок:

            1. Заявка на поиск специалиста;
            2. Заявка на поиск недвижимости;
            
            Заявка на поиск специалиста позволяет задать ваши личные параметры поиска необходимого профессионала в своей области. Чем точнее ваше описание – тем выше результаты!

            Заявка на поиск недвижимости привлекает всех брокеров на платформе, которые приступают к поиску нужного вам объекта в своих базах и задействуя свои каналы поиска. Таким образом в разы повышается эффективность поиска и экономится время.`,
            "ukr": `Смарт-заявка допомагає користувачам шукати потрібних спеціалістів чи нерухомість більш ефективно, залучаючи для цього внутрішній функціонал або інших користувачів платформи. Існує 2 типи смарт-заявок:

            1. Заявка на пошук спеціаліста;
            2. Заявка на пошук нерухомості;
            
            Заявка на пошук спеціаліста дозволяє задати ваші особисті параметри пошуку необхідного професіонала у своїй галузі. Чим точніше ваш опис – тим вищі результати!

            Заявка на пошук нерухомості залучає всіх брокерів на платформі, які приступають до пошуку потрібного вам об'єкта у своїх базах та залучаючи свої канали пошуку. Таким чином у рази підвищується ефективність пошуку та економиться час.`,
            "deu": `Eine intelligente Anwendung hilft den Nutzern, effizienter nach den richtigen Fachkräften oder Immobilien zu suchen, indem sie interne Funktionen oder andere Nutzer der Plattform nutzt. Es gibt 2 Arten von intelligenten Anwendungen:

            1. Anwendung für die Suche nach einem Spezialisten;
            2. Anwendung für die Immobiliensuche;
            
            Mit einem Antrag für einen Spezialisten können Sie Ihre persönlichen Suchparameter für den gesuchten Fachmann in Ihrem Bereich festlegen. Je genauer Ihre Beschreibung ist, desto besser sind die Ergebnisse!
            
            Der Antrag auf Immobiliensuche bezieht alle Makler auf der Plattform ein, die in ihren Datenbanken und über ihre Suchkanäle nach der von Ihnen gewünschten Immobilie suchen. Dies erhöht die Effizienz der Suche erheblich und spart Zeit.`
        },
        faq_item_title_10: {
            "eng": "How do I create a request to find a specialist?",
            "rus": "Как создать заявку на поиск специалиста?",
            "ukr": "Як створити заявку на пошук фахівця?",
            "deu": 'Wie erstelle ich eine Anfrage, um einen Spezialisten zu finden?'
        },
        faq_item_text_10: {
            "eng": "Within your personal account, navigate to the \"Smart application\" section on the left side of the screen, displaying all platform sections. Choose the appropriate tab, configure filters to precisely delineate the specialist you require.",
            "rus": "Выберите в личном кабинете раздел smart-заявка в левой части экрана, где отображается список всех разделов платформы. Далее выберите соответствующую вкладку, настройте фильтры таким образом, чтобы платформа точно подобрала нужного вам специалиста.",
            "ukr": "Виберіть у власному кабінеті розділ smart-заявка у лівій частині екрана, де відображається список усіх розділів платформи. Далі оберіть відповідну вкладку, налаштуйте фільтри таким чином, щоб платформа точно підібрала потрібного вам фахівця.",
            "deu": "Navigieren Sie in Ihrem persönlichen Konto zum Abschnitt „Smart-Anwendung“ auf der linken Seite des Bildschirms, der alle Plattformabschnitte anzeigt. Wählen Sie den entsprechenden Tab aus und konfigurieren Sie die Filter, um den gewünschten Spezialisten genau zu bestimmen."
        },
        faq_item_title_11: {
            "eng": "How do I create a request to search for a property?",
            "rus": "Как создать заявку на поиск объекта недвижимости?",
            "ukr": "Як створити заявку на пошук об'єкта нерухомості?",
            "deu": 'Wie erstelle ich eine Anfrage, um eine Immobilie zu finden?'
        },
        faq_item_text_11: {
            "eng": "In your personal account, access the \"Smart application\" section on the left side of the screen, presenting all platform sections. Configure the necessary filters, submit the application, and over 1000 brokers will endeavor to find the most suitable property for you.",
            "rus": "Выберите в личном кабинете в разделе «Smart-заявка» в левой части экрана, где отображается список всех разделов платформы. Настроив необходимые фильтры, отправьте заявку и более 1000 брокеров смогут подобрать наиболее подходящий вам объект.",
            "ukr": "Виберіть в особистому кабінеті у розділі «Smart-заявка» у лівій частині екрана, де відображається список усіх розділів платформи. Налаштувавши необхідні фільтри, відправте заявку і більше 1000 брокерів зможуть підібрати найбільш підходящий вам об'єкт.",
            "deu": "Greifen Sie in Ihrem persönlichen Konto auf den Abschnitt „Smart-Anwendung“ auf der linken Seite des Bildschirms zu, der alle Plattformabschnitte anzeigt. Konfigurieren Sie die erforderlichen Filter, senden Sie die Anfrage ab und über 1000 Makler werden sich bemühen, die geeignetste Immobilie für Sie zu finden."
        },
        faq_item_title_12: {
            "eng": "What is a commission split agreement?",
            "rus": "Что такое договор о разделении комиссии?",
            "ukr": "Що таке договір про розподіл комісії?",
            "deu": 'Was ist ein Provisionsaufteilungsvertrag?'
        },
        faq_item_text_12: {
            "eng": "This agreement is forged between a broker seeking to list a property for sale on the platform and the GREM platform. According to this agreement, the broker undertakes to pay the platform part of his remuneration in the event of a successful transaction with a buyer sourced through the platform. Please note that in order to finalize this agreement, you must have an exclusive agreement for this property or an agency agreement from the owner, guaranteeing payment of a commission in the event of a successful transaction.",
            "rus": "Данный договор заключается между брокером, желающим выставить на платформе объект недвижимости на продажу, и платформой GREM.  Согласно этому договору брокер обязуется выплатить платформе определенный процент  комиссии в случае успешной продажи объекта.",
            "ukr": "Цей договір укладається між брокером, який бажає виставити на платформі об'єкт нерухомості на продаж, та платформою GREM. Відповідно до цього договору брокер зобов'язується виплатити платформі частину своєї винагороди у разі успішної угоди із залученим через платформу покупцем. Зверніть увагу, щоб укласти цей договір, у вас повинен бути договір ексклюзиву на даний об'єкт або агентський договір від власника, який гарантує виплату комісійної винагороди у разі успішної угоди.",
            "deu": 'Dieser Vertrag wird zwischen einem Broker geschlossen, der beabsichtigt, ein Objekt zum Verkauf auf der Plattform zu listen, und der GREM-Plattform. Gemäß diesem Vertrag verpflichtet sich der Broker, der Plattform im Falle eines erfolgreichen Geschäfts mit einem Käufer, der über die Plattform gewonnen wurde, einen Teil seiner Vergütung zu zahlen. Bitte beachten Sie, dass Sie zur Abschluss dieses Vertrags einen Exklusivvertrag für dieses Objekt oder einen Agenturvertrag des Eigentümers haben müssen, der die Zahlung einer Provision im Falle eines erfolgreichen Geschäfts garantiert.'
        },
        faq_item_title_13: {
            "eng": "What is a commission agreement?",
            "rus": "Что такое договор о выплате комиссии?",
            "ukr": "Що таке договір про виплату комісії?",
            "deu": 'Was ist ein Provisionsvertrag?'
        },
        faq_item_text_13: {
            "eng": "This agreement is established between the GREM platform and a broker aiming to participate in the sale of real estate properties listed on the platform. Upon the successful conclusion of a deal with a client referred by the broker, the platform undertakes to pay the broker a predetermined percentage of the commission.",
            "rus": "Этот договор заключается между сторонами сделки – покупателем и продавцом объекта недвижимости. В нем прописываются условия выплаты комиссии  брокерам, которые принимали участие в  поиске покупателя или продавца.",
            "ukr": "Даний договір укладається між платформою GREM і брокером, що бажає приєднатися до продажу виставлених на платформі об'єктів нерухомості. У разі успішного завершення угоди з наведеним клієнтом брокером платформа зобов'язується виплатити брокеру встановлений % комісійної винагороди.",
            "deu": 'Dieser Vertrag wird zwischen der GREM-Plattform und einem Broker geschlossen, der beabsichtigt, am Verkauf von auf der Plattform aufgeführten Immobilien teilzunehmen. Nach dem erfolgreichen Abschluss eines Geschäfts mit einem vom Broker empfohlenen Kunden verpflichtet sich die Plattform, dem Broker einen vorher festgelegten Prozentsatz der Provision zu zahlen.'
        },
        faq_item_title_14: {
            "eng": "What is tokenization?",
            "rus": "Что такое токенизация?",
            "ukr": "Що таке токенізація?",
            "deu": 'Was ist Tokenisierung?'
        },
        faq_item_text_14: {
            "eng": "Tokenization refers to the process of converting ownership or the value of tangible assets into digital tokens on the blockchain. Essentially, it entails transforming real estate assets into digital tokens. Through tokenization on the platform, it becomes feasible to divide a substantial project into its constituent parts—tokens representing square meters—and execute such a project incrementally, thereby facilitating funding. Moreover, this process reduces the barrier for entry into the market for investors with limited capital.",
            "rus": "Токенизация – это процесс преобразования прав собственности на  актив (недвижимость, ценные бумаги, произведения искусства и т.д.) в цифровые токены. Токены можно свободно покупать и продавать на специализированных биржах, тем самым привлекая инвестиции в проект.",
            "ukr": "Токенізація – це процес перетворення права власності або вартості матеріальних активів на цифрові токени на блокчейні. Іншими словами, ваші об'єкти нерухомості перетворюються на цифрові токени. Завдяки токенізації на платформі можна, наприклад, розділити великий проект на складові – токени-квадратні метри та реалізовувати такий проект частинами, залучаючи фінансування. Крім того, це дозволить знизити мінімальний поріг для входу на ринок інвесторам з невеликим капіталом.",
            "deu": 'Tokenisierung bezieht sich auf den Prozess der Umwandlung von Eigentum oder dem Wert von materiellen Vermögenswerten in digitale Token auf der Blockchain. Im Wesentlichen bedeutet dies, dass Immobilienvermögen in digitale Token umgewandelt werden. Durch die Tokenisierung auf der Plattform wird es möglich, ein umfangreiches Projekt in seine Bestandteile – Token, die Quadratmeter repräsentieren – zu unterteilen und ein solches Projekt schrittweise auszuführen, wodurch die Finanzierung erleichtert wird. Darüber hinaus reduziert dieser Prozess die Einstiegshürde für Investoren mit begrenztem Kapital.'
        },
        faq_item_title_15: {
            "eng": "What is a smart contract?",
            "rus": "Что такое смарт-контракт? ",
            "ukr": "Що таке смартконтракт?",
            "deu": 'Was ist ein Smart-Vertrag?'
        },
        faq_item_text_15: {
            "eng": "A smart contract is a program designed to ascertain whether all conditions of a transaction have been met by its participants. Upon fulfillment of the conditions, assets are automatically distributed among the involved parties in accordance with predetermined agreements. Smart contracts are created on the basis of the Stellar blockchain, supporting functionalities such as multi-signature capabilities, grouping multiple operations into a single transaction, and strictly defining the sequence of transactions within the contract.",
            "rus": "Смарт-контракт – это  цифровой протокол, который  автоматически выполняет условия договора при  их  соблюдении всеми сторонами сделки.  Смарт-контракты  обеспечивают безопасность, прозрачность и  неизменяемость условий сделки.",
            "ukr": "Смарт-контракт – це програма, яка визначає, чи всі умови угоди виконані її учасниками. Якщо учасники виконали умови – активи автоматично розподіляються між учасниками такої угоди згідно з попередніми домовленостями. Смарт-контракти створюються на базі блокчейну Stellar і підтримують можливість мультипідпису, угруповання кількох операцій в одну транзакцію та суворо визначають послідовність усіх транзакцій у контракті.",
            "deu": 'Ein Smart-Vertrag ist ein Programm, das darauf ausgelegt ist, festzustellen, ob alle Bedingungen einer Transaktion von ihren Teilnehmern erfüllt wurden. Nach Erfüllung der Bedingungen werden die Vermögenswerte automatisch gemäß vorher festgelegten Vereinbarungen unter den beteiligten Parteien verteilt. Smart-Verträge werden auf der Basis der Stellar-Blockchain erstellt und unterstützen Funktionen wie Mehrfachsignaturfähigkeiten, Gruppierung mehrerer Operationen'
        },
        faq_item_title_16: {
            "eng": "What is a CRM system?",
            "rus": "Что такое CRM-система?",
            "ukr": "Що таке система CRM?",
            "deu": 'Was ist ein CRM-System?'
        },
        faq_item_text_16: {
            "eng": "In essence, a CRM (Customer Relationship Management) system is software utilized for engaging with partners, prospective clients, and existing clients of a company. The CRM system on the GREM platform serves to enhance the efficiency of the sales department and grants access to advanced functionality, including an interactive property facade and a checkerboard displaying real-time availability of sold and unsold units. This system systematically organizes and automates all data pertaining to leads, real estate assets, and the progression of transactions.",
            "rus": "В чистом виде, CRM-система – это программа для взаимодействия с партнерами, а также потенциальными и существующими клиентами компании. С помощью CRM-системы на платформе GREM вы сможете повысить эффективность отдела продаж, получите доступ к расширенному функционалу, такому как интерактивный фасад объекта и шахматка, где показаны реализованные и свободные юниты для продажи в режиме реального времени. CRM-система четко структурирует и автоматизирует все данные о лидах, объектах недвижимости и о ходе всех ваших сделок.",
            "ukr": "У чистому вигляді CRM-система – це програма для взаємодії з партнерами, а також потенційними та існуючими клієнтами компанії. За допомогою CRM-системи на платформі GREM ви зможете підвищити ефективність відділу продажів, отримаєте доступ до розширеного функціоналу, такого як інтерактивний фасад об'єкта та шахівниця, де показані реалізовані та вільні юніти для продажу в режимі реального часу. CRM-система чітко структурує та автоматизує всі дані про ліди, об'єкти нерухомості та про хід всіх ваших угод.\n",
            "deu": 'Im Wesentlichen ist ein CRM (Customer Relationship Management) System eine Software, die zur Interaktion mit Partnern, potenziellen Kunden und bestehenden Kunden eines Unternehmens verwendet wird. Das CRM-System auf der GREM-Plattform dient dazu, die Effizienz der Vertriebsabteilung zu steigern und den Zugriff auf erweiterte Funktionen zu gewähren, darunter eine interaktive Immobilienfassade und ein Schachbrett, das die Echtzeitverfügbarkeit von verkauften und unverkauften Einheiten anzeigt. Dieses System organisiert und automatisiert systematisch alle Daten zu Leads, Immobilienvermögen und dem Fortschritt von Transaktionen.'
        },
        ref_step_1: {
            "eng": 'Share the referral link with colleagues and partners, on social media or directly',
            "rus": 'Делитесь реферальной ссылкой с коллегами и партнерами, в социальных сетях или напрямую',
            "ukr": "Діліться реферальним посиланням з колегами і партнерами, в соціальних мережах або безпосередньо",
            "deu": "Teilen Sie den Empfehlungslink mit Kollegen und Partnern, in sozialen Medien oder direkt"
        },
        ref_step_2: {
            "eng": 'Receive % from any financial actions made by your referral',
            "rus": 'Получайте % от любых финансовых действий совершенных вашим рефералом',
            "ukr": "Отримуйте % від будь-яких фінансових дій, здійснених вашим рефералом",
            "deu": "Erhalten Sie % von allen finanziellen Aktionen, die Ihr Empfehlungspartner durchführt"
        },
        ref_step_3: {
            "eng": 'Withdraw % to your personal account or convert them to M2C to purchase tokens',
            "rus": 'Выводите % на личный счет или конвертируйте их в M2C для покупки токенов',
            "ukr": "Виводьте % на особистий рахунок або конвертуйте їх в M2C для покупки токенів",
            "deu": "Heben Sie % auf Ihr persönliches Konto ab oder konvertieren Sie sie in M2C, um Token zu kaufen"
        },
        ref_title: {
            "eng": 'Referral system',
            "rus": 'Реферальная система',
            "ukr": 'Реферальна система',
            "deu": 'Empfehlungssystem'
        },
        ref_title_sub: {
            "eng": 'Earn with GREM',
            "rus": 'Зарабатывайте вместе с GREM',
            "ukr": 'Заробляйте разом з GREM',
            "deu": 'Verdienen Sie mit GREM'
        },
        ref_text_1: {
            "eng": 'Receive ',
            "rus": 'Получайте ',
            "ukr": "Отримуйте ",
            "deu": "Erhalten "
        },
        ref_text_2: {
            "eng": '10% of the income',
            "rus": '10% от дохода',
            "ukr": '10% від доходу',
            "deu": '10% des Einkommens'
        },
        ref_text_3: {
            "eng": ', which your referral will bring to the GREM platform through transactions within the system!',
            "rus": ', который ваш рефеал  принесет платформе GREM  за счет совершенных сделок внутри системы!',
            "ukr": ', який ваш рефеал принесе платформі GREM за рахунок укладених угод всередині системи!',
            "deu": ', die Ihre Empfehlung der GREM-Plattform durch die im System getätigten Transaktionen bringt!'
        },
        ref_rules: {
            "eng": 'Referral program rules',
            "rus": 'Правила реферальной программы',
            "ukr": 'Правила реферальної програми',
            "deu": 'Regeln des Empfehlungsprogramms'
        },
        ref_invite: {
            "eng": 'Invite a referral',
            "rus": 'Пригласить реферала',
            "ukr": 'Запросити реферала',
            "deu": 'Empfehlung einladen'
        },
        ref_form_email: {
            "eng": 'Email',
            "rus": 'Электронная почта',
            "ukr": 'Електронна пошта',
            "deu": 'Email'
        },
        ref_form_submit: {
            "eng": 'Invite',
            "rus": 'Пригласить',
            "ukr": 'Запросити',
            "deu": 'Einladen'
        },
        ref_invite_social: {
            "eng": 'Invite via social networks',
            "rus": 'Пригласить через социальные сети',
            "ukr": 'Запросити через соціальні мережі',
            "deu": 'Einladen über soziale Netzwerke'
        },
        ref_social_title: {
            "eng": "Join GREM - discover new opportunities in the global real estate market",
            "rus": "Присоединяйтесь к GREM - откройте для себя новые возможности глобального рынка недвижимости.",
            "ukr": "Приєднуйтесь до GREM - відкрийте для себе нові можливості глобального ринку нерухомості.",
            "deu": "Treten Sie GREM bei - entdecken Sie neue Möglichkeiten des globalen Immobilienmarktes."
        },
        ref_step: {
            "eng": 'Step',
            "rus": 'шаг',
            "ukr": 'крок',
            "deu": 'Schritt'
        },
        ref_system_title: {
            "eng": 'My referral system',
            "rus": 'Мои реферальная система',
            "ukr": 'Моя реферальна система',
            "deu": 'Mein Empfehlungssystem'
        },
        ref_system_create_link: {
            "eng": 'Create a new link',
            "rus": 'Создать новую ссылку',
            "ukr": 'Створити нове посилання',
            "deu": 'Neuen Link erstellen'

        },
        ref_system_create_text: {
            "eng": 'Create new links to attract referrals',
            "rus": 'Создавайте новые ссылки для привлечения рефералов',
            "ukr": 'Створюйте нові посилання для привертання рефералів',
            "deu": 'Erstellen Sie neue Links, um Empfehlungen zu gewinnen'
        },
        ref_system_table_name: {
            "eng": 'Name',
            "rus": 'Название',
            "ukr": 'Назва',
            "deu": 'Name'
        },
        ref_system_table_click: {
            "eng": 'Clicks',
            "rus": 'Переходы',
            "ukr": 'Переходи',
            "deu": 'Klicks'
        },
        ref_system_table_regs: {
            "eng": 'Registrations',
            "rus": 'Регистрации',
            "ukr": 'Реєстрації',
            "deu": 'Registrierungen'
        },
        ref_system_table_link: {
            "eng": 'Link',
            "rus": 'Ссылка',
            "ukr": 'Посилання',
            "deu": 'Link'
        },
        ref_my_title: {
            "eng": 'My referrals',
            "rus": 'Мои рефералы',
            "ukr": 'Мої реферали',
            "deu": 'Meine Empfehlungen'
        },
        ref_my_table_name: {
            "eng": 'Name',
            "rus": 'Имя',
            "ukr": 'Ім\'я',
            "deu": 'Name'
        },
        ref_my_table_role: {
            "eng": 'Role',
            "rus": 'Роль',
            "ukr": 'Роль',
            "deu": 'Rolle'
        },
        ref_my_table_date: {
            "eng": "Sign up at",
            "rus": 'Зарегистрирован',
            "ukr": 'Зареєстрований',
            "deu": 'Registriert am'
        },
        ref_my_table_source: {
            "eng": 'Source',
            "rus": 'Ресурс привлечения',
            "ukr": 'Джерело привернення',
            "deu": 'Quelle'
        },
        ref_earnings_title: {
            "eng": 'My referral earnings',
            "rus": 'Мои реферальные вознаграждения',
            "ukr": 'Мої реферальні винагороди',
            "deu": 'Meine Empfehlungsverdienste'
        },
        ref_earnings_table_date: {
            "eng": 'Date',
            "rus": 'Дата',
            "ukr": 'Дата',
            "deu": 'Datum'
        },
        ref_earnings_table_ref: {
            "eng": 'Referral',
            "rus": 'Реферал',
            "ukr": 'Реферал',
            "deu": 'Empfehlung'
        },
        ref_earnings_table_order_type: {
            "eng": 'Order type',
            "rus": 'Тип сделки',
            "ukr": 'Тип угоди',
            "deu": 'Auftragstyp'
        },
        ref_earnings_table_rewards: {
            "eng": 'My referral rewards',
            "rus": 'Мои реферальные вознаграждения',
            "ukr": 'Мої реферальні винагороди',
            "deu": 'Meine Empfehlungsverdienste'
        },
        articles_title: {
            "eng": "News",
            "rus": 'Новости',
            "ukr": 'Новини',
            "deu": 'Nachrichten'
        },
        articles_filter_name: {
            "eng": 'Search news',
            "rus": 'Поиск новостей',
            "ukr": 'Пошук новин',
            "deu": 'Nachrichten suchen'
        },
        articles_filter_search: {
            "eng": 'Search',
            "rus": 'Поиск',
            "ukr": 'Пошук',
            "deu": 'Suche'
        },
        articles_filter_: {
            "eng": "",
            "rus": '',
            "ukr": '',
            "deu": ''
        },
        name: {
            eng: 'Name',
            rus: 'Название',
            ukr: 'Назва',
            deu: 'Name',
        },
        category: {
            eng: 'Category',
            rus: 'Категория',
            ukr: 'Категорія',
            deu: 'Kategorie',
        },
        ticket_table_head_id: {
            eng: 'Ticket id',
            rus: 'id тикета',
            ukr: 'id тікету',
            deu: 'Ticket-ID',
        },
        attached_file: {
            eng: 'Attached file',
            rus: 'Прикрепленный файл',
            ukr: 'Прикріплений файл',
            deu: 'Angehängte Datei',
        },
        add_file: {
            eng: 'Attach new file',
            rus: 'Прикрепить новый файл',
            ukr: 'Додати новий файл',
            deu: 'Neue Datei anhängen',
        },
        load_file: {
            eng: 'Load file',
            rus: 'Загрузить файл',
            ukr: 'Завантажити файл',
            deu: 'Datei laden',
        },
        ticket_add_title_error: {
            eng: 'The field cannot be empty or shorter than 3 characters',
            rus: 'Поле не может быть пустым или короче 3 символов',
            ukr: 'Поле не може бути порожнім або коротшим за 3 символи',
            deu: 'Das Feld darf nicht leer sein oder weniger als 3 Zeichen enthalten',
        },
        ticket_add_title_category_error: {
            eng: 'The field cannot be empty',
            rus: 'Поле не может быть пустым',
            ukr: 'Поле не може бути порожнім',
            deu: 'Das Feld darf nicht leer sein',
        },
        ticket_add_title_text_error: {
            eng: 'The field cannot be empty or shorter than 3 characters',
            rus: 'Поле не может быть пустым или короче 3 символов',
            ukr: 'Поле не може бути порожнім або коротшим за 3 символи',
            deu: 'Das Feld darf nicht leer sein oder weniger als 3 Zeichen enthalten',
        },
        smart_requests_title: {
            eng: 'Smart Request',
            rus: 'Смарт-заявка',
            ukr: 'Розумний запит',
            deu: 'Intelligente Anfrage',
        },
        smart_requests_removing: {
            eng: 'Error while removing request',
            rus: 'Ошибка при удалении заявки',
            ukr: 'Помилка при видаленні запиту',
            deu: 'Fehler beim Entfernen der Anfrage',
        },
        smart_requests_add_property_type: {
            eng: 'Property Type',
            rus: 'Тип недвижимости',
            ukr: 'Тип нерухомості',
            deu: 'Immobilientyp',
        },
        smart_requests_add_property_propertyStatus: {
            eng: 'Property Status',
            rus: 'Стадия готовности',
            ukr: 'Стадія готовності',
            deu: 'Immobilienstatus',
        },
        smart_requests_add_property_phone: {
            eng: 'Contact Phone',
            rus: 'Контактный телефон',
            ukr: 'Контактний телефон',
            deu: 'Kontakttelefon',
        },
        smart_requests_add_property_email: {
            eng: 'Email',
            rus: 'Электронная почта',
            ukr: 'Електронна пошта',
            deu: 'E-Mail',
        },
        smart_requests_add_property_name: {
            eng: 'Full Name',
            rus: 'ФИО',
            ukr: 'ПІБ',
            deu: 'Vollständiger Name',
        },
        smart_requests_add_property_contactLanguage: {
            eng: 'Contact Language',
            rus: 'Язык общения',
            ukr: 'Мова спілкування',
            deu: 'Kontaktsprache',
        },
        smart_requests_add_property_intent: {
            eng: 'Comment',
            rus: 'Комментарий',
            ukr: 'Коментар',
            deu: 'Kommentar',
        },
        smart_requests_add_specialist_type: {
            eng: 'Role',
            rus: 'Роль',
            ukr: 'Роль',
            deu: 'Rolle',
        },
        smart_requests_item_contact: {
            eng: 'Contact Person',
            rus: 'Контактное лицо',
            ukr: 'Контактна особа',
            deu: 'Kontaktperson',
        },
        smart_requests_item_criteria: {
            eng: 'Property Criteria',
            rus: 'Критерии недвижимости',
            ukr: 'Критерії нерухомості',
            deu: 'Immobilienkriterien',
        },
        smart_requests_item_area: {
            eng: 'Property Area',
            rus: 'Площадь недвижимости',
            ukr: 'Площа нерухомості',
            deu: 'Immobilienfläche',
        },
        smart_requests_item_price: {
            eng: 'Property Price',
            rus: 'Стоимость недвижимости',
            ukr: 'Вартість нерухомості',
            deu: 'Immobilienpreis',
        },
        smart_requests_item_intent: {
            eng: 'Comment',
            rus: 'Комментарий',
            ukr: 'Коментар',
            deu: 'Kommentar',
        },
        smart_requests_item_chat_message: {
            eng: 'Message',
            rus: 'Сообщение',
            ukr: 'Повідомлення',
            deu: 'Nachricht',
        },
        smart_request_item_property_type: {
            eng: 'Property Search',
            rus: 'Поиск недвижимости',
            ukr: 'Пошук нерухомості',
            deu: 'Immobiliensuche',
        },
        smart_request_item_specialist_type: {
            eng: 'Specialist Search',
            rus: 'Поиск специалиста',
            ukr: 'Пошук спеціаліста',
            deu: 'Spezialistensuche',
        },
        smart_requests_all_title: {
            eng: 'Requests you may be interested in',
            rus: 'Заявки, которые могут вас заинтересовать',
            ukr: 'Заявки, які можуть вас зацікавити',
            deu: 'Anfragen, die Sie interessieren könnten',
        },
        smart_requests_empty: {
            eng: 'No requests',
            rus: 'Нет заявок',
            ukr: 'Немає заявок',
            deu: 'Keine Anfragen',
        },
        smart_requests_my_title: {
            eng: 'Smart-application will help in your search for a property or a specialist',
            rus: 'Smart-заявка поможет в поиске недвижимости или специалиста',
            ukr: 'Smart-заявка допоможе в пошуку нерухомості або фахівця',
            deu: 'Smart-Anwendung hilft bei der Suche nach einer Immobilie oder einem Spezialisten',
        },
        smart_requests_my_description: {
            eng: 'In the Smart-application section, you can create a request for the search of a property or a specialist. The request will be sent to all users of the platform who are suitable for the role. If there are no responses to your request, the GREM team will conduct additional search for the necessary information',
            rus: 'При поиске недвижимости, заявка автоматически отправляется всем пользователям подходящей роли, зарегистрированным на платформе. Если по вашей заявке не будет откликов, команда GREM проведет дополнительный поиск необходимой вам информации',
            ukr: 'При пошуку нерухомості, заявка автоматично відправляється усім користувачам підходящої ролі, зареєстрованим на платформі. Якщо за вашою заявкою не буде відгуків, команда GREM проведе додатковий пошук необхідної вам інформації',
            deu: 'Bei der Suche nach einer Immobilie wird die Anfrage automatisch an alle Benutzer der Plattform gesendet, die für die Rolle geeignet sind. Wenn auf Ihre Anfrage keine Antworten eingehen, wird das GREM-Team zusätzliche Informationen für Sie suchen',
        },
        smart_requests_my_add_title: {
            eng: 'Create Smart Requests to quickly find professionals or properties',
            rus: 'Создавайте Smart-заявки для быстрого поиска специалистов или недвижимости',
            ukr: 'Створюйте Smart-заявки для швидкого пошуку фахівців або нерухомості',
            deu: 'Erstellen Sie Smart-Anfragen für die schnelle Suche nach Spezialisten oder Immobilien',
        },

        smart_requests_my_add_button: {
            eng: 'Create Smart Request',
            rus: 'Создать Smart-заявку',
            ukr: 'Створити Smart-заявку',
            deu: 'Smart-Anfrage erstellen',
        },
        smart_requests_tabs_all: {
            eng: 'Received applications',
            rus: 'Полученные заявки',
            ukr: 'Отримані заявки',
            deu: 'Alle Anfragen',
        },
        smart_requests_tabs_my: {
            eng: 'Sent requests',
            rus: 'Отправленные заявки',
            ukr: 'Відправлені заявки',
            deu: 'Gesendete Anfragen',
        },
        smart_requests_add_tabs_property: {
            eng: 'Property',
            rus: 'Недвижимость',
            ukr: 'Нерухомість',
            deu: 'Immobilien',
        },
        smart_requests_add_tabs_specialist: {
            eng: 'Specialist',
            rus: 'Специалист',
            ukr: 'Спеціаліст',
            deu: 'Spezialist',
        },
        smart_request_item_type_property: {
            eng: 'Looking for real estate',
            rus: 'Ищу недвижимость',
            ukr: 'Шукаю нерухомість',
            deu: 'Suche Immobilien',
        },
        smart_request_item_type_specialist: {
            eng: 'Looking for a specialist',
            rus: 'Ищу специалиста',
            ukr: 'Шукаю фахівця',
            deu: 'Suche Spezialisten',
        },
        smart_request_item_date: {
            eng: 'placed',
            rus: 'размещена',
            ukr: 'розміщена',
            deu: 'platziert',
        },
        smart_request_item_type: {
            eng: 'Type',
            rus: 'Тип',
            ukr: 'Тип',
            deu: 'Typ',
        },
        smart_request_item_location: {
            eng: 'Country, city',
            rus: 'страна, город',
            ukr: 'країна, місто',
            deu: 'Land, Stadt',
        },
        smart_request_item_area: {
            eng: 'Area',
            rus: 'площадь',
            ukr: 'площа',
            deu: 'Fläche',
        },
        smart_request_item_cost: {
            eng: 'Cost',
            rus: 'Стоимость',
            ukr: 'Вартість',
            deu: 'Kosten',
        },
        smart_request_item_role: {
            eng: 'Role',
            rus: 'роль',
            ukr: 'роль',
            deu: 'Rolle',
        },
        smart_request_item_request: {
            eng: 'Request',
            rus: 'Запрос',
            ukr: 'Запит',
            deu: 'Anfrage',
        },
        smart_request_item_menu_delete: {
            eng: 'Delete',
            rus: 'Удалить',
            ukr: 'Видалити',
            deu: 'Löschen',
        },
        smart_request_item_menu_edit: {
            eng: 'Edit',
            rus: 'Редактировать',
            ukr: 'Редагувати',
            deu: 'Bearbeiten',
        },
        smart_request_item_menu_update: {
            eng: 'Update',
            rus: 'Обновить',
            ukr: 'Оновити',
            deu: 'Aktualisieren',
        },
        smart_request_form_success: {
            eng: 'Form submitted successfully',
            rus: 'Форма успешно отправлена',
            ukr: 'Форму успішно відправлено',
            deu: 'Formular erfolgreich übermittelt',
        },
        smart_request_form_delete: {
            eng: 'Request deleted',
            rus: 'Заявка удалена',
            ukr: 'Заявка видалена',
            deu: 'Anfrage gelöscht',
        },
        contact_language: {
            eng: 'Communication Language',
            rus: 'Язык общения',
            ukr: 'Мова спілкування',
            deu: 'Kommunikationssprache',
        },
        smart_add_modal_title: {
            eng: 'Creating a smart request',
            rus: 'Создание smart-заявки',
            ukr: 'Створення розумного запиту',
            deu: 'Erstellen einer Smart-Anfrage',
        },
        smart_add_modal_button: {
            eng: 'Create smart request',
            rus: 'Создать smart-заявку',
            ukr: 'Створити розумний запит',
            deu: 'Smart-Anfrage erstellen',
        },
        smart_add_modal_button_update: {
            eng: 'Update smart request',
            rus: 'Обновить smart-заявку',
            ukr: 'Оновити smart-заявку',
            deu: 'Smart-Anfrage aktualisieren',
        },
        smart_add_modal_title_update: {
            eng: 'Update smart request',
            rus: 'Обновление smart-заявки',
            ukr: 'Оновлення smart-заявки',
            deu: 'Aktualisierung der smart-Anfrage',
        },
        smart_requests_add_property_space: {
            eng: 'Property space',
            rus: 'Площадь недвижимости',
            ukr: 'Площа нерухомості',
            deu: 'Immobilienfläche',
        },
        smart_requests_add_property_price: {
            eng: 'Property price',
            rus: 'Стоимость недвижимости',
            ukr: 'Вартість нерухомості',
            deu: 'Immobilienpreis',
        },
        smart_requests_specialists_none: {
            eng: 'Unfortunately, specialists matching your request were not found.',
            rus: 'К сожалению, специалисты, соответствующие вашему запросу, не найдены.',
            ukr: 'На жаль, спеціалісти, які відповідають вашому запиту, не знайдені.',
            deu: 'Leider wurden keine Spezialisten gefunden, die Ihrem Antrag entsprechen.',
        },
        smart_requests_specialists_property_none: {
            eng: 'Unfortunately, no property matching your request was found.',
            rus: 'К сожалению, недвижимость, соответствующая вашему запросу, не найдена.',
            ukr: 'На жаль, нерухомість, яка відповідає вашому запиту, не знайдена.',
            deu: 'Leider wurde keine Immobilie gefunden, die Ihrer Anfrage entspricht.',
        },
        you: {
            eng: 'You',
            rus: 'Вы',
            ukr: 'Ви',
            deu: 'Du',
        },
        documents_load_button_key: {
            eng: 'Load documents',
            rus: 'Загрузите документы',
            ukr: 'Завантажте документи',
            deu: 'Dokumente hochladen',
        },
        plan_load_button_key: {
            eng: 'Upload the plan',
            rus: 'Загрузите план',
            ukr: 'Завантажте план',
            deu: 'Laden Sie den Plan hoch',
        },
        contract_load_button_key: {
            eng: 'Load contract',
            rus: 'Загрузить договор',
            ukr: 'Завантажити договір',
            deu: 'Vertrag laden',
        },
        cost_from: {
            eng: 'Cost from (m²)',
            rus: 'Стоимость от (m²)',
            ukr: 'Вартість від (м²)',
            deu: 'Kosten ab (m²)',
        },
        profile_my_objects: {
            eng: 'Listed Properties',
            rus: 'Размещенные объекты',
            ukr: 'Розміщені об\'єкти',
            deu: 'Aufgelistete Objekte',
        },
        profile_my_objects_not_founded: {
            eng: 'Objects not found',
            rus: 'Объекты не найдены',
            ukr: 'Об’єкти не знайдені',
            deu: 'Objekte nicht gefunden',
        },
        smart_user_not_favorit: {
            eng: 'The user has withdrawn their participation in the smart request.',
            rus: 'Пользователь отменил свое участие в смарт-заявке',
            ukr: 'Користувач скасував свою участь у смарт-заявці',
            deu: 'Der Benutzer hat seine Teilnahme an der Smart-Anfrage zurückgezogen.',
        },
        project_list_item_1_title: {
            eng: 'Turkey Alanya',
            rus: 'Турция Аланья',
            ukr: 'Туреччина Аланья',
            deu: 'Türkei Alanya',
        },
        project_list_item_2_title: {
            eng: 'Principality of Andorra',
            rus: 'Княжество Андорра',
            ukr: 'Князівство Андорра',
            deu: 'Fürstentum Andorra',
        },
        project_list_item_3_title: {
            eng: 'Cyprus. Ayia Napa',
            rus: 'Кипр. Айя-Напа',
            ukr: 'Кіпр. Айя-Напа',
            deu: 'Zypern. Ayia Napa',
        },
        project_list_item_4_title: {
            eng: 'Turkey. Alanya',
            rus: 'Турция. Аланья',
            ukr: 'Туреччина. Аланья',
            deu: 'Türkei. Alanya',
        },
        project_list_item_5_title: {
            eng: 'Thailand. Phuket',
            rus: 'Тайланд. Пхукет',
            ukr: 'Таїланд. Пхукет',
            deu: 'Thailand. Phuket',
        },
        project_list_item_6_title: {
            eng: 'Cyprus. Limassol',
            rus: 'Кипр. Лимассол',
            ukr: 'Кіпр. Лімасол',
            deu: 'Zypern. Limassol',
        },
        project_list_item_7_title: {
            eng: 'Cyprus. Limassol',
            rus: 'Кипр. Лимассол',
            ukr: 'Кіпр. Лімасол',
            deu: 'Zypern. Limassol',
        },
        project_list_item_8_title: {
            eng: 'Norway. Oslo',
            rus: 'Норвегия. Осло',
            ukr: 'Норвегія. Осло',
            deu: 'Norwegen. Oslo',
        },
        project_list_investor_advantages_1: {
            eng: 'Risk diversification',
            rus: 'Диверсификация рисков',
            ukr: 'Диверсифікація ризиків',
            deu: 'Risikodiversifikation',
        },
        project_list_investor_advantages_2: {
            eng: 'Guaranteed payment of % and body',
            rus: 'Гарантия выплаты % и тела',
            ukr: 'Гарантія виплати % та тіла',
            deu: 'Garantierte Zahlung von % und Körper',
        },
        project_list_investor_advantages_3: {
            eng: 'Contract duration - 12 months',
            rus: 'Длительность контракта - 12 месяцев',
            ukr: 'Тривалість контракту - 12 місяців',
            deu: 'Vertragsdauer - 12 Monate',
        },
        project_list_investor_advantages_4: {
            eng: 'Minimum amount - 500$',
            rus: 'Минимальная сумма - 500$',
            ukr: 'Мінімальна сума - 500$',
            deu: 'Mindestbetrag - 500$',
        },
        project_list_investor_steps_1: {
            eng: 'You sign a contract with the GREM platform, which guarantees you a payment of 14% annual income in $',
            rus: 'Вы заключаете контракт с платформой GREM, который гарантирует вам выплату 14% годового дохода в $',
            ukr: 'Ви укладаєте контракт з платформою GREM, який гарантує вам виплату 14% річного доходу в $',
            deu: 'Sie schließen einen Vertrag mit der GREM-Plattform ab, der Ihnen eine Zahlung von 14% Jahresertrag in $ garantiert',
        },
        project_list_investor_steps_2: {
            eng: 'The GREM platform distributes funds between projects in different countries with different profitability',
            rus: 'Платформа GREM распределяет средства между проектами в разных странах с различной доходностью',
            ukr: 'Платформа GREM розподіляє кошти між проектами в різних країнах з різною доходністю',
            deu: 'Die GREM-Plattform verteilt die Mittel zwischen Projekten in verschiedenen Ländern mit unterschiedlicher Rentabilität',
        },
        project_list_investor_steps_3: {
            eng: 'After the expiration of the contract term (12 months), you will be charged a yield of 14% and the body - the amount of your investments will be returned',
            rus: 'По истечении срока контракта (12 месяцев), вам будет начислена доходность 14% и возвращено тело - сумма ваших инвестиций',
            ukr: 'Після закінчення терміну контракту (12 місяців) вам буде нарахована доходність 14% і повернено тіло - сума ваших інвестицій',
            deu: 'Nach Ablauf der Vertragslaufzeit (12 Monate) wird Ihnen eine Rendite von 14% und der Körper - die Höhe Ihrer Investitionen - gutgeschrieben',
        },
        project_list_title_1: {
            eng: 'Get',
            rus: 'Получайте',
            ukr: 'Отримуйте',
            deu: 'Erhalten',
        },
        project_list_title_2: {
            eng: 'passive income',
            rus: 'пассивную прибыль',
            ukr: 'пасивний дохід',
            deu: 'passives Einkommen',
        },
        project_list_title_3: {
            eng: 'from the development of developer projects',
            rus: 'от развития девелоперских проектов',
            ukr: 'від розвитку девелоперських проектів',
            deu: 'vom Entwickeln von Entwicklerprojekten',
        },
        project_list_block_1_text: {
            eng: 'GREM uses the assets of private investors and finances high-yield development projects',
            rus: 'GREM использует активы частных инвесторов и финансирует высокодоходные девелоперские проекты',
            ukr: 'GREM використовує активи приватних інвесторів та фінансує високодохідні девелоперські проекти',
            deu: 'GREM verwendet die Vermögenswerte privater Investoren und finanziert renditestarke Entwicklungsprojekte',
        },
        project_list_block_2_text: {
            eng: 'the average annual return on developer projects',
            rus: 'средняя годовая доходность девелоперских проектов',
            ukr: 'середньорічний дохід від девелоперських проектів',
            deu: 'die durchschnittliche jährliche Rendite von Entwicklerprojekten',
        },
        project_list_block_3_text: {
            eng: 'The received profit from developer projects is proportionally distributed among private investors',
            rus: 'Полученная прибыль от девелоперских проектов, пропорционально распределяется между частными инвесторами',
            ukr: 'Отриманий прибуток від девелоперських проектів пропорційно розподіляється між приватними інвесторами',
            deu: 'Der erzielte Gewinn aus Entwicklerprojekten wird proportional unter privaten Investoren verteilt',
        },
        project_list_second_title_2: {
            eng: "Development projects",
            rus: 'Девелоперские проекты',
            ukr: 'Девелоперські проекти',
            deu: 'Entwicklungsprojekte',
        },

        project_list_second_title_3: {
            eng: 'Offer for investors',
            rus: 'Предложение для инвесторов',
            ukr: 'Пропозиція для інвесторів',
            deu: 'Angebot für Investoren',
        },
        project_list_investor_info_1: {
            eng: '14% income',
            rus: '14% доходности',
            ukr: '14% доходу',
            deu: '14% Einkommen',
        },
        project_list_investor_info_2: {
            eng: 'for 12 months',
            rus: 'за 12 месяцев',
            ukr: 'на 12 місяців',
            deu: 'für 12 Monate',
        },
        project_list_investor_button_1: {
            eng: 'Make a contract',
            rus: 'Оформить контракт',
            ukr: 'Оформити контракт',
            deu: 'Vertrag abschließen',
        },
        project_list_investor_button_2: {
            eng: 'Consult a specialist',
            rus: 'Консультация специалиста',
            ukr: 'Консультація спеціаліста',
            deu: 'Fachliche Beratung'
        },
        project_list_investor_badge_text: {
            eng: 'Fixed interest rate per contract',
            rus: 'Фиксированная процентная ставка за контракт',
            ukr: 'Фіксована відсоткова ставка за контрактом',
            deu: 'Fester Zinssatz pro Vertrag',
        },
        project_list_annual_yield: {
            eng: 'Average Yield',
            rus: 'Средняя доходность',
            ukr: 'Середня дохідність',
            deu: 'Durchschnittliche Rendite',
        },
        project_contact_page_title: {
            eng: 'Consultation',
            rus: 'Консультация',
            ukr: 'Консультація',
            deu: 'Beratung',
        },
        project_contact_title: {
            eng: 'We\'ll answer your questions',
            rus: 'Ответим на ваши вопросы',
            ukr: 'Відповімо на ваші питання',
            deu: 'Wir beantworten Ihre Fragen',
        },
        project_contact_form_title: {
            eng: 'Leave a request in the feedback form and our managers will contact you',
            rus: 'Оставьте заявку в форме обратной связи и наши менеджеры свяжутся с вами',
            ukr: 'Залиште заявку в формі зворотнього зв’язку і наші менеджери зв’яжуться з вами',
            deu: 'Hinterlassen Sie eine Anfrage im Feedback-Formular und unsere Manager werden sich mit Ihnen in Verbindung setzen',
        },
        project_contact_form_name: {
            eng: 'Your name',
            rus: 'Ваше имя',
            ukr: 'Ваше ім\'я',
            deu: 'Ihr Name',
        },
        project_contact_form_messenger_login: {
            eng: 'Your login',
            rus: 'Ваш логин',
            ukr: 'Ваш логін',
            deu: 'Ihr Login',
        },
        project_contact_form_phone: {
            eng: 'Your phone',
            rus: 'Ваш телефон',
            ukr: 'Ваш телефон',
            deu: 'Ihr Telefon',
        },
        project_contact_form_question: {
            eng: 'Describe the question',
            rus: 'Опишите вопрос',
            ukr: 'Опишіть питання',
            deu: 'Beschreiben Sie die Frage',
        },
        project_contact_form_submit: {
            eng: 'Send request',
            rus: 'Отправить заявку',
            ukr: 'Відправити запит',
            deu: 'Anfrage senden',
        },
        project_tabs_investor: {
            eng: 'Basic staking',
            rus: 'Базовый стейкинг',
            ukr: 'Базовий стейкінг',
            deu: 'Grundlegendes Steakin',
        },
        project_tabs_developer: {
            eng: 'For developers',
            rus: 'Для застройщиков',
            ukr: 'Для забудовників',
            deu: 'Für Entwickler',
        },
        project_tabs_list: {
            eng: 'Project list',
            rus: 'Каталог проектов',
            ukr: 'Каталог проектів',
            deu: 'Projektliste',
        },
        project_developer_services_1: {
            eng: 'Consider your project',
            rus: 'Разберем ваш проект',
            ukr: 'Розглянемо ваш проект',
            deu: 'Wir werden Ihr Projekt studieren',
        },
        project_developer_services_2: {
            eng: 'Offer the best individual tokenization conditions',
            rus: 'Предложим лучшие индивидуальные условия токенизации',
            ukr: 'Запропонуємо кращі індивідуальні умови токенізації',
            deu: 'Bieten Sie die besten individuellen Tokenisierungsbedingungen',
        },
        project_developer_services_3: {
            eng: 'Discuss ways to attract financing',
            rus: 'Обсудим способы привлечения финансирования',
            ukr: 'Обговоримо способи привернення фінансування',
            deu: 'Diskutieren Sie Möglichkeiten zur Finanzierung',
        },
        project_developer_title_1: {
            eng: 'GREM for developers:',
            rus: 'GREM для застройщиков:',
            ukr: 'GREM для забудовників:',
            deu: 'GREM für Entwickler:',
        },
        project_developer_title_2: {
            eng: 'innovative opportunities',
            rus: 'инновационные возможности',
            ukr: 'інноваційні можливості',
            deu: 'innovative Möglichkeiten',
        },
        project_developer_title_3: {
            eng: ', that the platform opens up',
            rus: ', которые открывает платформа',
            ukr: ', які відкриває платформа',
            deu: ', die die Plattform eröffnet',
        },
        project_developer_item_1_title: {
            eng: 'Tokenization of your projects',
            rus: 'Токенизация ваших проектов',
            ukr: 'Токенізація ваших проектів',
            deu: 'Tokenisierung Ihrer Projekte',
        },
        project_developer_item_1_text: {
            eng: 'Digitize your project by breaking it down into individual square meters. GREM emits meters and releases unique tokens available for sale',
            rus: 'Цифровизируйте ваш проект, разбив его на отдельные квадратные метры. GREM эмитирует метры и выпускает уникальные токены, доступные для продажи',
            ukr: 'Цифровізуйте ваш проект, розбивши його на окремі квадратні метри. GREM емітує метри та випускає унікальні токени, доступні для продажу',
            deu: 'Digitalisieren Sie Ihr Projekt, indem Sie es in einzelne Quadratmeter aufteilen. GREM emittiert Meter und gibt einzigartige Tokens frei, die zum Verkauf stehen',
        },
        project_developer_item_2_title: {
            eng: 'GREM acts as a guarantor of security and transparency of the transactions made',
            rus: 'GREM выступает гарантом безопасности и прозрачности совершаемых сделок',
            ukr: 'GREM виступає гарантом безпеки та прозорості здійснюваних угод',
            deu: 'GREM tritt als Garant für Sicherheit und Transparenz der getätigten Transaktionen auf',
        },
        project_developer_item_3_title: {
            eng: 'Attracting financing',
            rus: 'Привлечение финансирование',
            ukr: 'Привернення фінансування',
            deu: 'Finanzierung anziehen',
        },
        project_developer_item_3_text: {
            eng: 'Thanks to tokenization and the issuance of unique tokens of your project, you get access to more than 10,000 private investors from around the world',
            rus: 'Благодаря токенизации и выпуску уникальных токенов вашего проекта, вам открывается доступ к более чем 10 000 частным инвесторам со всего мира',
            ukr: 'Завдяки токенізації та випуску унікальних токенів вашого проекту, вам відкривається доступ до понад 10 000 приватних інвесторів з усього світу',
            deu: 'Dank der Tokenisierung und der Ausgabe einzigartiger Tokens Ihres Projekts erhalten Sie Zugang zu mehr als 10.000 privaten Investoren aus der ganzen Welt',
        },
        project_developer_form_title: {
            eng: 'Consultation on your project',
            rus: 'Консультация по вашему проекту',
            ukr: 'Консультація по вашому проекту',
            deu: 'Beratung zu Ihrem Projekt',
        },
        project_developer_form_text: {
            eng: 'Fill out the contact form and get a free consultation from our experts',
            rus: 'Заполните контактную форму и получите бесплатную консультацию от наших экспертов',
            ukr: 'Заповніть контактну форму та отримайте безкоштовну консультацію від наших експертів',
            deu: 'Füllen Sie das Kontaktformular aus und erhalten Sie eine kostenlose Beratung von unseren Experten',
        },
        project_form_success: {
            eng: 'The form has been sent. We will contact you as soon as possible!',
            rus: 'Форма отправлена. Мы свяжемся с вами в ближайшее время!',
            ukr: 'Форма відправлена. Ми зв\'яжемося з вами найближчим часом!',
            deu: 'Das Formular wurde gesendet. Wir werden uns so schnell wie möglich bei Ihnen melden!',
        },
        project_developer_form_name: {
            eng: 'Contact person',
            rus: 'Контактное лицо',
            ukr: 'Контактна особа',
            deu: 'Kontaktperson',
        },
        project_developer_form_project_name: {
            eng: 'Project name',
            rus: 'Название проекта',
            ukr: 'Назва проекту',
            deu: 'Projektname',
        },
        project_developer_form_project_country: {
            eng: 'Project country',
            rus: 'Страна проекта',
            ukr: 'Країна проекту',
            deu: 'Projektland',
        },
        project_developer_form_project_description: {
            eng: 'Describe your project',
            rus: 'Опишите ваш проект',
            ukr: 'Опишіть ваш проект',
            deu: 'Beschreiben Sie Ihr Projekt',
        },
        project_developer_form_project_file: {
            eng: "Attach document",
            rus: 'Прикрепить документ',
            ukr: 'Прикріпити документ',
            deu: 'Dokument anhängen',
        },
        project_developer_form_project_file_text: {
            eng: 'You can attach a presentation, business plan or other useful document',
            rus: 'Вы можете прикрепить презентацию, бизнес-план или другой полезный документ',
            ukr: 'Ви можете прикріпити презентацію, бізнес-план або інший корисний документ',
            deu: 'Sie können eine Präsentation, einen Geschäftsplan oder ein anderes nützliches Dokument anhängen',
        },
        project_developer_form_project_send: {
            eng: "Send request",
            rus: 'Отправить заявку',
            ukr: 'Відправити запит',
            deu: 'Anfrage senden',
        },
        messenger_title: {
            eng: 'My messages',
            rus: 'Мои сообщения',
            ukr: 'Мої повідомлення',
            deu: 'Meine Nachrichten',
        },
        messenger_first_message_1: {
            eng: 'Welcome to the GREM platform',
            rus: 'Приветствуем вас на платформе GREM',
            ukr: 'Вітаємо вас на платформі GREM',
            deu: 'Willkommen auf der GREM-Plattform',
        },
        messenger_first_message_2: {
            eng: 'Start a conversation with anyone in the community by selecting the person you want in the Community tab and clicking the “Send Message” button.',
            rus: 'Начните общение с любым участником сообщества, выбрав нужного человека во вкладке “Сообщество” и нажав кнопку "Отправить сообщение".',
            ukr: 'Почніть спілкування з будь-яким учасником спільноти, обравши потрібну людину у вкладці «Спільнота» і натиснувши кнопку «Надіслати повідомлення».',
            deu: 'Beginnen Sie das Gespräch mit einem beliebigen Community-Mitglied, indem Sie die gewünschte Person im Tab "Community" auswählen und auf die Schaltfläche "Nachricht senden" klicken.',
        },
        messenger_first_message_3: {
            eng: 'You can contact the GREM team directly in the Contacts tab or in the Support tab',
            rus: 'Напрямую связаться с командой GREM, вы можете во вкладке Контакты или во вкладке Поддержка',
            ukr: 'Безпосередньо зв\'язатися з командою GREM, ви можете в вкладці Контакти або в вкладці Підтримка',
            deu: 'Sie können das GREM-Team direkt im Tab Kontakte oder im Tab Support kontaktieren',
        },
        messenger_input: {
            eng: 'Enter message...',
            rus: 'Введите сообщение...',
            ukr: 'Введіть повідомлення...',
            deu: 'Nachricht eingeben...',
        },
        messenger_header_online: {
            eng: 'online',
            rus: 'в сети',
            ukr: 'в мережі',
            deu: 'online',
        },
        messenger_no_chats: {
            eng: 'No chats available',
            rus: 'Нет доступных чатов',
            ukr: 'Немає доступних чатів',
            deu: 'Keine Chats verfügbar',
        },
        wallet_tab_balance: {
            eng: 'Balance',
            rus: 'Баланс',
            ukr: 'Баланс',
            deu: 'Guthaben',
        },
        wallet_tab_history: {
            eng: 'Transaction history',
            rus: 'История транзакций',
            ukr: 'Історія транзакцій',
            deu: 'Transaktionsgeschichte',
        },
        wallet_tab_contracts: {
            eng: "My contracts",
            rus: 'Мои контракты',
            ukr: 'Мої контракти',
            deu: 'Meine Verträge',
        },
        wallet_title: {
            eng: 'My wallet',
            rus: 'Мой кошелек',
            ukr: 'Мій гаманець',
            deu: 'Mein Geldbeutel',
        },
        wallet_balance_history_title: {
            eng: 'Recent operations',
            rus: 'Последние операции',
            ukr: 'Останні операції',
            deu: 'Letzte Operationen',
        },
        wallet_balance_history_date: {
            eng: 'Date',
            rus: 'Дата',
            ukr: 'Дата',
            deu: 'Datum',
        },
        wallet_balance_history_amount: {
            eng: 'Amount (M2C)',
            rus: 'Сумма (М2С)',
            ukr: 'Сума (М2С)',
            deu: 'Betrag (M2C)',
        },
        wallet_balance_history_type: {
            eng: 'Operation type',
            rus: 'Тип операции',
            ukr: 'Тип операції',
            deu: 'Operationstyp',
        },
        wallet_balance_history_op: {
            eng: 'Operation',
            rus: 'Операция',
            ukr: 'Операція',
            deu: 'Betrieb',
        },
        wallet_balance_history_op_id: {
            eng: 'Operation ID',
            rus: 'ID операции',
            ukr: 'ID операції',
            deu: 'Betriebs-ID',
        },
        wallet_balance_history_status: {
            eng: 'Status',
            rus: 'Статус',
            ukr: 'Статус',
            deu: 'Status',
        },
        wallet_balance_total_title: {
            eng: 'Total balance',
            rus: 'Общий баланс',
            ukr: 'Загальний баланс',
            deu: 'Gesamtsaldo',
        },
        wallet_balance_total_description: {
            eng: 'this is the sum of all your balances on the GREM platform',
            rus: 'это сумма всех ваших балансов на платформе GREM',
            ukr: 'це сума всіх ваших балансів на платформі GREM',
            deu: 'dies ist die Summe aller Ihrer Salden auf der GREM-Plattform',
        },
        wallet_balance_frozen_title: {
            eng: 'Frozen balance',
            rus: 'Замороженный баланс',
            ukr: 'Заморожений баланс',
            deu: 'Gefrorener Saldo',
        },
        wallet_balance_frozen_description: {
            eng: 'this is the sum of funds on your account that are not available for withdrawal at the moment',
            rus: 'это сумма средств на вашем счету, недоступная для вывода в данный момент',
            ukr: 'це сума коштів на вашому рахунку, недоступна для виведення в даний момент',
            deu: 'dies ist die Summe der Mittel auf Ihrem Konto, die im Moment nicht abgehoben werden können',
        },
        wallet_balance_available_title: {
            eng: 'Available balance',
            rus: 'Доступный баланс',
            ukr: 'Доступний баланс',
            deu: 'Verfügbarer Saldo',
        },
        wallet_balance_available_description: {
            eng: 'this is the sum of funds that you can withdraw from the platform at any time',
            rus: 'это сумма средств, которую вы можете вывести с платформы в любой момент',
            ukr: 'це сума коштів, яку ви можете вивести з платформи в будь-який момент',
            deu: 'dies ist die Summe der Mittel, die Sie jederzeit von der Plattform abheben können',
        },
        wallet_balance_deposit: {
            eng: 'Deposit',
            rus: 'Пополнить',
            ukr: 'Поповнити',
            deu: 'Einzahlen',
        },
        wallet_balance_withdraw: {
            eng: 'Withdraw',
            rus: 'Вывод',
            ukr: 'Вивід',
            deu: 'Abheben',
        },
        wallet_balance_staking: {
            eng: 'Staking',
            rus: 'Стейкинг',
            ukr: "Стейкінг",
            deu: 'Staking',
        },
        wallet_balance_explorer: {
            eng: 'Open wallet in explorer',
            rus: 'Открыть кошелёк в explorer',
            ukr: 'Відкрити гаманець в explorer',
            deu: 'Öffnen Sie das Wallet im Explorer',
        },
        wallet_balance_history_last: {
            eng: 'Last operations',
            rus: 'Последние операции',
            ukr: 'Останні операції',
            deu: 'Letzte Operationen',
        },
        wallet_balance_history_type_deposit: {
            eng: 'Deposit',
            rus: 'Начисление',
            ukr: 'Надходження',
            deu: 'Einzahlung',
        },
        wallet_balance_history_type_withdraw: {
            eng: 'Withdrawal',
            rus: 'Снятие',
            ukr: 'Списання',
            deu: 'Abhebung',
        },
        wallet_transaction_status_new: {
            eng: "New",
            rus: 'Новая',
            ukr: 'Нова',
            deu: 'Neu',
        },
        wallet_transaction_status_waiting: {
            eng: 'Waiting',
            rus: 'Ожидание',
            ukr: 'Очікування',
            deu: 'Warten',
        },
        wallet_transaction_status_processing: {
            eng: 'Processing',
            rus: 'Обработка',
            ukr: 'Обробка',
            deu: 'Verarbeitung',
        },
        wallet_transaction_status_done: {
            eng: 'Success',
            rus: 'Успех',
            ukr: 'Успіх',
            deu: 'Erfolg',
        },
        wallet_transaction_status_rejected: {
            eng: 'Failed',
            rus: 'Отклонено',
            ukr: 'Відхилено',
            deu: 'Fehlgeschlagen',
        },
        wallet_transaction_status_failed: {
            eng: 'Failed',
            rus: 'Неудача',
            ukr: 'Невдача',
            deu: 'Fehlgeschlagen',
        },
        wallet_history_title: {
            eng: 'Full list of your account transactions',
            rus: 'Полный список транзакций по вашему счету',
            ukr: 'Повний список транзакцій по вашому рахунку',
            deu: 'Vollständige Liste der Transaktionen auf Ihrem Konto',
        },
        wallet_deposit_no_methods: {
            eng: 'No available deposit methods',
            rus: 'Нет доступных методов пополнения',
            ukr: 'Немає доступних методів поповнення',
            deu: 'Keine verfügbaren Einzahlungsmethoden',
        },
        wallet_deposit_subtitle_1: {
            eng: 'Amount and method of deposit',
            rus: 'Сумма и способ пополнения',
            ukr: 'Сума та спосіб поповнення',
            deu: 'Betrag und Einzahlungsmethode',
        },
        wallet_deposit_subtitle_2: {
            eng: 'Choose the amount and method of deposit',
            rus: 'Вы пополняете баланас в валюте М2С',
            ukr: 'Ви поповнюєте баланс в валюті М2С',
            deu: 'Sie füllen das Guthaben in der Währung M2C auf',
        },
        wallet_deposit_form_amount: {
            eng: "Deposit amount",
            rus: 'Сумма пополнения',
            ukr: 'Сума поповнення',
            deu: 'Einzahlungsbetrag',
        },
        wallet_deposit_form_total_summ: {
            eng: 'Total amount',
            rus: 'Итоговая сумма',
            ukr: 'Загальна сума',
            deu: 'Gesamtbetrag',
        },
        wallet_deposit_form_qr_modal: {
            eng: 'Scan the QR code to get the deposit address',
            rus: 'Отсканируйте QR-код, чтобы получить адрес для пополнения',
            ukr: 'Отскануйте QR-код, щоб отримати адресу для поповнення',
            deu: 'Scannen Sie den QR-Code, um die Einzahlungsadresse zu erhalten',
        },
        wallet_deposit_form_method_name: {
            eng: 'Deposit method',
            rus: 'Способ пополнения',
            ukr: 'Спосіб поповнення',
            deu: 'Einzahlungsmethode',
        },
        wallet_deposit_form_method: {
            eng: 'Choice of deposit method',
            rus: 'Выбор метода пополнения',
            ukr: 'Вибір методу поповнення',
            deu: 'Wahl der Einzahlungsmethode',
        },
        wallet_deposit_form_conversion: {
            eng: 'Conversion',
            rus: 'Конвертация',
            ukr: 'Конвертація',
            deu: 'Konvertierung',
        },
        wallet_deposit_form_limit: {
            eng: 'Limit',
            rus: 'Лимит',
            ukr: 'Ліміт',
            deu: 'Limit',
        },
        wallet_deposit_form_fee: {
            eng: 'Payment fee',
            rus: 'Платежная комиссия',
            ukr: 'Платіжна комісія',
            deu: 'Zahlungsgebühr',
        },
        wallet_deposit_form_deposit_in: {
            eng: 'Amount to be deposited in',
            rus: 'Сумма к пополнению в',
            ukr: 'Сума до поповнення в',
            deu: 'Einzuzahlender Betrag in',
        },
        wallet_deposit_form_submit: {
            eng: "Top up balance",
            rus: 'Пополнить баланс',
            ukr: 'Поповнити баланс',
            deu: 'Guthaben aufladen',
        },
        wallet_deposit_title: {
            eng: 'Deposit of a personal wallet',
            rus: 'Пополнение личного кошелька',
            ukr: 'Поповнення особистого гаманця',
            deu: 'Einzahlung eines persönlichen Geldbeutels',
        },
        wallet_deposit_info_title: {
            eng: 'What is the purpose of the M2C token?',
            rus: 'Для чего нужна монета М2С?',
            ukr: 'Для чого потрібна монета М2С?',
            deu: 'Wofür ist der M2C-Token gedacht?',
        },
        wallet_deposit_info_text_1: {
            eng: 'With M2C you can invest in development projects, execute smart contracts and buy real estate from 1 square meter. M2C is designed with a focus on sustainability and reliability, making it an excellent choice for long-term investments.',
            rus: 'С помощью М2С вы можете инвестировать в девелоперские проекты, оформлять smart-контракты и покупать недвижимость от 1 кв.м. М2С разработана с акцентом на устойчивость и надежность, что делает её отличным выбором для долгосрочных инвестиций.',
            ukr: "За допомогою М2С ви можете інвестувати в девелоперські проекти, оформляти smart-контракти та купувати нерухомість від 1 кв.м. М2С розроблена з акцентом на стійкість та надійність, що робить її відмінним вибором для довгострокових інвестицій.",
            deu: 'Mit M2C können Sie in Entwicklungsprojekte investieren, Smart Contracts ausführen und Immobilien ab 1 Quadratmeter kaufen. M2C wurde mit einem Schwerpunkt auf Nachhaltigkeit und Zuverlässigkeit entwickelt und ist daher eine ausgezeichnete Wahl für langfristige Investitionen.',
        },
        wallet_deposit_info_text_2: {
            eng: 'Transactions within the GREM platform are automatic and almost instantaneous, giving you quick access to funds and the ability to respond quickly to any financial opportunity.',
            rus: 'Транзакции внутри платформы GREM проводятся автоматически и практически мгновенно, обеспечивая вам быстрый доступ к средствам и возможность оперативно реагировать на любые финансовые возможности.',
            ukr: 'Транзакції всередині платформи GREM проводяться автоматично та практично миттєво, забезпечуючи вам швидкий доступ до коштів та можливість оперативно реагувати на будь-які фінансові можливості.',
            deu: 'Transaktionen innerhalb der GREM-Plattform sind automatisch und nahezu sofort, sodass Sie schnell auf Gelder zugreifen und schnell auf jede finanzielle Gelegenheit reagieren können.',
        },
        wallet_deposit_info_sub_title: {
            eng: 'More info',
            rus: 'Дополнительная информация',
            ukr: 'Додаткова інформація',
            deu: 'Weitere Informationen',
        },
        wallet_deposit_info_sub_text_1: {
            eng: 'Transactions are automatic, but are processed manually',
            rus: 'Транзакции происходят автоматически, но обрабатываются вручную',
            ukr: 'Транзакції відбуваються автоматично, але обробляються вручну',
            deu: 'Transaktionen erfolgen automatisch, werden jedoch manuell verarbeitet',
        },
        wallet_deposit_info_sub_text_2: {
            eng: 'Processing time takes from 3 to 24 hours for BTC, ETH, USDT',
            rus: 'Время обработки занимает от 3 до 24 часов по направлению BTC, ETH, USDT',
            ukr: 'Час обробки займає від 3 до 24 годин по напрямку BTC, ETH, USDT',
            deu: 'Die Verarbeitungszeit beträgt 3 bis 24 Stunden für BTC, ETH, USDT',
        },
        wallet_deposit_not_allowed: {
            eng: 'Deposit is currently unavailable for your wallet',
            rus: 'На данный момент пополнение недоступно для вашего кошелька',
            ukr: 'На даний момент поповнення недоступне для вашого гаманця',
            deu: 'Die Einzahlung ist derzeit für Ihr Wallet nicht verfügbar',
        },
        wallet_deposit_invoice_details: {
            eng: 'Deposit details',
            rus: 'Детали пополнения',
            ukr: 'Деталі поповнення',
            deu: 'Einzahlungsdetails',
        },
        wallet_deposit_invoice_crypto_title: {
            eng: 'Address for deposit of the invoice in',
            rus: 'Адрес для пополнения инвойса в',
            ukr: 'Адреса для поповнення інвойса в',
            deu: 'Adresse für die Einzahlung der Rechnung in',
        },
        wallet_deposit_invoice_bill_edrpou: {
            eng: 'EDRPOU',
            rus: 'ЄДРПОУ',
            ukr: 'ЄДРПОУ',
            deu: 'EDRPOU',
        },
        wallet_deposit_invoice__bill_recipient: {
            eng: 'Recipient',
            rus: 'Получатель',
            ukr: 'Одержувач',
            deu: 'Empfänger',
        },
        wallet_deposit_invoice__bill_purpose: {
            eng: 'Purpose',
            rus: 'Назначение',
            ukr: 'Призначення',
            deu: 'Zweck',
        },
        wallet_deposit_invoice_title: {
            eng: 'Requisites for deposit',
            rus: 'Реквизиты для пополнения',
            ukr: 'Реквізити для поповнення',
            deu: 'Requisiten für die Einzahlung',
        },
        wallet_deposit_invoice_type_unknown: {
            eng: 'Unknown invoice type',
            rus: 'Неизвестный тип инвойса',
            ukr: 'Невідомий тип інвойса',
            deu: 'Unbekannter Rechnungstyp',
        },
        wallet_deposit_invoice_page_title: {
            eng: 'Top-up balance',
            rus: 'Пополнение баланса',
            ukr: 'Поповнення балансу',
            deu: 'Guthaben aufladen',
        },
        wallet_deposit_invoice_subtitle: {
            eng: 'Amount and method of deposit',
            rus: 'Сумма и способ пополнения',
            ukr: 'Сума та спосіб поповнення',
            deu: 'Betrag und Einzahlungsmethode',
        },
        wallet_deposit_invoice_confirm: {
            eng: 'Confirm deposit',
            rus: 'Подтвердить пополнение',
            ukr: 'Підтвердити поповнення',
            deu: 'Einzahlung bestätigen',
        },
        wallet_deposit_invoice_paid: {
            eng: 'Paid',
            rus: 'Оплачено',
            ukr: 'Оплачено',
            deu: 'Bezahlt',
        },
        wallet_deposit_invoice_overpayment: {
            eng: 'overpaid',
            rus: 'переплачено',
            ukr: 'переплачено',
            deu: 'überbezahlt',
        },
        wallet_deposit_invoice_underpayment: {
            eng: 'underpaid',
            rus: 'недоплачено',
            ukr: 'недоплачено',
            deu: 'unterbezahlt',
        },
        wallet_deposit_invoice_expired: {
            eng: 'Invoice expired',
            rus: 'Срок действия инвойса истек',
            ukr: 'Термін дії інвойса закінчився',
            deu: 'Rechnung abgelaufen',
        },
        wallet_deposit_invoice_expires_in: {
            eng: 'The invoice expires in',
            rus: 'Срок действия инвойса истекает через',
            ukr: 'Термін дії інвойса закінчується через',
            deu: 'Die Rechnung läuft ab in',
        },
        wallet_deposit_invoice_success_title: {
            eng: 'Your payment is successful, thank you for your trust!',
            rus: 'Ваша оплата успешна, спасибо за доверие!',
            ukr: 'Ваша оплата успішна, дякуємо за довіру!',
            deu: 'Ihre Zahlung war erfolgreich, danke für Ihr Vertrauen!',
        },
        wallet_deposit_invoice_success_text: {
            eng: 'Receive a stable annual income from your investment, with a guaranteed payout. Details and status of your contract are available in your wallet.',
            rus: 'Получайте стабильный годовой доход от ваших вложений, с гарантией выплаты. Подробности и состояние вашего контракта доступны в вашем кошельке.',
            ukr: 'Отримуйте стабільний щорічний дохід від ваших інвестицій, з гарантованою виплатою. Деталі та статус вашого контракту доступні в вашому гаманці.',
            deu: 'Erhalten Sie ein stabiles jährliches Einkommen aus Ihrer Investition mit einer garantierten Auszahlung. Details und Status Ihres Vertrags sind in Ihrem Geldbeutel verfügbar.',
        },
        wallet_deposit_invoice_my_wallet: {
            eng: 'My wallet',
            rus: 'Мой кошелек',
            ukr: 'Мій гаманець',
            deu: 'Mein Geldbeutel',
        },
        wallet_deposit_invoice_wait_confirm: {
            eng: 'Waiting for confirmation',
            rus: 'Ожидание подтверждения',
            ukr: 'Очікування підтвердження',
            deu: 'Warten auf Bestätigung',
        },
        wallet_deposit_invoice_wait_confirm_info_1: {
            eng: 'After confirmation, you\'ll be e-mailed',
            rus: 'После подтверждения Вам на почту',
            ukr: 'Після підтвердження Вам на пошту',
            deu: 'Nach Bestätigung erhalten Sie eine E-Mail',
        },
        wallet_deposit_invoice_wait_confirm_info_2: {
            eng: 'will be sent a mail of successful payment',
            rus: 'будет отправлено уведомление об успешной оплате',
            ukr: 'буде відправлено сповіщення про успішну оплату',
            deu: 'eine Benachrichtigung über die erfolgreiche Zahlung wird gesendet',
        },
        wallet_deposit_invoice_confirmation: {
            eng: 'Confirmations',
            rus: 'Подтверждения',
            ukr: 'Підтвердження',
            deu: 'Bestätigung',
        },
        wallet_deposit_invoice_payment_not_received: {
            eng: 'Unfortunately, payment has not been received',
            rus: 'К сожалению, оплата не поступила',
            ukr: 'На жаль, оплата не надійшла',
            deu: 'Leider ist die Zahlung nicht eingegangen',
        },
        wallet_deposit_invoice_not_received_1: {
            eng: 'Please check the data entered and try again. If the problem persists, please contact our',
            rus: 'Пожалуйста, проверьте введенные данные и попробуйте снова. Если проблема сохраняется, свяжитесь с нашей',
            ukr: 'Будь ласка, перевірте введені дані та спробуйте ще раз. Якщо проблема залишається, зверніться до нашої',
            deu: 'Bitte überprüfen Sie die eingegebenen Daten und versuchen Sie es erneut. Wenn das Problem weiterhin besteht, wenden Sie sich an unser',
        },
        wallet_deposit_invoice_not_received_2: {
            eng: 'support service.',
            rus: 'службой поддержки.',
            ukr: 'службою підтримки.',
            deu: 'durch den Kundendienst.',
        },
        wallet_staking_deposit_invoice_title: {
            eng: 'Formalization of the staking contract',
            rus: 'Оформление стейкинг-контракта',
            ukr: 'Оформлення стейкінг-контракту',
            deu: 'Formalisierung des Staking-Vertrags',
        },
        wallet_staking_deposit_invoice_pay_confirm_1: {
            eng: 'Payment',
            rus: 'Оплата',
            ukr: 'Оплата',
            deu: 'Zahlung',
        },
        wallet_staking_deposit_invoice_pay_confirm_2: {
            eng: 'has been made',
            rus: 'совершена',
            ukr: 'здійснена',
            deu: 'wurde gemacht',
        },
        wallet_staking_deposit_invoice_details: {
            eng: 'Contract details',
            rus: 'Детали контракта',
            ukr: 'Деталі контракту',
            deu: 'Vertragsdetails',
        },
        wallet_staking_deposit_invoice_details_contract_start: {
            eng: 'Contract start date',
            rus: 'Дата начала контракта',
            ukr: 'Дата початку контракту',
            deu: 'Vertragsbeginndatum',
        },
        wallet_staking_deposit_invoice_details_contract_end: {
            eng: 'Contract end date',
            rus: 'Дата окончания контракта',
            ukr: 'Дата закінчення контракту',
            deu: 'Vertragsenddatum',
        },
        wallet_staking_deposit_invoice_details_contract_payment_date: {
            eng: 'Payment date %',
            rus: 'Дата выплаты %',
            ukr: 'Дата виплати %',
            deu: 'Zahlungsdatum %',
        },
        wallet_staking_deposit_invoice_details_contract_apr: {
            eng: 'Contract yield',
            rus: 'Доходность по котракту',
            ukr: 'Дохідність по контракту',
            deu: 'Jahresprozentsatz',
        },
        wallet_staking_deposit_invoice_deposit_amount: {
            eng: 'Deposit amount',
            rus: 'Вносимая сумма',
            ukr: 'Внесена сума',
            deu: 'Einzahlungsbetrag',
        },
        wallet_staking_deposit_invoice_deposit_fee: {
            eng: 'Deposit fee',
            rus: 'Платежная комиссия',
            ukr: 'Платіжна комісія',
            deu: 'Zahlungsgebühr',
        },
        wallet_staking_deposit_invoice_deposit_payable_amount: {
            eng: 'Payable amount',
            rus: 'Сумма к оплате',
            ukr: 'Сума до оплати',
            deu: 'Zu zahlender Betrag',
        },
        wallet_staking_deposit_invoice_deposit_not_available: {
            eng: 'At the moment, staking is not available for your wallet',
            rus: 'На данный момент стейкинг для вашего кошелька недоступен',
            ukr: 'На даний момент стейкінг для вашого гаманця недоступний',
            deu: 'Derzeit ist das Staking für Ihr Wallet nicht verfügbar',
        },
        wallet_staking_deposit_invoice_deposit_native_token: {
            eng: 'Payment with M2C token',
            rus: 'Оплата с помощью валюты М2С',
            ukr: 'Оплата за допомогою валюти М2С',
            deu: 'Zahlung mit M2C-Token',
        },
        wallet_staking_deposit_invoice_deposit_summ: {
            eng: 'Staking amount',
            rus: 'Сумма стейкинга',
            ukr: 'Сума стейкінгу',
            deu: 'Staking-Betrag',
        },
        wallet_staking_deposit_invoice_deposit_details_title: {
            eng: 'Payment details',
            rus: 'Детали оплаты',
            ukr: 'Деталі оплати',
            deu: 'Zahlungsdetails',
        },
        wallet_staking_deposit_invoice_deposit_methods: {
            eng: 'Available payment methods',
            rus: 'Доступные способы оплаты',
            ukr: 'Доступні способи оплати',
            deu: 'Verfügbare Zahlungsmethoden',
        },
        wallet_staking_contract_title: {
            eng: 'Staking contracts purchased by you',
            rus: 'Купленные вами стейкинг контракты',
            ukr: 'Куплені вами стейкінг контракти',
            deu: 'Von Ihnen gekaufte Staking-Verträge',
        },
        wallet_staking_contract_history_title: {
            eng: 'History of purchase of contracts',
            rus: 'История покупки контрактов',
            ukr: 'Історія покупки контрактів',
            deu: 'Kaufhistorie von Verträgen',
        },
        wallet_staking_contract_new_text: {
            eng: 'Purchase a new staking contract and receive an annual income',
            rus: 'Оформляйте новый стейкинг контракт и получайте годовой доход',
            ukr: 'Оформлюйте новий стейкінг контракт та отримуйте щорічний дохід',
            deu: 'Kaufen Sie einen neuen Staking-Vertrag und erhalten Sie ein jährliches Einkommen',
        },
        wallet_staking_contract_item_started_at: {
            eng: 'Started at',
            rus: 'Дата начала',
            ukr: 'Дата початку',
            deu: 'Gestartet am',
        },
        wallet_staking_contract_item_finished_at: {
            eng: 'Finished at',
            rus: 'Дата окончания',
            ukr: 'Дата закінчення',
            deu: 'Beendet am',
        },
        wallet_staking_contract_item_amount: {
            eng: 'Investment amount',
            rus: 'Сумма вложений',
            ukr: 'Сума вкладень',
            deu: 'Investitionsbetrag',
        },
        wallet_staking_contract_item_duration: {
            eng: "Contract duration (days)",
            rus: 'Продолжительность контракта (дней)',
            ukr: 'Тривалість контракту (днів)',
            deu: 'Vertragsdauer (Tage)',
        },
        wallet_staking_contract_item_earned: {
            eng: 'Earned',
            rus: 'Заработано',
            ukr: 'Зароблено',
            deu: 'Verdient',
        },
        wallet_staking_contract_item_yield: {
            eng: "Yield",
            rus: 'Доходность',
            ukr: 'Дохідність',
            deu: 'Ertrag',
        },
        wallet_staking_contract_item_payment_through: {
            eng: 'Payment of remuneration under the contract through:',
            rus: 'Выплата вознаграждения по контракту через:',
            ukr: 'Виплата винагороди за контрактом через:',
            deu: 'Zahlung der Vergütung gemäß dem Vertrag durch:',
        },
        wallet_staking_contract_item_more: {
            eng: 'More',
            rus: 'Подробнее',
            ukr: 'Детальніше',
            deu: 'Mehr',
        },
        wallet_staking_history_purchase_date: {
            eng: 'Date of purchase of contract',
            rus: 'Дата покупки контракта',
            ukr: 'Дата покупки контракту',
            deu: 'Kaufdatum des Vertrags',
        },
        wallet_staking_history_contract_id: {
            eng: 'Contract ID',
            rus: 'ID контракта',
            ukr: 'ID контракту',
            deu: 'Vertrags-ID',
        },
        wallet_staking_history_operation_type: {
            eng: 'Operation type',
            rus: 'Тип операции',
            ukr: 'Тип операції',
            deu: 'Operationstyp',
        },
        wallet_staking_history_amount: {
            eng: 'Amount',
            rus: 'Сумма',
            ukr: 'Сума',
            deu: 'Betrag',
        },
        wallet_staking_item_title: {
            eng: 'Details of the staking contract',
            rus: 'Детали стейкинг контракта',
            ukr: 'Деталі стейкінг контракту',
            deu: 'Details des Staking-Vertrags',
        },
        wallet_staking_item_contract_id: {
            eng: 'Contract ID',
            rus: 'ID контракта',
            ukr: 'ID контракту',
            deu: 'Vertrags-ID',
        },
        wallet_staking_item_contract_owner: {
            eng: 'Contract Owner:',
            rus: 'Владелец контракта:',
            ukr: 'Власник контракту:',
            deu: 'Vertragsinhaber:',
        },
        wallet_staking_item_stellar_public_key: {
            eng: 'Public Key in the Stellar system:',
            rus: 'Public Key в системе Stellar:',
            ukr: 'Public Key в системі Stellar:',
            deu: 'Öffentlicher Schlüssel im Stellar-System:',
        },
        wallet_staking_item_contract: {
            eng: 'Contract',
            rus: 'Контракт',
            ukr: 'Контракт',
            deu: 'Vertrag',
        },
        wallet_staking_item_deposited_amount: {
            eng: 'Deposited amount',
            rus: 'Внесенная сумма',
            ukr: 'Внесена сума',
            deu: 'Eingezahlter Betrag',
        },
        wallet_staking_item_yield: {
            eng: 'Yield',
            rus: 'Доходность',
            ukr: 'Дохідність',
            deu: 'Ertrag',
        },
        wallet_staking_item_start_date: {
            eng: 'Contract start date',
            rus: 'Дата начала контракта',
            ukr: 'Дата початку контракту',
            deu: 'Vertragsbeginndatum',
        },
        wallet_staking_item_end_date: {
            eng: 'Contract end date',
            rus: 'Дата окончания контракта',
            ukr: 'Дата закінчення контракту',
            deu: 'Vertragsenddatum',
        },
        wallet_staking_item_contract_address: {
            eng: 'Contract address',
            rus: 'Адрес контракта',
            ukr: 'Адреса контракту',
            deu: 'Vertragsadresse',
        },
        wallet_staking_item_token_address: {
            eng: 'Token address',
            rus: 'Адрес токена',
            ukr: 'Адреса токена',
            deu: 'Token-Adresse',
        },
        wallet_staking_staking_op_new: {
            eng: 'Purchase of a staking contract',
            rus: 'Покупка стейкинг контракта',
            ukr: 'Покупка стейкінг контракту',
            deu: 'Kauf eines Staking-Vertrags',
        },
        wallet_staking_staking_op_accural: {
            eng: 'Accrual',
            rus: 'Начисление',
            ukr: 'Нарахування',
            deu: 'Rückstellung',
        },
        wallet_staking_staking_contract_payed: {
            eng: 'Contract paid',
            rus: 'Контракт оплачен',
            ukr: 'Контракт оплачен',
            deu: 'Vertrag bezahlt',
        },
        wallet_staking_staking_contract_not_payed: {
            eng: 'Contract not paid',
            rus: 'Контракт не оплачен',
            ukr: 'Контракт не оплачен',
            deu: 'Vertrag nicht bezahlt',
        },
        wallet_staking_staking_contract_pay_info: {
            eng: 'Payment date and method',
            rus: 'Дата и способ оплаты',
            ukr: 'Дата та спосіб оплати',
            deu: 'Zahlungsdatum und -methode',
        },
        wallet_staking_staking_contract_pay: {
            eng: 'Pay',
            rus: 'Оплатить',
            ukr: 'Оплатити',
            deu: 'Bezahlen',
        },
        wallet_withdraw_title: {
            eng: 'Withdraw request',
            rus: 'Заявка на вывод средств',
            ukr: 'Заявка на виведення коштів',
            deu: 'Auszahlungsantrag',
        },
        wallet_withdraw_server_error_title: {
            eng: 'Oops, the server is not responding at the moment',
            rus: 'Упс, сервер на данный момент не отвечает',
            ukr: 'Упс, сервер на даний момент не відповідає',
            deu: 'Hoppla, der Server antwortet derzeit nicht',
        },
        wallet_withdraw_server_error_text: {
            eng: 'Please try to create a withdrawal request a little later',
            rus: 'Пожалуйста, попробуйте создать повторную заявку на вывод средств немного позже',
            ukr: 'Будь ласка, спробуйте створити повторний запит на виведення коштів трохи пізніше',
            deu: 'Bitte versuchen Sie, etwas später einen Auszahlungsantrag zu erstellen',
        },
        wallet_withdraw_not_allowed: {
            eng: 'Withdrawal is currently unavailable for your wallet',
            rus: 'На данный момент вывод недоступен для вашего кошелька',
            ukr: 'На даний момент вивід недоступний для вашого гаманця',
            deu: 'Die Auszahlung ist derzeit für Ihr Wallet nicht verfügbar',
        },
        wallet_withdraw_form_title: {
            eng: 'Amount and method of withdrawal',
            rus: 'Сумма и способ вывода',
            ukr: 'Сума та спосіб виведення',
            deu: 'Betrag und Auszahlungsmethode',
        },
        wallet_withdraw_no_methods: {
            eng: 'No available withdrawal methods',
            rus: 'Нет доступных методов вывода',
            ukr: 'Немає доступних методів виведення',
            deu: 'Keine verfügbaren Auszahlungsmethoden',
        },
        wallet_withdraw_form_amount: {
            eng: 'Withdrawal amount',
            rus: 'Сумма вывода',
            ukr: 'Сума виведення',
            deu: 'Auszahlungsbetrag',
        },
        wallet_withdraw_form_method: {
            eng: 'Choice of withdrawal method',
            rus: 'Выбор метода вывода средств',
            ukr: 'Вибір методу виведення коштів',
            deu: 'Wahl der Auszahlungsmethode',
        },
        wallet_withdraw_form_withdraw_in: {
            eng: 'Amount to be withdrawn in',
            rus: 'Сумма зачисления в',
            ukr: 'Сума зарахування в',
            deu: 'Betrag in',
        },
        wallet_withdraw_cred_title: {
            eng: 'Details of the recipient of funds',
            rus: 'Детали получателя средств',
            ukr: 'Деталі отримувача коштів',
            deu: 'Details des Geldempfängers',
        },
        wallet_withdraw_cred_unknown_type: {
            eng: 'Unknown data type for withdraw',
            rus: 'Неизвестный тип данных для вывода',
            ukr: 'Невідомий тип даних для виведення',
            deu: 'Unbekannter Datentyp für die Ausgabe',
        },
        wallet_withdraw_cred_crypto_wallet: {
            eng: 'Crypto wallet',
            rus: 'Ваш кошелек',
            ukr: 'Ваш гаманець',
            deu: 'Ihr Geldbeutel',
        },
        wallet_withdraw_cred_info_title: {
            eng: 'Important information',
            rus: 'Важная информация',
            ukr: 'Важлива інформація',
            deu: 'Wichtige Information',
        },
        wallet_withdraw_cred_info_1: {
            eng: 'Transactions are automatic, but are processed manually',
            rus: 'Транзакции происходят автоматически, но обрабатываются вручную',
            ukr: 'Транзакції відбуваються автоматично, але обробляються вручну',
            deu: 'Transaktionen erfolgen automatisch, werden jedoch manuell verarbeitet',
        },
        wallet_withdraw_cred_info_2: {
            eng: 'Processing time takes from 3 to 24 hours for BTC, ETH, USDT',
            rus: 'Время обработки занимает от 3 до 24 часов по направлению BTC, ETH, USDT',
            ukr: 'Час обробки займає від 3 до 24 годин по напрямку BTC, ETH, USDT',
            deu: 'Die Verarbeitungszeit beträgt 3 bis 24 Stunden für BTC, ETH, USDT',
        },
        wallet_withdraw_cred_submit: {
            eng: 'Create a withdrawal request',
            rus: 'Создать заявку на вывод',
            ukr: 'Створити запит на виведення',
            deu: 'Erstellen Sie einen Auszahlungsantrag',
        },
        wallet_withdraw_history_title: {
            eng: 'History of withdrawal requests',
            rus: 'История заявок на вывод',
            ukr: 'Історія запитів на виведення',
            deu: 'Verlauf der Auszahlungsanfragen',
        },
        project_add_type_title: {
            eng: 'Adding a development project',
            rus: 'Добавление девелоперского проекта',
            ukr: 'Додавання девелоперського проекту',
            deu: 'Hinzufügen eines Entwicklungsprojekts',
        },
        project_add_type_text: {
            eng: 'The GREM platform allows tokenization by dividing projects into square meters at various stages of readiness, ranging from a land plot to a fully completed project that is already in operation.',
            rus: 'Платформа GREM позволяет токенизировать путем деления на квадратные метры проекты на разных стадиях готовности, начиная от земельного участка и заканчивая полностью готовым проектом, который уже сдан в эксплуатацию.',
            ukr: 'Платформа GREM дозволяє токенізувати шляхом поділу на квадратні метри проєкти на різних стадіях готовності, починаючи від земельної ділянки і закінчуючи повністю готовим проєктом, який уже здано в експлуатацію.',
            deu: 'Die GREM-Plattform ermöglicht die Tokenisierung durch die Aufteilung von Projekten in Quadratmeter in verschiedenen Fertigstellungsstadien, von einem Grundstück bis hin zu einem vollständig abgeschlossenen Projekt, das bereits in Betrieb ist.',
        },
        project_add_type_land_title: {
            eng: 'Land plot',
            rus: 'Земельный участок',
            ukr: 'Земельна ділянка',
            deu: 'Grundstück',
        },
        project_add_type_land_text: {
            eng: "You can convert a land plot into separate square meters, each of which will be sold separately",
            rus: 'Вы можете преобразовать земельный участок в отдельные квадратные метры, каждый из которых будет продан отдельно',
            ukr: 'Ви можете перетворити земельну ділянку на окремі квадратні метри, кожен з яких буде проданий окремо',
            deu: 'Sie können ein Grundstück in separate Quadratmeter umwandeln, die jeweils separat verkauft werden',
        },
        project_add_type_project_title: {
            eng: "Developer's project",
            rus: 'Девелоперский проект',
            ukr: 'Девелоперський проект',
            deu: 'Entwicklerprojekt',
        },
        project_add_type_project_text: {
            eng: 'Your project is under development and is divided into square meters, each of which can be sold separately',
            rus: 'Ваш проект находится на стадии развития и делиться на квадратные метры, каждый из которых может быть продан отдельно',
            ukr: 'Ваш проект знаходиться на стадії розвитку і ділиться на квадратні метри, кожен з яких може бути проданий окремо',
            deu: 'Ihr Projekt befindet sich in der Entwicklungsphase und wird in Quadratmeter unterteilt, die jeweils separat verkauft werden können',
        },
        project_add_type_unit_title: {
            eng: "Separate units",
            rus: 'Отдельные юниты',
            ukr: 'Окремі юніти',
            deu: 'Separate Einheiten',
        },
        project_add_type_unit_text: {
            eng: "Your project has already been put into operation and can be divided into separate units, each unit has the right of ownership",
            rus: 'Ваш проект уже сдан в эксплуатацию и может быть поделён на отдельные юниты, каждый юнит имеет право собственности',
            ukr: 'Ваш проект вже введений в експлуатацію і може бути поділений на окремі юніти, кожен юніт має право власності',
            deu: 'Ihr Projekt wurde bereits in Betrieb genommen und kann in'
        },
        project_add_type_button: {
            eng: "Add project",
            rus: 'Добавить проект',
            ukr: 'Додати проект',
            deu: 'Projekt hinzufügen',
        },
        project_add_init_button: {
            eng: 'Submit',
            rus: 'Отправить',
            ukr: 'Відправити',
            deu: 'Einreichen',
        },
        project_add_init_text: {
            eng: 'To add your project to the GREM platform, you need to:',
            rus: 'Для добавления вашего проекта на платформу GREM необходимо:',
            ukr: 'Для додавання вашого проекту на платформу GREM необхідно:',
            deu: 'Um Ihr Projekt zur GREM-Plattform hinzuzufügen, müssen Sie:',
        },
        project_add_init_approved_title: {
            eng: 'Congratulations! Your documents have passed verification',
            rus: 'Поздравляем! Ваши документы прошли проверку',
            ukr: 'Вітаємо! Ваші документи пройшли перевірку',
            deu: 'Herzlichen Glückwunsch! Ihre Dokumente wurden überprüft',
        },
        project_add_init_approved_text: {
            eng: "You can proceed to add a project to the platform, successful tokenizations!",
            rus: 'Можете переходить к добавлению проекта на платформу, удачных токенизаций!',
            ukr: "Можете переходити до додавання проекту на платформу, успішних токенізацій!",
            deu: "Sie können mit dem Hinzufügen eines Projekts zur Plattform fortfahren, erfolgreiche Tokenisierungen!",
        },
        project_add_init_pending_title: {
            eng: 'Thank you, your documents have been uploaded and sent for verification',
            rus: 'Спасибо, ваши документы загружены и отправлены на проверку',
            ukr: 'Дякуємо, ваші документи завантажені та відправлені на перевірку',
            deu: 'Vielen Dank, Ihre Dokumente wurden hochgeladen und zur Überprüfung gesendet',
        },
        project_add_init_pending_text_1: {
            eng: 'The verification process takes up to 24 hours',
            rus: 'Проверка документов занимает до 24 часов',
            ukr: 'Перевірка документів займає до 24 годин',
            deu: 'Der Überprüfungsprozess dauert bis zu 24 Stunden',
        },
        project_add_init_pending_text_2: {
            eng: 'After passing the verification, you will receive a notification to your email',
            rus: 'После прохождения проверки вы получите уведомление на свою электронную почту',
            ukr: 'Після проходження перевірки ви отримаєте сповіщення на свою електронну пошту',
            deu: 'Nach Bestehen der Überprüfung erhalten Sie eine Benachrichtigung an Ihre E-Mail-Adresse',
        },
        project_add_init_rejected_title: {
            eng: 'Unfortunately, your documents did not pass verification',
            rus: 'К сожалению, ваши документы не прошли проверку',
            ukr: 'На жаль, ваші документи не пройшли перевірку',
            deu: 'Leider haben Ihre Dokumente die Überprüfung nicht bestanden',
        },
        project_add_init_rejected_text_1: {
            eng: 'During the verification process, some discrepancies were found in the data you provided. The GREM platform strives to maintain a high standard of quality. For any questions, you can contact the',
            rus: 'В процессе проверки выявлены некоторые несоответствия в предоставленных вами данных. Платформа GREM старается обеспечить высокий стандарт качества. По любым вопросам вы можете обратиться в',
            ukr: 'Під час перевірки виявлені деякі розбіжності в наданих вами даних. Платформа GREM прагне забезпечити високий стандарт якості. З будь-якими питаннями ви можете звернутися в',
            deu: 'Während des Überprüfungsprozesses wurden einige Unstimmigkeiten in den von Ihnen bereitgestellten Daten festgestellt. Die GREM-Plattform strebt danach, einen hohen Qualitätsstandard zu gewährleisten. Bei Fragen können Sie sich an den',
        },
        project_add_init_rejected_text_2: {
            eng: 'customer service',
            rus: 'службу поддержки клиентов',
            ukr: 'службу підтримки клієнтів',
            deu: 'Kundendienst',
        },
        project_add_init_rejected_alert_title: {
            eng: 'We recommend fixing the errors listed and re-uploading the documents',
            rus: 'Рекомендуем исправить перечисленные ошибки и повторно загрузить документы',
            ukr: 'Рекомендуємо виправити перераховані помилки та повторно завантажити документи',
            deu: 'Wir empfehlen, die aufgeführten Fehler zu beheben und die Dokumente erneut hochzuladen',
        },
        project_add_init_form_nda_file: {
            eng: 'Non-disclosure agreement (NDA)',
            rus: 'Соглашение о неразглашении (NDA)',
            ukr: 'Угода про нерозголошення (NDA)',
            deu: 'Geheimhaltungsvereinbarung (NDA)',
        },
        project_add_init_form_nda_text_1: {
            eng: 'Sign',
            rus: 'Подписать',
            ukr: 'Підписати',
            deu: 'Unterschreiben',
        },
        project_add_init_form_nda_text_2: {
            eng: 'non-disclosure agreement (NDA).',
            rus: 'соглашение о неразглашении (NDA).',
            ukr: 'угоду про нерозголошення (NDA).',
            deu: 'Geheimhaltungsvereinbarung (NDA).',
        },
        project_add_init_form_nda_text_3: {
            eng: 'Download and sign the document, indicating the required data',
            rus: 'Скачайте и подпишите документ, указав в нём необходимые данные',
            ukr: 'Завантажте та підпишіть документ, вказавши в ньому необхідні дані',
            deu: 'Laden Sie das Dokument herunter und unterschreiben Sie es, indem Sie die erforderlichen Daten angeben',
        },
        project_add_init_form_nda_pattern: {
            eng: 'Download NDA template',
            rus: 'Скачать шаблон NDA',
            ukr: 'Завантажити шаблон NDA',
            deu: 'NDA-Vorlage herunterladen',
        },
        project_add_init_form_nda_formats: {
            eng: 'Available formats for sending: PDF, PNG, JPEG, DOCX and others, up to 20 mb',
            rus: 'Доступные форматы для отправки: PDF, PNG, JPEG, DOCX и другие, размером до 20 мб',
            ukr: 'Доступні формати для відправки: PDF, PNG, JPEG, DOCX та інші, розміром до 20 мб',
            deu: 'Verfügbare Formate zum Senden: PDF, PNG, JPEG, DOCX und andere, bis zu 20 MB',
        },

        project_list_my_title: {
            eng: 'My investment projects',
            rus: 'Мои инвестиционные проекты',
            ukr: 'Мої інвестиційні проекти',
            deu: 'Meine Investitionsprojekte',
        },
        project_list_add: {
            eng: 'Add project',
            rus: 'Добавить проект',
            ukr: 'Додати проект',
            deu: 'Projekt hinzufügen',
        },
        project_list_my_not_found: {
            eng: 'You currently do not have any personal projects. You can add a project at any time.',
            rus: 'У вас пока нет личных проектов. Вы можете добавить проект в любое время.',
            ukr: 'У вас поки що немає особистих проектів. Ви можете додати проект у будь-який час.',
            deu: 'Sie haben derzeit keine persönlichen Projekte. Sie können jederzeit ein Projekt hinzufügen.',
        },
        project_list_title: {
            eng: 'Overview of all investment projects',
            rus: 'Обзор всех инвестиционных проектов',
            ukr: 'Огляд всіх інвестиційних проектів',
            deu: 'Übersicht aller Investitionsprojekte',
        },
        project_list_not_found: {
            eng: 'Nothing was found for your request',
            rus: 'По вашему запросу ничего не найдено',
            ukr: 'За вашим запитом нічого не знайдено',
            deu: 'Für Ihre Anfrage wurde nichts gefunden',
        },
        project_list_filters_country: {
            eng: 'Country',
            rus: 'Страна',
            ukr: 'Країна',
            deu: 'Land',
        },
        project_list_filters_name: {
            eng: 'Object name',
            rus: 'Название объекта',
            ukr: 'Назва об\'єкта',
            deu: 'Objektname',
        },
        project_list_filters_submit: {
            eng: 'Search',
            rus: 'Поиск объектов',
            ukr: 'Пошук об\'єктів',
            deu: 'Objektsuche',
        },
        project_list_item_status_moderation: {
            eng: 'Moderation passed',
            rus: 'Модерация пройдена',
            ukr: 'Модерація пройдена',
            deu: 'Moderation bestanden',
        },
        project_list_item_status_moderation_failed: {
            eng: "Moderation didn't pass",
            rus: 'Модерация не пройдена',
            ukr: 'Модерація не пройдена',
            deu: 'Moderation fehlgeschlagen',
        },
        project_list_item_status_moderation_success: {
            eng: 'Moderation passed',
            rus: 'Модерация пройдена',
            ukr: 'Модерація пройдена',
            deu: 'Moderation bestanden',
        },
        project_list_item_status_financing: {
            eng: 'Fundraising',
            rus: 'Сбор финансирования',
            ukr: 'Збір фінансування',
            deu: 'Fundraising',
        },
        project_list_item_status_financing_closed: {
            eng: 'Fundraising is closed',
            rus: 'Сбор закрыт',
            ukr: 'Збір закрито',
            deu: 'Fundraising ist geschlossen',
        },
        project_list_item_contract_duration: {
            eng: 'Contract duration',
            rus: 'Длит. контракта',
            ukr: 'Трив. контракту',
            deu: 'Vertragsdauer',
        },
        project_list_item_yield: {
            eng: 'Yield',
            rus: 'Доходность',
            ukr: 'Дохідність',
            deu: 'Ausbeute',
        },
        project_list_item_m2_cost: {
            eng: 'Cost of M2 from',
            rus: 'Стоимость М2 от',
            ukr: 'Вартість М2 від',
            deu: 'Kosten für M2 von',
        },
        year_s: {
            eng: 'year(s)',
            rus: 'год(а)',
            ukr: 'рік(и)',
            deu: 'Jahr(e)',
        },
        project_list_item_funding_raised: {
            eng: 'Funding raised',
            rus: 'Собрано финансирования',
            ukr: 'Зібрано фінансування',
            deu: 'Fördermittel eingesammelt',
        },
        project_list_item_funding_progress: {
            eng: 'Progress',
            rus: 'Прогресс',
            ukr: 'Прогрес',
            deu: 'Fortschritt',
        },
        share_success: {
            eng: 'Link copied to clipboard',
            rus: 'Ссылка скопирована в буфер обмена',
            ukr: 'Посилання скопійовано в буфер обміну',
            deu: 'Link in die Zwischenablage kopiert',
        },
        context_list_item_edit: {
            eng: 'Edit',
            rus: 'Редактировать',
            ukr: 'Редагувати',
            deu: 'Bearbeiten',
        },
        context_list_item_share: {
            eng: 'Share',
            rus: 'Поделиться',
            ukr: 'Поділитися',
            deu: 'Teilen',
        },
        project_item_tab_wallet: {
            eng: 'Project wallet',
            rus: 'Кошелёк проекта',
            ukr: 'Гаманець проекту',
            deu: 'Projekt Brieftasche',
        },
        project_item_tab_contracts: {
            eng: 'Smart contracts',
            rus: 'Smart-контракты',
            ukr: 'Smart-контракти',
            deu: 'Intelligente Verträge',
        },
        project_item_tab_analytics: {
            eng: 'Analytics',
            rus: 'Аналитика',
            ukr: 'Аналітика',
            deu: 'Analytik',
        },
        project_item_tab_overview: {
            eng: 'Overview',
            rus: 'Информация о проекте',
            ukr: 'Інформація про проект',
            deu: 'Übersicht',
        },
        project_item_tab_landing: {
            eng: 'Project landing',
            rus: 'Лендинг проекта',
            ukr: 'Лендінг проекту',
            deu: 'Projektlandung',
        },
        project_item_tab_documents: {
            eng: 'Documents',
            rus: 'Документы',
            ukr: 'Документи',
            deu: 'Dokumente',
        },
        project_item_moderation_active_title: {
            eng: 'Your project is under moderation',
            rus: 'Ваш проект находится на модерации',
            ukr: 'Ваш проект знаходиться на модерації',
            deu: 'Ihr Projekt wird moderiert',
        },
        project_item_moderation_active_text_1: {
            eng: 'Creating contracts will be available after the Expert Council approves your project and the corresponding tokens are credited to the wallet of this project.',
            rus: 'Создание контрактов станет доступным после того, как Экспертный совет одобрит ваш проект и на кошелёк данного проекта будут начислены соответствующие токены.',
            ukr: 'Створення контрактів стане доступним після того, як Експертна рада схвалить ваш проект та на гаманець цього проекту будуть нараховані відповідні токени.',
            deu: 'Das Erstellen von Verträgen wird möglich, nachdem der Expertenrat Ihr Projekt genehmigt und die entsprechenden Token auf das Wallet dieses Projekts gutgeschrieben hat.',
        },
        project_item_moderation_active_text_2: {
            eng: 'Create contracts by allocating tokens to them. Each smart contract is verified by the Expert Council before it becomes available for purchase by other users of the platform.',
            rus: 'Создавайте контракты, распределяя токены по ним. Каждый smart-контракт проходит проверку Экспертным советом перед тем как станет доступным для покупки другими пользователями платформы.',
            ukr: 'Створюйте контракти, розподіляючи токени за ними. Кожен smart-контракт проходить перевірку Експертною радою перед тим як стане доступним для купівлі іншими користувачами платформи.',
            deu: 'Erstellen Sie Verträge, indem Sie ihnen Token zuweisen. Jeder intelligente Vertrag wird vom Expertenrat verifiziert, bevor er für andere Nutzer der Plattform zum Kauf verfügbar ist.',
        },
        project_item_moderation_failed_title: {
            eng: 'Your project did not pass the Expert Council check',
            rus: 'Ваш проект не прошёл проверку Экспертного совета',
            ukr: 'Ваш проект не пройшов перевірку Експертної ради',
            deu: 'Ihr Projekt hat die Überprüfung des Expertenrats nicht bestanden',
        },
        project_item_moderation_failed_text: {
            eng: 'Denied for cause:',
            rus: 'Отказано по причине: ',
            ukr: 'Відмовлено з причини:',
            deu: 'Aus wichtigem Grund abgelehnt:',
        },
        project_item_moderation_failed_edit: {
            eng: 'Edit draft',
            rus: 'Редактировать черновик',
            ukr: 'Редагувати чернетку',
            deu: 'Entwurf bearbeiten',
        },
        large: {
            eng: 'Large',
            rus: 'Большой',
            ukr: 'Великий',
            deu: 'Groß',
        },
        small: {
            eng: 'Small',
            rus: 'Маленький',
            ukr: 'Малий',
            deu: 'Klein',
        },
        lead_filter_company: {
            eng: 'Company',
            rus: 'Компания',
            ukr: 'Компанія',
            deu: 'Unternehmen',
        },
        lead_filter_person: {
            eng: 'Private individual',
            rus: 'Частное лицо',
            ukr: 'Приватна особа',
            deu: 'Privatperson',
        },
        lead_commission_agreement: {
            eng: 'Commission Agreement',
            rus: 'Договор о комиссии',
            ukr: 'Договір про комісію',
            deu: 'Kommissionsvereinbarung',
        },
        lead_alert_commission_agreement: {
            eng: 'To be able to send leads to an object with active contract, enter into a  ',
            rus: 'Чтобы получить все возможности платформы, заключите ',
            ukr: 'Щоб отримати всі можливості платформи, укладіть ',
            deu: 'Um alle Funktionen der Plattform zu nutzen, schließen Sie ',
        },
        lead_select_user_title: {
            eng: 'Select a community member to transfer the lead',
            rus: 'Выберите участника сообщества, для передачи лида',
            ukr: 'Виберіть учасника спільноти для передачі ліда',
            deu: 'Wählen Sie ein Mitglied der Gemeinschaft aus, um den Lead zu übergeben',
        },
        lead_select_user: {
            eng: 'Select user',
            rus: 'Выберите пользователя',
            ukr: 'Виберіть користувача',
            deu: 'Benutzer auswählen',
        },
        lead_write_info: {
            eng: 'Fill in the information about the lead being transferred',
            rus: 'Заполните информацию о передаваемом лиде',
            ukr: 'Заповніть інформацію про передаваного ліда',
            deu: 'Geben Sie die Informationen zum übergebenen Lead ein',
        },
        send_lead: {
            eng: 'Send lead',
            rus: 'Передать лида',
            ukr: 'Передати лід',
            deu: 'Lead senden'
        },
        FIO: {
            eng: 'Full Name',
            rus: 'ФИО',
            ukr: 'ПІБ',
            deu: 'Vollständiger Name',
        },
        company_name: {
            eng: 'Company Name',
            rus: 'Название компании',
            ukr: 'Назва компанії',
            deu: 'Firmenname',
        },
        project_item_: {
            eng: '',
            rus: '',
            ukr: '',
            deu: '',
        },
    },

    get: function (key, lang) {
        lang = languageStore.activeLanguage;
        let dictionary = this.dictionary[key]
            ? this.dictionary[key]
            : this.dictionary['Key_not_founded'];
        let dictionaryItem = dictionary[lang]
            ? dictionary[lang]
            : this.dictionary['Key_not_founded']['eng'];

        return dictionaryItem;
    },
};

export default globalDictionary;
