import React from 'react';
import languageStore from "../languageStore";

const formErrorDictionary = {
    dictionary: {
        empty_field: {
            eng: 'Field is empty',
            rus: 'Поле пусто',
            ukr: 'Поле порожнє',
            deu: 'Feld ist leer',
        },
        email: {
            eng: 'Invalid email',
            rus: 'Не валидный E-mail',
            ukr: 'Неправильний електронний адрес',
            deu: 'Ungültige E-Mail',
        },
        password: {
            eng: 'Password must be at least 8 characters',
            rus: 'Пароль должен содержать не менее 8 символов',
            ukr: 'Пароль повинен містити щонайменше 8 символів',
            deu: 'Das Passwort muss mindestens 8 Zeichen lang sein',
        },
        password_mismatch: {
            eng: 'Password mismatch',
            rus: 'Пароли не совпадают',
            ukr: 'Паролі не співпадають',
            deu: 'Passwörter stimmen nicht überein',
        },
        accept_terms: {
            eng: 'Read the Terms and Conditions',
            rus: 'Ознакомьтесь с Положениями и условиями',
            ukr: 'Ознайомтеся з Положеннями та умовами',
            deu: 'Allgemeine Geschäftsbedingungen lesen',
        },
        accept_privacy_policy: {
            eng: 'Read the Privacy Policy',
            rus: 'Ознакомьтесь с Политикой конфиденциальности',
            ukr: 'Ознайомтеся з Політикою конфіденційності',
            deu: 'Lesen Sie die Datenschutzrichtlinie',
        },
        phone: {
            eng: 'Invalid phone number',
            rus: 'Неверный номер телефона',
            ukr: 'Неправильний номер телефону',
            deu: 'Ungültige Telefonnummer',
        },
        wrong_language: {
            eng: 'Enter data in English',
            rus: 'Введите данные на Английском',
            ukr: 'Введіть дані Англійською',
            deu: 'Geben Sie Daten in Englisch ein',
        },
        form: {
            eng: 'Form validation error',
            rus: 'Ошибка валидации формы',
            ukr: 'Помилка валідації форми',
            deu: 'Formularvalidierungsfehler',
        },
        max_range: {
            eng: 'The maximum value must be equal to or greater than',
            rus: 'Максимальное значение должно равняться или быть больше чем',
            ukr: 'Максимальне значення повинно дорівнювати або бути більше ніж',
            deu: 'Der Höchstwert muss gleich oder größer als',
        },
        min_range: {
            eng: 'The minimum value must be equal to or less than',
            rus: 'Минимальное значение должно равняться или быть меньше чем',
            ukr: 'Мінімальне значення повинно дорівнювати або бути менше ніж',
            deu: 'Der Mindestwert muss gleich oder kleiner als',
        },

        empty_translate:{
            eng: '',
            rus: '',
            ukr: '',
            deu: '',
        },
        Key_not_founded: {
            eng: 'Error: Text not found',
            rus: 'Ошибка: Текст не найден',
            ukr: 'Помилка: Текст не знайдено',
            deu: 'Fehler: Text nicht gefunden',
        },
    },
    get: function (key, lang) {
        lang = languageStore.activeLanguage;
        let dictionary = this.dictionary[key]
            ? this.dictionary[key]
            : this.dictionary['Key_not_founded'];
        let dictionaryItem = dictionary[lang]
            ? dictionary[lang]
            : this.dictionary['Key_not_founded']['eng'];

        return dictionaryItem;
    },
}

export default formErrorDictionary;