import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../Providers";
import { GremService } from "services";
import { setUserInfoToLocalStorage } from "utils";

const LinkedinCallback = () => {
    const gremService = new GremService();
    const navigate = useNavigate();
    const { auth } = useStore();

    const authViaLinkedin = async (code, returnedState) => {
        try {

            const savedState = sessionStorage.getItem('linkedin_oauth_state');

            if (savedState !== returnedState) {
                throw new Error("State mismatch! Potential CSRF detected.");
            }

            const result = await gremService.authLinkedin(code);
            const user = result.data;
            if (user) {
                auth.user = user;
                setUserInfoToLocalStorage(user);
                navigate('/my/profile/info');
            }
        } catch (error) {
            console.error(error);
            navigate('/login');
        }
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const returnedState = urlParams.get('state'); // Получаем state из URL

        if (code && returnedState) {
            authViaLinkedin(code, returnedState);
        } else {
            navigate('/login');
        }
    }, []);


    return <div>Загрузка...</div>;
};

export default LinkedinCallback;
