import PropTypes from 'prop-types';
import {Draggable, Droppable} from '@hello-pangea/dnd';

import {Paper, Stack, Box} from '@mui/material';

import KanbanTaskItem from './kanban-task-item';
import KanbanColumnToolBar from './kanban-column-tool-bar';
import leadStatuses from '../../../assets/translations/leadStatuses';


export default function KanbanColumn({column, tasks, isTaskMove, index,sx}) {
    return (
        <Draggable draggableId={column.id} index={index} isDragDisabled={true}>
            {(provided, snapshot) => (
                <Paper
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    sx={{
                        backgroundColor: '#F4F6F8',
                        minWidth: {
                            xs: '100%',
                            sm: sx?.width || '300px'
                        },
                        width: sx?.width || '300px',
                        borderRadius: '10px'
                    }}
                >
                    <Stack {...provided.dragHandleProps}>
                        <KanbanColumnToolBar columnName={leadStatuses.get(column.name)} columnId={column.id}
                                             leadsCount={column.taskIds.length}/>
                        <Stack sx={{
                            mt: '15px',
                            pr: '4px',
                        }}>
                            <Droppable droppableId={column.id} type="TASK">
                                {(dropProvided) => (
                                    <Stack
                                        ref={dropProvided.innerRef}
                                        {...dropProvided.droppableProps}
                                        spacing={'15px'}
                                        sx={{
                                            height: '52vh',
                                        }}
                                    >
                                        {column.taskIds.map((taskId, taskIndex) => (
                                            <Box sx={{
                                                padding: '0 4px 0 15px'
                                            }}>
                                                <KanbanTaskItem
                                                    key={taskId}
                                                    index={taskIndex}
                                                    task={tasks[taskId]}
                                                    isTaskMove={isTaskMove}
                                                />
                                            </Box>
                                        ))}
                                        {dropProvided.placeholder}
                                    </Stack>
                                )}
                            </Droppable>
                        </Stack>
                    </Stack>
                </Paper>
            )}
        </Draggable>
    );
}

KanbanColumn.propTypes = {
    column: PropTypes.object,
    index: PropTypes.number,
    tasks: PropTypes.object,
    isTaskMove: PropTypes.bool,
};
